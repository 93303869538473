import { useEffect } from 'react'
import { Form, Field, useFormik, FormikProvider } from 'formik'
import { Card, CardContent, CardHeader, Divider, Grid, FormControl } from '@material-ui/core'
import {
  HeaderCardTitle,
  CustomFormikDatePicker,
  TextFieldDebounceFormik,
  SearchButton,
  CustomAutoCompleteSelectFormik,
} from '../../../../core/components'
import { useTranslation } from 'react-i18next'
import { useProgramOwners } from '../hooks'
import { generateFilterGraphql } from '../../../../core'

export type JockeyOrKennelFiltersType = {
  name: string
}

interface JockeyOrKennelFilterIProps {
  onFilterChange: (filter: JockeyOrKennelFiltersType) => void
}

export const JockeyOrKennelFilters = ({ onFilterChange }: JockeyOrKennelFilterIProps) => {
  const { t: translationLabel } = useTranslation('Label')
  const { results: owners, loading, getProgramOwners } = useProgramOwners()

  useEffect(() => {
    const customFilters = {
      and: [
        {
          name: { ...generateFilterGraphql('', 'contains') },
        },
      ],
    }

    getProgramOwners({ where: customFilters, skip: 0, take: 500 })
  }, [])

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    onSubmit: onFilterChange,
  })

  return (
    <Card>
      <CardHeader component={() => <HeaderCardTitle title={translationLabel('CRITERIOS_DE_BUSQUEDA')} />} />
      <Divider />
      <CardContent>
        <FormikProvider value={formik}>
          <Form>
            <Grid container item alignItems="center" spacing={3} xs={12} sm={12}>
              <Grid item xs={12} sm={12} md={5} lg={5}>
                <FormControl fullWidth>
                  <Field
                    component={TextFieldDebounceFormik}
                    type="text"
                    label={translationLabel('NOMBRE')}
                    name="name"
                    fullWidth
                  />
                </FormControl>
              </Grid>
              {/* <Grid item xs={12} sm={12} md={5} lg={5}>
                <FormControl fullWidth>
                  <Field
                    component={CustomAutoCompleteSelectFormik}
                    keyValue={'id'}
                    keyLabel={'name'}
                    data={owners.results}
                    label={translationLabel('PROPIETARIO')}
                    name="ownerId"
                    fullWidth
                    type="text"
                  />
                </FormControl>
              </Grid> */}
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <SearchButton onClick={formik.submitForm} />
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </CardContent>
    </Card>
  )
}
