import React from 'react'

import {
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@material-ui/core'
import { InfoCard } from '../../../core/components'
import { Dictionary } from '../../../core/models'
import { formatDateTime } from '../../../core/services/dateFunctions'
import { formatAsCurrency } from '../../../core/services/formatting'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

// Hooks
import { useTranslation } from 'react-i18next'
import { Ticket } from './models'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    heading: {
      fontSize: theme.typography.pxToRem(16),
      // fontWeight: theme.typography.fontWeightBold,
    },
  }),
)

type TicketInformationProps = {
  model: Ticket
}

interface BetOverpassInfo extends Dictionary {
  ESTATUS?: string
  LOCALIDAD?: string
}

interface IfRiskInfo extends Dictionary {
  APOSTADO_IF?: string
}

export const TicketInformation = ({ model }: TicketInformationProps) => {
  const classes = useStyles()
  const { t: translation } = useTranslation('Label')
  const betOverpassInfo: BetOverpassInfo = {}
  const ifRiskInfo: IfRiskInfo = {}

  const {
    id,
    typeTicket,
    state,
    risk,
    iFRisk,
    iFRiskWin,
    riskWin,
    possibleWin,
    salesTime,
    ticketMasterConditionDate,
    ticketMasterConditionLocation,
    ticketMasterConditionEstatus,
    expireDate,
    locationName,
    currencyAcronym,
    salesPeople,
  } = model

  const possibleWinCalculated = possibleWin !== undefined && iFRiskWin !== undefined ? possibleWin + iFRiskWin : 0
  const isAWinningTicket = state === 'GANADOR'
  const isTicketPaidOrNull = ticketMasterConditionEstatus === 'PAGADO' || ticketMasterConditionEstatus === 'NULO'
  const isTicketPaid = ticketMasterConditionEstatus === 'PAGADO'

  // Show status if ticket is paid or null
  if (isTicketPaidOrNull === true) {
    betOverpassInfo.ESTATUS = `${ticketMasterConditionEstatus} ${
      isTicketPaid ? formatDateTime(ticketMasterConditionDate) : ''
    }`
  }
  // Show location if ticket is paid
  if (isTicketPaid === true) {
    betOverpassInfo.LOCALIDAD = ticketMasterConditionLocation
  }
  // Show if type ticket is IF
  if (typeTicket === 'IF') {
    ifRiskInfo.APOSTADO_IF = `${currencyAcronym} ${formatAsCurrency(iFRisk, 0)} ${translation(
      'A_GANAR',
    )} ${formatAsCurrency(iFRiskWin, 0)}`
  }

  return (
    <div className={classes.root}>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography className={classes.heading}>{`${translation('TICKET_NO')} ${id} ${translation(
            typeTicket as string,
          )} - ${translation(state as string)} ${formatAsCurrency(risk, 0)} ${translation(
            'A_GANAR',
          )} ${formatAsCurrency(riskWin, 0)} `}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid lg={6} item xs={12} sm={6}>
              <InfoCard
                title={translation('VENTA_Y_ESTATUS')}
                fields={{
                  CLIENTE: salesPeople,
                  LOCALIDAD: locationName,
                  HORA: `${formatDateTime(salesTime)} ${isAWinningTicket ? formatDateTime(expireDate) : ''}`,
                  ...betOverpassInfo,
                }}
              />
            </Grid>
            <Grid lg={6} item xs={12} sm={6}>
              <InfoCard
                title={translation('INFORMACION_DEL_TICKET')}
                fields={{
                  NUMERO: id,
                  TIPO_TICKET: `${translation(typeTicket as string)} - ${translation(state as string)}`,
                  APOSTADO: `${currencyAcronym} ${formatAsCurrency(risk, 0)} ${translation(
                    'A_GANAR',
                  )} ${formatAsCurrency(riskWin, 0)}`,
                  POSIBLE_PAGO: formatAsCurrency(possibleWinCalculated, 0),
                  ...ifRiskInfo,
                }}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}
