import { Ticket } from './models'
import { gql, useLazyQuery } from '@apollo/client'
import { Metadata, ResultsList, SearchGraphql } from '../../../core/models'
import { useState, useEffect, useCallback } from 'react'

const GET_TICKETS = gql`
  query getTicket($where: TicketFilterInput!, $skip: Int, $take: Int) {
    tickets(where: $where, skip: $skip, take: $take) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      items {
        id
        enterpriseId
        expireDate
        possibleWin
        risk
        riskWin
        salesDate
        salesTime
        state
        typeTicket
        currency
      }
    }
  }
`

interface TicketsData {
  tickets: {
    totalCount: number
    items: Ticket[]
  }
}

export const useTickets = () => {
  const [getResults, { loading, error, data }] = useLazyQuery<TicketsData>(GET_TICKETS, {
    fetchPolicy: 'network-only',
  })

  const [results, setResults] = useState<ResultsList<Ticket, Metadata>>({
    results: [],
    metadata: {},
  })

  const getTickets = useCallback(
    ({ where, take, skip }: SearchGraphql) => {
      getResults({ variables: { where, take, skip } })
    },
    [getResults],
  )

  useEffect(() => {
    setResults({
      results: data?.tickets.items || [],
      metadata: {
        Count: data?.tickets.totalCount,
      },
    })
  }, [data, loading])

  return { loading, error, results, getTickets }
}
