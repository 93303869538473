import React from 'react'
import { Card, CardContent } from '@material-ui/core'

export const AgencyOperationsResults = () => {
  return (
    <Card>
      <CardContent>AgencyOperationsResults</CardContent>
    </Card>
  )
}
