import { createStyles, makeStyles } from '@material-ui/core'
import { ArrowUpward, ArrowDownward } from '@material-ui/icons'

type ProgressArrowProps = {
  value: number
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      textAlign: 'right',
    },
    arrow: {
      backgroundColor: ({ value }: ProgressArrowProps) => `${value < 0 ? '#ff9c9c' : '#72e5ff'}`,
      width: ({ value }: ProgressArrowProps) => `${value}%`,
      height: '20px',
      justifyContent: 'center',
      lineHeight: '20px',
    },
    upIcon: {
      fontSize: '1rem',
      marginBottom: '-3px',
    },
  }),
)

export const PercentArrow = ({ value }: ProgressArrowProps) => {
  const classes = useStyles({ value })

  return (
    <div className={classes.root}>
      {`${value}`}
      {value > 0 && <ArrowUpward className={classes.upIcon} htmlColor={'#06ef0f'} />}
      {value < 0 && <ArrowDownward className={classes.upIcon} htmlColor={'#ef3d06'} />}
    </div>
  )
}
