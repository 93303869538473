import { gql, useLazyQuery, useQuery } from '@apollo/client'
import { Metadata, ResultsList, SearchGraphql } from '../../../../../core/models'
import { EventProgram } from '../../models'

const GET_TRAINERS = gql`
  query GetProgramTrainers($where: ProgramTrainerFilterInput!, $skip: Int, $take: Int) {
    programTrainers(where: $where, skip: $skip, take: $take, order: { name: ASC }) {
      items {
        id
        name
        isActive
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`

interface TrainersData {
  programTrainers: {
    totalCount: number
    items: EventProgram[]
  }
}

export const useProgramTrainers = () => {
  const [getResults, { loading, error, data }] = useLazyQuery<TrainersData>(GET_TRAINERS, {
    fetchPolicy: 'network-only',
  })

  const getProgramTrainers = ({ where, take, skip }: SearchGraphql) => {
    getResults({
      variables: {
        where: { ...where },
        skip: skip ? skip : 0,
        take: take ? take : 100,
      },
    })
  }

  const results: ResultsList<EventProgram, Metadata> = {
    results: data?.programTrainers.items || [],
    metadata: {
      Count: data?.programTrainers.totalCount,
    },
  }

  return { getProgramTrainers, loading, error, results }
}
