import React, { useState } from 'react'
import Chart from 'react-google-charts'
import { Select, Grid, MenuItem, makeStyles, createStyles } from '@material-ui/core'
import { GoogleChartWrapperChartType } from 'react-google-charts/dist/types'
import { useTranslation } from 'react-i18next'
import { EmptyData } from '..'

export interface BarOptions {
  title?: string
  chartArea?: object
  colors?: Array<string>
  hAxis?: object
  vAxis?: object
  chart?: object
}

export type CustomBarChartProps = {
  data: Array<Array<any>> | undefined
  chartType?: GoogleChartWrapperChartType
  options?: BarOptions
  width?: string
  height?: string
}

const useStyles = makeStyles(() =>
  createStyles({
    select: {
      marginBottom: '15px',
    },
  }),
)

export const CustomBarChart = ({ data, chartType, options, width, height }: CustomBarChartProps) => {
  const classes = useStyles()

  const [selectedChart, setSelectedChart] = useState<GoogleChartWrapperChartType>(chartType || 'Bar')
  const { t: translationLabel } = useTranslation('Label')
  const changeChartType = (event: React.ChangeEvent<{ value: unknown }>) => {
    const type = event.target.value !== undefined ? (event.target.value as GoogleChartWrapperChartType) : 'Bar'
    setSelectedChart(type)
  }

  return (
    <Grid container spacing={2}>
      <Grid item lg={12} sm={12} xs={12}>
        <Select
          className={classes.select}
          labelId="chart-select-label"
          id="chart-select"
          value={selectedChart}
          onChange={changeChartType}
        >
          <MenuItem value={'Bar'}>{translationLabel('BAR')}</MenuItem>
          <MenuItem value={'BarChart'}>{translationLabel('BAR_CHART')}</MenuItem>
          <MenuItem value={'Line'}>{translationLabel('LINE')}</MenuItem>
          <MenuItem value={'LineChart'}>{translationLabel('LINE_CHART')}</MenuItem>
          <MenuItem value={'BubbleChart'}>{translationLabel('BUBBLE_CHART')}</MenuItem>
          <MenuItem value={'ScatterChart'}>{translationLabel('SCATTER_CHART')}</MenuItem>
          <MenuItem value={'Table'}>{translationLabel('TABLE')}</MenuItem>
        </Select>
      </Grid>
      {data !== undefined ? (
        <Chart
          width={width || '100%'}
          height={height || '450px'}
          chartType={selectedChart}
          loader={<div>Loading Chart</div>}
          data={data}
          options={
            { ...options } || {
              // Material design options
              chart: {
                title: 'Company Performance',
                subtitle: 'Sales, Expenses, and Profit: 2014-2017',
              },
            }
          }
          // For tests
          rootProps={{ 'data-testid': '2' }}
        />
      ) : (
        <EmptyData />
      )}
    </Grid>
  )
}
