import React from 'react'
import { Box, Card, makeStyles, createStyles, Theme, Typography, Avatar, Grid } from '@material-ui/core'
import AvatarGroup from '@material-ui/lab/AvatarGroup'
import { ArrowDownward, ArrowUpward } from '@material-ui/icons'

// Hooks
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardStyle: {
      padding: theme.spacing(2),
      width: '100%',
      borderBottom: '0.5rem solid #8cd0eb',
    },
    avatarGroup: {
      justifyContent: 'flex-end',
      // display: 'unset',
    },
    groupName: {
      textAlign: 'end',
      marginTop: 'inherit',
      color: '#999999',
    },
    avatar: {
      backgroundColor: '#8cd0eb',
    },
  }),
)
interface IProps {
  value: string | number
  Icon: any
  titleValue: string
  groupName: string
  percentage?: number
  percentageTitle?: string
}

export const CustomAvatarGroupCard = ({ value, titleValue, Icon, groupName, percentage, percentageTitle }: IProps) => {
  const classes = useStyles()
  const { t: translation } = useTranslation('Label')

  return (
    <Card className={classes.cardStyle}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Typography
            style={{
              color: '#9e9e9e',
              fontSize: '14px',
              marginBottom: 5,
            }}
          >
            {translation(titleValue)}
          </Typography>
          <Typography
            variant="h4"
            component="h3"
            style={{
              marginBottom: 15,
              fontSize: '1.5rem',
              fontWeight: 500,
            }}
          >
            {value}
          </Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item lg={12} sm={12}>
            <AvatarGroup max={4} className={classes.avatarGroup}>
              <Avatar className={classes.avatar}>
                <Icon />
              </Avatar>
              <Avatar className={classes.avatar}>
                <Icon />
              </Avatar>
              <Avatar className={classes.avatar}>
                <Icon />
              </Avatar>
            </AvatarGroup>
          </Grid>
          <Grid item sm={12} className={classes.groupName}>
            <span>{translation(groupName)}</span>
          </Grid>
        </Grid>
      </Box>
      {percentage !== undefined ? (
        <Box
          display="flex"
          alignItems="center"
          style={{
            color: percentage <= 0 ? '#ff0000' : '#20c248',
          }}
        >
          {percentage <= 0 ? <ArrowDownward /> : <ArrowUpward />}

          <Typography
            style={{
              marginRight: '10px',
            }}
          >
            {percentage}%
          </Typography>
          <Typography
            style={{
              color: '#8d8d8d',
              fontSize: '14px',
            }}
          >
            {translation(percentageTitle !== undefined ? percentageTitle : '')}
          </Typography>
        </Box>
      ) : null}
    </Card>
  )
}
