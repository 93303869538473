import React from 'react'
import {
  createStyles,
  makeStyles,
  Theme,
  responsiveFontSizes,
  createMuiTheme,
  ThemeProvider,
} from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import { Toolbar, Grid, Typography, GridSize } from '@material-ui/core'

// My components
import { isMobile } from '../..'

let theme = createMuiTheme()
theme = responsiveFontSizes(theme)

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      boxShadow: 'none !important',
      borderBottom: '0.1px solid #d2d2d2',
      marginBottom: '25px',
    },
    toolbar: {
      padding: 'inherit',
    },
    title: {
      flexGrow: 1,
      fontWeight: 'bold',
    },
    description: {
      flexGrow: 1,
      fontSize: '1.0rem',
      color: '#969696',
    },
    actionButtonGrid: {
      paddingBottom: isMobile() ? '5px' : '0px',
      textAlign: isMobile() ? 'start' : 'end',
    },
    breadCrumbGrid: {
      marginTop: '5px',
    },
  }),
)
export interface AppBreadcrumbsBarProps {
  title: string
  description?: string
  customBreadCrumbPath?: Array<string>
  actions?: React.ReactElement | null
  colTitleSize?: GridSize
  colActionSize?: GridSize
}

export const AppBreadcrumbsBar = (props: AppBreadcrumbsBarProps) => {
  const classes = useStyles()
  const { title, description, actions, colTitleSize = 6, colActionSize = 6 } = props

  return (
    <AppBar position="static" color="transparent" className={classes.root}>
      <Toolbar className={classes.toolbar}>
        <Grid item container>
          <Grid item xs={12} lg={colTitleSize} md={colTitleSize} sm={12}>
            <Grid item xs={12}>
              <ThemeProvider theme={theme}>
                <Typography variant="h5" className={classes.title}>
                  {title}
                </Typography>
              </ThemeProvider>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.description}>{description}</Typography>
            </Grid>
            {/* <Grid item xs={12} className={classes.breadCrumbGrid}>
            <BreadcrumbsApp customPath={props.customBreadCrumbPath} />
          </Grid> */}
          </Grid>
          <Grid item xs={12} lg={colActionSize} md={colActionSize} sm={12} className={classes.actionButtonGrid}>
            {actions}
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}
