import React from 'react'
import CloudOffIcon from '@material-ui/icons/CloudOff'
import { makeStyles, createStyles } from '@material-ui/core'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'grid',
      margin: '0 auto',
    },
    icon: {
      fontSize: '10rem',
      color: 'gainsboro',
    },
  }),
)

export const EmptyData: React.FC<{ text?: string }> = ({ text }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <CloudOffIcon className={classes.icon} />
      <span>{`${text ? text : 'Datos no encontrados'}`}</span>
    </div>
  )
}
