import { useEffect, useState } from 'react'
import moment from 'moment'

import {
  Card,
  CardContent,
  CardHeader,
  CardActions,
  createStyles,
  Divider,
  Grid,
  makeStyles,
  Theme,
  FormControl,
} from '@material-ui/core'

import { HeaderCardTitle } from '../../../core/components'
import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-material-ui'

// My Components
import { SearchButton, ClearButton, LocalizedContentSelect, CustomDatePicker } from '../../../core/components'

// Models
import { GraphqlExpression, SearchGraphql } from '../../../core/models'

// Hooks
import { useLocalizedContent } from '../../../core/hooks/useLocalizedContent'
import { useTranslation } from 'react-i18next'
import { generateFilterGraphql, isDateToday } from '../../../core'
import { isMobile } from '../../../core/services/configurationServices'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardRoot: {
      minWidth: '100%',
    },
    description: {
      fontSize: 15,
      padding: theme.spacing(0.5),
    },
    formControl: {
      minWidth: '100%',
    },
    select: {
      minWidth: '100%',
    },
    textField: {
      minWidth: '100%',
    },
    dates: {
      marginTop: '0px !important',
      width: '100%',
    },
    resetButton: {
      marginLeft: isMobile() ? '0px' : '-20px',
      marginTop: isMobile() ? 'inherit' : '0px',
    },
    searchButton: {
      marginRight: '10px',
    },
    buttonGroup: {
      marginTop: isMobile() ? '10px' : '13px',
      marginLeft: isMobile() ? '10px' : '0px',
    },
    dateValidation: {
      color: '#f44336',
      fontSize: '12px',
    },
    numberMessage: {
      color: '#f44336',
      fontSize: '16px',
    },
  }),
)

export interface ITicketFilters {
  from?: string | Date | undefined
  to?: string | Date | undefined
  ticketCondition?: string
  ticketId?: string | number
  currencyName?: string
}

interface TicketFiltersErrors {
  from?: string
  to?: string
  ticketCondition?: string
  ticketId?: string
}

type TicketFiltersProps = {
  onChange: (params: SearchGraphql) => void
}

// const formSchema = Yup.object().shape({
//   from: Yup.date(),
//   to: Yup.date(),
//   ticketCondition: Yup.string(),
//   ticketId: Yup.number()
//     .min(0, 'El número debe de ser mayor a cero.')
//     .max(99999999, 'El número debe de ser menor o igual a 99999999.'),
// })

export const TicketFilters = ({ onChange }: TicketFiltersProps) => {
  const classes = useStyles()
  const [message, setMessage] = useState('')
  const { t: translation } = useTranslation('Label')

  const [fromDateSelected, setFromDateSelected] = useState<string>(moment().startOf('isoWeek').format('MM-DD-YYYY'))
  const [toDateSelected, setToDateSelected] = useState<string>(moment().format('MM-DD-YYYY'))
  const [fromToDate, setFromToDate] = useState<GraphqlExpression>({
    gte: moment().startOf('isoWeek').format('YYYY-MM-DD'),
  })
  const [toDate, setToDate] = useState<GraphqlExpression>({ lte: moment().format('YYYY-MM-DD') })
  const [ticketCondition, setTicketCondition] = useState<GraphqlExpression>({ contains: '' })
  const [currencyName, setCurrencyName] = useState<GraphqlExpression>({ eq: 'PESOS_DOMINICANOS' })
  const [numberTicket, setNumberTicket] = useState<GraphqlExpression>({ eq: 0 })

  // LocalizedContent
  const { results } = useLocalizedContent({ entityType: 'System', entityName: 'TicketResult' })
  const { results: resultCurrency } = useLocalizedContent({ entityType: 'System', entityName: 'Currency' })

  function onChangeCurrency(currencyName: string) {
    setCurrencyName(generateFilterGraphql(currencyName, 'eq'))
    setNumberTicket(generateFilterGraphql(0, 'eq'))
  }

  function onChangeFromDate(newDate: string) {
    setFromDateSelected(newDate)
    setFromToDate(generateFilterGraphql(moment(newDate).format('YYYY-MM-DD'), 'gte'))
    setNumberTicket(generateFilterGraphql(0, 'gte'))
  }

  function onChangeToDate(newDate: string) {
    setToDateSelected(newDate)
    setToDate(generateFilterGraphql(moment(newDate).format('YYYY-MM-DD'), 'lte'))
    setNumberTicket(generateFilterGraphql(0, 'lte'))
  }

  function onChangeTcketCondition(newCondition: string) {
    setTicketCondition(generateFilterGraphql(newCondition, 'contains'))
    setNumberTicket(generateFilterGraphql(0, 'contains'))
  }

  useEffect(() => {
    if (numberTicket.eq > 0) {
      onChange({
        where: {
          id: numberTicket,
        },
      })

      return
    }

    onChange({
      where: {
        and: [
          {
            expireDate: fromToDate,
          },
          {
            expireDate: toDate,
          },
          {
            currency: currencyName,
          },
          {
            state: ticketCondition,
          },
        ],
      },
    })
  }, [fromToDate, toDate, ticketCondition, currencyName, numberTicket])

  return (
    <Card className={classes.cardRoot}>
      <CardHeader component={() => <HeaderCardTitle title={translation('CRITERIOS_DE_BUSQUEDA')} />} />
      <Divider />
      <CardContent>
        <Formik
          validate={(values: ITicketFilters) => {
            const errors: Partial<TicketFiltersErrors> = {}
            if (moment(values.from).isAfter(values.to)) {
              errors.to = 'Fecha Hasta debe ser mayor a la fecha Desde.'
            }
            if (values.ticketId && (values.ticketCondition || !isDateToday(values.from) || !isDateToday(values.to))) {
              setMessage('La búsqueda solo aplicará por Número.')
            } else {
              setMessage('')
            }
            return errors
          }}
          initialValues={{
            ticketId: '',
            ticketCondition: '',
            from: moment().format('MM-DD-YYYY'),
            to: moment().format('MM-DD-YYYY'),
            currencyName: 'PESOS_DOMINICANOS',
          }}
          onSubmit={(values: ITicketFilters, { setSubmitting }) => {
            setSubmitting(false)
            const numberTicket = `${values.ticketId}`
            setNumberTicket(generateFilterGraphql(parseInt(numberTicket), 'eq'))
          }}
        >
          {({ errors, submitForm, handleReset }) => {
            const onResetForm = () => {
              handleReset()
              setMessage('')
            }

            return (
              <Form>
                <Grid container item spacing={3} lg={12} xs={12} sm={12}>
                  <Grid item lg={2} xs={12} sm={12}>
                    <Field
                      component={CustomDatePicker}
                      name="from"
                      label={translation('DESDE')}
                      format="dd/MM/yyyy"
                      customClassName={classes.dates}
                      fullWidth
                      onDateChange={onChangeFromDate}
                      value={fromDateSelected}
                      disableFuture={true}
                    />
                  </Grid>
                  <Grid item lg={2} xs={12} sm={12}>
                    <Field
                      component={CustomDatePicker}
                      name="to"
                      label={translation('HASTA')}
                      format="dd/MM/yyyy"
                      customClassName={classes.dates}
                      onDateChange={(value: string) => {
                        onChangeToDate(value)
                      }}
                      value={toDateSelected}
                      disableFuture={true}
                    />
                    <br />
                    <span className={classes.dateValidation}>{errors.to ? errors.to : ''}</span>
                  </Grid>
                  <Grid item lg={2} xs={12} sm={2}>
                    <FormControl className={classes.formControl}>
                      <Field
                        component={LocalizedContentSelect}
                        name="ticketCondition"
                        label={translation('CONDICION_TICKET')}
                        data={results}
                        className={classes.select}
                        onChange={onChangeTcketCondition}
                        isGrupalValue
                        showNoneOption
                      />
                    </FormControl>
                  </Grid>
                  <Grid item lg={2} xs={12} sm={2}>
                    <FormControl className={classes.formControl}>
                      <Field
                        component={LocalizedContentSelect}
                        name="currencyName"
                        label={translation('DIVISA')}
                        data={resultCurrency}
                        className={classes.select}
                        onChange={onChangeCurrency}
                        isGrupalValue
                      />
                    </FormControl>
                  </Grid>
                  <Grid item lg={1} xs={12} sm={12}>
                    <Field
                      component={TextField}
                      type="number"
                      label={translation('NUMERO')}
                      name="ticketId"
                      className={classes.textField}
                    />
                  </Grid>
                  <Grid container item md={3} lg={3} xs={12} sm={12} className={classes.buttonGroup}>
                    <Grid item md={6} lg={6} xs={12} sm={12} className={classes.searchButton}>
                      <SearchButton onClick={submitForm} />
                    </Grid>
                    <Grid item md={6} lg={6} xs={12} sm={12} className={classes.resetButton}>
                      <ClearButton onClick={onResetForm} />
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            )
          }}
        </Formik>
      </CardContent>
      <CardActions>
        <span className={classes.numberMessage}>{message}</span>
      </CardActions>
    </Card>
  )
}
