import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { CustomAutoCompleteSelectFormik } from '../selects'
import { useMunicipalities } from './hooks'
import { generateFilterGraphql } from '../..'
import { FormProps } from '../../models'
import { Municipality } from './model'

export interface MunicipalitySelectProps extends FormProps {
  label?: string
  countryId: number
  provinceId: number
  onChangeMunicipality: (province: Municipality) => void
  disabled?: boolean
}

export const MunicipalitySelect = ({
  countryId,
  provinceId,
  onChangeMunicipality,
  disabled,
  ...props
}: MunicipalitySelectProps) => {
  const {
    form: { setFieldValue, values },
    field: { name },
  } = props

  const { getMunicipalities, results, loading } = useMunicipalities()
  const { t: transLabel } = useTranslation('Label')

  useEffect(() => {
    filterMunicipalities('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryId, provinceId])

  const filterMunicipalities = (province: string, skip = 0, take = 100) => {
    const customFilters = {
      and: [{ value: { ...generateFilterGraphql(province, 'contains') } }],
    }

    getMunicipalities({ where: customFilters, skip, take }, countryId, provinceId)
  }

  const handleFilter = () => {}

  const handleChange = useCallback(
    (value: Municipality | null) => {
      setFieldValue(name, value ? value.key : '')
      if (onChangeMunicipality instanceof Function && value) {
        onChangeMunicipality(value)
      }
    },
    [setFieldValue, name, onChangeMunicipality],
  )

  let defaultValue = null
  if (results.results.length > 0) {
    const filter = results.results.filter((item: any) => item.key === values[name])
    if (filter.length > 0) {
      defaultValue = filter[0]
    }
  }

  return (
    <CustomAutoCompleteSelectFormik
      {...props}
      disabled={disabled}
      defaultValue={defaultValue}
      data={results.results}
      keyValue={'value'}
      keyLabel={'value'}
      isLoading={loading}
      label={transLabel('MUNICIPIO')}
      onChangeValue={handleChange}
      onInputValueChange={handleFilter}
    />
  )
}
