import { Fragment, useEffect, useState } from 'react'
import {
  Grid,
  makeStyles,
  createStyles,
  Theme,
  FormControl,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControlLabel,
  Button,
  withStyles,
} from '@material-ui/core'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import {
  AppBreadcrumbsBar,
  StringRadioButtonFormik,
  StringAutoCompleteSelectFormik,
  CustomFormikDatePicker,
  DialogAlerts,
  HeaderCardTitle,
  ModifyButton,
  PhoneTextFieldMask,
  SaveButton,
  TextAreaFieldFormik,
  CheckboxFormik,
} from '../../../core/components'
import { useTranslation } from 'react-i18next'
import { FormikProvider, Field, Form, useFormik, FormikValues, FormikState } from 'formik'
import { TextField, Checkbox } from 'formik-material-ui'
import { useRole } from '../roles/hooks'

import * as Yup from 'yup'
import { User } from './models'
import { useHistory } from 'react-router-dom'
import { Role } from '../roles/models'
import {
  canDoesActionSelector,
  ClaimActions,
  excludedRolesForNotUserAdmin,
  excludedRolesForUserAdmin,
  getAppBarState,
  isAdminSelector,
  phoneRegExp,
  useUserClaimActions,
} from '../../../core'
import { green } from '@material-ui/core/colors'
import _ from 'lodash'
import { ResponsiveBreakpoints } from '@amcharts/amcharts4/core'

const ColorButton = withStyles((theme) => ({
  root: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
}))(Button)

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fieldItem: {
      marginBottom: theme.spacing(2),
    },
    labelEditActive: {
      color: '#ff6600',
    },
    labelEditDisabled: {
      color: '#000000',
    },
    saveButton: {
      marginRight: 5,
    },
    divider: {
      marginTop: -22,
    },
    checkBoxField: {
      alignItems: 'flex-end',
      marginBottom: -10,
    },
    buttonActions: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    addressDiv: {
      paddingTop: 5,
    },
    sexContainer: {
      marginTop: 20,
      marginBottom: -30,
    },
    roleContainer: {
      marginBottom: 20,
    },
    userNameLabel: {
      color: '#3f51b5',
    },
  }),
)

const initialFormValues = {
  id: '',
  givenName: '',
  familyName: '',
  nickname: '',
  email: '',
  phoneNumber: '',
  birthdate: new Date(),
  address: '',
  zoneinfo: '',
  locale: '',
  gender: '',
  roles: [],
  active: false,
} as User

type UserFormProps = {
  onSave: (formValues: User) => void
  onChangeRole: (value: any, reason: string, item: any) => void
  user?: User | undefined
  onGenerateCode: (user: User) => void
}

export const UserForm = ({ user, onSave, onChangeRole, onGenerateCode }: UserFormProps) => {
  const isCreating = user && user.id && user.id.length > 0 ? false : true
  const { t: transAction } = useTranslation('Action')
  const { t: transLabel } = useTranslation('Label')
  const history = useHistory()
  const classes = useStyles()
  const [isEditing, setIsEditing] = useState<boolean>(isCreating)
  const [alertForm, setAlertForm] = useState(false)
  const { validateClaimActions } = useUserClaimActions()
  const actions = validateClaimActions('security.users', [
    ClaimActions.CanCreate,
    ClaimActions.CanEdit,
    ClaimActions.CanView,
  ])

  const {
    results: { results: roles },
    getRoles,
  } = useRole()

  const formSchema = Yup.object().shape({
    givenName: Yup.string().required(transLabel('REQUERIDO')),
    familyName: Yup.string().required(transLabel('REQUERIDO')),
    phoneNumber: Yup.string()
      .required(transLabel('REQUERIDO'))
      .matches(phoneRegExp, transLabel('VALOR_NO_VALIDO'))
      .nullable(),
    roles: Yup.array().min(1, transLabel('REQUERIDO')),
  })

  const formik = useFormik({
    validationSchema: formSchema,
    initialValues: user || initialFormValues,
    onSubmit: onSave,
    enableReinitialize: true,
  })

  window.sessionStorage.setItem('hasFormChanges', formik.dirty ? 'true' : 'false')

  useEffect(() => {
    getRoles({ where: undefined, skip: 0, take: 100 })
  }, [])

  const goToUsers = () => {
    if (formik.dirty === false) {
      history.push(`/admin/security/users`)
    } else {
      setAlertForm(true)
    }
  }

  let userRoles: string[] = []
  if (roles.length > 0) {
    roles.map((role: Role) => {
      if (actions.isAdmin && !excludedRolesForUserAdmin.includes(role.name)) {
        userRoles.push(role.name)
      } else if (!excludedRolesForNotUserAdmin.includes(role.name)) {
        userRoles.push(role.name)
      }
    })
  }

  return (
    <FormikProvider value={formik}>
      <Form>
        <Grid item xs={12}>
          <AppBreadcrumbsBar
            title={transLabel('USUARIO_TITULO')}
            description={transLabel('USUARIO_SUB_TITULO')}
            actions={
              <Fragment>
                {user && actions.canCreate && (
                  <ColorButton
                    className={classes.buttonActions}
                    color="secondary"
                    variant="contained"
                    onClick={() => onGenerateCode(user)}
                    startIcon={<LockOpenIcon className={classes.buttonActions} />}
                  >
                    {transAction('CAMBIAR_CLAVE')}
                  </ColorButton>
                )}
                {isEditing === false && actions.canEdit ? (
                  <ModifyButton className={classes.buttonActions} onClick={() => setIsEditing(true)} />
                ) : actions.canCreate ? (
                  <SaveButton
                    className={classes.buttonActions}
                    onClick={formik.submitForm}
                    isLoading={false}
                    disabled={!(formik.dirty && formik.dirty)}
                  />
                ) : null}

                <Button
                  variant="contained"
                  onClick={goToUsers}
                  startIcon={<KeyboardBackspaceIcon className={classes.buttonActions} />}
                >
                  REGRESAR
                </Button>
              </Fragment>
            }
          />
        </Grid>

        <Card>
          <CardHeader
            title={
              <HeaderCardTitle
                title={
                  <div>
                    {`${transLabel('DATOS_DEL_USUARIO')}: `}
                    <span className={classes.userNameLabel}>{user && user.userName ? `${user.userName}` : ''}</span>
                  </div>
                }
              />
            }
          />
          <Divider className={classes.divider} />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Field
                  component={TextField}
                  type="text"
                  label={transLabel('NOMBRE')}
                  name="givenName"
                  fullWidth
                  disabled={!isEditing}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Field
                  component={TextField}
                  type="text"
                  label={transLabel('APELLIDO')}
                  name="familyName"
                  fullWidth
                  disabled={!isEditing}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Field
                  component={TextField}
                  type="text"
                  label={transLabel('APODO')}
                  name="nickname"
                  fullWidth
                  disabled={!isEditing}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Field
                  component={TextField}
                  type="email"
                  label={transLabel('EMAIL')}
                  name="email"
                  fullWidth
                  disabled={!isEditing}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Field
                  disabled={!isEditing}
                  component={PhoneTextFieldMask}
                  type="text"
                  label={transLabel('TELEFONO')}
                  name="phoneNumber"
                  fullWidth
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Field
                  fullWidth
                  component={CustomFormikDatePicker}
                  name="birthdate"
                  label={transLabel('FECHA_NACIMIENTO')}
                  format={'dd/MM/yyyy'}
                  disableFuture={true}
                  disabled={!isEditing}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Field
                  component={TextField}
                  type="text"
                  label={transLabel('ZONE_INFO')}
                  name="zoneinfo"
                  fullWidth
                  disabled={!isEditing}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Field
                  component={TextField}
                  type="text"
                  label={transLabel('LOCALE')}
                  name="locale"
                  fullWidth
                  disabled={!isEditing}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3} className={classes.sexContainer}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Field
                  component={StringRadioButtonFormik}
                  title={'SEXO'}
                  name="gender"
                  label1={'MALE'}
                  label2={'FEMALE'}
                  value1={'MALE'}
                  value2={'FEMALE'}
                  disabled={!isEditing}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3} className={classes.roleContainer}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Field
                  name={'roles'}
                  onChangeValue={onChangeRole}
                  component={StringAutoCompleteSelectFormik}
                  data={userRoles}
                  label={transLabel('ROLE')}
                  fullWidth
                  multiple
                  disabled={!isEditing}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormControl fullWidth className={classes.addressDiv}>
                  <Field
                    rowsMin={9}
                    component={TextAreaFieldFormik}
                    name="address"
                    placeholder={transLabel('DIRECCION')}
                    disabled={!isEditing}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormControl fullWidth>
                  <FormControlLabel
                    control={<Field component={CheckboxFormik} name="active" color="primary" disabled={!isEditing} />}
                    label={transLabel('ACTIVO') as string}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <DialogAlerts
          state={alertForm}
          titulo={'SE_HAN_DETECTADO_CAMBIOS_EN_EL_FORMULARIO'}
          contenido={'SI_CONTINUA_PERDERA_LOS_CAMBIOS_EN_EL_FORMULARIO_DESEA_CONTINUAR'}
          tipo={'PREGUNTA'}
          onSecondaryClick={() => setAlertForm(false)}
          handleClose={() => setAlertForm(false)}
          onPrimaryClick={() => history.push(`/admin/security/users`)}
        />
      </Form>
    </FormikProvider>
  )
}
