import { useCallback, useState } from 'react'
import { TextFieldProps, TextField } from '@material-ui/core'
import InputMask, { Props } from 'react-input-mask'
import { FormProps } from '../../models'
export interface PhoneTextFieldMaskProps extends FormProps {
  customClassName?: object
  onDateChange?: (date: string | Date | null) => void
  value?: string
  defaultValue?: string
  label: string
  fullWidth?: boolean
  disabled?: boolean
}

export const PhoneTextFieldMask = ({
  customClassName,
  onDateChange,
  defaultValue,
  value,
  label,
  disabled,
  ...props
}: PhoneTextFieldMaskProps) => {
  const {
    form: { setFieldValue, values, errors },
    field: { name },
  } = props

  const [hasTouched, setHasTouched] = useState(false)
  /**
   * @description change date selected
   * @param date
   */
  const handleChange = useCallback(
    (event: React.ChangeEvent<any>) => {
      const value = event.target.value
      setFieldValue(name, value)
    },
    [setFieldValue, name],
  )

  const errorMessage = errors[name] !== undefined ? errors[name] : ''

  return (
    <InputMask
      mask="(999) 999-9999"
      value={values[name]}
      onChange={handleChange}
      disabled={disabled}
      onBlur={() => setHasTouched(true)}
    >
      {/*
        'props' supplied to TextField weren't matching the required type. 
        Hence we use '&' operator which is for intersecting two types
     */}
      {(inputProps: Props & TextFieldProps) => (
        <TextField
          {...props}
          {...inputProps}
          disabled={disabled}
          type="tel"
          label={label}
          fullWidth
          error={errorMessage.length > 0}
          helperText={errorMessage.length > 0 ? errorMessage : ''}
        />
      )}
    </InputMask>
  )
}
