import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Divider, Card, CardActions, CardContent, CardHeader, Button, Typography } from '@material-ui/core'
import { ProviderConnection } from '../models'
import { HeaderCardTitle } from '../../../../core/components'
import { AddBox } from '@material-ui/icons'
import EditIcon from '@material-ui/icons/Edit'
import Chip from '@material-ui/core/Chip'
import { useHistory } from 'react-router-dom'

import { useTranslation } from 'react-i18next'
import { useUserClaimActions, ClaimActions } from '../../../../core'

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    '& .MuiChip-root': {
      backgroundColor: (props: undefined | ProviderConnection) => (props && props.isActive ? '#4caf50' : '#dc004e'),
      color: 'white',
    },
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  divider: {
    marginTop: -22,
  },
})

type ConnectionProfileProps = {
  connection: undefined | ProviderConnection
  providerId: number
}

export const ConnectionProfile = ({ connection, providerId }: ConnectionProfileProps) => {
  const classes = useStyles(connection)
  const { t: transLabel } = useTranslation('Label')
  const history = useHistory()
  const { validateClaimActions } = useUserClaimActions()
  const actions = validateClaimActions('configuration.connections', [ClaimActions.CanCreate, ClaimActions.CanEdit])

  return (
    <Card className={classes.root}>
      <CardHeader
        title={<HeaderCardTitle title={transLabel('CONEXION')} />}
        action={
          connection && connection.id && connection.id > 0 && actions.canEdit ? (
            <Button
              variant="contained"
              size="small"
              color="primary"
              onClick={() => history.push(`/admin/entities/providers/${providerId}/connections/${connection.id}`)}
            >
              <EditIcon /> {transLabel('EDITAR_CONEXION')}
            </Button>
          ) : (
            actions.canEdit && (
              <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={() => history.push(`/admin/entities/providers/${providerId}/connections/create`)}
              >
                <AddBox /> {transLabel('NUEVA_CONEXION')}
              </Button>
            )
          )
        }
      />
      <Divider className={classes.divider} />
      <CardContent>
        {connection ? (
          <React.Fragment>
            <Typography variant="h6">BaseUrl: {connection.apiBaseUrl}</Typography>
            <Typography variant="h6" className={classes.pos}>
              Version: {connection.version}
            </Typography>
            <Typography variant="body2" component="p">
              <Chip label={connection.isActive ? transLabel('ACTIVA') : transLabel('INACTIVA')} />
            </Typography>
          </React.Fragment>
        ) : (
          <Typography variant="h6" className={classes.pos}>
            {transLabel('NO_SE_ENCONTRO_CONEXION_PARA_ESTE_PROVEEDOR')}
          </Typography>
        )}
      </CardContent>
    </Card>
  )
}
