import { gql, useLazyQuery, useQuery } from '@apollo/client'
import { Metadata, ResultsList, SearchGraphql } from '../../../../../core/models'
import { Runner } from '../../models'

const GET_RUNNERS = gql`
  query GetProgramRunners($where: ProgramRunnersOfRaceFilterInput!, $skip: Int, $take: Int) {
    programRunnersOfRace(where: $where, skip: $skip, take: $take) {
      items {
        id
        programRaceId
        competitorId
        number
        odds
        position
        weight
        jockeyOrKennelId
        medication
        isActive
        competitorName
        jockeyOrKennelName
        ownerName
        ownerId
        trainerName
        description
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`

interface RunnersData {
  programRunnersOfRace: {
    totalCount: number
    items: Runner[]
  }
}

export const useRunners = () => {
  const [getResults, { loading, error, data }] = useLazyQuery<RunnersData>(GET_RUNNERS, {
    fetchPolicy: 'network-only',
  })

  const getRunners = ({ where, take, skip }: any) => {
    getResults({
      variables: {
        where: { ...where },
        skip: skip ? skip : 0,
        take: take ? take : 5,
      },
    })
  }

  const results: ResultsList<Runner, Metadata> = {
    results: data?.programRunnersOfRace.items || [],
    metadata: {
      Count: data?.programRunnersOfRace.totalCount,
    },
  }

  return { getRunners, loading, error, results }
}
