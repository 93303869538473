import React from 'react'
import { useQuery, gql } from '@apollo/client'
import { Grid } from '@material-ui/core'
interface Company {
  id: number
  name: string
  active: boolean
}

interface CompaniesData {
  companies: {
    items: Company[]
  }
}

interface CompaniesVars {
  id: number
}

const GET_ROCKET_INVENTORY = gql`
  query GetRocketInventory($year: Int!) {
    rocketInventory(year: $year) {
      id
      model
      year
      stock
    }
  }
`

const GET_COMPANIES = gql`
  query All_Companies_Query {
    companies {
      items {
        id
        name
        active
      }
    }
  }
`

export function GraphqlTest() {
  // const { loading, data } = useQuery<CompaniesData, CompaniesVars>(
  //   GET_ROCKET_INVENTORY,
  //   { variables: { id: 2 } }
  // );
  const { loading, data } = useQuery<CompaniesData>(GET_COMPANIES)
  return (
    <div>
      <h3>Available Inventory</h3>
      {loading ? (
        <p>Loading ...</p>
      ) : (
        <table>
          <thead>
            <tr>
              <th>Model</th>
              <th>Stock</th>
            </tr>
          </thead>
          <tbody>
            {data &&
              data.companies.items.map((item) => (
                <tr>
                  <td>{item.id}</td>
                  <td>{item.name}</td>
                  <td>{item.active}</td>
                </tr>
              ))}
          </tbody>
        </table>
      )}
    </div>
  )
}
