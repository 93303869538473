import React from 'react'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

export const AgesDependent = () => {
  return (
    <Select fullWidth value={40} onChange={() => {}} displayEmpty inputProps={{ 'aria-label': 'Without label' }}>
      <MenuItem value={20}>20 Años</MenuItem>
      <MenuItem value={30}>30 Años</MenuItem>
      <MenuItem value={40}>40 Años</MenuItem>
    </Select>
  )
}
