import React from 'react'
import { DataGrid, GridCellParams, GridColDef, GridPageChangeParams, GridRowParams } from '@material-ui/data-grid'

import { Metadata, ResultsList } from '../../models'
import { useTranslation } from 'react-i18next'
import { Button, createStyles, makeStyles, Tooltip } from '@material-ui/core'
import { formatAsCurrency } from '../../services/formatting'
import { ColumnAction, getActionsColumn } from './ActionsColumn'
import * as formatters from '../../services/formatting'
import clsx from 'clsx'
import { formatToLocalTime, formatToShortDateUS } from '../../services/dateFunctions'
import { PercentBar, PercentArrow } from '../percentage'
import { StatusChips } from '../chips/StatusChips'
import { isMobile } from '../..'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    columns: {
      '& .MuiDataGrid-columnsContainer': {
        lineHeight: '18px !important',
      },
      '& MuiDataGrid-colCellTitle': {
        whiteSpace: 'normal',
        wordBreak: 'breakWord',
      },
      '& .MuiDataGrid-colCellTitleContainer': {
        overflow: 'unset',
        whiteSpace: 'unset',
      },
      '& .MuiDataGrid-colCellTitle': {
        whiteSpace: 'unset',
      },
    },
    uppercase: {
      textTransform: 'uppercase',
    },
    columnCentered: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    columnRight: {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
    },
    columnLeft: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
    },
    cellContentTruncated: {
      whiteSpace: 'nowrap',
      // overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  }),
)

export interface DataGridProps {
  page?: number
  pageSize?: number
  columns: DataGridColumns
  data: ResultsList<any, Metadata>
  onPageChange?: (param: GridPageChangeParams) => void
  onPageSizeChange?: (param: GridPageChangeParams) => void
  onRowClick?: (param: GridRowParams) => void
  disableColumnMenu?: boolean
  hideFooterPagination?: boolean
}

export interface DataGridColumns {
  [key: string]: GridColDef | DefaultColumDef
}

export type ColumnContentType =
  | 'id'
  | 'string'
  | 'largeString'
  | 'localized'
  | 'currency'
  | 'number'
  | 'numberWithDecimal'
  | 'boolean'
  | 'date'
  | 'dateTime'
  | 'time'
  | 'percentageBar'
  | 'percentage'
  | 'percentageArrow'
  | 'actions'
  | 'object'
  | 'undefined'
  | 'custom'
  | 'button'
  | (() => React.ReactNode)

export interface DefaultColumDef {
  field: string
  tooltip?: string
  onAction?: (row: any, action: ColumnAction) => void
  contentType: ColumnContentType
  flex?: number
  renderCell?: (params: GridCellParams) => React.ReactElement
}

export const CustomDataGrid = ({ data, columns, pageSize, page, ...props }: DataGridProps) => {
  const {
    results,
    metadata: { Count: count },
  } = data

  const classes = useStyles()
  const { t: translation } = useTranslation('Label')

  const getCellContent = (content: string, tooltip?: string) => {
    return tooltip ? (
      <Tooltip title={tooltip} arrow>
        <span className={classes.cellContentTruncated}>{content}</span>
      </Tooltip>
    ) : (
      <React.Fragment>{content}</React.Fragment>
    )
  }

  const getFieldColumnDefinition = (
    field: string,
    headerName: string,
    contentType: ColumnContentType,
    tooltip?: string,
    renderCell?: (params: GridCellParams) => React.ReactElement,
    flex?: number,
    onAction?: (row: any, action: ColumnAction) => void,
  ): GridColDef => {
    switch (contentType) {
      case 'id':
        return {
          field,
          headerName,
          headerAlign: 'center',
          align: 'right',
          flex: 0.5,
        }

      case 'string':
        return {
          // width: 200,
          flex: 1.25,
          field,
          headerName,
          headerAlign: 'center',
          cellClassName: clsx(classes.columnLeft),
          renderCell: (params: GridCellParams) => {
            const value = params.getValue(params.field)?.toString()
            return getCellContent(translation(value || 'NONE'), tooltip)
          },
        }

      case 'largeString':
        return {
          // width: 400,
          flex: 1,
          field,
          headerName,
          headerAlign: 'center',
          cellClassName: clsx(classes.columnLeft),
        }

      case 'localized':
        return {
          // width: 200,
          flex: 1,
          field,
          headerName,
          headerAlign: 'center',
          cellClassName: clsx(classes.columnLeft),
          renderCell: (params: GridCellParams) => {
            const value = params.getValue(params.field)?.toString()
            return getCellContent(translation(value || 'NONE'), tooltip)
          },
          align: 'left',
        }

      case 'date':
        return {
          // width: 200,
          flex: 0.5,
          field,
          headerName,
          headerAlign: 'center',
          cellClassName: clsx(classes.columnCentered),
          renderCell: (params: GridCellParams) => {
            const value = params.getValue(params.field) as any
            return getCellContent(formatToShortDateUS(value), tooltip)
          },
        }

      case 'dateTime':
        return {
          // width: 200,
          flex: 1,
          field,
          headerName,
          headerAlign: 'center',
          cellClassName: clsx(classes.columnCentered),
          renderCell: (params: GridCellParams) => {
            const value = params.getValue(params.field) as any
            return getCellContent(formatToShortDateUS(value), tooltip)
          },
        }

      case 'time':
        return {
          // width: 200,
          flex: 1,
          field,
          headerName,
          headerAlign: 'center',
          cellClassName: clsx(classes.columnCentered),
          renderCell: (params: GridCellParams) => {
            const value = params.getValue(params.field) as any
            return getCellContent(formatToLocalTime(value), tooltip)
          },
        }

      case 'number':
        return {
          // width: 125,
          flex: 0.75,
          field,
          headerName,
          headerAlign: 'center',
          cellClassName: clsx(classes.columnRight),
          renderCell: (params: GridCellParams) => {
            const stringValue = params.getValue(params.field)?.toString()
            const value = Number.parseFloat(stringValue || '0')
            return getCellContent(formatters.formatAsNumber(value, 0), tooltip)
          },
        }
      case 'percentage':
        return {
          // width: 125,
          flex: 0.5,
          field,
          headerName,
          headerAlign: 'center',
          cellClassName: clsx(classes.columnRight),
          renderCell: (params: GridCellParams) => {
            const stringValue = params.getValue(params.field)?.toString()
            const value = Number.parseFloat(stringValue || '0')
            return getCellContent(formatters.formatAsPercentage(value, 0), tooltip)
          },
        }
      case 'percentageArrow':
        return {
          // width: 100,
          flex: 1,
          field,
          headerName,
          headerAlign: 'center',
          cellClassName: clsx(classes.columnRight),
          renderCell: (params: GridCellParams) => {
            const stringValue = params.getValue(params.field)?.toString()
            const number = Number.parseFloat(stringValue || '0')
            return <PercentArrow value={number} />
          },
        }
      case 'percentageBar':
        return {
          // width: 125,
          flex: 1,
          field,
          headerName,
          headerAlign: 'center',
          cellClassName: clsx(classes.columnCentered),
          renderCell: (params: GridCellParams) => {
            const stringValue = params.getValue(params.field)?.toString()
            const number = Number.parseFloat(stringValue || '0')
            return <PercentBar value={number} />
          },
        }
      case 'currency':
        return {
          flex: 1,
          field,
          headerName,
          headerAlign: 'center',
          align: 'right',
          cellClassName: clsx(classes.columnRight),
          renderCell: (params: GridCellParams) => {
            const stringValue = params.getValue(params.field)?.toString()
            const value = Number.parseFloat(stringValue || '0')
            return getCellContent(formatAsCurrency(value, 0), tooltip)
          },
        }
      case 'boolean':
        return {
          // width: 125,
          flex: 1,
          field,
          headerName,
          headerAlign: 'center',
          cellClassName: clsx(classes.columnCentered),
          renderCell: (params: GridCellParams) => {
            const value = params.getValue(params.field) as boolean
            return <StatusChips status={value} />
          },
        }

      case 'custom':
        return {
          // width: 100,
          flex: 0.5,
          field,
          headerName,
          headerAlign: 'center',
          cellClassName: clsx(classes.columnCentered),
          disableClickEventBubbling: true, // Stop event propagation
          renderCell:
            renderCell !== undefined
              ? renderCell
              : (params: GridCellParams) => {
                  const value = params.getValue(params.field) as boolean
                  return <StatusChips status={value} />
                },
        }

      case 'button':
        return {
          // width: 125,
          flex: 1,
          field,
          headerName,
          headerAlign: 'center',
          cellClassName: clsx(classes.columnCentered),
          disableClickEventBubbling: true, // Stop event propagation
          renderCell:
            renderCell !== undefined
              ? renderCell
              : (params: GridCellParams) => {
                  const value = params.getValue(params.field) as boolean
                  return onAction ? (
                    <Button size="small" variant="contained" color="primary" onClick={() => onAction(params, 'Edit')}>
                      VER
                    </Button>
                  ) : (
                    <StatusChips status={value} />
                  )
                },
        }

      case 'actions':
        return getActionsColumn(headerName)

      default:
        return {
          width: 200,
          field,
          headerName,
        }
    }
  }

  const getCustomGridColumns = (columns: DataGridColumns): GridColDef[] => {
    return Object.keys(columns).map((key: string) => {
      const column = columns[key]
      const isDefaultGridColDef = (column as DefaultColumDef).contentType !== undefined
      if (isDefaultGridColDef) {
        const { field, contentType, tooltip, renderCell, onAction, flex } = column as DefaultColumDef
        return getFieldColumnDefinition(
          field,
          translation(key.toUpperCase()),
          contentType,
          tooltip,
          renderCell,
          flex,
          onAction,
        )
      } else {
        return column as GridColDef
      }
    })
  }

  return (
    <DataGrid
      className={clsx({
        [classes.columns]: !isMobile(),
      })}
      autoHeight={results.length <= 100}
      rows={results}
      rowHeight={38}
      rowsPerPageOptions={[5, 10, 15, 25, 50, 100]}
      columns={getCustomGridColumns(columns)}
      pageSize={pageSize}
      paginationMode="server"
      page={page}
      rowCount={count}
      {...props}
    />
  )
}
