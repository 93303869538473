import { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import { useParams, useHistory } from 'react-router-dom'
import { UserForm } from './UserForm'
import { excludedRolesForNotUserAdmin, useNotification } from '../../../core'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { Loader } from '../../../core/components'
import { User as UserModel, UserCredentials } from './models'
import {
  useCreateUser,
  useUpdateUser,
  useUser,
  useAssignUserRole,
  useRemoveUserRole,
  usePasswordResetCode,
} from './hooks'
import { UserCredentialsBanner } from './UserCredentialsBanner'

var u: undefined | UserModel = undefined
export const User = () => {
  const { id } = useParams<{ id: string | undefined }>()
  const { successNotification, errorNotification, warningNotification } = useNotification()
  const { t: transSystem } = useTranslation('System')
  const history = useHistory()
  const { createUser } = useCreateUser()
  const { updateUser } = useUpdateUser()
  const { assignUserRole } = useAssignUserRole()
  const { removeUserRole } = useRemoveUserRole()
  const { passwordResetCode } = usePasswordResetCode()
  const [isFetchingAfterSaved, setIsFetchingAfterSaved] = useState(false)
  const [userCredentials, setUserCredentials] = useState<UserCredentials | undefined>(undefined)
  const { data, loading, getUser } = useUser()
  let user: undefined | UserModel = data ? _.cloneDeep(data.user) : undefined
  u = data ? data.user : undefined

  if (user?.roles) {
    user.roles = user.roles.filter((role) => !excludedRolesForNotUserAdmin.includes(role))
  }

  useEffect(() => {
    if (id !== undefined && id.length > 25) {
      getUser(id)
    }
  }, [id])

  const handleSave = (userToSave: UserModel) => {
    if (userToSave.id === undefined || userToSave.id === '') {
      // Add Customer role by default
      //userToSave.roles?.push('customer')
      const response = createUser({
        variables: {
          input: {
            roles: userToSave.roles && userToSave.roles.length > 0 ? userToSave.roles : '',
            claims: {
              familyName: userToSave.familyName,
              givenName: userToSave.givenName,
              email: userToSave.email,
              middleName: userToSave.middleName,
              nickname: userToSave.nickname,
              address: userToSave.address,
              // gender: userToSave.gender,
              birthdate: userToSave.birthdate,
              phoneNumber: userToSave.phoneNumber,
              locale: userToSave.locale,
              zoneinfo: userToSave.zoneinfo,
              // active: userToSave.active,
            },
          },
        },
      })
      response
        .then((result: any) => {
          // successNotification('USUARIO_CREADO')
          const id = _.get(result, 'data.createUser.id', '')
          setUserCredentials({
            userName: _.get(result, 'data.createUser.userName', ''),
            password: _.get(result, 'data.createUser.password', ''),
            code: undefined,
          })
          if (id.length > 0) {
            history.push(`/admin/security/users/${id}`)
          }
        })
        .catch(() => {
          // errorNotification('ERROR_CREANDO_EL_USUARIO')
        })
    } else {
      const response = updateUser({
        variables: {
          input: {
            id: userToSave.id,
            // roles: userToSave.roles && userToSave.roles.length > 0 ? userToSave.roles : '',
            claims: {
              familyName: userToSave.familyName,
              givenName: userToSave.givenName,
              email: userToSave.email,
              middleName: userToSave.middleName,
              nickname: userToSave.nickname,
              address: userToSave.address,
              gender: userToSave.gender,
              birthdate: userToSave.birthdate,
              phoneNumber: userToSave.phoneNumber,
              locale: userToSave.locale,
              zoneinfo: userToSave.zoneinfo,
              // active: userToSave.active,
            },
          },
        },
      })
      return response
        .then(() => {
          successNotification(transSystem('DATO_ACTUALIZADO'))
          if (userToSave.id) {
            setIsFetchingAfterSaved(true)
            getUser(userToSave.id)
          }
          return Promise.resolve(true)
        })
        .catch(() => {
          errorNotification(transSystem('ERROR_ACTUALIZANDO_EL_DATO'))
          return Promise.resolve(false)
        })
    }
  }

  const handleChangeRole = (value: any, reason: string, item: string) => {
    if (item && u) {
      if (reason === 'select-option') {
        const response = assignUserRole({
          variables: {
            input: {
              role: item,
              userName: u.userName,
            },
          },
        })
        response
          .then((e: any) => {
            const succeeded = _.get(e, 'data.assignUserToRole.succeeded', false)
            if (succeeded) {
              successNotification(transSystem('ROLE_CLAIM_AGREGADO'))
            } else {
              errorNotification(transSystem('ERROR_REGISTRANDO_EL_DATO'))
            }
          })
          .catch((e: any) => {
            errorNotification(transSystem('ERROR_REGISTRANDO_EL_DATO'))
          })
      } else if (reason === 'remove-option') {
        const rolesDiff = _.xor(u.roles, value)
        if (u && u.id && rolesDiff.length > 0) {
          const response = removeUserRole({
            variables: {
              input: {
                role: rolesDiff[0],
                userName: u.userName,
              },
            },
          })
          response
            .then((e: any) => {
              const succeeded = _.get(e, 'data.removeUserFromRole.succeeded', false)
              if (succeeded) {
                warningNotification(transSystem('ROLE_CLAIM_ELIMINADO'))
                // Refresh user form
                if (u && u.id) {
                  getUser(u.id)
                }
              } else {
                errorNotification(transSystem('ERROR_ACTUALIZANDO_EL_DATO'))
              }
            })
            .catch((e: any) => {
              errorNotification(transSystem('ERROR_ACTUALIZANDO_EL_DATO'))
            })
        }
      } else {
        errorNotification(transSystem('ERROR_ACTUALIZANDO_EL_DATO'))
      }
    }
  }

  const handleGenerateCode = (user: UserModel) => {
    if (user && user.id) {
      const response = passwordResetCode({
        variables: {
          input: {
            owner: user.id,
            lifetimeInSeconds: 300,
          },
        },
      })
      response.then((result: any) => {
        const code = _.get(result, 'data.passwordResetCode.passwordResetCode', undefined)
        if (code && user.userName) {
          setUserCredentials({
            userName: user.userName,
            password: undefined,
            code: code,
          })
        }
      })
    } else {
      errorNotification(transSystem('USUARIO_INVALIDO'))
    }
  }

  return (
    <Loader loading={false}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {userCredentials && (
            <UserCredentialsBanner
              userName={userCredentials.userName}
              password={userCredentials.password}
              code={userCredentials.code}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Loader loading={loading && isFetchingAfterSaved === false}>
            <UserForm
              user={user}
              onSave={handleSave}
              onChangeRole={handleChangeRole}
              onGenerateCode={handleGenerateCode}
            />
          </Loader>
        </Grid>
      </Grid>
    </Loader>
  )
}
