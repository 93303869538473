import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { useTranslation } from 'react-i18next'
import { TicketCensusTypeBet } from './models'
import { formatTime } from '../../../core'

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  contentRow: {
    display: 'grid',
    minWidth: 'max-content',
  },
})

type PlaysTableProps = {
  data: TicketCensusTypeBet[]
}

export const PlaysTable = ({ data }: PlaysTableProps) => {
  const classes = useStyles()
  const { t: translation } = useTranslation('Label')

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="caption table">
        <TableHead>
          <TableRow>
            <TableCell>
              <span>{translation('LIGA')}</span>
            </TableCell>
            <TableCell align="left">
              <span>{translation('DIRECTAS')}</span>
            </TableCell>
            <TableCell align="left">{translation('ML')}</TableCell>
            <TableCell align="left">{translation('RL')}</TableCell>
            <TableCell align="left">{translation('JC_ML')}</TableCell>
            <TableCell align="left">{translation('JC_RL')}</TableCell>
            <TableCell align="left">{translation('OVER_UNDER')}</TableCell>
            <TableCell align="left">{translation('S_MAS')}</TableCell>
            <TableCell align="left">{translation('S_MENOS')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item, index) => {
            return (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  <div className={classes.contentRow}>
                    <span>{item.leagueAbbreviation}</span>
                    <span>{formatTime(item.eventDate)}</span>
                  </div>
                </TableCell>
                <TableCell component="th" scope="row">
                  <div className={classes.contentRow}>
                    <span>{item.nameAway}</span>
                    <span>{item.nameHome}</span>
                  </div>
                </TableCell>
                <TableCell component="th" scope="row">
                  <div className={classes.contentRow}>
                    <span>{item.mLAway}</span>
                    <span>{item.mLHome}</span>
                  </div>
                </TableCell>
                <TableCell component="th" scope="row">
                  <div className={classes.contentRow}>
                    <span>{item.rLAway}</span>
                    <span>{item.rLHome}</span>
                  </div>
                </TableCell>
                <TableCell component="th" scope="row">
                  <div className={classes.contentRow}>
                    <span>{item.mLAwayAmountBet}</span>
                    <span>{item.mLAway}</span>
                  </div>
                </TableCell>
                <TableCell component="th" scope="row">
                  <div className={classes.contentRow}>
                    <span>{item.rLAwayAmountBet}</span>
                    <span>{item.rLAway}</span>
                  </div>
                </TableCell>
                <TableCell component="th" scope="row">
                  <div className={classes.contentRow}>
                    <span>{item.totalOverAmountBet}</span>
                    <span>{item.totalOver}</span>
                  </div>
                </TableCell>
                <TableCell component="th" scope="row">
                  <div className={classes.contentRow}>
                    <span>{item.solomasAwayAmountBet}</span>
                    <span>{item.solomasAway}</span>
                  </div>
                </TableCell>
                <TableCell component="th" scope="row">
                  <div className={classes.contentRow}>
                    <span>{item.solomenosAwayAmountBet}</span>
                    <span>{item.solomenosAway}</span>
                  </div>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
