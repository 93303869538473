import React, { ReactElement, useEffect, useState, useCallback, useMemo } from 'react'
import { createStyles, Grid, GridSize, makeStyles, Theme } from '@material-ui/core'
import { AppBreadcrumbsBar, CreateButton, EmptyData, Loader } from '../../../core/components'
import { useTranslation } from 'react-i18next'
import { RolesCard } from './RolesCard'
import { AddRole } from './AddRole'
import Carousel from 'react-material-ui-carousel'
import {
  ClaimActions,
  excludedRolesForUserAdmin,
  excludedRolesForNotUserAdmin,
  isMobile,
  useNotification,
  useUserClaimActions,
} from '../../../core'
import { useCreateRole, useRole, useRoleClaims, useRenameRole } from './hooks'
import { Role } from './models'
import { Permissions } from './permissions/Permissions'
import _lodash from 'lodash'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    grid: {
      paddingBottom: theme.spacing(2),
    },
    roleCarousel: {
      paddingTop: 25,
      backgroundColor: 'aliceblue',
    },
    roleMainContent: {
      display: 'flex',
      justifyContent: 'center',
    },
    roleCardContent: {
      paddingLeft: 10,
      paddingRight: 10,
    },
  }),
)

type RoleContainer = {
  role: Role
  grid: GridSize
  onSelectToEdit: () => void
  onSelectRole: () => void
  isSelected: boolean
}

export const RoleContainer = ({ role, grid, onSelectToEdit, onSelectRole, isSelected }: RoleContainer) => {
  const classes = useStyles()
  return (
    <div className={classes.roleCardContent}>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={grid}>
          <RolesCard role={role} selectToEdit={onSelectToEdit} isSelected={isSelected} onSelectRole={onSelectRole} />
        </Grid>
      </Grid>
    </div>
  )
}

const initialRoleValues = { id: '', name: '' }

export const Roles = () => {
  const classes = useStyles()
  const [selectedRole, setSelectedRole] = useState<Role>(initialRoleValues)
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true)
  const [selectedCardRole, setSelectedCardRole] = useState('')
  const [showRoleForm, setShowRoleForm] = React.useState(false)
  const { createRole } = useCreateRole()
  const { renameRole } = useRenameRole()
  const { validateClaimActions } = useUserClaimActions()
  const actions = validateClaimActions('security.profiles', [ClaimActions.CanCreate, ClaimActions.CanView])

  const { successNotification, errorNotification } = useNotification()
  const { t: transLabel } = useTranslation('Label')

  const {
    results: { results: rolesData },
    getRoles,
  } = useRole()

  let roles: Role[] = []
  if (rolesData.length > 0) {
    if (actions.isAdmin) {
      roles = rolesData.filter((role) => !excludedRolesForUserAdmin.includes(role.name))
    } else {
      roles = rolesData.filter((role) => !excludedRolesForNotUserAdmin.includes(role.name))
    }
  }

  const {
    results: { roleScopeClaims, allowedClaimValues },
    loading: loadingRoleClaims,
    getRoleClaims,
  } = useRoleClaims()

  useEffect(() => {
    getRoles({ where: undefined, skip: 0, take: 100 })
  }, [])

  const setCurrentWorkingRole = (role: Role) => {
    setSelectedRole(role)
    setSelectedCardRole(role.name)
    getRoleClaims(role.id)
  }

  // Set initial card role with first role in array
  if (isFirstLoad === true && roles.length > 0) {
    setCurrentWorkingRole(roles[0])
    setIsFirstLoad(false)
  }

  const openRoleForm = () => {
    setSelectedRole(initialRoleValues)
    toggleRoleForm()
  }

  const toggleRoleForm = () => {
    setShowRoleForm(!showRoleForm)
  }

  const saveRole = useCallback(
    (role: Role) => {
      toggleRoleForm()
      if (role.id === '') {
        createRole({
          variables: {
            name: role.name,
          },
        })
          .then((res) => {
            const result = _lodash.get(res, 'data.createRole.succeeded', false)
            if (result === true) {
              successNotification(transLabel('ROLE_CREADO'))
              getRoles({ where: undefined, skip: 0, take: 100 })
              setCurrentWorkingRole(role)
            }
          })
          .catch((e) => {
            errorNotification(transLabel('ERROR_CREANDO_EL_ROLE'))
          })
      } else {
        renameRole({
          variables: {
            renameRole: {
              from: selectedRole.name,
              to: role.name,
            },
          },
        })
          .then((res) => {
            const result = _lodash.get(res, 'data.renameRole.succeeded', false)
            if (result === true) {
              successNotification(transLabel('DATO_ACTUALIZADO'))
              getRoles({ where: undefined, skip: 0, take: 100 })
            } else {
              errorNotification(transLabel('ERROR_ACTUALIZANDO_EL_ROLE'))
            }
          })
          .catch((e) => {})
      }
    },
    [selectedRole],
  )

  const selectToEdit = (role: Role) => {
    setSelectedRole(role)
    toggleRoleForm()
  }

  const setSelectedCard = (role: Role) => {
    setSelectedRole(role)
    setSelectedCardRole(role.name)
    getRoleClaims(role.id)
  }

  // const buildContainerData = useMemo(() => {
  //   let container: ReactElement[] = []
  //   let allData: Array<ReactElement[]> = []
  //   let containerData = []
  //   let dataLength = roles.length

  //   if (dataLength <= 4) {
  //     container = roles.map((role, index) => {
  //       return (
  //         <RoleContainer
  //           role={role}
  //           grid={12}
  //           key={index}
  //           onSelectToEdit={() => selectToEdit(role)}
  //           onSelectRole={() => setSelectedCard(role)}
  //           isSelected={role.name == selectedCardRole}
  //         />
  //       )
  //     })
  //     allData.push(container)
  //     return allData
  //   } else {
  //     let container: Array<ReactElement | any> = []
  //     let count = 1

  //     roles.forEach((role, index) => {
  //       container.push(
  //         <RoleContainer
  //           role={role}
  //           grid={3}
  //           key={index}
  //           onSelectToEdit={() => selectToEdit(role)}
  //           onSelectRole={() => setSelectedCard(role)}
  //           isSelected={role.name == selectedCardRole}
  //         />,
  //       )
  //       if (count % 3 === 0) {
  //         allData.push(container)
  //         count = 1
  //         container = []
  //       } else {
  //         count++
  //       }
  //     })
  //     return allData
  //   }
  // }, [roles, selectedCardRole])

  const buildContainerData = useMemo(() => {
    let container: ReactElement[]

    container = roles.map((role, index) => {
      return (
        <RoleContainer
          role={role}
          grid={12}
          key={index}
          onSelectToEdit={() => selectToEdit(role)}
          onSelectRole={() => setSelectedCard(role)}
          isSelected={role.name === selectedCardRole}
        />
      )
    })

    return container
  }, [roles, selectedCardRole])

  const buildContainerDataMobile = useMemo(() => {
    let container: ReactElement[]

    container = roles.map((role, index) => {
      return (
        <RoleContainer
          role={role}
          grid={12}
          key={index}
          onSelectToEdit={() => selectToEdit(role)}
          onSelectRole={() => setSelectedCard(role)}
          isSelected={role.name === selectedCardRole}
        />
      )
    })

    return container
  }, [roles, selectedCardRole])

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <AppBreadcrumbsBar
              title={transLabel('ROLES_DE_USUARIOS')}
              description={transLabel('ESTABLECE_LOS_PERMISOS_DE_LOS_USUARIOS')}
              actions={actions.canCreate ? <CreateButton onClick={openRoleForm} /> : null}
            />
          </Grid>
          {roles.length > 0 ? (
            <Grid item xs={12} className={classes.roleCarousel}>
              <Carousel
                animation={'slide'}
                timeout={500}
                fullHeightHover={true}
                swipe={isMobile()}
                navButtonsAlwaysVisible={!isMobile()}
                autoPlay={false}
                next={(next: string, active: boolean) => {}}
                prev={(prev: string, active: boolean) => {}}
              >
                {isMobile()
                  ? buildContainerDataMobile.map((element, index) => (
                      <div key={index} className={classes.roleMainContent}>
                        {' '}
                        {element}{' '}
                      </div>
                    ))
                  : buildContainerData.map((element, index) => (
                      <div key={index} className={classes.roleMainContent}>
                        {element}
                      </div>
                    ))}
              </Carousel>
            </Grid>
          ) : null}
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Loader loading={loadingRoleClaims}>
            {roles.length === 0 && !loadingRoleClaims && !actions.isAdmin ? (
              <Grid container spacing={2}>
                <EmptyData text="CONTACTAR_ADMINISTRACION" />
              </Grid>
            ) : null}
            {useMemo(() => {
              return (
                <Permissions
                  role={selectedRole}
                  allowedClaimValues={allowedClaimValues}
                  roleScopeClaims={roleScopeClaims}
                />
              )
            }, [allowedClaimValues, roleScopeClaims])}
          </Loader>
        </Grid>
      </Grid>

      <AddRole open={showRoleForm} submit={saveRole} cancel={() => setShowRoleForm(false)} initialRole={selectedRole} />
    </div>
  )
}
