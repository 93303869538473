import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { CustomAutoCompleteSelectFormik } from '../selects'
import { useSectors } from './hooks'
import { generateFilterGraphql } from '../..'
import { FormProps } from '../../models'
import { Sector } from './model'

export interface SectorSelectProps extends FormProps {
  label?: string
  countryId: number
  provinceId: number
  municipalityId: number
  disabled?: boolean
}

export const SectorSelect = ({ countryId, provinceId, municipalityId, disabled, ...props }: SectorSelectProps) => {
  const {
    form: { setFieldValue, values },
    field: { name },
  } = props

  const { getSectors, results, loading } = useSectors()
  const { t: transLabel } = useTranslation('Label')

  useEffect(() => {
    filterSectors('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryId, provinceId, municipalityId])

  const filterSectors = (sector: string, skip = 0, take = 100) => {
    const customFilters = {
      and: [{ value: { ...generateFilterGraphql(sector, 'contains') } }],
    }

    getSectors({ where: customFilters, skip, take }, countryId, provinceId, municipalityId)
  }

  const handleFilter = () => {}

  const handleChange = useCallback(
    (value: Sector | null) => {
      setFieldValue(name, value ? value.key : '')
    },
    [setFieldValue, name],
  )

  let defaultValue = null
  if (results.results.length > 0) {
    const filter = results.results.filter((item: any) => item.key === values[name])
    if (filter.length > 0) {
      defaultValue = filter[0]
    }
  }

  return (
    <CustomAutoCompleteSelectFormik
      {...props}
      disabled={disabled}
      defaultValue={defaultValue}
      data={results.results}
      keyValue={'value'}
      keyLabel={'value'}
      isLoading={loading}
      label={transLabel('SECTOR')}
      onChangeValue={handleChange}
      onInputValueChange={handleFilter}
    />
  )
}
