import React from 'react'
import clsx from 'clsx'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'

const drawerWidth = 270

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      // width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      '& .MuiDrawer-paper': {
        '-webkit-transition': '0.2s !important',
        marginTop: 60,
      },
    },
    drawerOpen: {
      width: drawerWidth,
      transition: 'width 0.5s',
      height: '-webkit-fill-available',
    },
    drawerClose: {
      overflowX: 'hidden',
      transition: 'width 0.5s',
      width: theme.spacing(7) + 1,
      height: '-webkit-fill-available',
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1,
      },
    },
  }),
)

type CustomMiniDrawerProps = {
  children: JSX.Element | JSX.Element[]
  open: boolean
}

export const CustomMiniDrawer = ({ open, children }: CustomMiniDrawerProps) => {
  const classes = useStyles()

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      {children}
    </Drawer>
  )
}
