import { gql, useMutation } from '@apollo/client'

const ASSIGN_USER_ROLE = gql`
  mutation AssignUserToRole($input: AssignUserInput!) {
    assignUserToRole(input: $input) {
      succeeded
      errors {
        code
        description
      }
    }
  }
`
export interface AssignUserInput {
  role: string
  userName: string
}

export const useAssignUserRole = () => {
  const [assignUserRole, { loading, error }] = useMutation<AssignUserInput>(ASSIGN_USER_ROLE)
  return { assignUserRole, loading, error }
}
