import React, { useCallback, useEffect } from 'react'
import { makeStyles, createStyles, Theme, Card, CardHeader, CardContent, Divider, Button } from '@material-ui/core'
import _lodash from 'lodash'
import { useTranslation } from 'react-i18next'
import { HeaderCardTitle, CustomDataGrid, DataGridColumns, Loader } from '../../../core/components'
import { AddBox } from '@material-ui/icons'
import { useWorkstations } from './hooks'
import { ClaimActions, generateFilterGraphql, useUserClaimActions } from '../../../core'
import { GridRowParams } from '@material-ui/data-grid'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    grid: {
      paddingBottom: theme.spacing(2),
    },
    accordionContainer: {
      paddingTop: 20,
    },
    divider: {
      marginTop: -22,
    },
    saveButton: {
      marginRight: 5,
    },
  }),
)

type StationListProps = {
  locationId: number
  onNewStation: () => void
  onSelectedStation: (station: any) => void
}

export const StationList = ({ locationId, onNewStation, onSelectedStation }: StationListProps) => {
  const classes = useStyles()
  const { results: locations, loading, getWorkstations } = useWorkstations()
  const { validateClaimActions } = useUserClaimActions()
  const actions = validateClaimActions('configuration.agencies', [ClaimActions.CanCreate])

  const { t: transLabel } = useTranslation('Label')

  useEffect(() => {
    fetchWorkstationsByLocations(locationId)
  }, [locationId])

  const fetchWorkstationsByLocations = (locationId: number) => {
    const customFilters = {
      and: [{ locationId: { ...generateFilterGraphql(locationId, 'eq') } }],
    }

    getWorkstations({ where: customFilters, skip: 0, take: 100 })
  }

  const rowSelectHandler = (params: GridRowParams) => {
    onSelectedStation(params.row)
  }

  const getColumnDefinitions = useCallback((): DataGridColumns => {
    return {
      NOMBRE: { field: 'name', contentType: 'string' },
      ESTADO: { field: 'active', contentType: 'boolean' },
    }
  }, [])

  return (
    <Card>
      <CardHeader
        title={<HeaderCardTitle title={transLabel('ESTACIONES')} />}
        action={
          <React.Fragment>
            {actions.canCreate && (
              <Button variant="contained" size="small" color="primary" onClick={onNewStation}>
                <AddBox /> {transLabel('NUEVA_ESTACION')}
              </Button>
            )}
          </React.Fragment>
        }
      />
      <Divider className={classes.divider} />
      <CardContent>
        <Loader loading={loading}>
          <div style={{ minHeight: '70vh', width: '100%' }}>
            <CustomDataGrid
              data={locations}
              columns={getColumnDefinitions()}
              pageSize={20}
              onRowClick={rowSelectHandler}
            />
          </div>
        </Loader>
      </CardContent>
    </Card>
  )
}
