import React, { useEffect, useState } from 'react'
import { Grid, Card, CardContent, Typography, FormControl, makeStyles, createStyles, Divider } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { LocalizedContentSelect, CustomDatePicker as DateField, TextFieldDebounce } from '../../../core/components'
import { Formik, Field } from 'formik'
import { generateFilterGraphql, useLocalizedContent } from '../../../core'
import { GraphqlExpression, SearchGraphql } from '../../../core/models'

const useStyles = makeStyles(() =>
  createStyles({
    datesClass: {
      marginTop: '0px !important',
    },
  }),
)

export interface IAgenciesOperationsFilter {
  fecha: string
  divisa: string
  matchString: string
}

interface AgenciesOperationsFilterProps {
  onChange: (newFilter: SearchGraphql) => void
}

export const AgenciesOperationsFilter = ({ onChange }: AgenciesOperationsFilterProps) => {
  const { t: translationLabel } = useTranslation('Label')
  const { results: resultCurrency } = useLocalizedContent({ entityType: 'System', entityName: 'Currency' })
  const { datesClass } = useStyles()
  const [currentDateSelected, setCurrentDateSelected] = useState<string>(moment().format('MM-DD-YYYY'))
  const [eventDate, setEventDate] = useState<GraphqlExpression>({ eq: moment().format('MM-DD-YYYY') })
  const [currencyName, setCurrencyName] = useState<GraphqlExpression>({ eq: 'PESOS_DOMINICANOS' })
  const [matchString, setMatchString] = useState<GraphqlExpression>({ contains: '' })

  function onChangeDate(eventDate: string) {
    setCurrentDateSelected(eventDate)
    setEventDate(generateFilterGraphql(moment(eventDate).format('YYYY-MM-DD'), 'eq'))
  }

  function onChangeCurrency(currencyName: string) {
    setCurrencyName(generateFilterGraphql(currencyName, 'eq'))
  }
  function onChangeMatchString(matchString: string) {
    setMatchString(generateFilterGraphql(matchString, 'contains'))
  }

  useEffect(() => {
    onChange({
      where: {
        currencyName,
        eventDate,
        locationName: matchString,
      },
    })
  }, [currencyName, eventDate, matchString, onChange])

  return (
    <Card>
      <CardContent>
        <Typography variant="h6">{translationLabel('CRITERIOS_DE_BUSQUEDA')}</Typography>
      </CardContent>
      <Divider />
      <CardContent>
        <Formik
          initialValues={{
            eventDate: moment().format('MM-DD-YYYY'),
            currencyName: 'PESOS_DOMINICANOS',
            matchString: '',
          }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(false)
          }}
        >
          {() => (
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Field
                  component={DateField}
                  name="eventDate"
                  label={translationLabel('FECHA')}
                  format={'dd/MM/yyyy'}
                  fullWidth
                  customClassName={datesClass}
                  onDateChange={onChangeDate}
                  value={currentDateSelected}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <FormControl fullWidth>
                  <Field
                    component={LocalizedContentSelect}
                    name="currencyName"
                    isGrupalValue
                    label={translationLabel('DIVISA')}
                    data={resultCurrency}
                    fullWidth
                    onChange={onChangeCurrency}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <FormControl fullWidth>
                  <Field
                    name="matchString"
                    component={TextFieldDebounce}
                    label={translationLabel('MOSTRAR_LOS_QUE_COINCIDAD_CON')}
                    fullWidth
                    onChange={onChangeMatchString}
                  />
                </FormControl>
              </Grid>
            </Grid>
          )}
        </Formik>
      </CardContent>
    </Card>
  )
}
