import React from 'react'
import { AuthProvider } from 'pb-shared'
import { BrowserRouter } from 'react-router-dom'
import { Routes } from './core/routing/routes'
import { Provider } from 'react-redux'
import { store } from './core'
import { SnackbarProvider } from 'notistack'
import './app.css'

const App = () => {
  return (
    <Provider store={store}>
      <AuthProvider>
        <SnackbarProvider>
          <BrowserRouter children={Routes} basename={'/'} />
        </SnackbarProvider>
      </AuthProvider>
    </Provider>
  )
}

export default App
