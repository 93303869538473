import React, { useEffect, useState } from 'react'
import {
  Grid,
  makeStyles,
  createStyles,
  Theme,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Button,
  FormControl,
  FormControlLabel,
} from '@material-ui/core'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import {
  HeaderCardTitle,
  SaveButton,
  AppBreadcrumbsBar,
  ModifyButton,
  DialogAlerts,
  CheckboxFormik,
} from '../../../../core/components'
import { useTranslation } from 'react-i18next'
import { useFormik, Field, Form, FormikProvider } from 'formik'
import { TextField } from 'formik-material-ui'
import * as Yup from 'yup'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { AppState } from '../../../../core/redux/store'
import { useUserClaimActions, ClaimActions } from '../../../../core'
import { ProviderSelect } from '../ProviderSelect'
import { ProviderConnection } from '../models'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiAutocomplete-root': {
        width: 'auto',
      },
    },
    fieldItem: {
      marginBottom: theme.spacing(2),
    },
    buttonActions: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    labelEditActive: {
      color: '#ff6600',
    },
    labelEditDisabled: {
      color: '#000000',
    },
    divider: {
      marginTop: -22,
    },
    addressDiv: {
      paddingTop: 5,
    },
  }),
)

const initialFormValues = {
  id: 0,
  providerId: 0,
  apiBaseUrl: '',
  accessKey: '',
  mapping: '',
  lastSyncDate: new Date(),
  version: '',
  isActive: false,
}

type ConnectionFormProps = {
  onSave: (values: ProviderConnection) => void
  connection?: undefined | ProviderConnection
  initialProviderId?: number | undefined
}

export const ConnectionForm = ({ connection, onSave, initialProviderId }: ConnectionFormProps) => {
  const classes = useStyles()
  const [isEditing, setIsEditing] = useState(false)
  const [alertForm, setAlertForm] = useState(false)
  const { t: transLabel } = useTranslation('Label')
  const history = useHistory()
  const { validateClaimActions } = useUserClaimActions()
  const actions = validateClaimActions('configuration.connections', [ClaimActions.CanCreate, ClaimActions.CanEdit])

  const formSchema = Yup.object().shape({
    apiBaseUrl: Yup.string().required(transLabel('REQUERIDO')),
  })

  const formik = useFormik({
    validationSchema: formSchema,
    initialValues: connection || initialFormValues,
    onSubmit: onSave,
    enableReinitialize: true,
  })

  window.sessionStorage.setItem('hasFormChanges', formik.dirty ? 'true' : 'false')

  useEffect(() => {
    if (initialProviderId && !connection) {
      formik.setFieldValue('providerId', initialProviderId)
    }
  }, [initialProviderId])

  const goToConnections = () => {
    if (formik.dirty === false) {
      history.push(`/admin/entities/provider-connections`)
    } else {
      setAlertForm(true)
    }
  }

  const disableFields = !isEditing && connection

  return (
    <FormikProvider value={formik}>
      <Form>
        <Grid item xs={12}>
          <AppBreadcrumbsBar
            title={transLabel('PROVEEDOR_CONEXIONES_FORM_TITULO')}
            description={transLabel('PROVEEDOR_CONEXIONES_FORM_SUB_TITULO')}
            actions={
              <React.Fragment>
                {connection && connection.id && isEditing === false && actions.canEdit ? (
                  <ModifyButton className={classes.buttonActions} onClick={() => setIsEditing(true)} />
                ) : (
                  (actions.canEdit || actions.canCreate) && (
                    <SaveButton
                      className={classes.buttonActions}
                      onClick={formik.submitForm}
                      isLoading={false}
                      disabled={!(formik.dirty && formik.dirty)}
                    />
                  )
                )}
                <Button
                  variant="contained"
                  onClick={goToConnections}
                  startIcon={<KeyboardBackspaceIcon className={classes.buttonActions} />}
                >
                  {transLabel('REGRESAR')}
                </Button>
              </React.Fragment>
            }
          />
        </Grid>

        <Card>
          <CardHeader title={<HeaderCardTitle title={transLabel('DATOS_DE_LA_CONEXION')} />} />
          <Divider className={classes.divider} />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Field component={ProviderSelect} name={'providerId'} disabled={disableFields} />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Field
                  component={TextField}
                  type="text"
                  label={transLabel('URL')}
                  name="apiBaseUrl"
                  fullWidth
                  disabled={disableFields}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Field
                  component={TextField}
                  type="text"
                  label={transLabel('VERSION')}
                  name="version"
                  fullWidth
                  disabled={disableFields}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Field
                  component={TextField}
                  type="text"
                  label={transLabel('ACCESS_KEY')}
                  name="accessKey"
                  fullWidth
                  disabled={disableFields}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl fullWidth>
                  <FormControlLabel
                    control={
                      <Field component={CheckboxFormik} name="isActive" color="primary" disabled={disableFields} />
                    }
                    label={transLabel('ACTIVO')}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <DialogAlerts
          state={alertForm}
          titulo={'SE_HAN_DETECTADO_CAMBIOS_EN_EL_FORMULARIO'}
          contenido={'SI_CONTINUA_PERDERA_LOS_CAMBIOS_EN_EL_FORMULARIO_DESEA_CONTINUAR'}
          tipo="PREGUNTA"
          onSecondaryClick={() => setAlertForm(false)}
          handleClose={() => setAlertForm(false)}
          onPrimaryClick={() => history.push(`/admin/entities/provider-connections`)}
        />
      </Form>
    </FormikProvider>
  )
}
