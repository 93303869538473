import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Entity, GraphqlExpression, SearchGraphql } from '../../../core/models'
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
  FormControl,
  makeStyles,
  createStyles,
} from '@material-ui/core'
import {
  HeaderCardTitle,
  LocalizedContentSelect,
  CustomDatePicker as DateField,
  EntitySelect,
} from '../../../core/components'
import { useTranslation } from 'react-i18next'
import { generateFilterGraphql, useLocalizedContent, useLocation } from '../../../core'
import { Formik, Field } from 'formik'

const useStyles = makeStyles(() =>
  createStyles({
    datesClass: {
      marginTop: '0px !important',
    },
  }),
)

type currentWeekType = {
  start: string
  end: string
}

type WeeklyBalanceFilterProps = {
  onChange: (filter: SearchGraphql) => void
  getWeeksDate: (weeks: currentWeekType) => void
}

const WeeklyBalanceFilter = ({ onChange, getWeeksDate }: WeeklyBalanceFilterProps) => {
  const { t: translationAction } = useTranslation('Action')
  const { t: translationLabel } = useTranslation('Label')
  const [currentDateSelected, setCurrentDateSelected] = useState<string>(
    moment().startOf('isoWeek').format('YYYY-MM-DD'),
  )
  const [currencyId, setCurrencyId] = useState<GraphqlExpression>({ eq: 0 })
  const [location, setLocation] = useState<GraphqlExpression>({ eq: 0 })
  const [lunDate, setLunDate] = useState<GraphqlExpression>({ eq: moment().startOf('isoWeek').format('YYYY-MM-DD') })
  const { results: resultCurrency } = useLocalizedContent({ entityType: 'System', entityName: 'Currency' })
  const { results } = useLocation()

  const { datesClass } = useStyles()

  function getCurrentWeek(date: string): void {
    const monday: string = moment(date, 'YYYY-MM-DD').startOf('isoWeek').format('YYYY-MM-DD')
    const sunday: string = moment(date, 'YYYY-MM-DD').endOf('isoWeek').format('YYYY-MM-DD')

    getWeeksDate({
      start: moment(monday, 'YYYY-MM-DD').locale('es-do').format('DD-MM-YYYY'),
      end: moment(sunday, 'YYYY-MM-DD').locale('es-do').format('DD-MM-YYYY'),
    })
  }

  function getMondayCurrentWeek(date: string): void {
    const monday: string = moment(date, 'YYYY-MM-DD').startOf('isoWeek').format('YYYY-MM-DD')
    onChangeDate(date)
    setCurrentDateSelected(monday)
  }

  function onChangeDate(eventDate: string) {
    const monday = moment(eventDate).startOf('isoWeek').format('YYYY-MM-DD')
    const newEventDate = generateFilterGraphql(monday, 'eq')
    setLunDate(newEventDate)
    setCurrentDateSelected(monday)
    getCurrentWeek(eventDate)
  }

  function onChangeCurrency(currencyId: string) {
    setCurrencyId(generateFilterGraphql(Number.parseInt(currencyId), 'eq'))
  }

  function onChangeLocation(value: Entity) {
    setLocation(generateFilterGraphql(value.name, 'eq'))
  }

  useEffect(() => {
    const filters: any = {
      salesDate: lunDate,
    }
    if (location.eq > 0) {
      filters.locationId = location
    }
    if (currencyId.eq > 0) {
      filters.currencyId = currencyId
    }
    const filter = {
      where: {
        and: filters,
      },
    }

    onChange(filter)
  }, [currencyId, lunDate, location])

  return (
    <Card>
      <CardHeader component={() => <HeaderCardTitle title={translationLabel('CRITERIOS_DE_BUSQUEDA')} />} />
      <Divider />
      <CardContent>
        <Formik
          initialValues={{
            eventDate: moment().format('YYYY-MM-DD'),
            currencyId: 0,
            coincidencias: '',
            location: '',
          }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(false)
          }}
        >
          {() => (
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <FormControl fullWidth>
                  <Field
                    component={EntitySelect}
                    name="currencyId"
                    isGrupalValue
                    label={translationLabel('DIVISA')}
                    data={resultCurrency}
                    fullWidth
                    onChange={onChangeCurrency}
                    keyLabel={'grupal'}
                    keyValue={'entityId'}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={2}>
                <FormControl fullWidth>
                  <Field
                    component={EntitySelect}
                    name="location"
                    isGrupalValue
                    label={translationLabel('AGENCIAS')}
                    data={results}
                    fullWidth
                    onChange={onChangeLocation}
                    showNoneOption
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <Field
                  component={DateField}
                  name="eventDate"
                  label="Fecha"
                  format={'dd/MM/yyyy'}
                  fullWidth
                  customClassName={datesClass}
                  onDateChange={onChangeDate}
                  value={currentDateSelected}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={1}>
                <Typography variant="h6">Semana:</Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    const lastWeek = moment(currentDateSelected, 'YYYY-MM-DD').subtract(7, 'days').format('YYYY-MM-DD')
                    getMondayCurrentWeek(lastWeek)
                  }}
                >
                  {translationAction('ANTERIOR')}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    const currentDate = moment().format('YYYY-MM-DD')
                    getMondayCurrentWeek(currentDate)
                  }}
                >
                  {translationAction('ACTUAL')}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    const nextWeek = moment(currentDateSelected, 'YYYY-MM-DD').add(7, 'days').format('YYYY-MM-DD')
                    getMondayCurrentWeek(nextWeek)
                  }}
                >
                  {translationAction('SIGUIENTE')}
                </Button>
              </Grid>
            </Grid>
          )}
        </Formik>
      </CardContent>
    </Card>
  )
}

export default WeeklyBalanceFilter
