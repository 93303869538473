import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Card, CardHeader } from '@material-ui/core'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import InfoIcon from '@material-ui/icons/Info'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    '& .MuiCardHeader-root': {
      background: '#e6b4b0',
    },
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  actionText: {
    fontWeight: 'bold',
    fontSize: 'initial',
  },
  headerTitle: {
    display: 'flex',
    color: '#b3615a',
    fontSize: 20,
  },
})

type PageErrorProps = {
  code?: number
  message?: string
}

export const PageError = ({ code, message }: PageErrorProps) => {
  const classes = useStyles()
  const { t: transSystem } = useTranslation('System')
  const { t: transLabel } = useTranslation('Label')
  const history = useHistory()

  const goToHomePage = () => {
    history.push(`/admin/statistics/sales-profit-dashboard`)
  }

  return (
    <Card className={classes.root}>
      <CardHeader
        title={
          <Typography className={classes.headerTitle} color="textSecondary" gutterBottom>
            <InfoIcon /> <label>{transLabel('INFORMACION')}</label>
          </Typography>
        }
        action={
          <Typography className={classes.actionText} color="textSecondary" gutterBottom>
            {transLabel('CODIGO')} {code}
          </Typography>
        }
      />
      <CardContent>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          {transSystem(message ? message : '')}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" variant="outlined" onClick={goToHomePage}>
          {transLabel('VOLVER_AL_INICIO')}
        </Button>
        <Button size="small" variant="outlined" disabled={true}>
          {transLabel('CONTACTAR_ADMINISTRADOR')}
        </Button>
      </CardActions>
    </Card>
  )
}
