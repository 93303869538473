import React, { useCallback, useState } from 'react'
import { GridCellParams, GridPageChangeParams, GridRowParams } from '@material-ui/data-grid'
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core'
import { Metadata, ResultsList } from '../../../../core/models'
import { CustomDataGrid, DataGridColumns, DialogAlerts, Loader } from '../../../../core/components'
import { useTranslation } from 'react-i18next'
import { Event, Race } from '../models'
import { formatToShortLocalDate } from '../../../../core'
import { IconButton } from '@material-ui/core'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      '& .MuiDataGrid-columnsContainer': {
        background: '#bdbdbd',
      },
    },
    grid: {
      paddingBottom: theme.spacing(2),
    },
  }),
)

type RacesProps = {
  onSelectRow: (race: Race) => void
  races: ResultsList<Race, Metadata>
  isSelectionDisabled?: boolean
  event: Event
  onSelectToRemove: (row: GridCellParams) => void
}

export const Races = ({ onSelectRow, races, isSelectionDisabled, event, onSelectToRemove }: RacesProps) => {
  const classes = useStyles()
  const { t: transLabel } = useTranslation('Label')

  const [paging, setPaging] = useState<GridPageChangeParams>({
    page: 0,
    pageCount: 0,
    pageSize: 10,
    paginationMode: 'server',
    rowCount: 10,
  })

  const getColumnDefinitions = useCallback((): DataGridColumns => {
    return {
      DE_CARRERA_NO: { field: 'raceNumber', contentType: 'number' },
      HORA: { field: 'postTime', contentType: 'time' },
      DESCRIPCION: { field: 'description', contentType: 'string' },
      ESTADO: { field: 'isActive', contentType: 'boolean' },
      ACTION: {
        field: '',
        contentType: 'custom',
        renderCell: (params: GridCellParams) => {
          return (
            <React.Fragment>
              <IconButton onClick={() => onSelectToRemove(params)} color={'secondary'} disabled={!event.isActive}>
                <DeleteForeverIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          )
        },
      },
    }
  }, [])

  const handleRowSelected = (params: GridRowParams) => {
    if (!isSelectionDisabled) {
      onSelectRow(params.row as Race)
    }
  }

  return (
    <div className={classes.root}>
      <Typography variant="h5">{`${transLabel('LISTA_DE_CARRERAS_DE_FECHA')} ${formatToShortLocalDate(
        event.date,
      )}`}</Typography>
      <div style={{ width: '100%' }}>
        <CustomDataGrid
          data={races}
          columns={getColumnDefinitions()}
          pageSize={25}
          onRowClick={handleRowSelected}
          hideFooterPagination={true}
        />
      </div>
    </div>
  )
}
