import React from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import {
  Link,
  List,
  ListItem,
  ListItemText,
  Divider,
  Typography,
  Avatar,
  CardActions,
  CardContent,
  CardHeader,
  Card,
  ListItemAvatar,
} from '@material-ui/core'
import WorkIcon from '@material-ui/icons/Work'
import PlaceBetIcon from '../../../svgIcons/PlaceBet/PlaceBetIcon'
import { LogoutButton } from '../../buttons'
import { Profile } from './ProfileHeader'
import { useTranslation } from 'react-i18next'
import { Enterprise } from '../../../../scenes/entities/enterprises/models'
import { useSelector } from 'react-redux'
import { AppState } from '../../..'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 345,
      '& .MuiAvatar-root': {
        width: 35,
        height: 35,
      },
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    iconPlaceBet: {
      width: '9rem !important',
    },
    logout: {
      color: '#837d7d',
    },
    typography: {
      padding: theme.spacing(2),
    },

    avatar: {
      width: theme.spacing(9),
      height: theme.spacing(9),
    },
    profileContentRoot: {
      display: 'flex',
      width: '100%',
    },
    labelUser: {
      color: '#525252',
      fontSize: '1rem',
    },
    labelEmail: {
      color: '#969696',
    },
    profileContent: {
      display: 'flex',
      flexDirection: 'column',
      minWidth: 250,
      paddingLeft: 25,
    },

    profileCompaniesRoot: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: '#ececec',
      '& .MuiListItem-root:hover': {
        backgroundColor: '#d8d8d8',
        cursor: 'pointer',
      },
    },

    cardAction: {
      backgroundColor: '#ececec',
    },
  }),
)

const getInitialLettersFromName = (name: string) => {
  if (name === '') return ''

  const splitName = name.split(' ')

  return `${splitName.length > 0 ? splitName[0][0] : ''} ${splitName.length > 1 ? splitName[1][0] : ''}`
}

type ProfileCardProps = {
  profile: Profile
  onSelectEnterprise?: (enterprise: Enterprise) => void
}

export const ProfileCard = ({ profile, onSelectEnterprise }: ProfileCardProps) => {
  const classes = useStyles()
  const { t: transLabel } = useTranslation('Label')
  const { enterprises } = useSelector((state: AppState) => state.appBar)

  const handleChangeEnterprise = (enterprise: Enterprise) => {
    if (onSelectEnterprise && onSelectEnterprise instanceof Function) {
      onSelectEnterprise(enterprise)
    }
  }

  return (
    <React.Fragment>
      <Card className={classes.root}>
        <CardHeader
          avatar={<PlaceBetIcon classes={classes.iconPlaceBet} />}
          action={
            <div className={classes.logout}>
              <LogoutButton />
            </div>
          }
        />

        <CardContent>
          <div className={classes.profileContentRoot}>
            <Avatar alt={'avatar-profile'} className={classes.avatar}>
              {getInitialLettersFromName(profile.name)}
            </Avatar>

            <div className={classes.profileContent}>
              <Typography variant="h6" className={classes.labelUser}>
                {`${profile.fullName}`}
              </Typography>
              <Typography variant="body2">
                <Link component="button" variant="body2">
                  {transLabel('MI_CUENTA')}
                </Link>
              </Typography>
            </div>
          </div>
        </CardContent>
        <CardActions disableSpacing className={classes.cardAction}>
          <List className={classes.profileCompaniesRoot}>
            {enterprises.map((item: Enterprise, index: number) => {
              return (
                <div key={index}>
                  <ListItem
                    onClick={() => handleChangeEnterprise(item)}
                    style={{
                      paddingTop: 4,
                      paddingBottom: 4,
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <WorkIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={item.name} secondary="" />
                  </ListItem>
                  {index + 1 < enterprises.length && <Divider />}
                </div>
              )
            })}
          </List>
        </CardActions>
      </Card>
    </React.Fragment>
  )
}
