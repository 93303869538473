import React, { useCallback } from 'react'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { useTranslation } from 'react-i18next'
import { FormProps, AccountManager } from '../../models'

export interface AccountManagersSelectProps extends FormProps {
  label: string
  data: AccountManager[]
}
export const AccountManagersSelect = ({ label, data, ...props }: AccountManagersSelectProps) => {
  const {
    form: { setFieldValue, values },
    field: { name },
  } = props

  // App Label Translation
  const { t: translation } = useTranslation('Label')

  const handleChange = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      setFieldValue(name, event.target.value)
    },
    [setFieldValue, name],
  )

  return (
    <React.Fragment>
      <InputLabel id={name}>{label}</InputLabel>
      <Select {...props} labelId={name} id={name} value={values[name]} onChange={handleChange}>
        {data
          ? data.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {translation(item.name)}
              </MenuItem>
            ))
          : null}
      </Select>
    </React.Fragment>
  )
}
