import { Agency } from './models'
import { gql, useLazyQuery } from '@apollo/client'
import { Metadata, ResultsList, SearchGraphql } from '../../../core/models'
import { useState, useEffect, useCallback } from 'react'

const GET_AGENCY_SITUATION = gql`
  query GetAgenciesFinancialSituation($where: AgenciesFinancialSituationFilterInput, $skip: Int, $take: Int) {
    agenciesFinancialSituation(where: $where, skip: $skip, take: $take) {
      totalCount
      items {
        id
        currencyId
        currencyName
        enterpriseId
        enterpriseName
        eventDate
        incomeCash
        incomeConfirmedTransference
        incomeCreditCard
        incomeUnconfirmedTransference
        lastBalance
        actualBalance
        locationId
        locationName
        outcomeCash
        outcomeConfirmedTransference
        outcomeCreditCard
        outcomeUnconfirmedTransference
        typeLocationStationId
        typeLocationStationName
      }
    }
  }
`

interface AgencySituationData {
  agenciesFinancialSituation: {
    totalCount: number
    items: Agency[]
  }
}

export const useAgencySituation = () => {
  const [getResults, { loading, error, data }] = useLazyQuery<AgencySituationData>(GET_AGENCY_SITUATION)

  const [results, setResults] = useState<ResultsList<Agency, Metadata>>({
    results: [],
    metadata: {},
  })

  const getAgencySituation = useCallback(
    ({ where, take, skip }: SearchGraphql) => {
      getResults({ variables: { where, take, skip } })
    },
    [getResults],
  )

  useEffect(() => {
    setResults({
      results: data?.agenciesFinancialSituation.items || [],
      metadata: {
        Count: data?.agenciesFinancialSituation.totalCount,
      },
    })
  }, [data, loading])

  return { loading, error, results, getAgencySituation }
}
