import React, { useCallback } from 'react'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { Entity, FormProps } from '../../models'
import { useTranslation } from 'react-i18next'
export interface EntitySelectProps extends FormProps {
  label: string
  data: Entity[]
  showNoneOption?: boolean
  onChange?: (value: string) => void
  keyLabel?: string
  keyValue?: string
}
export const EntitySelect = ({
  label,
  data,
  showNoneOption,
  keyLabel,
  keyValue,
  onChange,
  ...props
}: EntitySelectProps) => {
  const {
    form: { setFieldValue, values },
    field: { name },
  } = props
  const { t: translation } = useTranslation('Label')

  const handleChange = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      setFieldValue(name, event.target.value)
      if (onChange instanceof Function) {
        onChange(`${event.target.value}`)
      }
    },
    [setFieldValue, name, onChange],
  )

  return (
    <React.Fragment>
      <InputLabel id={name}>{label}</InputLabel>
      <Select {...props} labelId={name} id={name} value={values[name]} onChange={handleChange}>
        {showNoneOption && (
          <MenuItem value="">
            <em>{translation('NINGUNO')}</em>
          </MenuItem>
        )}
        {data
          ? data.map((item: any, index) => (
              <MenuItem key={index} value={keyValue ? item[keyValue] : item.id}>
                {keyLabel ? item[keyLabel] : item.name}
              </MenuItem>
            ))
          : null}
      </Select>
    </React.Fragment>
  )
}
