import { gql, useLazyQuery, useQuery } from '@apollo/client'
import { useCallback, useEffect, useState } from 'react'
import { Metadata, ResultsList, SearchGraphql } from '../../../../core/models'
import { Provider } from '../models'

const GET_PROVIDERS = gql`
  query GetProviders($where: ProviderFilterInput!, $skip: Int, $take: Int) {
    providers(where: $where, skip: $skip, take: $take) {
      items {
        id
        acronym
        name
        closingTime

        description
        generalInfo
        isActive
        isVirtualGame
        openingTime
        timeZone
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`

interface ProviderData {
  providers: {
    totalCount: number
    items: Provider[]
  }
}

export const useProviders = () => {
  const [getResults, { loading, error, data }] = useLazyQuery<ProviderData>(GET_PROVIDERS, {
    fetchPolicy: 'network-only',
  })

  const getProviders = ({ where, take, skip }: SearchGraphql) => {
    getResults({
      variables: {
        where: { ...where },
        skip: skip ? skip : 0,
        take: take ? take : 100,
      },
    })
  }

  const results = {
    results: data?.providers.items || [],
    metadata: {
      Count: data?.providers.totalCount,
    },
  }
  return { getProviders, loading, error, results }
}
