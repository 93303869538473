import { gql, useMutation } from '@apollo/client'

const ADD_PROGRAM_RACE = gql`
  mutation CreateProgramRace($input: AddProgramRaceInput!) {
    addProgramRace(input: $input) {
      programRace {
        id
      }
    }
  }
`
export interface AddProgramRaceInput {
  programEventId: number
  raceNumber: number
  typeOfRace: string
  purse: string
  distanceId: number
  wagersId: string
  runners: number
  genderRestriction: string
  ageRestriction: string
  weight: number
  breed: string
  course: string
  postTime: Date
  isActive: boolean
}

export const useAddProgramRace = () => {
  const [addProgramRace, { loading, error }] = useMutation<AddProgramRaceInput>(ADD_PROGRAM_RACE)
  return { addProgramRace, loading, error }
}
