import { gql, useLazyQuery } from '@apollo/client'
import { useCallback, useEffect, useState } from 'react'
import { Metadata, ResultsList, SearchGraphql } from '../../models'
import { Country, Municipality, Province, Sector } from './model'

const GET_COUNTRIES = gql`
  query GetCountries {
    countries(order: { value: DESC }) {
      key
      value
    }
  }
`

interface CountriesData {
  countries: Country[]
}

export const useCountries = () => {
  const [getResults, { loading, error, data }] = useLazyQuery<CountriesData>(GET_COUNTRIES, {
    fetchPolicy: 'network-only',
  })

  const [results, setResults] = useState<ResultsList<Country, Metadata>>({
    results: [],
    metadata: {},
  })

  const getCountries = useCallback(
    ({ where, take, skip }: SearchGraphql) => {
      getResults({
        variables: {
          skip: skip ? skip : 0,
          take: take ? take : 100,
        },
      })
    },
    [getResults],
  )

  useEffect(() => {
    setResults({
      results: data?.countries || [],
      metadata: {
        Count: data?.countries.length,
      },
    })
  }, [data?.countries, loading])

  return { loading, error, results, getCountries }
}

const GET_PROVINCES = gql`
  query GetProvinces($countryId: Int!) {
    provinces(countryId: $countryId, order: { value: DESC }) {
      key
      value
    }
  }
`

interface ProvincesData {
  provinces: Province[]
}

export const useProvinces = () => {
  const [getResults, { loading, error, data }] = useLazyQuery<ProvincesData>(GET_PROVINCES, {
    fetchPolicy: 'network-only',
  })

  const [results, setResults] = useState<ResultsList<Province, Metadata>>({
    results: [],
    metadata: {},
  })

  const getProvinces = useCallback(
    ({ where, take, skip }: SearchGraphql, countryId: number) => {
      getResults({
        variables: {
          countryId: countryId,
          skip: skip ? skip : 0,
          take: take ? take : 100,
        },
      })
    },
    [getResults],
  )

  useEffect(() => {
    setResults({
      results: data?.provinces || [],
      metadata: {
        Count: data?.provinces.length,
      },
    })
  }, [data?.provinces, loading])

  return { loading, error, results, getProvinces }
}

const GET_MUNICIPALITIES = gql`
  query GetMunicipalities($countryId: Int!, $provinceId: Int!) {
    municipalities(countryId: $countryId, provinceId: $provinceId, order: { value: DESC }) {
      key
      value
    }
  }
`

interface MunicipalitiesData {
  municipalities: Municipality[]
}

export const useMunicipalities = () => {
  const [getResults, { loading, error, data }] = useLazyQuery<MunicipalitiesData>(GET_MUNICIPALITIES, {
    fetchPolicy: 'network-only',
  })

  const [results, setResults] = useState<ResultsList<Municipality, Metadata>>({
    results: [],
    metadata: {},
  })

  const getMunicipalities = useCallback(
    ({ where, take, skip }: SearchGraphql, countryId: number, provinceId: number) => {
      getResults({
        variables: {
          countryId: countryId,
          provinceId: provinceId,
          skip: skip ? skip : 0,
          take: take ? take : 100,
        },
      })
    },
    [getResults],
  )

  useEffect(() => {
    setResults({
      results: data?.municipalities || [],
      metadata: {
        Count: data?.municipalities.length,
      },
    })
  }, [data?.municipalities, loading])

  return { loading, error, results, getMunicipalities }
}

const GET_SECTORS = gql`
  query GetSectors($countryId: Int!, $provinceId: Int!, $municipalityId: Int!) {
    sectors(countryId: $countryId, provinceId: $provinceId, municipalityId: $municipalityId, order: { value: DESC }) {
      key
      value
    }
  }
`

interface SectorsData {
  sectors: Sector[]
}

export const useSectors = () => {
  const [getResults, { loading, error, data }] = useLazyQuery<SectorsData>(GET_SECTORS, {
    fetchPolicy: 'network-only',
  })

  const [results, setResults] = useState<ResultsList<Sector, Metadata>>({
    results: [],
    metadata: {},
  })

  const getSectors = useCallback(
    ({ where, take, skip }: SearchGraphql, countryId: number, provinceId: number, municipalityId: number) => {
      getResults({
        variables: {
          countryId: countryId,
          provinceId: provinceId,
          municipalityId: municipalityId,
          skip: skip ? skip : 0,
          take: take ? take : 100,
        },
      })
    },
    [getResults],
  )

  useEffect(() => {
    setResults({
      results: data?.sectors || [],
      metadata: {
        Count: data?.sectors.length,
      },
    })
  }, [data?.sectors, loading])

  return { loading, error, results, getSectors }
}
