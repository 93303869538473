import { Route } from './models'
import {
  CreateClientAccount,
  ClientAccount,
  ClientAccounts,
  ClientAccountTransactions,
  CreateClientAccountTransactions,
  ProfitAndLosses,
  TicketDetails,
  Tickets,
  WeeklyBalances,
  Enterprises,
  Enterprise,
  EnterpriseDetail,
  AgencyOperations,
  AgencySituation,
  AgenciesOperations,
  BasicRegistrationPlays,
  MachineLocationDetails,
  MachineLocationSummarized,
  Roles,
  MachineChartRace,
  AccountSummary,
  OperationPartnerships,
  OperationLocations,
  Users,
  User,
  Agencies,
  AgencyDetails,
  Stations,
  Providers,
  Provider,
  Connections,
  Connection,
  Events,
  Event,
  Competitors,
  Competitor,
  JockeyOrKennels,
  JockeyOrKennel,
  Trainers,
  Trainer,
  Owners,
  Owner,
} from '../../scenes'

export const routes: Route[] = [
  // Consults
  {
    path: '/admin/consults/tickets/:id',
    allowedClaims: 'consults.tickets',
    Component: TicketDetails,
  },
  {
    path: '/admin/consults/tickets',
    allowedClaims: 'consults.tickets',
    Component: Tickets,
  },
  {
    path: '/admin/consults/weekly-balances',
    allowedClaims: 'consults.weekly-balances',
    Component: WeeklyBalances,
  },
  {
    path: '/admin/consults/agencies-operations',
    allowedClaims: 'consults.weekly-balances',
    Component: AgenciesOperations,
  },
  {
    path: '/admin/consults/profit-and-loss',
    allowedClaims: 'consults.profit-and-loss',
    Component: ProfitAndLosses,
  },
  {
    path: '/admin/consults/agencies-operations/:id',
    allowedClaims: 'consults.agency-situation',
    Component: AgencyOperations,
  },
  {
    path: '/admin/consults/agency-situation',
    allowedClaims: 'consults.agency-situation',
    Component: AgencySituation,
  },
  {
    path: '/admin/consults/partnership-operations',
    allowedClaims: 'consults.agencies-operations',
    Component: OperationPartnerships,
  },
  {
    path: '/admin/consults/locations-operations',
    allowedClaims: 'consults.agencies-operations',
    Component: OperationLocations,
  },
  {
    path: '/admin/consults/basic-registration-plays',
    allowedClaims: 'consults.basic-registration-plays',
    Component: BasicRegistrationPlays,
  },
  {
    path: '/admin/consults/machine-details',
    allowedClaims: 'consults.machine-details.view',
    Component: MachineLocationDetails,
  },
  {
    path: '/admin/consults/machine-summarized',
    allowedClaims: 'consults.machine-summarized',
    Component: MachineLocationSummarized,
  },
  {
    path: '/admin/consults/machine-chart',
    allowedClaims: 'consults.machine-summarized',
    Component: MachineChartRace,
  },
  {
    path: '/admin/consults/account-summary',
    allowedClaims: 'consults.profit-and-loss',
    Component: AccountSummary,
  },
  // Entities
  {
    path: '/admin/entities/client-accounts/create',
    allowedClaims: 'entities.client-accounts',
    Component: CreateClientAccount,
  },
  {
    path: '/admin/entities/client-accounts/:id',
    allowedClaims: 'entities.client-accounts',
    Component: ClientAccount,
  },
  {
    path: '/admin/entities/client-accounts',
    allowedClaims: 'entities.client-accounts',
    Component: ClientAccounts,
  },
  // Transactions
  {
    path: '/admin/transactions/client-account/create',
    allowedClaims: 'transactions.client-accounts',
    Component: CreateClientAccountTransactions,
  },
  {
    path: '/admin/transactions/client-account',
    allowedClaims: 'transactions.client-accounts',
    Component: ClientAccountTransactions,
  },
  // Entities
  {
    path: '/admin/entities/enterprises/create',
    allowedClaims: 'configuration.enterprises',
    Component: Enterprise,
  },
  {
    path: '/admin/entities/enterprises/:id',
    allowedClaims: 'configuration.enterprises',
    Component: EnterpriseDetail,
  },
  {
    path: '/admin/entities/enterprises',
    allowedClaims: 'configuration.enterprises',
    Component: Enterprises,
  },
  {
    path: '/admin/entities/agencies/:agencyId/station/:stationId',
    allowedClaims: 'configuration.agencies',
    Component: Stations,
  },
  {
    path: '/admin/entities/agencies/:agencyId/station',
    allowedClaims: 'configuration.agencies',
    Component: Stations,
  },
  {
    path: '/admin/entities/agencies/:id',
    allowedClaims: 'configuration.agencies',
    Component: AgencyDetails,
  },
  {
    path: '/admin/entities/agencies/create',
    allowedClaims: 'configuration.agencies',
    Component: AgencyDetails,
  },
  {
    path: '/admin/entities/agencies',
    allowedClaims: 'configuration.agencies',
    Component: Agencies,
  },
  // Provider
  {
    path: '/admin/entities/provider-connections/:id',
    allowedClaims: 'configuration.connections',
    Component: Connection,
  },
  {
    path: '/admin/entities/provider-connections/create',
    allowedClaims: 'configuration.connections',
    Component: Connection,
  },
  {
    path: '/admin/entities/provider-connections',
    allowedClaims: 'configuration.connections',
    Component: Connections,
  },
  {
    path: '/admin/entities/providers/:providerId/connections/:id',
    allowedClaims: 'configuration.connections',
    Component: Connection,
  },
  {
    path: '/admin/entities/providers/:providerId/connections/create',
    allowedClaims: 'configuration.connections',
    Component: Connection,
  },
  {
    path: '/admin/entities/providers/:id',
    allowedClaims: 'configuration.providers',
    Component: Provider,
  },
  {
    path: '/admin/entities/providers/create',
    allowedClaims: 'configuration.providers',
    Component: Provider,
  },
  {
    path: '/admin/entities/providers',
    allowedClaims: 'configuration.providers',
    Component: Providers,
  },
  // Events
  {
    path: '/admin/entities/events/:id',
    allowedClaims: 'configuration.events',
    Component: Event,
  },
  {
    path: '/admin/entities/events/create',
    allowedClaims: 'configuration.events',
    Component: Event,
  },
  {
    path: '/admin/entities/events',
    allowedClaims: 'configuration.events',
    Component: Events,
  },
  {
    path: '/admin/entities/events-competitors/:id',
    allowedClaims: 'configuration.competitors',
    Component: Competitor,
  },
  {
    path: '/admin/entities/events-competitors/create',
    allowedClaims: 'configuration.competitors',
    Component: Competitor,
  },
  {
    path: '/admin/entities/events-competitors',
    allowedClaims: 'configuration.competitors',
    Component: Competitors,
  },
  {
    path: '/admin/entities/events-jockeyOrKennels/:id',
    allowedClaims: 'configuration.jockey',
    Component: JockeyOrKennel,
  },
  {
    path: '/admin/entities/events-jockeyOrKennels/create',
    allowedClaims: 'configuration.jockey',
    Component: JockeyOrKennel,
  },
  {
    path: '/admin/entities/events-jockeyOrKennels',
    allowedClaims: 'configuration.jockey',
    Component: JockeyOrKennels,
  },
  {
    path: '/admin/entities/events-trainers/:id',
    allowedClaims: 'configuration.trainers',
    Component: Trainer,
  },
  {
    path: '/admin/entities/events-trainers/create',
    allowedClaims: 'configuration.trainers',
    Component: Trainer,
  },
  {
    path: '/admin/entities/events-trainers',
    allowedClaims: 'configuration.trainers',
    Component: Trainers,
  },
  {
    path: '/admin/entities/events-owners/:id',
    allowedClaims: 'configuration.owners',
    Component: Owner,
  },
  {
    path: '/admin/entities/events-owners/create',
    allowedClaims: 'configuration.owners',
    Component: Owner,
  },
  {
    path: '/admin/entities/events-owners',
    allowedClaims: 'configuration.owners',
    Component: Owners,
  },
  // Security
  {
    path: '/admin/security/roles',
    allowedClaims: 'security.profiles',
    Component: Roles,
  },
  {
    path: '/admin/security/users/:id',
    allowedClaims: 'security.users',
    Component: User,
  },
  {
    path: '/admin/security/users/create',
    allowedClaims: 'security.users',
    Component: User,
  },
  {
    path: '/admin/security/users',
    allowedClaims: 'security.users',
    Component: Users,
  },
]
