import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Divider } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginBottom: 'auto',
      color: '#3f51b5',
    },
    titleLine: {
      background: '#3f51b5',
      height: 3,
      borderWidth: 0,
    },
  }),
)

type TitleProps = {
  text: string
}

export const Title = ({ text }: TitleProps) => {
  const classes = useStyles()

  return (
    <React.Fragment>
      <h3 className={classes.title}>{text}</h3>
      <Divider light className={classes.titleLine} />
    </React.Fragment>
  )
}
