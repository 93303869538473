import React, { useCallback, useEffect, useState } from 'react'
import { Card, CardContent, createStyles, Grid, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import WeeklyBalanceFilter from './WeeklyBalanceFilter'
import moment from 'moment'

import { ClaimActions, DailyCommission, Metadata, ResultsList, SearchGraphql } from '../../../core/models'
import { CustomDataGrid, DataGridColumns, Loader, AppBreadcrumbsBar } from '../../../core/components'

import { useWeeklyBalances } from './useWeeklyBalances'
import WeeklyBalanceChart from './WeeklyBalanceChart'
import { useTranslation } from 'react-i18next'
import { GridPageChangeParams } from '@material-ui/data-grid'
import { groupArrayOfObjectsByKey, useUserClaimActions } from '../../../core'
import { useDailyCommissions } from './useDailyCommissions'
import _ from 'lodash'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridFilter: {
      marginBottom: theme.spacing(2),
    },
    cardSpacing: {
      marginBottom: theme.spacing(2),
    },
  }),
)

type currentWeekType = {
  start: string
  end: string
}

export const WeeklyBalances = () => {
  const { validateClaimActions } = useUserClaimActions()
  const actions = validateClaimActions('consults.weekly-balances', [ClaimActions.CanSearch, ClaimActions.CanView])
  const [currentWeek, setCurrentWeek] = useState<currentWeekType>({
    start: moment().startOf('isoWeek').format('DD-MM-YYYY'),
    end: moment().endOf('isoWeek').format('DD-MM-YYYY'),
  })

  const [searchRequest, setSearchRequest] = useState<SearchGraphql>()

  const { t: translationLabel } = useTranslation('Label')

  const classes = useStyles()
  const [dailyData, setDailyData] = useState<ResultsList<any, Metadata>>({
    results: [],
    metadata: {},
  })
  const { results, loading, getDailyCommissions } = useDailyCommissions()

  useEffect(() => {
    const preparedData = prepareDailyData(results.results)
    setDailyData({
      results: preparedData,
      metadata: results.metadata,
    })
  }, [results.results])

  const prepareDailyData = (data: DailyCommission[]) => {
    const results: any[] = []
    const grouped = groupArrayOfObjectsByKey(data, (pet: any) => pet.location)
    grouped.forEach((value: DailyCommission[], key: string) => {
      const obj = {
        id: key,
        MONDAY: 0,
        TUESDAY: 0,
        WEDNESDAY: 0,
        THURSDAY: 0,
        FRIDAY: 0,
        SATURDAY: 0,
        SUNDAY: 0,
        balance: 0,
        commission: 0,
        location: key,
      }
      let bal = 0
      let comm = 0
      value.forEach((item) => {
        if (item.weekday === 'MONDAY') {
          obj.MONDAY = item.balance
          bal = item.balance + bal
          comm = item.commission + comm
        }
        if (item.weekday === 'TUESDAY') {
          obj.TUESDAY = item.balance
          bal = item.balance + bal
          comm = item.commission + comm
        }
        if (item.weekday === 'WEDNESDAY') {
          obj.WEDNESDAY = item.balance
          bal = item.balance + bal
          comm = item.commission + comm
        }
        if (item.weekday === 'THURSDAY') {
          obj.THURSDAY = item.balance
          bal = item.balance + bal
          comm = item.commission + comm
        }
        if (item.weekday === 'FRIDAY') {
          obj.FRIDAY = item.balance
          bal = item.balance + bal
          comm = item.commission + comm
        }
        if (item.weekday === 'SATURDAY') {
          obj.SATURDAY = item.balance
          bal = item.balance + bal
          comm = item.commission + comm
        }
        if (item.weekday === 'SUNDAY') {
          obj.SUNDAY = item.balance
          bal = item.balance + bal
          comm = item.commission + comm
        }
      })
      obj.balance = bal
      obj.commission = comm
      results.push(obj)
    })

    return results
  }

  const getColumnDefinitions = useCallback((): DataGridColumns => {
    return {
      AGENCIAS: { field: 'location', contentType: 'string' },
      LUN: { field: 'MONDAY', contentType: 'currency' },
      MAR: { field: 'TUESDAY', contentType: 'currency' },
      MIE: { field: 'WEDNESDAY', contentType: 'currency' },
      JUE: { field: 'THURSDAY', contentType: 'currency' },
      VIE: { field: 'FRIDAY', contentType: 'currency' },
      SAB: { field: 'SATURDAY', contentType: 'currency' },
      DOM: { field: 'SUNDAY', contentType: 'currency' },
      TOTAL: { field: 'balance', contentType: 'currency' },
      COMISION: { field: 'commission', contentType: 'currency' },
    }
  }, [])

  function onChangeFilter(filter: SearchGraphql): void {
    filter = {
      ...filter,
      skip: 0,
      take: 20,
    }
    setSearchRequest(filter)
    getDailyCommissions(filter)
  }

  function getWeeksDate({ start, end }: currentWeekType): void {
    setCurrentWeek({ start, end })
  }

  const handlePageChange = (params: GridPageChangeParams) => {
    const { pageSize, page } = params
    const newFilter = {
      ...searchRequest,
      take: pageSize,
      skip: pageSize * (page - 1),
    }

    setSearchRequest(newFilter)
    getDailyCommissions(newFilter)
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid item xs={12}>
          <AppBreadcrumbsBar
            title={translationLabel('BALANCE_SEMANAL_TITULO')}
            description={`${translationLabel('BALANCE_SEMANAL_SUBTITULO')} ${currentWeek.start} - ${currentWeek.end}`}
          />
        </Grid>
        {actions.canSearch && (
          <Grid item xs={12} className={classes.gridFilter}>
            <WeeklyBalanceFilter onChange={onChangeFilter} getWeeksDate={getWeeksDate} />
          </Grid>
        )}
        <Card className={classes.cardSpacing}>
          <CardContent>
            <Loader loading={loading}>
              <div style={{ minHeight: '35vh', width: '100%', overflow: 'auto' }}>
                <CustomDataGrid
                  // onRowClick={rowSelectHandler}
                  data={dailyData}
                  columns={getColumnDefinitions()}
                  pageSize={20}
                  onPageChange={handlePageChange}
                />
              </div>
            </Loader>
          </CardContent>
        </Card>
        {/* {!!results.results.length && (
          <Card>
            <WeeklyBalanceChart results={results.results} />
          </Card>
        )} */}
      </Grid>
    </Grid>
  )
}
