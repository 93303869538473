import { useEffect } from 'react'
import {
  Grid,
  makeStyles,
  createStyles,
  Theme,
  InputLabel,
  FormControl,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControlLabel,
} from '@material-ui/core'
import { HeaderCardTitle, CheckboxFormik, EntitySelect } from '../../../core/components'
import { useTranslation } from 'react-i18next'
import { Field, Form } from 'formik'
import { TextField } from 'formik-material-ui'
import { Workstation } from './models'
import { useWorkstationTypes } from './hooks'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fieldItem: {
      marginBottom: theme.spacing(2),
    },
    labelEditActive: {
      color: '#ff6600',
    },
    labelEditDisabled: {
      color: '#000000',
    },
    saveButton: {
      marginRight: 5,
    },
    divider: {
      marginTop: -22,
    },
    form: {
      borderTopColor: '#474747',
      borderTopStyle: 'solid',
    },
    codeCardContainer: {
      marginTop: 25,
    },
    codeCardHeader: {
      paddingBottom: 0,
      paddingTop: 8,
    },
    codeCardContent: {
      display: 'flex',
      paddingBottom: 0,
      paddingTop: 0,
    },
    copyButton: {
      marginTop: -5,
    },
  }),
)

type StationFormProps = {
  station: Workstation | undefined
  locationId: number | undefined
  disableFields: boolean
}

export const StationForm = ({ station, locationId, disableFields }: StationFormProps) => {
  const classes = useStyles()
  const { t: transLabel } = useTranslation('Label')
  const { results: stationTypes, getWorkstationTypes } = useWorkstationTypes()

  useEffect(() => {
    if (locationId) {
      getWorkstationTypes(locationId)
    }
  }, [locationId])

  return (
    <Form>
      <Card>
        <CardHeader
          title={<HeaderCardTitle title={transLabel(station ? 'EDITANDO_ESTACION' : 'CREANDO_ESTACION')} />}
        />
        <Divider className={classes.divider} />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth className={classes.fieldItem}>
                <InputLabel>{transLabel('TIPO_DE_CAJA')}</InputLabel>
                <Field
                  name={'workStationTypeId'}
                  component={EntitySelect}
                  data={stationTypes.results}
                  disabled={disableFields}
                />
              </FormControl>
              <Field
                component={TextField}
                type="text"
                label={transLabel('NOMBRE')}
                name="name"
                fullWidth
                disabled={disableFields}
              />
              <FormControlLabel
                control={<Field disabled={disableFields} component={CheckboxFormik} name="active" color="primary" />}
                label={transLabel('CAJA_ACTIVA')}
              />
              <FormControlLabel
                control={
                  <Field
                    value={true}
                    component={CheckboxFormik}
                    name="canRegister"
                    color="primary"
                    disabled={disableFields}
                  />
                }
                label={transLabel('PUEDE_REGISTRARSE')}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Form>
  )
}
