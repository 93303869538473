import { gql, useMutation } from '@apollo/client'

const ADD_PROGRAM_JOCKEY_OR_KENNEL = gql`
  mutation CreateProgramJockeyOrKennel($input: AddProgramEntityInput!) {
    addProgramJockeyOrKennel(input: $input) {
      programJockeyOrKennel {
        name
      }
    }
  }
`
export interface AddProgramJockeyOrKennelInput {
  name: string
  isActive: boolean
}

export const useAddProgramJockeyOrKennel = () => {
  const [addProgramJockeyOrKennel, { loading, error }] =
    useMutation<AddProgramJockeyOrKennelInput>(ADD_PROGRAM_JOCKEY_OR_KENNEL)
  return { addProgramJockeyOrKennel, loading, error }
}
