import React from 'react'
import clsx from 'clsx'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'

const drawerWidth = 240

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      '& .MuiDrawer-paper': {
        marginTop: 60,
      },
    },
    drawerOpen: {
      width: drawerWidth,
    },
    drawerClose: {
      width: 0,
      '& .MuiDrawer-paperAnchorLeft': {
        left: '-240px',
      },
    },
    listDrawer: {
      width: 250,
      marginTop: 65,
    },
  }),
)

type CustomDrawerFloatingProps = {
  children: JSX.Element | JSX.Element[]
  open: boolean
}

export const CustomDrawerFloating = ({ open, children }: CustomDrawerFloatingProps) => {
  const classes = useStyles()

  return (
    <Drawer open={open} anchor={'left'}>
      <div
        role="presentation"
        className={clsx(classes.listDrawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
      >
        {children}
      </div>
    </Drawer>
  )
}
