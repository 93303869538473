import React, { useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { useParams, useHistory } from 'react-router-dom'
import { ConnectionForm } from './ConnectionForm'
import { ConnectionEvents } from './ConnectionEvents'
import { useTranslation } from 'react-i18next'
import { useAddProviderConnection, useProviderConnection, useUpdateProviderConnection } from '../hooks'
import { ProviderConnection } from '../models'
import { differenceBetweenTwoObjects as difference } from '../../../../core/services/genericFunctions'
import _ from 'lodash'
import { useNotification } from '../../../../core'
import { Loader } from '../../../../core/components'

export const Connection = () => {
  const { id } = useParams<{ id: string | undefined }>()
  const { providerId } = useParams<{ providerId: string | undefined }>()
  const { addProviderConnection } = useAddProviderConnection()
  const { updateProviderConnection } = useUpdateProviderConnection()
  const { data: connectionData, loading, getProviderConnection } = useProviderConnection()
  const connection = connectionData ? connectionData.providerApiConnection : undefined
  const { successNotification, errorNotification } = useNotification()
  const { t: transLabel } = useTranslation('Label')
  const { t: transSystem } = useTranslation('System')
  const history = useHistory()

  useEffect(() => {
    if (id !== undefined) {
      getProviderConnection(Number.parseInt(id))
    }
  }, [id])

  const handleSave = (connectionToSave: ProviderConnection) => {
    if (connectionToSave.id == 0) {
      delete connectionToSave.id
      const response = addProviderConnection({
        variables: {
          input: { ...connectionToSave },
        },
      })
      response
        .then((result: any) => {
          successNotification(transSystem('DATO_REGISTRADO'))
          const id = _.get(result, 'data.addProviderApiConnection.providerApiConnection.id', null)
          if (id) {
            history.push(`/admin/entities/provider-connections/${id}`)
          } else {
            history.push(`/admin/entities/provider-connections`)
          }
        })
        .catch(() => {
          errorNotification(transSystem('ERROR_REGISTRANDO_EL_DATO'))
        })
    } else {
      delete connectionToSave.providerId
      const connectionChanges = difference(connectionToSave, connection)
      const response = updateProviderConnection({
        variables: {
          id: connectionToSave.id,
          input: { ...connectionChanges },
        },
      })
      response
        .then(() => {
          successNotification(transSystem('DATO_ACTUALIZADO'))
        })
        .catch(() => {
          errorNotification(transSystem('ERROR_ACTUALIZANDO_EL_DATO'))
        })
    }
  }

  const initialProviderId = providerId ? Number.parseInt(providerId) : undefined

  return (
    <Loader loading={loading}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <ConnectionForm onSave={handleSave} connection={connection} initialProviderId={initialProviderId} />
        </Grid>
        {connection && (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <ConnectionEvents connectionId={connection.id} />
          </Grid>
        )}
      </Grid>
    </Loader>
  )
}
