import { Button, CircularProgress } from '@material-ui/core'
import SaveIcon from '@material-ui/icons/Save'
import { useTranslation } from 'react-i18next'

type buttonProps = {
  onClick?: () => void
  className?: string
  disabled?: boolean
  isLoading: boolean
}
export const SaveButton = ({ onClick, isLoading, ...props }: buttonProps) => {
  const { t: transAction } = useTranslation('Action')

  return (
    <Button
      {...props}
      variant="contained"
      color="primary"
      onClick={onClick}
      startIcon={isLoading ? <CircularProgress size={20} color={'inherit'} /> : <SaveIcon />}
    >
      {transAction('GUARDAR')}
    </Button>
  )
}
