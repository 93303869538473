import React from 'react'
import clsx from 'clsx'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import CssBaseline from '@material-ui/core/CssBaseline'
import Typography from '@material-ui/core/Typography'
import PlaceBetIcon from '../../svgIcons/PlaceBet/PlaceBetIcon'

const drawerWidth = 240

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 200,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      // width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    iconPlaceBet: {
      width: '9rem !important',
      // marginTop: 10,
    },
    grow: {
      flexGrow: 1,
      marginTop: 5,
    },
    menuButton: {
      marginRight: 10,
    },
    logout: {
      marginRight: '-20px',
    },
    enterpriseTitle: {
      backgroundColor: '#6675cc',
      opacity: 0.8,
      padding: 70,
      paddingTop: 10,
      paddingBottom: 10,
      borderRadius: 12,
    },
  }),
)

export interface SimpleHeaderProps {
  children: React.ReactNode
}

export function SimpleHeader({ children }: SimpleHeaderProps) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: true,
        })}
      >
        <Toolbar>
          <Typography className={classes.grow} variant="h6" noWrap>
            <PlaceBetIcon classes={classes.iconPlaceBet} />
          </Typography>
        </Toolbar>
      </AppBar>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <React.Fragment>{children}</React.Fragment>
      </main>
    </div>
  )
}
