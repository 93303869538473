import { Grid, withStyles, makeStyles, createStyles, Theme, Button, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { FormikProvider, Field, Form, useFormik } from 'formik'
import { TextField } from 'formik-material-ui'
import * as Yup from 'yup'
import { PasswordReset } from '../models'

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText('#000000'),
    backgroundColor: '#000000',
    '&:hover': {
      backgroundColor: '#000000bf',
    },
    minWidth: '-webkit-fill-available',
  },
}))(Button)

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      flexGrow: 1,
    },
    fieldItem: {
      marginBottom: theme.spacing(2),
    },
    saveButton: {
      maxWidth: '50%',
    },
    codeContent: {
      display: 'inherit',
      flexDirection: 'column',
    },
    infoIcon: {
      color: 'silver',
      marginTop: 25,
      fontSize: 'x-large',
    },
    invalidCodeMessage: {
      color: '#f44336',
      fontSize: '0.75rem',
      marginLeft: 14,
      marginRight: 14,
    },
  }),
)

const initialFormValues = {
  password: '',
  passwordConfirmation: '',
  userName: '',
  passwordResetCode: '',
} as PasswordReset

type PasswordResetFormProps = {
  onSave: (formValues: any) => void
}

export const PasswordResetForm = ({ onSave }: PasswordResetFormProps) => {
  const { t: transLabel } = useTranslation('Label')
  const { t: transAction } = useTranslation('Action')
  const classes = useStyles()

  const formSchema = Yup.object().shape({
    userName: Yup.string().required(transLabel('REQUERIDO')),
    passwordResetCode: Yup.string().required(transLabel('REQUERIDO')),
    password: Yup.string()
      .required(transLabel('REQUERIDO'))
      .min(6, transLabel('CLAVE_NO_CUMPLE'))
      .matches(/[0-9]+/, transLabel('CLAVE_NO_CUMPLE'))
      .matches(/[A-Z]/, transLabel('CLAVE_NO_CUMPLE'))
      .matches(/[^\w\s]/, transLabel('CLAVE_NO_CUMPLE')),
    passwordConfirmation: Yup.string().oneOf(
      [Yup.ref('password'), null],
      transLabel('THE_PASSWORD_AND_CONFIRMATION_DID_NOT_MATCH'),
    ),
  })

  const formik = useFormik({
    validationSchema: formSchema,
    initialValues: initialFormValues,
    onSubmit: onSave,
  })

  return (
    <FormikProvider value={formik}>
      <Form>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Typography variant="h5" className={classes.title}>
              {transLabel('CAMBIAR_CLAVE_TITULO')}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Field
              component={TextField}
              type="text"
              label={transLabel('USUARIO')}
              name="userName"
              fullWidth
              variant="outlined"
              disabled={false}
              autoComplete="off"
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6} lg={6} className={classes.codeContent}>
            <Field
              component={TextField}
              type="text"
              label={transLabel('CODIGO')}
              name="passwordResetCode"
              fullWidth
              variant="outlined"
              disabled={false}
              autoComplete="off"
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Field
              component={TextField}
              type="password"
              label={transLabel('NUEVA_CLAVE')}
              name="password"
              fullWidth
              variant="outlined"
              disabled={false}
              autoComplete="off"
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Field
              component={TextField}
              type="password"
              label={transLabel('CONFIRMAR_CLAVE')}
              name="passwordConfirmation"
              fullWidth
              variant="outlined"
              disabled={false}
              autoComplete="off"
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12} className={classes.saveButton}>
            <ColorButton variant="contained" color="primary" onClick={formik.submitForm} disabled={!formik.dirty}>
              {transAction('CAMBIAR_CLAVE')}
            </ColorButton>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  )
}
