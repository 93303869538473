import { useCallback, useEffect, useState } from 'react'
import { GridPageChangeParams, GridRowParams } from '@material-ui/data-grid'
import {
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Button,
} from '@material-ui/core'
import { SearchRequest } from '../../../../core/models'
import { useProgramTrainers } from '../hooks'
import { useUserClaimActions, ClaimActions, generateFilterGraphql } from '../../../../core'
import {
  AppBreadcrumbsBar,
  CreateButton,
  CustomDataGrid,
  DataGridColumns,
  HeaderCardTitle,
  Loader,
} from '../../../../core/components'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { TrainerFilters, TrainerFiltersType } from './TrainerFilters'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    grid: {
      paddingBottom: theme.spacing(2),
    },
  }),
)

export const Trainers = () => {
  const { validateClaimActions } = useUserClaimActions()
  const actions = validateClaimActions('configuration.trainers', [ClaimActions.CanCreate, ClaimActions.CanSearch])
  const classes = useStyles()
  const history = useHistory()
  const { results, loading, getProgramTrainers } = useProgramTrainers()
  const { t: transLabel } = useTranslation('Label')
  const [filters, setFilters] = useState<TrainerFiltersType>({ name: '' })
  const [paging, setPaging] = useState<GridPageChangeParams>({
    page: 0,
    pageCount: 0,
    pageSize: 10,
    paginationMode: 'server',
    rowCount: 10,
  })

  useEffect(() => {
    const customFilters = {
      and: [
        {
          name: { ...generateFilterGraphql(filters.name, 'contains') },
        },
      ],
    }

    getProgramTrainers({ where: customFilters, skip: paging.page * paging.pageSize, take: paging.pageSize })
  }, [, paging, filters])

  const getColumnDefinitions = useCallback((): DataGridColumns => {
    return {
      NOMBRE: { field: 'name', contentType: 'string' },
      ESTADO: { field: 'isActive', contentType: 'boolean' },
    }
  }, [])

  const handleRowSelected = (params: GridRowParams) => {
    history.push(`/admin/entities/events-trainers/${params.id}`)
  }

  const handlePageChange = (param: GridPageChangeParams) => {
    setPaging(param)
  }

  const handlePageSizeChange = (param: GridPageChangeParams) => {
    setPaging(param)
  }

  const handleFilters = (filters: TrainerFiltersType) => {
    setFilters({
      ...filters,
      name: filters.name,
    })
  }

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <AppBreadcrumbsBar
              colTitleSize={10}
              colActionSize={2}
              title={transLabel('ENTRENADORES_TITULO')}
              description={transLabel('ENTRENADORES_SUB_TITULO')}
              actions={
                actions.canCreate ? (
                  <CreateButton onClick={() => history.push(`/admin/entities/events-trainers/create`)} />
                ) : null
              }
            />
          </Grid>
          {actions.canSearch && (
            <Grid item xs={12} className={classes.grid}>
              <TrainerFilters onFilterChange={handleFilters} />
            </Grid>
          )}
          <Grid item xs={12}>
            <Card>
              <CardHeader component={() => <HeaderCardTitle title={transLabel('ENTRENADORES')} />} />
              <Divider />
              <CardContent>
                <Loader loading={loading}>
                  <div style={{ minHeight: '70vh', width: '100%' }}>
                    <CustomDataGrid
                      data={results}
                      columns={getColumnDefinitions()}
                      page={paging.page}
                      pageSize={paging.pageSize}
                      onRowClick={handleRowSelected}
                      onPageChange={handlePageChange}
                      onPageSizeChange={handlePageSizeChange}
                    />
                  </div>
                </Loader>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
