import { gql, useMutation } from '@apollo/client'

const UPDATE_PROGRAM_RACE = gql`
  mutation UpdateProgramRace($id: Int!, $input: UpdateProgramRaceInput!) {
    updateProgramRace(id: $id, input: $input) {
      programRace {
        id
        programEventId
        raceNumber
        typeOfRace
        purse
        distanceId
        runners
        genderRestriction
        ageRestriction
        weight
        wagersId
        breed
        course
        postTime
        createdDate
        createdBy
        isActive
      }
    }
  }
`

export interface UpdateProgramRaceInput {
  id: number
  programEventId: number
  raceNumber: number
  typeOfRace: string
  purse: string
  distanceId: number
  wagersId: string
  runners: number
  genderRestriction: string
  ageRestriction: string
  weight: number
  breed: string
  course: string
  postTime: string
  isActive: boolean
}

export const useUpdateProgramRace = () => {
  const [updateProgramRace, { loading, error }] = useMutation<UpdateProgramRaceInput>(UPDATE_PROGRAM_RACE)
  return { updateProgramRace, loading, error }
}
