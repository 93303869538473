import React from 'react'
import { AdminAnimation } from '../../../core/components/animations/dashboard/AdminAnimation'
import { createStyles, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() =>
  createStyles({
    animation: {
      '& #animation-admin': {
        maxWidth: '80%',
        marginLeft: '10%',
      },
    },
  }),
)

export const Admin = () => {
  const classes = useStyles()

  return (
    <div className={classes.animation}>
      <AdminAnimation />
    </div>
  )
}
