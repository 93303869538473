import { useCallback } from 'react'
import DateFnsUtils from '@date-io/date-fns'
import { KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { FormProps } from '../../models'
import { TextField } from 'formik-material-ui'
import { formatToLocalTime } from '../../services/dateFunctions'

export interface CustomFormikTimePickerProps extends FormProps {
  customClassName?: object
  onDateChange?: (date: string | Date | null) => void
  defaultValue?: string
}

const WrapperTextField = (fieldProps: any) => {
  let value = ''
  if (fieldProps && fieldProps.field) {
    value = formatToLocalTime(fieldProps.field?.value)
  }

  return <TextField {...fieldProps} fullWidth value={value} />
}

export const CustomFormikTimePicker = ({
  customClassName,
  onDateChange,
  defaultValue,
  ...props
}: CustomFormikTimePickerProps) => {
  const {
    form: { setFieldValue, values },
    field: { name },
  } = props

  /**
   * @description change date selected
   * @param date
   */
  const handleDateChange = useCallback(
    (date: Date | null) => {
      setFieldValue(name, date)
      if (onDateChange instanceof Function) {
        onDateChange(date)
      }
    },
    [setFieldValue, name, onDateChange],
  )

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardTimePicker
        {...props}
        id="time-picker"
        value={values[name]}
        onChange={handleDateChange}
        KeyboardButtonProps={{
          'aria-label': 'change time',
        }}
        TextFieldComponent={WrapperTextField}
      />
    </MuiPickersUtilsProvider>
  )
}
