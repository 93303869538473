import { gql, useLazyQuery } from '@apollo/client'
import { Race } from '../../models'

const GET_RACE = gql`
  query GetRace($id: Int!) {
    programRace(id: $id) {
      id
      programEventId
      raceNumber
      typeOfRace
      purse
      distanceId
      runners
      genderRestriction
      ageRestriction
      weight
      wagersId
      breed
      course
      postTime
      createdDate
      createdDate
      createdBy
      isActive
    }
  }
`

interface RaceData {
  programRace: Race
}

export const useRace = () => {
  const [get, { loading, error, data }] = useLazyQuery<RaceData>(GET_RACE, {
    fetchPolicy: 'network-only',
  })

  const getRace = (id: number) => {
    get({
      variables: {
        id: id,
      },
    })
  }

  const race = data?.programRace || undefined
  return { getRace, loading, error, race }
}
