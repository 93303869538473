import React, { useState, useEffect } from 'react'
import * as Yup from 'yup'

import {
  Card,
  CardContent,
  CardHeader,
  createStyles,
  Divider,
  Grid,
  makeStyles,
  Theme,
  Radio,
  FormControlLabel,
  FormLabel,
} from '@material-ui/core'

import {
  BooleanRadioButton,
  HeaderCardTitle,
  LocalizedContentSelect,
  TextFieldDebounceFormik,
} from '../../../core/components'
import { Formik, Form, Field } from 'formik'
import { RadioGroup } from 'formik-material-ui'

// My Components
// Models
import { GraphqlExpression, SearchGraphql } from '../../../core/models'

// Hooks
import { useTranslation } from 'react-i18next'
import { generateFilterGraphql, useLocalizedContent } from '../../../core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardRoot: {
      minWidth: '100%',
    },
    description: {
      fontSize: 15,
      padding: theme.spacing(0.5),
    },
    formControl: {
      minWidth: '100%',
    },
    dates: {
      marginTop: '0px !important',
    },
    buttonGrid: {
      marginTop: '10px',
    },
    searchText: {
      minWidth: '100%',
    },
    accountTitle: {
      fontSize: '12px',
    },
  }),
)

export interface ClientAccountFilter {
  currencyName: string
  accountName: string
  isActive: boolean
}

const formSchema = Yup.object().shape({
  currencyName: Yup.string(),
  accountName: Yup.string(),
  isActive: Yup.boolean(),
})

type AccountFiltersProps = {
  onFiltersChange: (filter: ClientAccountFilter) => void
}

export const ClientAccountsFilters = ({ onFiltersChange }: AccountFiltersProps) => {
  const classes = useStyles()
  const { t: translation } = useTranslation('Label')
  const { results: resultCurrency } = useLocalizedContent({ entityType: 'System', entityName: 'Currency' })
  const [currencyName, setCurrencyName] = useState<GraphqlExpression>({ eq: 'PESOS_DOMINICANOS' })
  const [accountStatus, setAccountStatus] = useState('active')
  const [matchString, setMatchString] = useState<GraphqlExpression>({ contains: '' })

  function onChangeCurrency(currencyName: string) {
    setCurrencyName(generateFilterGraphql(currencyName, 'eq'))
  }

  function onChangeAccountStatus(event: React.ChangeEvent<HTMLInputElement>) {
    const result = event.target.value
    setAccountStatus(result)
  }
  function onChangeMatchString(matchString: string) {
    setMatchString(generateFilterGraphql(matchString, 'contains'))
  }

  return (
    <Card className={classes.cardRoot}>
      <CardHeader component={() => <HeaderCardTitle title={translation('CRITERIOS_DE_BUSQUEDA')} />} />
      <Divider />
      <CardContent>
        <Formik
          validationSchema={formSchema}
          initialValues={{ currencyName: 'PESOS_DOMINICANOS', isActive: true, accountName: '' }}
          onSubmit={(values: ClientAccountFilter, { setSubmitting }) => {
            onFiltersChange({ ...values })
            setSubmitting(false)
          }}
        >
          {({ values, handleChange, submitForm }) => {
            return (
              <Form>
                <Grid container item spacing={3} xs={12} sm={12}>
                  <Grid item lg={2} xs={12} sm={2}>
                    <FormLabel component="legend">{translation('CUENTAS')}</FormLabel>
                    <Field
                      component={BooleanRadioButton}
                      title={''}
                      name="isActive"
                      label1={'ACTIVO'}
                      label2={'INACTIVO'}
                      value1={'active'}
                      value2={'inactive'}
                      onChange={() => submitForm()}
                    />
                  </Grid>
                  <Grid item sm={4} lg={2} xs={12}>
                    <Field
                      fullWidth
                      component={LocalizedContentSelect}
                      isGrupalValue
                      label={translation('DIVISA')}
                      data={resultCurrency}
                      name="currencyName"
                      onChange={() => submitForm()}
                    />
                  </Grid>
                  <Grid item lg={4} xs={12} sm={4}>
                    <Field
                      name={'accountName'}
                      fullWidth
                      className={classes.searchText}
                      component={TextFieldDebounceFormik}
                      label={translation('MOSTRAR_LOS_QUE_COINCIDAD_CON')}
                      onChange={() => submitForm()}
                    />
                  </Grid>
                </Grid>
              </Form>
            )
          }}
        </Formik>
      </CardContent>
    </Card>
  )
}
