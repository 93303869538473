import { Divider, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import React from 'react'
import { NavLink } from 'react-router-dom'
import { ListItemWithExpandableMenu } from './ListItemWithExpandableMenu'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    option: {
      textDecoration: 'none',
      color: theme.palette.grey[700],
    },
    activeOption: {
      backgroundColor: theme.palette.grey[700],
      color: theme.palette.primary.dark,
      fontWeight: 'bolder',
    },
  }),
)

export interface MenuRoute {
  option: string
  icon?: any
  path?: string
  name?: string
  doesNotRequiredEnterprise?: boolean
  allowedClaims?: string
  public?: boolean
}

export interface MenuGroup {
  enable: boolean
  name?: string
  icon?: any
  path?: string
  options?: MenuRoute[]
  doesNotRequiredEnterprise?: boolean
  allowedModules: string
  public?: boolean
}

export type Items = {
  menu: MenuGroup
  onSelectItem: () => void
  index: number
}

export const MenuItems = ({ menu, onSelectItem, index }: Items) => {
  const classes = useStyles()
  const { t: transMenu } = useTranslation('Menu')

  return (
    <React.Fragment>
      {index > 0 && <Divider />}

      <List>
        {menu.options !== undefined ? (
          <ListItemWithExpandableMenu menuGroup={menu} onChangeRoute={onSelectItem} />
        ) : (
          <NavLink
            key={menu.name}
            to={menu.path || ''}
            className={classes.option}
            activeClassName={classes.activeOption}
          >
            <ListItem button key={menu.name}>
              <ListItemIcon>{menu.icon}</ListItemIcon>
              <ListItemText primary={transMenu(menu.name ? menu.name : '')} disableTypography />
            </ListItem>
          </NavLink>
        )}
      </List>
    </React.Fragment>
  )
}
