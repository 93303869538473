import React, { useEffect, useState } from 'react'
import { Grid, Card, CardContent, CardHeader, makeStyles, Divider, Theme, createStyles } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Collapse from '@material-ui/core/Collapse'
import IconButton from '@material-ui/core/IconButton'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import ReactJson from 'react-json-view'
import { HeaderCardTitle, Loader } from '../../../../core/components'
import { useTranslation } from 'react-i18next'
import { generateFilterGraphql } from '../../../../core'
import { useProviderConnectionEvents } from '../hooks'
import { ProviderConnectionEvent } from '../models'
import { ConnectionEventFilters } from './ConnectionEventFilters'

const useRowStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
    grid: {
      paddingBottom: theme.spacing(2),
    },
  }),
)

function Row(props: { row: ProviderConnectionEvent }) {
  const { row } = props
  const [open, setOpen] = React.useState(false)
  const classes = useRowStyles()

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="left">{row.id}</TableCell>
        <TableCell align="left">{row.eventType}</TableCell>
        <TableCell align="left">{row.syncDate}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Event
              </Typography>
              <Grid item xs={12} sm={12} lg={12}>
                <ReactJson src={row.event} />
              </Grid>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

type ConnectionEventsProps = {
  connectionId: number
}

export const ConnectionEvents = ({ connectionId }: ConnectionEventsProps) => {
  const { t: transLabel } = useTranslation('Label')
  const { results, loading, getProviderConnectionEvents } = useProviderConnectionEvents()
  const classes = useRowStyles()
  const events = results.results
  const [eventTypeFilter, setEventTypeFilter] = useState('')

  useEffect(() => {
    const customFilters = {
      and: [
        {
          providerApiConnectionId: { ...generateFilterGraphql(connectionId, 'eq') },
          // eventType: { ...generateFilterGraphql(eventTypeFilter, 'contains') },
        },
      ],
    }

    getProviderConnectionEvents({ where: customFilters, skip: 0, take: 100 })
  }, [, connectionId, eventTypeFilter])

  const handleFilters = (value: string) => {
    setEventTypeFilter(value)
  }

  return (
    <Card>
      <CardHeader title={<HeaderCardTitle title={transLabel('EVENTOS_DE_LA_CONEXION')} />} />
      <Divider />
      <CardContent>
        {/* <Grid item xs={12} className={classes.grid}>
          <ConnectionEventFilters onFiltersChange={handleFilters} />
        </Grid> */}
        <Loader loading={loading}>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>ID</TableCell>
                  <TableCell>Event Type</TableCell>
                  <TableCell>Sync Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {events.map((row: ProviderConnectionEvent) => (
                  <Row key={row.id} row={row} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Loader>
      </CardContent>
    </Card>
  )
}
