import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ProgramJockeyOrKennel } from '../models'
import { useHistory, useParams } from 'react-router-dom'
import { JockeyOrKennelForm } from './JockeyOrKennelForm'
import { useProgramJockeyOrKennel, useAddProgramJockeyOrKennel, useUpdateProgramJockeyOrKennel } from '../hooks'
import { useNotification } from '../../../../core'
import { differenceBetweenTwoObjects as difference } from '../../../../core/services/genericFunctions'
import { Loader } from '../../../../core/components'

export const JockeyOrKennel = () => {
  const { id } = useParams<{ id: string }>()
  const { jockeyOrKennel, loading: loadingJockeyOrKennel, getProgramJockeyOrKennel } = useProgramJockeyOrKennel()
  const { t: transSystem } = useTranslation('System')
  const history = useHistory()
  const { addProgramJockeyOrKennel } = useAddProgramJockeyOrKennel()
  const { updateProgramJockeyOrKennel } = useUpdateProgramJockeyOrKennel()
  const { successNotification, errorNotification } = useNotification()

  useEffect(() => {
    if (id !== undefined) {
      getProgramJockeyOrKennel(Number.parseInt(id))
    }
  }, [id])

  const handleSave = (jockeyOrKennelToSave: ProgramJockeyOrKennel) => {
    if (jockeyOrKennelToSave.id == 0) {
      delete jockeyOrKennelToSave.id
      const response = addProgramJockeyOrKennel({
        variables: {
          input: { ...jockeyOrKennelToSave },
        },
      })
      response
        .then(() => {
          successNotification(transSystem('DATO_REGISTRADO'))
          history.push(`/admin/entities/events-jockeyOrKennels`)
        })
        .catch(() => {
          errorNotification(transSystem('ERROR_REGISTRANDO_EL_DATO'))
        })
    } else {
      const changes = difference(jockeyOrKennelToSave, jockeyOrKennel)
      const response = updateProgramJockeyOrKennel({
        variables: {
          id: jockeyOrKennelToSave.id,
          input: { ...changes },
        },
      })
      response
        .then(() => {
          successNotification(transSystem('DATO_ACTUALIZADO'))
          history.push(`/admin/entities/events-jockeyOrKennels`)
        })
        .catch(() => {
          errorNotification(transSystem('ERROR_ACTUALIZANDO_EL_DATO'))
        })
    }
  }

  return (
    <Loader loading={loadingJockeyOrKennel}>
      <JockeyOrKennelForm onSubmit={handleSave} jockeyOrKennel={jockeyOrKennel} />
    </Loader>
  )
}
