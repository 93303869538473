import { useState, useCallback } from 'react'
import { GridPageChangeParams } from '@material-ui/data-grid'
import { createStyles, Grid, makeStyles, Theme, Card, CardContent, CardHeader, Divider } from '@material-ui/core'
import { TicketFilters } from './TicketFilters'
import {
  CustomDataGrid,
  DataGridColumns,
  AppBreadcrumbsBar,
  Loader,
  PrintButton,
  HeaderCardTitle,
} from '../../../core/components'
import { useTickets } from './useTickets'
import { useTranslation } from 'react-i18next'
import { ClaimActions, SearchGraphql } from '../../../core/models'
import { useUserClaimActions } from '../../../core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    grid: {
      paddingBottom: theme.spacing(2),
    },
    selectRowIcon: {
      cursor: 'pointer',
    },
  }),
)

export const Tickets = () => {
  const { validateClaimActions } = useUserClaimActions()
  const actions = validateClaimActions('consults.tickets', [
    ClaimActions.CanPrint,
    ClaimActions.CanSearch,
    ClaimActions.CanView,
  ])

  const classes = useStyles()

  // App Label Translation
  const { t: translation } = useTranslation('Label')
  const [searchRequest, setSearchRequest] = useState<SearchGraphql>({})

  // Fetch Ticket
  const { results, loading, getTickets } = useTickets()

  const onChangeFilter = (filter: SearchGraphql) => {
    filter = {
      ...filter,
      skip: 0,
      take: 20,
    }
    setSearchRequest(filter)
    getTickets(filter)
  }

  const getColumnDefinitions = useCallback((): DataGridColumns => {
    return {
      NUMERO: { field: 'id', contentType: 'id' },
      TIPO_TICKET: { field: 'typeTicket', contentType: 'localized' },
      FECHA: { field: 'salesTime', contentType: 'date' },
      APOSTADO: { field: 'risk', contentType: 'number' },
      A_GANAR: { field: 'possibleWin', contentType: 'number' },
      RESULTADO: { field: 'riskWin', contentType: 'number' },
      ESTATUS: { field: 'state', contentType: 'localized' },
    }
  }, [])

  const handlePageChange = (params: GridPageChangeParams) => {
    const { pageSize, page } = params
    const newFilter = {
      ...searchRequest,
      take: pageSize,
      skip: pageSize * (page - 1),
    }

    setSearchRequest(newFilter)
    getTickets(newFilter)
  }

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <AppBreadcrumbsBar
              title={translation('LISTA_DE_APUESTAS')}
              description={translation('LISTA_DE_APUESTAS')}
              actions={actions.canPrint ? <PrintButton onClick={() => {}} /> : null}
            />
          </Grid>
          {actions.canSearch && (
            <Grid item xs={12} className={classes.grid}>
              <TicketFilters onChange={onChangeFilter} />
            </Grid>
          )}
          <Grid item xs={12}>
            <Card>
              <CardHeader component={() => <HeaderCardTitle title={translation('VENTA_Y_ESTATUS')} />} />
              <Divider />
              <CardContent>
                <Loader loading={loading}>
                  <div style={{ minHeight: '70vh', width: '100%' }}>
                    <CustomDataGrid
                      // onRowClick={rowSelectHandler}
                      data={results}
                      columns={getColumnDefinitions()}
                      pageSize={20}
                      onPageChange={handlePageChange}
                    />
                  </div>
                </Loader>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
