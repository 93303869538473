import { gql, useMutation } from '@apollo/client'

const ADD_PROVIDER = gql`
  mutation addProvider($input: AddProviderInput!) {
    addProvider(input: $input) {
      provider {
        id
        name
        acronym
        description
        generalInfo
        openingTime
        closingTime
        currencyId
        timeZone
        isVirtualGame
        isActive
      }
    }
  }
`
export interface AddProviderInput {
  acronym: string
  name: string
  closingTime?: Date
  openingTime?: Date
  description?: string
  generalInfo?: string
  currencyId?: number
  isActive: boolean
  isVirtualGame: boolean
  timeZone?: string
}

export const useAddProvider = () => {
  const [addProvider, { loading, error }] = useMutation(ADD_PROVIDER)
  return { addProvider, loading, error }
}
