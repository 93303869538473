import { ProviderTransactionGlobal } from './models'
import { gql, useLazyQuery } from '@apollo/client'
import { Metadata, ResultsList, SearchGraphql } from '../../../core/models'
import { useState, useEffect, useCallback } from 'react'

const GET_PROVIDER_TRANSACTION_GLOBAL = gql`
  query GetProviderTransactionGlobal($where: ProviderTransactionsGlobalFilterInput!, $skip: Int, $take: Int) {
    providerTransactionsGlobal(where: $where, skip: $skip, take: $take) {
      totalCount
      items {
        startDate
        endDate
        enterpriseId
        parentName
        currencyType
        product
        totalTickets
        totalTicketsCancelled
        totalTicketsPayed
        totalBetsAmount
        totalWinsAmount
        totalPaidAmount
        totalCancelAmount
        riskWin
        riskWinPercentage
        tax
        providerName
      }
    }
  }
`

interface ProviderTransactionGlobalData {
  providerTransactionsGlobal: {
    totalCount: number
    items: ProviderTransactionGlobal[]
  }
}

export const useProviderTransactionGlobal = () => {
  const [getResults, { loading, error, data }] = useLazyQuery<ProviderTransactionGlobalData>(
    GET_PROVIDER_TRANSACTION_GLOBAL,
    { fetchPolicy: 'network-only' },
  )

  const [results, setResults] = useState<ResultsList<ProviderTransactionGlobal, Metadata>>({
    results: [],
    metadata: {},
  })

  const getProviderTransactionGlobal = useCallback(
    ({ where, take, skip }: SearchGraphql) => {
      getResults({ variables: { where, take, skip } })
    },
    [getResults],
  )

  useEffect(() => {
    setResults({
      results: data?.providerTransactionsGlobal.items || [],
      metadata: {
        Count: data?.providerTransactionsGlobal.totalCount,
      },
    })
  }, [data?.providerTransactionsGlobal, loading])

  return { loading, error, results, getProviderTransactionGlobal }
}
