import React from 'react'
import { AuthConsumer } from 'pb-shared'

import { LoadingAnimation } from '../../core/components'

export const SilentRenew = () => (
  <AuthConsumer>
    {({ signinSilentCallback }) => {
      signinSilentCallback()
      return <LoadingAnimation />
    }}
  </AuthConsumer>
)
