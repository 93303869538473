import React, { useEffect } from 'react'
import { makeStyles, createStyles, Theme, Grid } from '@material-ui/core'
// import { Location } from './models'
// import { useLocation, useAddLocation, useUpdateLocation } from './hooks'
import { useParams, useHistory } from 'react-router-dom'
import { ProviderForm } from './ProviderForm'
import { ConnectionProfile } from './connections/ConnectionProfile'
import { generateFilterGraphql, useNotification } from '../../../core'
import { useTranslation } from 'react-i18next'
import { useProviderConnections, useUpdateProvider, useAddProvider, useProvider } from './hooks'
import { Provider as ModelProvider, ProviderConnection } from './models'
import { differenceBetweenTwoObjects as difference } from '../../../core/services/genericFunctions'
import _ from 'lodash'
import { Loader } from '../../../core/components'

export const Provider = () => {
  const { id } = useParams<{ id: string | undefined }>()
  const { results: connections, loading, getProviderConnections } = useProviderConnections()

  const { data: providerData, loading: loadingProvider, getProvider } = useProvider()
  const { addProvider } = useAddProvider()
  const { updateProvider } = useUpdateProvider()
  const provider = providerData ? providerData.provider : undefined
  const { successNotification, errorNotification } = useNotification()
  const { t: transLabel } = useTranslation('Label')
  const { t: transSystem } = useTranslation('System')
  const history = useHistory()

  let providerConnection: undefined | ProviderConnection = undefined
  if (connections.results.length > 0) {
    providerConnection = connections.results[0]
  }
  useEffect(() => {
    if (id !== undefined && Number.parseInt(id) > 0) {
      getProvider(Number.parseInt(id))
      fetchProviderConnection(Number.parseInt(id))
    }
  }, [id])

  const fetchProviderConnection = (providerId: number) => {
    const customFilters = {
      and: [{ providerId: { ...generateFilterGraphql(providerId, 'eq') } }],
    }
    getProviderConnections({ where: customFilters, skip: 0, take: 1 })
  }

  const handleSave = (providerToSave: ModelProvider) => {
    if (providerToSave.id == 0) {
      delete providerToSave.id
      const response = addProvider({
        variables: {
          input: { ...providerToSave },
        },
      })
      response
        .then((result: any) => {
          successNotification(transSystem('DATO_REGISTRADO'))
          const id = _.get(result, 'data.addProvider.provider.id', null)
          if (id) {
            history.push(`/admin/entities/providers/${id}`)
          } else {
            history.push(`/admin/entities/providers`)
          }
        })
        .catch(() => {
          errorNotification(transSystem('ERROR_REGISTRANDO_EL_DATO'))
        })
    } else {
      const providerChanges = difference(providerToSave, provider)
      const response = updateProvider({
        variables: {
          id: providerToSave.id,
          input: { ...providerChanges },
        },
      })
      response
        .then(() => {
          successNotification(transSystem('DATO_ACTUALIZADO'))
        })
        .catch(() => {
          errorNotification(transSystem('ERROR_ACTUALIZANDO_EL_DATO'))
        })
    }
  }

  // const handleNewStation = () => {
  //   history.push(`/admin/entities/agencies/${id}/station`)
  // }

  // const handleEditStation = (station: any) => {
  //   history.push(`/admin/entities/agencies/${id}/station/${station.id}`)
  // }

  return (
    <Loader loading={loadingProvider}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <ProviderForm onSave={handleSave} provider={provider} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {id !== undefined && Number.parseInt(id) > 0 && (
            <ConnectionProfile connection={providerConnection} providerId={Number.parseInt(id)} />
          )}
        </Grid>
      </Grid>
    </Loader>
  )
}
