import * as Yup from 'yup'

import { Card, CardContent, CardHeader, createStyles, Divider, Grid, makeStyles, Theme } from '@material-ui/core'

import { HeaderCardTitle } from '../../../core/components'
import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-material-ui'

// My Components
import { SearchButton } from '../../../core/components/buttons'
// Models

// Hooks
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardRoot: {
      minWidth: '100%',
    },
    description: {
      fontSize: 15,
      padding: theme.spacing(0.5),
    },
    formControl: {
      minWidth: '100%',
    },
    dates: {
      marginTop: '0px !important',
    },
    buttonGrid: {
      marginTop: '10px',
    },
    searchText: {
      minWidth: '100%',
    },
    accountTitle: {
      fontSize: '12px',
    },
  }),
)

export interface UsersFiltersModel {
  searchBy: string
}
const initialValues: UsersFiltersModel = {
  searchBy: '',
}

const formSchema = Yup.object().shape({
  companies: Yup.string(),
  searchBy: Yup.string(),
})

type UsersFiltersProps = {
  onSearch: (params: UsersFiltersModel) => void
}

export const UsersFilters = ({ onSearch }: UsersFiltersProps) => {
  const classes = useStyles()
  const { t: translation } = useTranslation('Label')

  return (
    <Card className={classes.cardRoot}>
      <CardHeader component={() => <HeaderCardTitle title={translation('CRITERIOS_DE_BUSQUEDA')} />} />
      <Divider />
      <CardContent>
        <Formik
          validationSchema={formSchema}
          validate={(values: UsersFiltersModel) => {
            // Validate filters here
          }}
          initialValues={{ ...initialValues }}
          onSubmit={(values: UsersFiltersModel, { setSubmitting }) => {
            setSubmitting(false)
            onSearch(values)
          }}
        >
          {({ errors, submitForm, isSubmitting, isValid }) => {
            return (
              <Form>
                <Grid container item spacing={3} xs={12} sm={12}>
                  <Grid item lg={8} xs={12} sm={12}>
                    <Field
                      className={classes.searchText}
                      component={TextField}
                      type="text"
                      label={translation('MOSTRAR_LOS_QUE_COINCIDAD_CON')}
                      name="searchBy"
                    />
                  </Grid>
                  <Grid item lg={4} xs={12} sm={12} className={classes.buttonGrid}>
                    <SearchButton onClick={submitForm} />
                  </Grid>
                </Grid>
              </Form>
            )
          }}
        </Formik>
      </CardContent>
    </Card>
  )
}
