import React, { useState, useEffect } from 'react'
import { createStyles, Grid, makeStyles, Theme, Button, withStyles } from '@material-ui/core'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import { AppBreadcrumbsBar, ClearButton, ModifyButton, SaveButton } from '../../../core/components'
import { StationForm } from './StationForm'
// import { AgencyForm } from './AgencyForm'
import { useWorkstation, useAddWorkstation, useUpdateWorkstation } from './hooks'
import _lodash from 'lodash'
import { useTranslation } from 'react-i18next'
import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup'
import { Workstation, Location } from './models'
import { ClaimActions, useNotification, useUserClaimActions } from '../../../core'
import { useParams, useHistory } from 'react-router-dom'
import { differenceBetweenTwoObjects as difference } from '../../../core/services/genericFunctions'
import { StationCodeGenerator } from './StationCodeGenerator'
import { green } from '@material-ui/core/colors'
import RefreshIcon from '@material-ui/icons/Refresh'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    grid: {
      paddingBottom: theme.spacing(2),
    },
    accordionContainer: {
      paddingTop: 20,
    },
    divider: {
      marginTop: -22,
    },
    saveButton: {
      marginRight: 5,
    },
    buttonActions: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  }),
)

const GreenButton = withStyles(() => ({
  root: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
}))(Button)

const initialFormValues: Workstation = {
  id: 0,
  name: '',
  workStationTypeId: 0,
  securityCode: '',
  active: true,
  canRegister: false,
}

export const Stations = () => {
  const classes = useStyles()

  const [openGenerator, setOpenGenerator] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [selectedForm, setSelectedForm] = useState('')
  const { getWorkstation, data: workstationData } = useWorkstation()
  const { addWorkstation } = useAddWorkstation()
  const { updateWorkstation } = useUpdateWorkstation()
  const { agencyId, stationId } = useParams<{ stationId: string; agencyId: string }>()
  const { validateClaimActions } = useUserClaimActions()
  const actions = validateClaimActions('configuration.agencies', [ClaimActions.CanCreate, ClaimActions.CanEdit])

  const history = useHistory()

  const { successNotification, errorNotification } = useNotification()

  const { t: transLabel } = useTranslation('Label')
  const { t: transAction } = useTranslation('Action')
  const { t: transSystem } = useTranslation('System')

  const formSchema = Yup.object().shape({
    name: Yup.string().required(transLabel('REQUERIDO')),
  })

  useEffect(() => {
    if (stationId !== undefined) {
      getWorkstation(Number.parseInt(stationId))
    }
  }, [stationId])

  const goToAgency = () => {
    history.push(`/admin/entities/agencies/${agencyId}`)
  }

  const onSaveStation = (station: Workstation) => {
    if (station.id <= 0) {
      const response = addWorkstation({
        variables: {
          input: {
            name: station.name,
            workStationTypeId: station.workStationTypeId,
            locationId: Number.parseInt(agencyId),
            canRegister: station.canRegister,
          },
        },
      })
      response
        .then(() => {
          successNotification(transSystem('DATO_REGISTRADO'))
          setSelectedForm('AGENCY_FORM')
        })
        .catch(() => {
          errorNotification(transSystem('ERROR_REGISTRANDO_EL_DATO'))
        })
    } else {
      const changesData = difference(station, workstationData.workstation)
      if (changesData) {
        const response = updateWorkstation({
          variables: {
            id: station.id,
            input: { ...changesData },
          },
        })
        response
          .then(() => {
            successNotification(transSystem('DATO_ACTUALIZADO'))
            setSelectedForm('AGENCY_FORM')
          })
          .catch((e) => {
            errorNotification(transSystem('ERROR_REGISTRANDO_EL_DATO'))
          })
      }
    }
  }

  const station = workstationData !== undefined ? workstationData.workstation : undefined
  return (
    <Formik
      validationSchema={formSchema}
      initialValues={station || initialFormValues}
      onSubmit={(values) => onSaveStation(values)}
      enableReinitialize
    >
      {({ setFieldValue, submitForm, isValid, dirty, handleReset }) => {
        const onAfterGenerateCode = () => {
          setFieldValue('canRegister', true)
        }
        return (
          <Grid container>
            <Grid item xs={12}>
              <AppBreadcrumbsBar
                title={transLabel('ADMINISTRACION_DE_CAJAS')}
                description={transLabel('ADMINISTRACION_DE_CAJAS_O_PUNTOS_DE_VENTA')}
                actions={
                  <React.Fragment>
                    {station && station.id && actions.canCreate && (
                      <GreenButton
                        color="secondary"
                        variant="contained"
                        onClick={() => setOpenGenerator(true)}
                        startIcon={<RefreshIcon className={classes.buttonActions} />}
                      >
                        {transAction('GENERAR_CODIGO')}
                      </GreenButton>
                    )}
                    {station && station.id && isEditing === false && actions.canEdit ? (
                      <ModifyButton className={classes.buttonActions} onClick={() => setIsEditing(true)} />
                    ) : (
                      (actions.canCreate || actions.canEdit) && (
                        <>
                          <SaveButton
                            className={classes.buttonActions}
                            onClick={submitForm}
                            disabled={!(dirty && isValid)}
                            isLoading={false}
                          />

                          <ClearButton
                            className={classes.buttonActions}
                            onClick={handleReset}
                            label={'CANCELAR'}
                            disabled={!(dirty && isValid)}
                          />
                        </>
                      )
                    )}

                    <Button
                      className={classes.buttonActions}
                      variant="contained"
                      onClick={goToAgency}
                      startIcon={<KeyboardBackspaceIcon />}
                    >
                      {transLabel('REGRESAR')}
                    </Button>
                  </React.Fragment>
                }
              />
            </Grid>

            <Grid item xs={12}>
              <StationForm
                disableFields={!isEditing && station}
                station={workstationData !== undefined ? workstationData.workstation : undefined}
                locationId={Number.parseInt(agencyId)}
              />
            </Grid>
            {station && station.id && (
              <StationCodeGenerator
                isOpen={openGenerator}
                onClose={() => setOpenGenerator(false)}
                workstationId={station.id}
                afterGenerateCode={onAfterGenerateCode}
              />
            )}
          </Grid>
        )
      }}
    </Formik>
  )
}
