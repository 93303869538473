import React from 'react'
import { Button } from '@material-ui/core'
import PrintIcon from '@material-ui/icons/Print'
import { useTranslation } from 'react-i18next'

type buttonProps = {
  onClick: () => void
}
export const PrintButton = ({ onClick }: buttonProps) => {
  const { t: transAction } = useTranslation('Action')

  return (
    <Button variant="contained" onClick={onClick} color="primary" startIcon={<PrintIcon />}>
      {transAction('IMPRIMIR')}
    </Button>
  )
}
