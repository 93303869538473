import React, { useEffect, useState } from 'react'
import {
  Grid,
  makeStyles,
  createStyles,
  Theme,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Button,
  FormControlLabel,
  FormControl,
  withStyles,
} from '@material-ui/core'

import { orange } from '@material-ui/core/colors'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import FileCopyOutlined from '@material-ui/icons/FileCopyOutlined'
import GetAppIcon from '@material-ui/icons/GetApp'

import {
  HeaderCardTitle,
  SaveButton,
  AppBreadcrumbsBar,
  ModifyButton,
  DialogAlerts,
  CustomFormikDatePicker,
  CustomAutoCompleteSelectFormik,
  CheckboxFormik,
} from '../../../../core/components'
import { useTranslation } from 'react-i18next'
import { useFormik, Field, Form, FormikProvider } from 'formik'
import { Checkbox, TextField } from 'formik-material-ui'
// import { provider } from './models'
import * as Yup from 'yup'
// import { useproviderTypes, useprovider } from './hooks'
import { Link, useHistory } from 'react-router-dom'
import { Event, ProgramSelectOptions } from '../models'
import {
  dateIsBeforeThanTodayMoment,
  useNotification,
  useUserClaimActions,
  ClaimActions,
  normalizeSpaces,
  useRestClient,
} from '../../../../core'
import { useUploadProgramFile } from '../hooks'
import _ from 'lodash'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiAutocomplete-root': {
        width: 'auto',
      },
      '& .MuiButton-containedSecondary': {
        backgroundColor: '#2e7d32',
      },
    },
    fieldItem: {
      marginBottom: theme.spacing(2),
    },
    buttonActions: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    labelEditActive: {
      color: '#ff6600',
    },
    labelEditDisabled: {
      color: '#000000',
    },
    divider: {
      marginTop: -22,
    },
    addressDiv: {
      paddingTop: 5,
    },
  }),
)

const DownloadButton = withStyles((theme) => ({
  root: {
    color: '#ffffff',
    backgroundColor: orange[500],
    '&:hover': {
      backgroundColor: orange[700],
    },
    marginRight: theme.spacing(2),
  },
}))(Button)

const today = new Date()

const initialFormValues = {
  id: 0,
  name: '',
  date: today,
  performanceCode: '',
  numberOfRaces: 1,
  isActive: true,
}

type EventFormProps = {
  isSaving: boolean
  onSave: (values: Event) => void
  event?: Event | undefined
  programSelectOptions: [] | ProgramSelectOptions[]
}

export const EventForm = ({ event, onSave, programSelectOptions, isSaving }: EventFormProps) => {
  const classes = useStyles()
  const [isEditing, setIsEditing] = useState(event?.id !== undefined)
  const [alertForm, setAlertForm] = useState(false)
  const { t: transLabel } = useTranslation('Label')
  const { t: transSystem } = useTranslation('System')
  const { uploadProgramFile, loading: uploadFileLoading, error } = useUploadProgramFile()
  const { successNotification, errorNotification } = useNotification()
  const { validateClaimActions } = useUserClaimActions()
  const { downloadFile, response: downloadResponse } = useRestClient()

  const actions = validateClaimActions('configuration.events', [ClaimActions.CanCreate, ClaimActions.CanEdit])
  const history = useHistory()
  const formStateMode = event?.id !== undefined ? 'EDIT' : 'CREATE'

  useEffect(() => {
    if (downloadResponse.errors.length > 0) {
      errorNotification(downloadResponse.errors[0])
    }
  }, [downloadResponse])

  const formSchema = Yup.object().shape({
    name: Yup.string()
      .max(30, '(30) ' + transLabel('MAXIMO_CARACTERES'))
      .required(transLabel('REQUERIDO')),
    performanceCode: Yup.string().nullable().required(transLabel('REQUERIDO')),
    date: Yup.date().nullable().required(transLabel('REQUERIDO')).typeError(transLabel('FECHA_INVALIDA')),
    numberOfRaces: Yup.number()
      .min(1, transLabel('SOLO_VALORES_ENTRE') + ' 1-99')
      .max(99, transLabel('SOLO_VALORES_ENTRE') + ' 1-99')
      .required(transLabel('REQUERIDO')),
  })

  const formik = useFormik({
    validationSchema: formSchema,
    initialValues: event || initialFormValues,
    onSubmit: onSave,
    enableReinitialize: true,
  })

  window.sessionStorage.setItem('hasFormChanges', formik.dirty ? 'true' : 'false')

  const goToEvents = () => {
    if (formik.dirty === false) {
      history.push(`/admin/entities/events`)
    } else {
      setAlertForm(true)
    }
  }

  const onGenerateFile = (event: Event) => {
    if (event.id !== undefined) {
      if (!dateIsBeforeThanTodayMoment(event.date)) {
        const response = uploadProgramFile({
          variables: {
            eventId: event.id,
          },
        })
        response
          .then((result: any) => {
            const error = _.get(result, 'errors', undefined)
            if (error && error.length > 0) {
              errorNotification(transSystem(error[0].message))
            } else {
              successNotification(transSystem('ARCHIVO_GENERADO'))
            }
          })
          .catch((e: any) => {
            // console.log(e)
          })
      } else {
        errorNotification(transSystem('LA_FECHA_DEL_EVENTO_HA_PASADO'))
      }
    }
  }

  const getProgramSelectOptionsByEntity = (entity: string): [] | ProgramSelectOptions[] => {
    if (programSelectOptions && programSelectOptions.length > 0) {
      return programSelectOptions.filter((item: ProgramSelectOptions, index: number) => item.entity === entity)
    } else {
      return []
    }
  }

  const onDownloadEventFile = (event: Event) => {
    const response = downloadFile(`/program/download/hvc/event/${event.id}`, event.name)
  }

  const performanceCodeOptions = getProgramSelectOptionsByEntity('PerformanceCode')
  let codeDefaultValue = undefined
  if (event) {
    const code = performanceCodeOptions.filter((item) => item.code == event.performanceCode)
    if (code.length > 0) {
      codeDefaultValue = code[0]
    }
  }

  const isFieldDisabled = !isEditing && formStateMode == 'EDIT'

  return (
    <FormikProvider value={formik}>
      <Form>
        <Grid item xs={12} className={classes.root}>
          <AppBreadcrumbsBar
            colTitleSize={4}
            colActionSize={8}
            title={transLabel('EVENTO_TITULO')}
            description={transLabel('EVENTO_SUB_TITULO')}
            actions={
              <React.Fragment>
                {event && !dateIsBeforeThanTodayMoment(event.date) ? (
                  <>
                    <DownloadButton
                      variant="contained"
                      onClick={() => onDownloadEventFile(event)}
                      startIcon={<GetAppIcon className={classes.buttonActions} />}
                    >
                      {transLabel('DESCARGAR')}
                    </DownloadButton>

                    {actions.canCreate && (
                      <Button
                        color="secondary"
                        variant="contained"
                        onClick={() => onGenerateFile(event)}
                        startIcon={<FileCopyOutlined className={classes.buttonActions} />}
                      >
                        {transLabel('GENERAR_ARCHIVO')}
                      </Button>
                    )}

                    {isEditing === false && formStateMode == 'EDIT' && actions.canEdit ? (
                      <ModifyButton className={classes.buttonActions} onClick={() => setIsEditing(true)} />
                    ) : (
                      (actions.canEdit || actions.canCreate) && (
                        <SaveButton
                          className={classes.buttonActions}
                          onClick={formik.submitForm}
                          isLoading={isSaving}
                          disabled={!(formik.dirty && formik.dirty)}
                        />
                      )
                    )}
                  </>
                ) : formStateMode === 'CREATE' ? (
                  <SaveButton
                    className={classes.buttonActions}
                    onClick={formik.submitForm}
                    isLoading={isSaving}
                    disabled={!(formik.dirty && formik.dirty)}
                  />
                ) : null}
                <Button
                  variant="contained"
                  onClick={goToEvents}
                  startIcon={<KeyboardBackspaceIcon className={classes.buttonActions} />}
                >
                  {transLabel('REGRESAR')}
                </Button>
              </React.Fragment>
            }
          />
        </Grid>

        <Card>
          <CardHeader title={<HeaderCardTitle title={transLabel('DATOS_DEL_EVENTO')} />} />
          <Divider className={classes.divider} />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Field
                  component={TextField}
                  type="text"
                  label={transLabel('NOMBRE')}
                  name="name"
                  fullWidth
                  disabled={isFieldDisabled}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    formik.setFieldValue('name', normalizeSpaces(event.target.value))
                  }
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Field
                  component={CustomAutoCompleteSelectFormik}
                  keyValue={'code'}
                  keyLabel={'name'}
                  data={performanceCodeOptions}
                  type="text"
                  label={transLabel('CODIGO')}
                  name="performanceCode"
                  fullWidth
                  disabled={isFieldDisabled}
                  defaultValue={codeDefaultValue}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Field
                  component={CustomFormikDatePicker}
                  label={transLabel('FECHA')}
                  name="date"
                  fullWidth
                  disabled={isFieldDisabled}
                  format={'dd/MM/yyyy'}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Field
                  InputProps={{ inputProps: { min: 1, max: 99 } }}
                  component={TextField}
                  type="number"
                  label={transLabel('CARRERAS')}
                  name="numberOfRaces"
                  fullWidth
                  disabled={isFieldDisabled}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <FormControl fullWidth>
                  <FormControlLabel
                    control={
                      <Field component={CheckboxFormik} name="isActive" color="primary" disabled={isFieldDisabled} />
                    }
                    label={transLabel('ESTADO') as string}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <DialogAlerts
          state={alertForm}
          titulo={'SE_HAN_DETECTADO_CAMBIOS_EN_EL_FORMULARIO'}
          contenido={'SI_CONTINUA_PERDERA_LOS_CAMBIOS_EN_EL_FORMULARIO_DESEA_CONTINUAR'}
          tipo="PREGUNTA"
          onSecondaryClick={() => setAlertForm(false)}
          handleClose={() => setAlertForm(false)}
          onPrimaryClick={() => history.push(`/admin/entities/events`)}
        />
      </Form>
    </FormikProvider>
  )
}
