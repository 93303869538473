import { gql, useLazyQuery, useQuery } from '@apollo/client'
import { useCallback, useEffect, useState } from 'react'
import { Metadata, ResultsList, SearchGraphql } from '../../../../../core/models'
import { Race } from '../../models'

const GET_RACES = gql`
  query GetProgramRacesInfo($where: ProgramRaceInfoFilterInput!, $skip: Int, $take: Int) {
    programRacesInfo(where: $where, skip: $skip, take: $take) {
      items {
        id
        programEventId
        raceNumber
        description
        typeOfRace
        purse
        distanceId
        runners
        genderRestriction
        ageRestriction
        weight
        wagersId
        breed
        course
        postTime
        createdDate
        createdBy
        isActive
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`

interface RacesData {
  programRacesInfo: {
    totalCount: number
    items: Race[]
  }
}

export const useRacesInfo = () => {
  const [getResults, { loading, error, data }] = useLazyQuery<RacesData>(GET_RACES, {
    fetchPolicy: 'network-only',
  })

  const getRacesInfo = ({ where, take, skip }: any) => {
    getResults({
      variables: {
        where: { ...where },
        skip: skip ? skip : 0,
        take: take ? take : 5,
      },
    })
  }

  const results: ResultsList<Race, Metadata> = {
    results: data?.programRacesInfo.items || [],
    metadata: {
      Count: data?.programRacesInfo.totalCount,
    },
  }

  return { getRacesInfo, loading, error, results }
}
