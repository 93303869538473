import React, { useState } from 'react'
import * as Yup from 'yup'
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core'
import { Edit, Save } from '@material-ui/icons'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import { useTranslation } from 'react-i18next'
import {
  HeaderCardTitle,
  AppBreadcrumbsBar,
  Loader,
  BackButton,
  SaveButton,
  AddTitle,
  DialogAlerts,
} from '../../../core/components'
import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-material-ui'

import { Company as CompanyModel } from './models'
import { useCreateCompany } from './hooks'
import { useHistory } from 'react-router-dom'
import { useNotification } from '../../../core'

const initialValues: CompanyModel = {
  name: '',
  active: true,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonActions: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    labelEditActive: {
      color: '#ff6600',
    },
    labelEditDisabled: {
      color: '#000000',
    },
  }),
)

export const Company = () => {
  const history = useHistory()
  const { createCompany, loading, error } = useCreateCompany()
  const [alertForm, setAlertForm] = useState(false)

  const { t: translationLabel } = useTranslation('Label')
  const { t: translationAction } = useTranslation('Action')
  const { successNotification, errorNotification } = useNotification()

  const classes = useStyles()

  const formSchema = Yup.object().shape({
    name: Yup.string().required(translationLabel('REQUERIDO')),
    active: Yup.boolean(),
  })

  return (
    <Grid container>
      <Formik
        validationSchema={formSchema}
        initialValues={{ ...initialValues }}
        onSubmit={(values: CompanyModel, { setSubmitting }) => {
          setSubmitting(true)
          const response = createCompany({
            variables: {
              input: { ...values },
            },
          })
          response
            .then((res) => {
              setTimeout(() => {
                history.push(`/admin/entities/companies`)
              }, 1000)
              successNotification(translationLabel('COMPANIA_CREADA'))
            })
            .catch((e) => {
              errorNotification(translationLabel('ERROR_CREANDO_LA_COMPANIA'))
            })
        }}
        enableReinitialize
      >
        {({ submitForm, isValid, dirty, isSubmitting }) => {
          const goToCompanies = () => {
            if (dirty === false) {
              history.push(`/admin/entities/companies`)
            } else {
              setAlertForm(true)
            }
          }
          return (
            <Grid item xs={12}>
              <Grid item xs={12}>
                <AppBreadcrumbsBar
                  title={translationLabel('COMPANIA_TITULO')}
                  description={translationLabel('COMPANIA_SUB_TITULO')}
                  actions={
                    <React.Fragment>
                      <SaveButton
                        className={classes.buttonActions}
                        onClick={submitForm}
                        disabled={!(dirty && isValid)}
                        isLoading={false}
                      />
                      <Button
                        className={classes.buttonActions}
                        variant="contained"
                        onClick={goToCompanies}
                        startIcon={<KeyboardBackspaceIcon />}
                      >
                        {translationAction('REGRESAR')}
                      </Button>
                    </React.Fragment>
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <Card>
                  <Box display="flex" justifyContent="space-between" alignItems="center" mr={1}>
                    <CardHeader
                      component={() => <HeaderCardTitle title={<AddTitle title={'CREANDO_LA_COMPANIA'} />} />}
                    />

                    <Typography variant="h6" component="h6">
                      <b>
                        {loading
                          ? translationLabel('CREANDO')
                          : isSubmitting === true
                          ? error === undefined
                            ? translationLabel('COMPANIA_CREADA')
                            : translationLabel('ERROR_CREANDO_COMPANIA')
                          : ''}
                      </b>
                    </Typography>
                  </Box>

                  <Divider />
                  <CardContent>
                    <Form>
                      <Grid container spacing={3}>
                        <Grid item lg={12} xs={12} sm={12}>
                          <Field component={TextField} type="text" label={translationLabel('COMPANIA')} name="name" />
                        </Grid>
                      </Grid>
                    </Form>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          )
        }}
      </Formik>
      <DialogAlerts
        state={alertForm}
        titulo={'SE_HAN_DETECTADO_CAMBIOS_EN_EL_FORMULARIO'}
        contenido={'SI_CONTINUA_PERDERA_LOS_CAMBIOS_EN_EL_FORMULARIO_DESEA_CONTINUAR'}
        tipo="PREGUNTA"
        onSecondaryClick={() => setAlertForm(false)}
        handleClose={() => setAlertForm(false)}
        onPrimaryClick={() => history.push(`/admin/entities/companies`)}
      />
    </Grid>
  )
}
