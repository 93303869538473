import { Dictionary } from '../models/shared'

const modules = ['role', 'dashboard', 'consults', 'entities', 'transactions', 'configuration', 'security']

export const isUserAdmin = (claims: Dictionary): boolean => {
  let isAdmin = false
  Object.keys(claims).forEach((key) => {
    if (key === 'role') {
      const roles: string[] | any = claims['role']
      if (roles && Array.isArray(roles) && roles.length > 0) {
        isAdmin = roles.includes('administrator')
      } else {
        // Validate if the role is a string
        isAdmin = roles === 'administrator'
      }
    }
  })
  return isAdmin
}

export const extractUserClaims = (claims: Dictionary) => {
  Object.keys(claims).forEach((key) => {
    if (claims[key] !== 'None') {
      const claimModule = key.split('.')[0]
      if (!modules.includes(claimModule)) {
        delete claims[key]
      }
    } else {
      delete claims[key]
    }
  })
  return claims
}

export const extractUserModules = (claims: Dictionary) => {
  const modulesAllowed: string[] = []
  Object.keys(claims).forEach((key) => {
    const claimModule = key.split('.')[0]
    if (modules.includes(claimModule) && !modulesAllowed.includes(claimModule)) {
      modulesAllowed.push(claimModule)
    }
  })

  return modulesAllowed
}

export const excludedRolesForNotUserAdmin = ['customer', 'developer', 'administrator', 'employee']
export const excludedRolesForUserAdmin = ['customer', 'developer', 'administrator', 'employee']
