import { useCallback, useState } from 'react'
import { timeout, Post } from 'pb-shared'
import { PasswordReset } from '../models'
import { getAppConfiguration, RequestState } from '../../../../core'

interface RegistrationResponse {
  result: RequestState
  errors: string[]
}

export const usePasswordReset = () => {
  const { graphBaseUrl, requestTimeout } = getAppConfiguration()
  const [response, setResponse] = useState<RegistrationResponse>({ result: 'None', errors: [] })

  const resetPassword = useCallback(
    async (passwordReset: PasswordReset): Promise<any> => {
      const response = await timeout(requestTimeout, Post(`${graphBaseUrl}/user/passwordreset`, { ...passwordReset }))

      if (response.ok) {
        return response
          .json()
          .then((result) => {
            if (result.success) {
              setResponse({ result: 'Success', errors: [] })
            } else {
              setResponse({ result: 'Failed', errors: result.errors })
            }
          })
          .catch((e) => {
            setResponse({ result: 'Unknown', errors: [e.message] })
          })
      }

      const errorMessage = await response.text()
      switch (response.status) {
        case 401:
          setResponse({ result: 'Unauthorized', errors: [errorMessage] })
          break
        case 403:
          setResponse({ result: 'Forbidden', errors: [errorMessage] })
          break
        default:
          setResponse({ result: 'Unknown', errors: ['ERROR_DESCONOCIDO'] })
      }
    },
    [graphBaseUrl, requestTimeout],
  )

  return {
    response,
    resetPassword,
  }
}
