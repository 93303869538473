import React, { useState, useCallback, useMemo, useEffect } from 'react'
import { createStyles, Grid, makeStyles, Theme, Card, CardContent, CardHeader, Divider } from '@material-ui/core'
// My Components
import { ClientAccountFilter, ClientAccountsFilters } from './ClientAccountsFilters'
import { HeaderCardTitle } from '../../../core/components'
import { CustomDataGrid, CreateButton, DataGridColumns, AppBreadcrumbsBar, Loader } from '../../../core/components'

// Hooks
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// Models
import { ClaimActions, SearchGraphql } from '../../../core/models'
import { useClientAccounts } from './hooks'
import { GridPageChangeParams, GridRowParams } from '@material-ui/data-grid'
import { generateFilterGraphql, useUserClaimActions } from '../../../core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    grid: {
      paddingBottom: theme.spacing(2),
    },
  }),
)

const initialFilters = {
  where: {
    currencyName: generateFilterGraphql('', 'contains'),
    accountName: generateFilterGraphql('', 'contains'),
    isActive: generateFilterGraphql(true, 'eq'),
  },
  skip: 0,
  take: 10,
}

export const ClientAccounts = () => {
  const classes = useStyles()

  const { validateClaimActions } = useUserClaimActions()
  const actions = validateClaimActions('entities.client-accounts', [
    ClaimActions.CanCreate,
    ClaimActions.CanSearch,
    ClaimActions.CanView,
  ])

  // React router navigation
  const history = useHistory()
  // App Label Translation
  const { t: transLabel } = useTranslation('Label')

  // Filter values
  const [searchRequest, setSearchRequest] = useState<SearchGraphql>({})

  // Fetch Ticket
  const { results, loading, getClientAccounts } = useClientAccounts()

  const [currentPage, setCurrentPage] = useState(0)
  const [currentPageSize, setCurrentPageSize] = useState(10)

  useEffect(() => {
    getClientAccounts(initialFilters)
  }, [])

  function onChangeFilter(filter: ClientAccountFilter) {
    const filters = {
      where: {
        currencyName: generateFilterGraphql(filter.currencyName, 'contains'),
        accountName: generateFilterGraphql(filter.accountName, 'contains'),
        isActive: generateFilterGraphql(filter.isActive, 'eq'),
      },
      skip: 0,
      take: 10,
    }

    // setSearchRequest(filter)
    getClientAccounts(filters)
  }

  const getColumnDefinitions = useCallback((): DataGridColumns => {
    return {
      NOMBRE: { field: 'accountName', contentType: 'string' },
      PIN: { field: 'accountPin', contentType: 'id' },
      BALANCE: { field: 'balance', contentType: 'number' },
      POR_COBRAR: { field: 'noPayed', contentType: 'number' },
      MARGEN: { field: 'margen', contentType: 'percentageBar' },
      FECHA_INSCRIPCION: { field: 'creationdDate', contentType: 'date' },
      ULTIMA_ACTIVIDAD: { field: 'lastActivity', contentType: 'dateTime' },
    }
  }, [])

  const handlePageChange = (params: GridPageChangeParams) => {
    const { pageSize, page } = params

    setCurrentPage(page)
    setCurrentPageSize(pageSize)

    const newFilter = {
      ...searchRequest,
      take: pageSize,
      skip: pageSize * page,
    }

    setSearchRequest(newFilter)
    getClientAccounts(newFilter)
  }

  const rowSelectHandler = (params: GridRowParams) => {
    if (params.row) {
      history.push(`/admin/entities/client-accounts/${params.row.id}`)
    }
  }

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <AppBreadcrumbsBar
              title={transLabel('CUENTAS_DE_CLIENTES')}
              description={transLabel('CUENTAS_Y_BALANCES_DE_LOS_CLIENTES')}
              actions={
                actions.canCreate ? (
                  <CreateButton onClick={() => history.push(`/admin/entities/client-accounts/create`)} />
                ) : null
              }
            />
          </Grid>
          {actions.canSearch && (
            <Grid item xs={12} className={classes.grid}>
              <ClientAccountsFilters onFiltersChange={onChangeFilter} />
            </Grid>
          )}

          <Grid item xs={12}>
            <Card>
              <CardHeader component={() => <HeaderCardTitle title={transLabel('CLIENTE_CUENTAS')} />} />
              <Divider />
              <CardContent>
                <Loader loading={loading}>
                  <div style={{ minHeight: '70vh', width: '100%' }}>
                    <CustomDataGrid
                      data={results}
                      columns={getColumnDefinitions()}
                      pageSize={currentPageSize}
                      page={currentPage}
                      onRowClick={rowSelectHandler}
                      onPageChange={handlePageChange}
                      onPageSizeChange={handlePageChange}
                    />
                  </div>
                </Loader>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
