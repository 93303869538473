import React, { useState, useEffect } from 'react'
import * as Yup from 'yup'

import {
  Card,
  CardContent,
  CardHeader,
  createStyles,
  Divider,
  Grid,
  makeStyles,
  Theme,
  Radio,
  FormControlLabel,
  FormLabel,
} from '@material-ui/core'

import { HeaderCardTitle } from '../../../core/components'
import { Formik, Form, Field } from 'formik'
import { TextField, RadioGroup } from 'formik-material-ui'

// My Components
import { SearchButton, ClearButton } from '../../../core/components/buttons'
// Models
import { Filter, KeyValuePair, MatchType, SearchRequest } from '../../../core/models'

// Hooks
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardRoot: {
      minWidth: '100%',
    },
    description: {
      fontSize: 15,
      padding: theme.spacing(0.5),
    },
    formControl: {
      minWidth: '100%',
    },
    dates: {
      marginTop: '0px !important',
    },
    buttonGrid: {
      marginTop: '10px',
    },
    searchText: {
      minWidth: '100%',
    },
    accountTitle: {
      fontSize: '12px',
    },
  }),
)

export interface CompaniesFilters {
  companies: string
  searchBy: string
}
const initialValues: CompaniesFilters = {
  companies: 'active',
  searchBy: '',
}

const formSchema = Yup.object().shape({
  companies: Yup.string(),
  searchBy: Yup.string(),
})

type CompaniesFiltersProps = {
  onSearch: (params: CompaniesFilters) => void
}

export const CompaniesFilters = ({ onSearch }: CompaniesFiltersProps) => {
  const classes = useStyles()
  const { t: translation } = useTranslation('Label')

  return (
    <Card className={classes.cardRoot}>
      <CardHeader component={() => <HeaderCardTitle title={translation('CRITERIOS_DE_BUSQUEDA')} />} />
      <Divider />
      <CardContent>
        <Formik
          validationSchema={formSchema}
          validate={(values: CompaniesFilters) => {
            // Validate filters here
          }}
          initialValues={{ ...initialValues }}
          onSubmit={(values: CompaniesFilters, { setSubmitting }) => {
            setSubmitting(false)
            onSearch(values)
          }}
        >
          {({ errors, submitForm, isSubmitting, isValid }) => {
            return (
              <Form>
                <Grid container item spacing={3} xs={12} sm={12}>
                  <Grid item lg={2} xs={12} sm={2}>
                    <FormLabel component="legend">{translation('CUENTAS')}</FormLabel>
                    <Field component={RadioGroup} row defaultValue={'active'} name="companies">
                      <FormControlLabel
                        value={'active'}
                        control={<Radio disabled={isSubmitting} color="primary" />}
                        label={translation('ACTIVAS')}
                        disabled={isSubmitting}
                      />
                      <FormControlLabel
                        value={'inactive'}
                        control={<Radio disabled={isSubmitting} color="primary" />}
                        label={translation('INACTIVAS')}
                        disabled={isSubmitting}
                      />
                    </Field>
                  </Grid>
                  <Grid item lg={4} xs={12} sm={12}>
                    <Field
                      className={classes.searchText}
                      component={TextField}
                      type="text"
                      label={translation('MOSTRAR_LOS_QUE_COINCIDAD_CON')}
                      name="searchBy"
                    />
                  </Grid>
                  <Grid item lg={4} xs={12} sm={12} className={classes.buttonGrid}>
                    <SearchButton onClick={submitForm} />
                  </Grid>
                </Grid>
              </Form>
            )
          }}
        </Formik>
      </CardContent>
    </Card>
  )
}
