import { useCallback } from 'react'
import DateFnsUtils from '@date-io/date-fns'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import classname from 'classnames'
import { FormProps } from '../../models'

export interface CustomDatePickerProps extends FormProps {
  customClassName?: string
  onDateChange?: (date: string | Date | null) => void
  value?: string
  defaultValue?: string
}

export const CustomDatePicker = ({
  customClassName,
  onDateChange,
  defaultValue,
  value,
  ...props
}: CustomDatePickerProps) => {
  const {
    form: { setFieldValue, values },
    field: { name },
  } = props

  /**
   * @description change date selected
   * @param date
   */
  const handleDateChange = useCallback(
    (date: Date | null) => {
      setFieldValue(name, date)
      if (onDateChange instanceof Function) {
        onDateChange(date)
      }
    },
    [setFieldValue, name, onDateChange],
  )

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        {...props}
        id="date-picker-dialog"
        cancelLabel={'Cancelar'}
        todayLabel={'Hoy'}
        className={classname('input-date', customClassName)}
        value={value || values}
        onChange={handleDateChange}
        showTodayButton
        margin="normal"
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
      />
    </MuiPickersUtilsProvider>
  )
}
