import { gql, useMutation } from '@apollo/client'

const ADD_PROGRAM_COMPETITOR = gql`
  mutation CreateProgramCompetitor($input: AddProgramEntityWithOwnerInput!) {
    addProgramCompetitor(input: $input) {
      programCompetitor {
        name
      }
    }
  }
`
export interface AddProgramCompetitorInput {
  name: string
  ownerId: number
  isActive: boolean
}

export const useAddProgramCompetitor = () => {
  const [addProgramCompetitor, { loading, error }] = useMutation<AddProgramCompetitorInput>(ADD_PROGRAM_COMPETITOR)
  return { addProgramCompetitor, loading, error }
}
