import React, { ReactElement } from 'react'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert'
import { AlertTitle } from '@material-ui/lab'

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

export interface CustomAlertProps {
  message: React.ReactNode | String
  type: 'error' | 'success' | 'warning' | 'info'
  title?: string | ReactElement
  variant?: 'outlined' | 'filled' | 'standard' | undefined
}

export function CustomAlert({ message, type, title, variant }: CustomAlertProps) {
  return (
    <Alert severity={type} variant={variant}>
      {title && <AlertTitle>{title}</AlertTitle>}
      {message}
    </Alert>
  )
}
