import { Card, CardContent, CardHeader, createStyles, Divider, makeStyles, Theme } from '@material-ui/core'
import React, { useCallback, useEffect, useState } from 'react'
import { HeaderCardTitle } from '../../../core/components'
import { CustomDataGrid, DataGridColumns } from '../../../core/components/dataGrid/CustomDataGrid'
import { Metadata, ResultsList } from '../../../core/models'
import { useTranslation } from 'react-i18next'

interface IProps {
  results: ResultsList<any, Metadata>
  getColumnDefinitions: () => DataGridColumns
  groupByField: string
  pageSize: number
}

interface IGroupField {
  groupName: string
  data: ResultsList<any, Metadata>
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridFilter: {
      marginBottom: theme.spacing(2),
    },
    buttonsActions: {
      marginLeft: theme.spacing(1),
    },
    customDataGrid: {
      '& > div:nth-child(1)': {
        height: 'max-content !important;',
        width: '100% !important;',
      },
    },
    container: {
      height: '100vh',
    },
    containerDataGrid: {
      marginBottom: theme.spacing(2),
    },
  }),
)

const GroupCurrencyDataGrid = ({ results, getColumnDefinitions, groupByField, pageSize }: IProps) => {
  const [resultsCurrencyName, setResultsCurrencyName] = useState<Array<IGroupField>>([])
  const { t: translation } = useTranslation('Label')
  const classes = useStyles()

  useEffect(() => {
    formatResult()
  }, [results, formatResult])

  function formatResult() {
    let groupNames: Array<string> = Array.from(new Set(results.results.map((result) => result[groupByField])))

    let groups: Array<IGroupField> = []

    groupNames.forEach((groupName) => {
      const resultsFiltred = results.results.filter((item) => item[groupByField] === groupName)

      const data: ResultsList<any, Metadata> = {
        results: resultsFiltred,
        metadata: results.metadata,
      }

      groups.push({ groupName, data })
    })

    setResultsCurrencyName(groups)
  }

  const getColumnDefinitionsCallback = useCallback(getColumnDefinitions, [])

  useEffect(() => {
    formatResult()
  }, [results, formatResult])

  return (
    <>
      {resultsCurrencyName.map((itemResult, i) => {
        return (
          <Card key={i} className={classes.container}>
            <CardHeader component={() => <HeaderCardTitle title={translation(itemResult.groupName)} />} />
            <Divider />
            <CardContent className={classes.container}>
              <CustomDataGrid data={itemResult.data} columns={getColumnDefinitionsCallback()} pageSize={pageSize} />
            </CardContent>
          </Card>
        )
      })}
    </>
  )
}

export default GroupCurrencyDataGrid
