import { Fragment, useState } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Container, Grid, Button } from '@material-ui/core'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
// import Select from '@material-ui/core/Select'
// import MenuItem from '@material-ui/core/MenuItem'
import { AgesDependent } from './AgesDependent'
import _lodash from 'lodash'

import dependentsImg from './dependents.jpg'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    img: {
      minWidth: '-webkit-fill-available',
    },
    mainContainer: {
      position: 'relative',
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    footer: {
      position: 'absolute',
      bottom: 0,
      width: '92%',
      backgroundColor: '#cdd5dc',
      color: 'white',
      textAlign: 'center',
    },
    continueBtnDiv: {
      textAlign: 'right',
    },
    continueBtn: {
      textTransform: 'capitalize',
      marginTop: 10,
    },
    addFloatingBtnDiv: {
      textAlign: 'left',
      paddingLeft: 10,
    },
  }),
)

export interface Age {
  age: string
}

export type DependentsProps = {
  onContinue?: () => void
}

export const Dependents = ({ onContinue }: DependentsProps) => {
  const classes = useStyles()
  const [dependents, setDependents] = useState<Array<Age>>([{ age: '40' }])

  const addDependents = () => {
    const dependentsClone = _lodash.cloneDeep(dependents)
    dependentsClone.push({ age: '40' })
    setDependents(dependentsClone)
  }

  return (
    <Fragment>
      <Container>
        <form className={classes.root} noValidate autoComplete="off">
          <div>
            <img src={dependentsImg} className={classes.img} alt="dependent" />
          </div>
          {dependents.map((items, index) => {
            return (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <AgesDependent />
                </Grid>
              </Grid>
            )
          })}
        </form>
      </Container>
      <div className={classes.footer}>
        <Grid container xs={12} sm={12}>
          <Grid item xs={6} sm={6} md={6} lg={6} className={classes.addFloatingBtnDiv}>
            <Fab color="primary" aria-label="add" onClick={addDependents}>
              <AddIcon />
            </Fab>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} className={classes.continueBtnDiv}>
            <Button color="primary" className={classes.continueBtn} onClick={onContinue}>
              Continuar
            </Button>
          </Grid>
        </Grid>
      </div>
    </Fragment>
  )
}
