import { useCallback } from 'react'
import { Grid, CardContent, Card } from '@material-ui/core'
import { AppBreadcrumbsBar, CustomDataGrid, DataGridColumns, Loader } from '../../../core/components'
import { useTranslation } from 'react-i18next'
import { OperationPartnershipsFilter } from './OperationPartnershipsFilter'
import { OperationPartnershipsResults } from './OperationPartnershipsResults'
import { useProviderTransactionGlobal } from './hooks'
import { Metadata, ResultsList, Dictionary, SearchGraphql, ClaimActions } from '../../../core/models'
import { ProviderTransactionGlobal as ProviderTransactionGlobalModel } from './models'
import { GridRowParams } from '@material-ui/data-grid'
import { useHistory } from 'react-router-dom'
import { useUserClaimActions } from '../../../core'

export const OperationPartnerships = () => {
  const history = useHistory()
  const { t: translationLabel } = useTranslation('Label')
  const { results: providerData, loading, getProviderTransactionGlobal } = useProviderTransactionGlobal()
  const { validateClaimActions } = useUserClaimActions()
  const actions = validateClaimActions('consults.agencies-operations', [ClaimActions.CanSearch, ClaimActions.CanView])

  const getColumnDefinitions = useCallback((): DataGridColumns => {
    return {
      AGENCIAS: { field: 'locationName', contentType: 'currency' },
      PRODUCTO: { field: 'product', contentType: 'currency' },
      TICKETS: { field: 'totalTickets', contentType: 'currency' },
      APOSTADO: { field: 'totalBetsAmount', contentType: 'currency' },
      GANANCIA: { field: 'riskWin', contentType: 'currency' },
      GANANCIA_: { field: 'riskWinPercentage', contentType: 'currency' },
      IMPUESTOS: { field: 'tax', contentType: 'currency' },
      IMPUESTOS_: { field: 'taxPercentage', contentType: 'currency' },
    }
  }, [])

  const getOperationData = (data: ResultsList<ProviderTransactionGlobalModel, Metadata>): Dictionary | undefined => {
    if (data.results !== undefined) {
      let totalTickets = 0
      let totalBetsAmount = 0
      let totalPaidAmount = 0
      let riskWin = 0
      let tax = 0

      data.results.forEach((item) => {
        // product = item.product + product
        totalTickets = item.totalTickets + totalTickets
        totalBetsAmount = item.totalBetsAmount + totalBetsAmount
        totalPaidAmount = item.totalPaidAmount + totalPaidAmount
        riskWin = item.riskWin + riskWin
        tax = item.tax + tax
      })
      return {
        // CONSORCIO: 0,
        AGENCIAS: 0,
        // PRODUCTO: product,
        TICKETS: totalTickets,
        APOSTADO: totalBetsAmount,
        // A_PAGAR: totalPaidAmount,
        GANANCIA: riskWin,
        GANANCIA_: 0,
        IMPUESTOS: tax,
        IMPUESTOS_: 0,
      }
    }
    return undefined
  }

  const operationData = getOperationData(providerData)

  function onChangeFilter(filter: SearchGraphql) {
    filter = {
      ...filter,
      skip: 0,
      take: 20,
    }
    getProviderTransactionGlobal(filter)
  }

  const rowSelectHandler = (params: GridRowParams) => {
    if (params.row) {
      history.push({
        pathname: `/admin/consults/locations-operations`,
        state: params.row,
      })
    }
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <AppBreadcrumbsBar
          title={translationLabel('OPERACIONES_POR_CONSORCIOS')}
          description={`${translationLabel('OPERACIONES_POR_CONSORCIOS')}`}
        />
      </Grid>
      {actions.canSearch && (
        <Grid item xs={12}>
          {/*  onChange={handleChangeFilter} getWeeksDate={getWeeksDate} */}
          <OperationPartnershipsFilter onChange={onChangeFilter} />
        </Grid>
      )}
      <Grid item xs={12} lg={1} />
      <Grid
        item
        xs={12}
        lg={11}
        style={{
          width: '95vw',
        }}
      >
        {/*  onChange={handleChangeFilter} getWeeksDate={getWeeksDate} */}
        {operationData !== undefined && <OperationPartnershipsResults data={operationData} />}
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Loader loading={loading}>
              <div style={{ minHeight: '70vh', width: '100%' }}>
                <CustomDataGrid
                  onRowClick={rowSelectHandler}
                  columns={getColumnDefinitions()}
                  pageSize={20}
                  data={providerData}
                />
              </div>
            </Loader>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}
