import React from 'react'
import { AuthConsumer } from 'pb-shared'

import { LoadingAnimation } from '../../core/components'

export const Logout = () => (
  <AuthConsumer>
    {({ logout }) => {
      logout()
      return <LoadingAnimation />
    }}
  </AuthConsumer>
)
