import React, { useEffect, useState } from 'react'
import moment, { unitOfTime } from 'moment'
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  createStyles,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { GraphqlExpression, SearchGraphql } from '../../../core/models'
import { useLocalizedContent } from '../../../core/hooks/useLocalizedContent'
import { HeaderCardTitle, CustomDatePicker as DateField, LocalizedContentSelect } from '../../../core/components'
import { Formik, Field } from 'formik'
import { generateFilterGraphql } from '../../../core'

type TypeQueryRange = 'DIARIO' | 'SEMANAL' | 'QUINCENAL' | 'MENSUAL' | 'TRIMESTRAL' | 'SEMESTRAL' | 'ANUAL'

type StateTypeQuery = {
  type: TypeQueryRange
  steps: number
  startOf: unitOfTime.StartOf
  durationAs: unitOfTime.DurationAs
}

interface IProps {
  onChange: (filter: SearchGraphql) => void
}
const useStyles = makeStyles(() =>
  createStyles({
    datesClass: {
      marginTop: '0px !important',
    },
  }),
)

const ProfitAndLossFilter = ({ onChange }: IProps) => {
  const [currentDateSelected, setCurrentDateSelected] = useState<string>(
    moment().startOf('isoWeek').format('MM-DD-YYYY'),
  )
  const { t: translationLabel } = useTranslation('Label')
  const { t: translationAction } = useTranslation('Action')

  const [typeQuery, setTypeQuery] = useState<StateTypeQuery>({
    type: 'SEMANAL',
    steps: 1,
    startOf: 'week',
    durationAs: 'week',
  })
  // LocalizedContent
  const { results: resultPeriodity } = useLocalizedContent({ entityType: 'System', entityName: 'Periodicity' })
  const { results: resultCurrency } = useLocalizedContent({ entityType: 'System', entityName: 'Currency' })

  const [currencyName, setCurrencyName] = useState<GraphqlExpression>({ eq: 'PESOS_DOMINICANOS' })
  const [eventDate, setEventDate] = useState<GraphqlExpression>({ eq: moment().format('MM-DD-YYYY') })
  const [periodicityName, setPeriodicityName] = useState<GraphqlExpression>({ eq: 'SEMANAL' })
  const [isPeople, setIsPeople] = useState<GraphqlExpression>({ eq: true })
  const { datesClass } = useStyles()
  function addTime() {
    let nextDate = moment(currentDateSelected, 'MM-DD-YYYY')
      .add(typeQuery.steps, typeQuery.durationAs)
      .format('MM-DD-YYYY')

    nextDate = moment(nextDate, 'MM-DD-YYYY').clone().startOf(typeQuery.startOf).format('MM-DD-YYYY')
    onChangeDate(nextDate)
    setCurrentDateSelected(nextDate)
  }

  function subtractTime() {
    let nextDate = moment(currentDateSelected, 'MM-DD-YYYY')
      .subtract(typeQuery.steps, typeQuery.durationAs)
      .format('MM-DD-YYYY')

    nextDate = moment(nextDate, 'MM-DD-YYYY').clone().startOf(typeQuery.startOf).format('MM-DD-YYYY')
    onChangeDate(nextDate)

    setCurrentDateSelected(nextDate)
  }

  const handleSelectChange = (newPeriodicityName: string) => {
    let steps: number = 0
    let exactDate: string = ''
    let unitTime: unitOfTime.StartOf | unitOfTime.DurationAs = 'week'
    let typeQueryRange: TypeQueryRange = 'SEMANAL'

    switch (newPeriodicityName) {
      case 'DIARIO':
        steps = 1
        exactDate = moment(currentDateSelected, 'MM-DD-YYYY').format('MM-DD-YYYY')
        unitTime = 'day'
        typeQueryRange = 'DIARIO'
        break
      case 'SEMANAL':
        steps = 1
        exactDate = moment(currentDateSelected, 'MM-DD-YYYY').startOf('isoWeek').format('MM-DD-YYYY')
        unitTime = 'week'
        typeQueryRange = 'SEMANAL'
        break
      case 'QUINCENAL':
        steps = 15
        exactDate = moment(currentDateSelected, 'MM-DD-YYYY').startOf('isoWeek').format('MM-DD-YYYY')
        unitTime = 'day'
        typeQueryRange = 'QUINCENAL'
        break
      case 'MENSUAL':
        steps = 1
        exactDate = moment(currentDateSelected, 'MM-DD-YYYY').clone().startOf('month').format('MM-DD-YYYY')
        unitTime = 'month'
        typeQueryRange = 'MENSUAL'
        break
      case 'TRIMESTRAL':
        exactDate = moment(currentDateSelected, 'MM-DD-YYYY').clone().startOf('months').format('MM-DD-YYYY')
        steps = 3
        unitTime = 'months'
        typeQueryRange = 'TRIMESTRAL'
        break
      case 'SEMESTRAL':
        exactDate = moment(currentDateSelected, 'MM-DD-YYYY').clone().startOf('months').format('MM-DD-YYYY')
        steps = 6
        unitTime = 'months'
        typeQueryRange = 'SEMESTRAL'
        break
      case 'ANUAL':
        exactDate = moment(currentDateSelected, 'MM-DD-YYYY').clone().startOf('year').format('MM-DD-YYYY')
        steps = 1
        unitTime = 'year'
        typeQueryRange = 'ANUAL'
        break
    }

    setTypeQuery({ type: typeQueryRange, steps, startOf: unitTime, durationAs: unitTime })
    setCurrentDateSelected(exactDate)
  }

  function onChangeDate(eventDate: string) {
    const newEventDate = generateFilterGraphql(moment(eventDate).format('MM-DD-YYYY'), 'eq')
    setEventDate(newEventDate)
    setCurrentDateSelected(eventDate)
  }
  function onChangeCurrency(currencyName: string) {
    setCurrencyName(generateFilterGraphql(currencyName, 'eq'))
  }
  function onChangePeriodicityName(periodicityName: string) {
    handleSelectChange(periodicityName)
    setPeriodicityName(generateFilterGraphql(periodicityName, 'eq'))
  }
  function onChangeIsPeople(isPeople: React.ChangeEvent<HTMLInputElement>) {
    const result = isPeople.target.value === 'true'

    setIsPeople(generateFilterGraphql(result, 'eq'))
  }

  useEffect(() => {
    onChange({
      where: {
        currencyName,
        eventDate,
        periodicityName,
        isPeople,
      },
    })
  }, [currencyName, eventDate, periodicityName, isPeople])
  return (
    <Card>
      <CardHeader component={() => <HeaderCardTitle title={translationLabel('CRITERIOS_DE_BUSQUEDA')} />} />
      <Divider />
      <CardContent>
        <Formik
          initialValues={{
            eventDate: moment().format('MM-DD-YYYY'),
            currencyName: 'PESOS_DOMINICANOS',
            periodicityName: 'SEMANAL',
            isPeople: true,
          }}
          onSubmit={(_values, { setSubmitting }) => {
            setSubmitting(false)
          }}
        >
          {() => (
            <Grid container spacing={3}>
              <Grid item lg={2} xs={12} sm={2}>
                <FormLabel component="legend">{translationLabel('TIPO_ENTIDAD')}</FormLabel>
                <Field component={RadioGroup} row defaultValue={'true'} name="isPeople" onChange={onChangeIsPeople}>
                  <FormControlLabel
                    control={<Radio name="isPeople" color="primary" value={'true'} />}
                    label={translationLabel('CLIENTE')}
                  />
                  <FormControlLabel
                    control={<Radio name="isPeople" color="primary" value={'false'} />}
                    label={translationLabel('INSTITUCION')}
                  />
                </Field>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <Field
                  component={DateField}
                  name="eventDate"
                  label="Fecha"
                  format="dd/MM/yyyy"
                  fullWidth
                  customClassName={datesClass}
                  onDateChange={onChangeDate}
                  value={currentDateSelected}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <FormControl fullWidth>
                  <Field
                    component={LocalizedContentSelect}
                    name="currencyName"
                    isGrupalValue
                    label={translationLabel('DIVISA')}
                    data={resultCurrency}
                    fullWidth
                    onChange={onChangeCurrency}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <FormControl fullWidth>
                  <Field
                    component={LocalizedContentSelect}
                    name="periodicityName"
                    isGrupalValue
                    label={translationLabel('PERIODO')}
                    data={resultPeriodity}
                    fullWidth
                    onChange={onChangePeriodicityName}
                  />
                </FormControl>
              </Grid>
              <Grid spacing={1} item container xs={12} sm={12} md={6} lg={4} xl={3}>
                <Grid item>
                  <Button variant="contained" color="primary" onClick={subtractTime}>
                    {translationAction('ANTERIOR')}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      let currentDate = moment().format('MM-DD-YYYY')
                      currentDate = moment(currentDate, 'MM-DD-YYYY')
                        .clone()
                        .startOf(typeQuery.startOf)
                        .format('MM-DD-YYYY')
                      setCurrentDateSelected(currentDate)
                    }}
                  >
                    {translationAction('ACTUAL')}
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant="contained" color="primary" onClick={addTime}>
                    {translationAction('SIGUIENTE')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Formik>
      </CardContent>
    </Card>
  )
}

export default ProfitAndLossFilter
