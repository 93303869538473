import React, { useEffect, useState } from 'react'
import { Formik, Form, Field } from 'formik'
import { Select } from 'formik-material-ui'
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  FormControl,
  makeStyles,
  Theme,
  createStyles,
  MenuItem,
} from '@material-ui/core'
import { HeaderCardTitle, CustomDatePicker as DateField, LocalizedContentSelect } from '../../../core/components'
import { useTranslation } from 'react-i18next'
// My Components
import { generateFilterGraphql, isMobile, useLocalizedContent } from '../../../core'
import { GraphqlExpression, SearchGraphql } from '../../../core/models'
import moment from 'moment'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    select: {
      minWidth: '100%',
      marginTop: !isMobile() ? 23 : 'unset',
    },
  }),
)

interface AccountSummaryFilterIProps {
  onChange: (newFilter: SearchGraphql) => void
  // getWeeksDate: (weeks: currentWeekType) => void
}

const AccountSummaryFilter = ({ onChange }: AccountSummaryFilterIProps) => {
  const classes = useStyles()

  const { t: translationLabel } = useTranslation('Label')
  const { results: resultCurrency } = useLocalizedContent({ entityType: 'System', entityName: 'Currency' })

  const [currentDateSelected, setCurrentDateSelected] = useState<string>(moment().format('MM-DD-YYYY'))
  const [eventDate, setEventDate] = useState<GraphqlExpression>({ eq: moment().format('MM-DD-YYYY') })
  const [currencyName, setCurrencyName] = useState<GraphqlExpression>({ eq: 'PESOS_DOMINICANOS' })
  const [paymentName, setPaymentName] = useState('DEBITO')

  function onChangeDate(eventDate: string) {
    setCurrentDateSelected(eventDate)
    setEventDate(generateFilterGraphql(moment(eventDate).format('YYYY-MM-DD'), 'eq'))
  }

  function onChangeCurrency(currencyName: string) {
    setCurrencyName(generateFilterGraphql(currencyName, 'eq'))
  }

  function onChangePaymentName(event: React.ChangeEvent<HTMLInputElement>) {
    setPaymentName(event.target.value)
  }

  useEffect(() => {
    onChange({
      where: {
        currencyName,
        eventDate,
        typePaymentName: generateFilterGraphql(paymentName, 'eq'),
      },
    })
  }, [currencyName, eventDate, paymentName])

  return (
    <Card>
      <CardHeader component={() => <HeaderCardTitle title={translationLabel('CRITERIOS_DE_BUSQUEDA')} />} />
      <Divider />
      <CardContent>
        <Formik
          initialValues={{
            eventDate: moment().format('MM-DD-YYYY'),
            currencyName: 'PESOS_DOMINICANOS',
            typePaymentName: 'DEBITO',
          }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(false)
          }}
        >
          {({ submitForm, isSubmitting }) => (
            <Form>
              <Grid container item alignItems="center" spacing={3} xs={12} sm={12}>
                <Grid item lg={3} xs={12} sm={12}>
                  <Field
                    onChange={onChangePaymentName}
                    className={classes.select}
                    component={Select}
                    name={'typePaymentName'}
                    value={paymentName}
                    fullWidth
                  >
                    <MenuItem key={'DEBITO'} value={'DEBITO'}>
                      {translationLabel('DEBITO')}
                    </MenuItem>
                    <MenuItem key={'CREDITO'} value={'CREDITO'}>
                      {translationLabel('CREDITO')}
                    </MenuItem>
                    <MenuItem key={'EFECTIVO'} value={'EFECTIVO'}>
                      {translationLabel('EFECTIVO')}
                    </MenuItem>
                  </Field>
                </Grid>
                <Grid item lg={3} xs={12} sm={12}>
                  <Field
                    component={DateField}
                    name="eventDate"
                    label={translationLabel('FECHA')}
                    format={'dd/MM/yyyy'}
                    fullWidth
                    // customClassName={datesClass}
                    onDateChange={onChangeDate}
                    value={currentDateSelected}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <FormControl fullWidth>
                    <Field
                      component={LocalizedContentSelect}
                      name="currencyName"
                      isGrupalValue
                      label={translationLabel('DIVISA')}
                      data={resultCurrency}
                      fullWidth
                      onChange={onChangeCurrency}
                    />
                  </FormControl>
                </Grid>
                {/* <Grid item lg={3} xs={12} sm={4}>
                  <SearchButton onClick={submitForm} />
                </Grid> */}
              </Grid>
            </Form>
          )}
        </Formik>
      </CardContent>
    </Card>
  )
}

export default AccountSummaryFilter
