import { gql, useLazyQuery, useQuery } from '@apollo/client'
import { Metadata, ResultsList, SearchGraphql } from '../../../../core/models'
import { ProviderConnectionEvent } from '../models'

const GET_PROVIDER_CONNECTION_EVENTS = gql`
  query GetProviderApiConnectionEvents($skip: Int, $take: Int, $where: ProviderApiConnectionEventFilterInput!) {
    providerApiConnectionEvents(where: $where, skip: $skip, take: $take) {
      items {
        id
        providerApiConnectionId
        syncDate
        eventType
        event
      }
    }
  }
`

interface ProviderConnectionEventData {
  providerApiConnectionEvents: {
    totalCount: number
    items: ProviderConnectionEvent[]
  }
}

export const useProviderConnectionEvents = () => {
  const [getResults, { loading, error, data }] = useLazyQuery<ProviderConnectionEventData>(
    GET_PROVIDER_CONNECTION_EVENTS,
    {
      fetchPolicy: 'network-only',
    },
  )

  const getProviderConnectionEvents = ({ where, take, skip }: SearchGraphql) => {
    getResults({
      variables: {
        where: { ...where },
        skip: skip ? skip : 0,
        take: take ? take : 100,
      },
    })
  }

  const results = {
    results: data?.providerApiConnectionEvents.items || [],
    metadata: {
      Count: data?.providerApiConnectionEvents.totalCount,
    },
  }
  return { getProviderConnectionEvents, loading, error, results }
}
