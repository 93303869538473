import React, { useState, useCallback, useEffect } from 'react'
import _lodash from 'lodash'
import { Card, CardContent, Grid, makeStyles, createStyles, Theme, Select, MenuItem } from '@material-ui/core'
import { PersonOutline, Receipt, Business, GroupAdd, MonetizationOn } from '@material-ui/icons'

import { useTranslation } from 'react-i18next'
import {
  AppBreadcrumbsBar,
  ValuePlusIncreaseDecreased,
  CustomAvatarGroupCard,
  Loader,
  CustomDataGrid,
  DataGridColumns,
  CustomBarChart,
} from '../../../core/components'
import {
  formatAsCurrency,
  isMobile,
  useLocalizedContent,
  formatAsNumber,
  formatToShortLocalDate,
  generateFilterGraphql,
} from '../../../core'
import { ResultsList, Metadata } from '../../../core/models'
import { useSalesSummary, useProductNextPeriod } from './hooks'
import { SalesSummary } from './models'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardRoot: {
      minWidth: '100%',
      maxHeight: '600px',
      marginBottom: theme.spacing(3),
    },
    cardTable: {
      marginBottom: theme.spacing(3),
    },
    select: {
      minWidth: '100%',
      marginTop: !isMobile() ? '85px' : 'unset',
    },
    valuePlusIcon: {
      width: '3.5rem',
      height: '3.5rem',
      color: '#20c248',
    },
  }),
)

export const SalesProfitDashboard = () => {
  const classes = useStyles()
  const [periodicity, setPeriodicity] = useState<string>('SEMANAL')
  const [currency, setCurrency] = useState<string>('PESOS_DOMINICANOS')

  const { t: translationLabel } = useTranslation('Label')
  const { results: salesSummaryData, getSalesSummary, loading: loadingSalesSummary } = useSalesSummary(true)
  const { loading: loadingLastSalesWithoutLocations, results: lastSalesDataWithoutLocations } = useSalesSummary(false)

  const { results: productsPeriod, getProduct: getProductPeriod } = useProductNextPeriod(1)
  const { results: productsNextPeriod, getProduct: getProductNextPeriod } = useProductNextPeriod(2)

  const { results: periodicityResults } = useLocalizedContent({ entityType: 'System', entityName: 'Periodicity' })
  const { results: currencyResults } = useLocalizedContent({ entityType: 'System', entityName: 'Currency' })

  useEffect(() => {
    getProductNextPeriod({
      where: {
        periodicityName: generateFilterGraphql(periodicity, 'eq'),
        currencyName: generateFilterGraphql(currency, 'eq'),
      },
    })
    getProductPeriod({
      where: {
        periodicityName: generateFilterGraphql(periodicity, 'eq'),
        currencyName: generateFilterGraphql(currency, 'eq'),
      },
    })
    getSalesSummary({
      where: {
        periodicityName: generateFilterGraphql(periodicity, 'eq'),
        currencyName: generateFilterGraphql(currency, 'eq'),
      },
    })
  }, [periodicity, currency])

  const onChangePeriodicity = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      const periodicityValue = event.target.value !== undefined ? (event.target.value as string) : ''
      setPeriodicity(periodicityValue)
      // handleFilters(periodicityValue, currency)
    },
    [setPeriodicity],
  )

  const onChangeCurrency = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      const currencyValue = event.target.value !== undefined ? (event.target.value as string) : ''
      setCurrency(currencyValue)
      // handleFilters(periodicity, currencyValue)
    },
    [setCurrency],
  )

  const getColumnDefinitions = useCallback((): DataGridColumns => {
    return {
      AGENCIAS: { field: 'salesDate', contentType: 'date' },
      TICKETS: { field: 'totalTickets', contentType: 'number' },
      '# %': { field: 'ticketsIncrease', contentType: 'percentageArrow' },
      BENEFICIOS: { field: 'totalProfit', contentType: 'number' },
      '$ %': { field: 'profitIncrease', contentType: 'percentageArrow' },
    }
  }, [])

  const getColumnDefinitionsAccount = useCallback((): DataGridColumns => {
    return {
      CUENTAS: { field: 'salesDate', contentType: 'date' },
      TICKETS: { field: 'totalTickets', contentType: 'number' },
      '# %': { field: 'ticketsIncrease', contentType: 'percentageArrow' },
      BENEFICIOS: { field: 'totalProfit', contentType: 'number' },
      '$ %': { field: 'profitIncrease', contentType: 'percentageArrow' },
    }
  }, [])

  const salesSummary: SalesSummary = salesSummaryData?.results[0]

  // Product Next Period
  let chartDataNextPeriod = undefined
  if (productsNextPeriod.results.length > 0) {
    const productNamesNextPeriod: Array<string> = getProductNames(productsNextPeriod)
    const datesNextPeriod: Array<string> = getDateNumbers(productsNextPeriod)
    chartDataNextPeriod = getChartData(productsNextPeriod, productNamesNextPeriod, datesNextPeriod)
    productNamesNextPeriod.unshift('')
    chartDataNextPeriod.unshift(productNamesNextPeriod)
  }

  // Product Period
  let chartDataPeriod = undefined
  if (productsPeriod.results.length > 0) {
    const productNamesPeriod: Array<string> = getProductNames(productsPeriod)
    const datesPeriod: Array<string> = getDateNumbers(productsPeriod)
    chartDataPeriod = getChartData(productsPeriod, productNamesPeriod, datesPeriod)
    productNamesPeriod.unshift('')
    chartDataPeriod.unshift(productNamesPeriod)
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <AppBreadcrumbsBar
          title={translationLabel('ESTADISTICAS_DE_CUENTAS_VENTAS_Y_GANANCIAS')}
          description={translationLabel('DATOS_MOSTRADOS_ESTAN_BASADOS_EN_ESTAS_ENTIDADADES')}
        />
      </Grid>
      <Card className={classes.cardRoot}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item lg={3} sm={12} xs={12}>
              <Select
                className={classes.select}
                labelId="typeQuery"
                name={'typeQuery'}
                value={periodicity}
                onChange={onChangePeriodicity}
                fullWidth
              >
                {periodicityResults.map((item) => (
                  <MenuItem key={item.entityId} value={item.grupal}>
                    {translationLabel(item.grupal)}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item lg={3} sm={12} xs={12}>
              {/* currency */}
              <Select
                className={classes.select}
                labelId="typeQuery"
                name={'typeQuery'}
                value={currency}
                onChange={onChangeCurrency}
                fullWidth
              >
                {currencyResults.map((item) => (
                  <MenuItem key={item.entityId} value={item.grupal}>
                    {translationLabel(item.grupal)}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item lg={3} sm={12} xs={12}>
              <CustomAvatarGroupCard
                Icon={Receipt}
                value={formatAsNumber(salesSummary?.totalTickets, 0)}
                percentage={salesSummary?.ticketsIncrease}
                titleValue=""
                groupName={'TICKETS'}
              />
            </Grid>
            <Grid item lg={3} sm={12} xs={12}>
              <CustomAvatarGroupCard
                Icon={MonetizationOn}
                value={formatAsCurrency(salesSummary?.totalSales, 0)}
                percentage={salesSummary?.salesIncrease}
                titleValue=""
                groupName={'APOSTADO'}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card className={classes.cardRoot}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item lg={4} xs={12} sm={12}>
              <ValuePlusIncreaseDecreased
                Icon={<PersonOutline className={classes.valuePlusIcon} />}
                value={formatAsNumber(salesSummary?.totalAccounts, 0)}
                titleValue="TOTAL_CUENTAS"
                percentageTitle="CRECIMIENTO_DEL_PERIODO"
              />
            </Grid>
            <Grid item lg={4} xs={12} sm={12}>
              <ValuePlusIncreaseDecreased
                Icon={<GroupAdd className={classes.valuePlusIcon} />}
                value={formatAsNumber(salesSummary?.newAccounts, 0)}
                percentage={salesSummary?.accountIncrease}
                titleValue="NUEVAS_CUENTAS"
                percentageTitle="CRECIMIENTO_DEL_PERIODO"
              />
            </Grid>
            <Grid item lg={4} xs={12} sm={12}>
              <ValuePlusIncreaseDecreased
                Icon={<Business className={classes.valuePlusIcon} />}
                value={formatAsNumber(salesSummary?.totalLocations, 0)}
                titleValue="TOTAL_OFICINAS"
                percentageTitle="CRECIMIENTO_DEL_PERIODO"
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Grid container spacing={2}>
        <Grid item lg={6} xs={12} sm={12}>
          <Card className={classes.cardTable}>
            <CardContent>
              <div
                style={{
                  minHeight: `${salesSummaryData.results.length > 0 ? '70vh' : '250px'}`,
                  width: '100%',
                }}
              >
                <Loader loading={loadingSalesSummary}>
                  <CustomDataGrid
                    data={salesSummaryData}
                    columns={getColumnDefinitions()}
                    pageSize={20}
                    onPageChange={() => {}}
                    onRowClick={() => {}}
                    disableColumnMenu={true}
                    hideFooterPagination={true}
                  />
                </Loader>
              </div>
            </CardContent>
          </Card>
        </Grid>

        <Grid item lg={6} xs={12} sm={12}>
          <Card className={classes.cardTable}>
            <CardContent>
              <div
                style={{
                  minHeight: `${lastSalesDataWithoutLocations.results.length > 0 ? '70vh' : '250px'}`,
                  width: '100%',
                }}
              >
                <Loader loading={loadingLastSalesWithoutLocations}>
                  <CustomDataGrid
                    data={lastSalesDataWithoutLocations}
                    columns={getColumnDefinitionsAccount()}
                    pageSize={20}
                    onPageChange={() => {}}
                    onRowClick={() => {}}
                    disableColumnMenu={true}
                    hideFooterPagination={true}
                  />
                </Loader>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item lg={6} xs={12} sm={12}>
          <Card>
            <CardContent>
              <Loader loading={false}>
                <CustomBarChart
                  options={{
                    chart: {
                      title: translationLabel('PROFIT_PRODUCT'),
                    },
                  }}
                  data={chartDataPeriod}
                />
              </Loader>
            </CardContent>
          </Card>
        </Grid>

        <Grid item lg={6} xs={12} sm={12}>
          <Card>
            <CardContent>
              <CustomBarChart
                chartType="Line"
                options={{
                  chart: {
                    title: translationLabel('PROFIT_PRODUCT_NEXT_PERIOD'),
                  },
                }}
                data={chartDataNextPeriod}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  )
}

/**
 * Get Product array Names
 * @returns [string]
 */
const getProductNames = (ProductsData: ResultsList<SalesSummary, Metadata>): Array<string> => {
  const products = ProductsData.results
  let uniqueProducts: string[] = []
  if (products !== undefined) {
    uniqueProducts = _lodash.uniq(_lodash.map(products, 'productName'))
  }
  return uniqueProducts
}

/**
 * Get array Dates
 * @returns [string]
 */
const getDateNumbers = (ProductsData: ResultsList<SalesSummary, Metadata>): Array<string> => {
  const products = ProductsData.results
  let uniqueDays: string[] = []
  if (products !== undefined) {
    const dates = products.map((product) => {
      return formatToShortLocalDate(product.salesDate, 'DD/MM/YYYY')
    })
    uniqueDays = _lodash.uniq(dates)
  }
  return uniqueDays
}

/**
 * Build chart data
 * @param productNames
 * @param days
 * @returns
 */
const getChartData = (
  ProductsData: ResultsList<SalesSummary, Metadata>,
  productNames: Array<string>,
  days: Array<string>,
): Array<Array<any>> => {
  const products = ProductsData.results
  let chartData: any[] = []
  if (products !== undefined) {
    days.forEach((d) => {
      let prodRow: any[] = []
      productNames.forEach((productName) => {
        const product = products.find(
          (prod) => prod.productName === productName && formatToShortLocalDate(prod.salesDate, 'DD/MM/YYYY') === d,
        )
        prodRow.push(product?.totalProfit)
      })
      prodRow.unshift(d.toString())
      chartData.push(prodRow)
    })
  }
  return chartData
}
