import { useCallback, useEffect, useState } from 'react'
import { GridCellParams, GridPageChangeParams, GridRowParams } from '@material-ui/data-grid'
import {
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
} from '@material-ui/core'
import { useEvents } from '../hooks'
import { generateFilterGraphql, useUserClaimActions, ClaimActions } from '../../../../core'
import {
  AppBreadcrumbsBar,
  CreateButton,
  CustomDataGrid,
  DataGridColumns,
  HeaderCardTitle,
  Loader,
} from '../../../../core/components'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { EventsFilters, EventsFiltersType } from './EventsFilters'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    grid: {
      paddingBottom: theme.spacing(2),
    },
  }),
)

export const Events = () => {
  const classes = useStyles()
  const history = useHistory()
  const { validateClaimActions } = useUserClaimActions()
  const actions = validateClaimActions('configuration.events', [ClaimActions.CanCreate, ClaimActions.CanSearch])
  const { results, loading, getEvents } = useEvents()
  const { t: transLabel } = useTranslation('Label')
  const [filters, setFilters] = useState<EventsFiltersType>({ name: '', date: '' })
  const [paging, setPaging] = useState<GridPageChangeParams>({
    page: 0,
    pageCount: 0,
    pageSize: 10,
    paginationMode: 'server',
    rowCount: 10,
  })

  useEffect(() => {
    let customFilters: any = []
    customFilters.push({
      name: { ...generateFilterGraphql(filters.name, 'contains') },
    })

    getEvents({
      where: {
        or: customFilters,
      },
      skip: paging.page * paging.pageSize,
      take: paging.pageSize,
    })
  }, [, paging, filters])

  const getColumnDefinitions = useCallback((): DataGridColumns => {
    return {
      FECHA: { field: 'date', contentType: 'date' },
      NOMBRE: { field: 'name', contentType: 'string' },
      CARRERAS: { field: 'numberOfRaces', contentType: 'number' },
      // CODIGO: { field: 'performanceCode', contentType: 'string' },
      CODIGO: {
        field: '',
        contentType: 'custom',
        renderCell: (params: GridCellParams) => {
          console.log(params)
          return (
            <Typography>
              {params.row.performanceCode} - {params.row.name}
            </Typography>
          )
        },
      },
      ESTADO: { field: 'isActive', contentType: 'boolean' },
    }
  }, [])

  const handleRowSelected = (params: GridRowParams) => {
    history.push(`/admin/entities/events/${params.id}`)
  }

  const handlePageChange = (param: GridPageChangeParams) => {
    setPaging(param)
  }

  const handlePageSizeChange = (param: GridPageChangeParams) => {
    setPaging(param)
  }

  const handleFilters = (filters: EventsFiltersType) => {
    if (filters.date) {
      setFilters({
        ...filters,
        date: filters.date,
      })
    } else {
      setFilters({
        ...filters,
        name: filters.name,
      })
    }
  }

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <AppBreadcrumbsBar
              colTitleSize={10}
              colActionSize={2}
              title={transLabel('EVENTOS_TITULO')}
              description={transLabel('EVENTOS_SUB_TITULO')}
              actions={
                actions.canCreate ? (
                  <CreateButton onClick={() => history.push(`/admin/entities/events/create`)} />
                ) : null
              }
            />
          </Grid>
          {actions.canSearch && (
            <Grid item xs={12} className={classes.grid}>
              <EventsFilters onFilterChange={handleFilters} />
            </Grid>
          )}
          <Grid item xs={12}>
            <Card>
              <CardHeader component={() => <HeaderCardTitle title={transLabel('LISTA_DE_EVENTOS')} />} />
              <Divider />
              <CardContent>
                <Loader loading={loading}>
                  <div style={{ minHeight: '70vh', width: '100%' }}>
                    <CustomDataGrid
                      data={results}
                      columns={getColumnDefinitions()}
                      page={paging.page}
                      pageSize={paging.pageSize}
                      onRowClick={handleRowSelected}
                      onPageChange={handlePageChange}
                      onPageSizeChange={handlePageSizeChange}
                    />
                  </div>
                </Loader>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
