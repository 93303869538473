import { Fragment } from 'react'
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles'
import { Typography, Card, CardContent, CardMedia, CardActions } from '@material-ui/core'

import dependentsImg from './dependents.jpg'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    card: {
      // maxWidth: 345,
      margin: 5,
      '& .MuiCardContent-root': {
        padding: 55,
      },
    },
    fiCardContent: {
      color: '#ffffff',
      backgroundColor: '#00000070',
    },
    fiCardContentTextSecondary: {
      color: 'rgba(255,255,255,0.78)',
    },
    footerLabel: {
      fontSize: 'x-large',
      fontWeight: 'bolder',
    },
  }),
)

export const FiCard = withStyles({
  root: {
    position: 'relative',
  },
})(Card)

export const FiCardActions = withStyles({
  root: {
    position: 'relative',
  },
})(CardActions)

export const FiCardContent = withStyles({
  root: {
    position: 'relative',
    backgroundColor: 'transparent',
  },
})(CardContent)

export const FiCardMedia = withStyles({
  root: {
    position: 'absolute',
    top: 0,
    right: 0,
    height: '100%',
    width: '100%',
  },
})(CardMedia)

export const Plans = () => {
  const classes = useStyles()
  return (
    <Fragment>
      <FiCard className={classes.card}>
        <FiCardMedia image={dependentsImg} title="Contemplative Reptile" />
        <FiCardContent className={classes.fiCardContent}>
          <Typography gutterBottom variant="h5" component="h2" />
          <Typography variant="body2" className={classes.fiCardContentTextSecondary} component="p" />
        </FiCardContent>
        <FiCardActions className={classes.fiCardContent}>
          <span className={classes.footerLabel}>Serie 3000</span>
        </FiCardActions>
      </FiCard>

      <FiCard className={classes.card}>
        <FiCardMedia image={dependentsImg} title="Contemplative Reptile" />
        <FiCardContent className={classes.fiCardContent}>
          <Typography gutterBottom variant="h5" component="h2" />
          <Typography variant="body2" className={classes.fiCardContentTextSecondary} component="p" />
        </FiCardContent>
        <FiCardActions className={classes.fiCardContent}>
          <span className={classes.footerLabel}>Plan Meridian II</span>
        </FiCardActions>
      </FiCard>

      <FiCard className={classes.card}>
        <FiCardMedia image={dependentsImg} title="Contemplative Reptile" />
        <FiCardContent className={classes.fiCardContent}>
          <Typography gutterBottom variant="h5" component="h2" />
          <Typography variant="body2" className={classes.fiCardContentTextSecondary} component="p" />
        </FiCardContent>
        <FiCardActions className={classes.fiCardContent}>
          <span className={classes.footerLabel}>Plan Azure</span>
        </FiCardActions>
      </FiCard>

      <FiCard className={classes.card}>
        <FiCardMedia image={dependentsImg} title="Contemplative Reptile" />
        <FiCardContent className={classes.fiCardContent}>
          <Typography gutterBottom variant="h5" component="h2" />
          <Typography variant="body2" className={classes.fiCardContentTextSecondary} component="p" />
        </FiCardContent>
        <FiCardActions className={classes.fiCardContent}>
          <span className={classes.footerLabel}>Plan Ideal</span>
        </FiCardActions>
      </FiCard>
    </Fragment>
  )
}
