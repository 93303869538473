import { gql, useMutation } from '@apollo/client'

const GENERATE_WORKSTATION_CODE = gql`
  mutation generateWorstationRegistrationCode($input: GetRegistrationCodeInput!) {
    generateWorstationRegistrationCode(input: $input) {
      registrationCode
    }
  }
`

export const useAddGenerateWorkstationCode = () => {
  const [addGenerateWorkstationCode, { loading, error }] = useMutation(GENERATE_WORKSTATION_CODE)
  return { addGenerateWorkstationCode, loading, error }
}
