import { Workstation } from '../models'
import { gql, useLazyQuery } from '@apollo/client'
import { Metadata, ResultsList, SearchGraphql } from '../../../../core/models'
import { useState, useEffect, useCallback } from 'react'

const GET_WORKSTATIONS = gql`
  query GetWorkstations($where: WorkstationFilterInput, $skip: Int, $take: Int) {
    workstations(where: $where, skip: $skip, take: $take) {
      items {
        id
        name
        enterpriseId
        locationId
        workstationTypeId
        active
        __typename
      }
      __typename
    }
  }
`

interface WorkstationsData {
  workstations: {
    totalCount: number
    items: Workstation[]
  }
}

export const useWorkstations = () => {
  const [getResults, { loading, error, data }] = useLazyQuery<WorkstationsData>(GET_WORKSTATIONS, {
    fetchPolicy: 'network-only',
  })

  const [results, setResults] = useState<ResultsList<Workstation, Metadata>>({
    results: [],
    metadata: {},
  })

  const getWorkstations = useCallback(
    ({ where, take, skip }: SearchGraphql) => {
      getResults({
        variables: {
          where: { ...where },
          skip: skip ? skip : 0,
          take: take ? take : 100,
        },
      })
    },
    [getResults],
  )

  useEffect(() => {
    setResults({
      results: data?.workstations.items || [],
      metadata: {
        Count: data?.workstations.totalCount,
      },
    })
  }, [data?.workstations.items, data?.workstations.totalCount, loading])

  return { loading, error, results, getWorkstations }
}
