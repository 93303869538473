import React from 'react'
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core'
import { LoadingAnimation } from '../animations/Loading/LoadingAnimation'

const useStyle = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignContent: 'flex-center',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
    },
  })

export interface ComponentProps extends WithStyles<typeof useStyle> {
  children: React.ReactNode
  loading: boolean
}

const Component = ({ classes, children, loading }: ComponentProps) => {
  return loading ? (
    <div className={classes.root}>
      <LoadingAnimation />
    </div>
  ) : (
    <div>{children}</div>
  )
}

export const Loader = withStyles(useStyle)(Component)
