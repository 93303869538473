import { gql, useMutation } from '@apollo/client'

const ADD_LOCATION = gql`
  mutation AddLocation($input: AddLocationInput) {
    addLocation(input: $input) {
      location {
        id
        name
        typeLocationId
        enterpriseId
        address
        phone
        countryId
        provinceId
        municipalityId
        sectorId
        restrictedByGeography
        active
      }
    }
  }
`
export interface AddLocationInput {
  name: string
  typeLocationId: string
  address: string
  phone: string
  countryId: string
  provinceId: string
  municipalityId: string
  sectorId: number
  restrictedByGeography: boolean
}

export const useAddLocation = () => {
  const [addLocation, { loading, error }] = useMutation(ADD_LOCATION)
  return { addLocation, loading, error }
}
