import { createStyles, makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      width: '100%',
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      flex: '1 0 auto',
    },
    icon: {
      marginRight: '.5rem',
      '& .MuiSvgIcon-root': {
        fontSize: '60px',
        color: '#969696',
        opacity: '0.5',
      },
    },
    label: {
      color: '#969696',
      marginBottom: '0.7rem',
      // textAlign: 'center',
    },
    balance: {
      fontSize: '1.2em',
      color: '#0a2ee5',
      // fontWeight: 'bold',
      marginTop: '-10px',
      textAlign: 'end',
    },
    controls: {
      display: 'flex',
      alignItems: 'center',
    },
  }),
)

type MoneyCardProps = {
  title: string
  balance: number | string | React.ReactElement
  icon?: React.ReactElement
}

export const MoneyCard = ({ title, balance, icon }: MoneyCardProps) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.icon}>{icon}</div>

      <div className={classes.details}>
        <div className={classes.content}>
          <Typography variant="body2" color="textSecondary" className={classes.label}>
            {title}
          </Typography>
          <Typography component="h6" variant="h6" className={classes.balance}>
            {balance}
          </Typography>
        </div>
      </div>
    </div>
  )
}
