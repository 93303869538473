import _ from 'lodash'
import { FilterColumn, FilterField, SearchGraphqlType, SearchRequest, GraphqlExpression } from '../models/search'
import { formatToShortDate } from './dateFunctions'

export const getColumnFilterExpression = (field: FilterField) => {
  switch (field.matchType) {
    case 'Exact': {
      if (typeof field.value === 'string') {
        return `${field.name} = "${field.value}"`
      } else {
        return `${field.name} = ${field.value}`
      }
    }
    case 'DateRange': {
      const range = field.value as [Date, Date]
      const startDate = formatToShortDate(range[0])
      const endDate = formatToShortDate(range[1])
      return `(${field.name} >= ${startDate} && ${field.name} <= ${endDate}})`
    }
    case 'Contains':
      return `${field.name}.Contains("${field.value}")`

    case 'Date':
      return `${field.name} = ${field.value}`

    case 'StartsWith':
      return `${field.name}.StartsWith("${field.value}")`
    case 'EndsWith':
      return `${field.name}.EndsWith("${field.value}")`
    default:
      return ''
  }
}

export const buildQueryString = (searchRequest: SearchRequest) => {
  //
  if (!searchRequest) {
    return ''
  }

  const { restrictions, filter, ...search } = searchRequest

  let query: string = ''

  if (filter) {
    const filterList = Object.keys(filter)
      .filter((k) => filter[k] !== undefined)
      .map((key) => {
        const f = filter[key] as FilterColumn
        const item: FilterField = { name: key, ...f }
        return item
      })

    var parsedFilter = _.chain(filterList)
      .groupBy((f) => f?.group)
      .map((value, key) => {
        return value.length === 1
          ? getColumnFilterExpression(value[0])
          : `(${value.map((v) => getColumnFilterExpression(v)).join(' OR ')})`
      })
      .value()
      .join(' AND ')

    query = encodeURIComponent(parsedFilter)
  }

  const params = {
    ...restrictions,
    filter: query,
    ...search,
  } as any

  const queryString = Object.keys(params)
    .map((key) => key + '=' + params[key])
    .join('&')

  return queryString.length > 0 ? `?${queryString}` : ''
}

export const filterBuilder = (filter: string, columns: Array<string>): string => {
  const query = columns
    .map((column) => {
      return `${column}.Contains("${filter}")`
    })
    .join(' || ')

  return `(${query})`
}

export function generateFilterGraphql(value: string | boolean | number, type: SearchGraphqlType): GraphqlExpression {
  const eqFilter: GraphqlExpression = {
    [type]: value,
  }
  return eqFilter
}
