import { ProviderTransaction } from './models'
import { gql, useLazyQuery } from '@apollo/client'
import { Metadata, ResultsList, SearchGraphql } from '../../../core/models'
import { useState, useEffect, useCallback } from 'react'

const GET_PROVIDER_TRANSACTION = gql`
  query GetProviderTransaction($where: ProviderTransactionsFilterInput!, $skip: Int, $take: Int) {
    providerTransactions(where: $where, skip: $skip, take: $take) {
      totalCount
      items {
        startDate
        endDate
        enterpriseId
        parentName
        currencyType
        product
        totalTickets
        totalTicketsCancelled
        totalBetsAmount
        totalWinsAmount
        totalPaidAmount
        totalCancelAmount
        riskWin
        riskWinPercentage
        tax
      }
    }
  }
`

interface ProviderTransactionData {
  providerTransactions: {
    totalCount: number
    items: ProviderTransaction[]
  }
}

export const useProviderTransaction = () => {
  const [getResults, { loading, error, data }] = useLazyQuery<ProviderTransactionData>(GET_PROVIDER_TRANSACTION, {
    fetchPolicy: 'network-only',
  })

  const [results, setResults] = useState<ResultsList<ProviderTransaction, Metadata>>({
    results: [],
    metadata: {},
  })

  const getProviderTransaction = useCallback(
    ({ where, take, skip }: SearchGraphql) => {
      getResults({ variables: { where, take, skip } })
    },
    [getResults],
  )

  useEffect(() => {
    setResults({
      results: data?.providerTransactions.items || [],
      metadata: {
        Count: data?.providerTransactions.totalCount,
      },
    })
  }, [data?.providerTransactions, loading])

  return { loading, error, results, getProviderTransaction }
}
