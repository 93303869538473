import { gql, useLazyQuery, useQuery } from '@apollo/client'
import { useCallback, useEffect, useState } from 'react'
import { Metadata, ResultsList, SearchGraphql } from '../../../../../core/models'
import { Race } from '../../models'

const GET_RACES = gql`
  query GetProgramRaces($where: ProgramRaceFilterInput!, $skip: Int, $take: Int) {
    programRaces(where: $where, skip: $skip, take: $take) {
      items {
        id
        programEventId
        raceNumber
        typeOfRace
        purse
        distanceId
        runners
        genderRestriction
        ageRestriction
        weight
        wagersId
        breed
        course
        postTime
        createdDate
        createdBy
        isActive
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`

interface RacesData {
  programRaces: {
    totalCount: number
    items: Race[]
  }
}

export const useRaces = () => {
  const [getResults, { loading, error, data }] = useLazyQuery<RacesData>(GET_RACES, {
    fetchPolicy: 'network-only',
  })

  const getRaces = ({ where, take, skip }: any) => {
    getResults({
      variables: {
        where: { ...where },
        skip: skip ? skip : 0,
        take: take ? take : 5,
      },
    })
  }

  const results: ResultsList<Race, Metadata> = {
    results: data?.programRaces.items || [],
    metadata: {
      Count: data?.programRaces.totalCount,
    },
  }

  return { getRaces, loading, error, results }
}
