import React, { useState, useCallback, useEffect } from 'react'
import { GridPageChangeParams, GridRowParams } from '@material-ui/data-grid'
import {
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Button,
} from '@material-ui/core'
// My Components
import { EnterprisesFilters } from './EnterprisesFilters'
import { HeaderCardTitle } from '../../../core/components'
import { CustomDataGrid, CreateButton, DataGridColumns, AppBreadcrumbsBar, Loader } from '../../../core/components'

// Hooks
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// Models
import { ClaimActions, SearchRequest } from '../../../core/models'
import { useEnterprises } from './hooks'
import { generateFilterGraphql, useUserClaimActions } from '../../../core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    grid: {
      paddingBottom: theme.spacing(2),
    },
  }),
)

export const Enterprises = () => {
  const classes = useStyles()
  const history = useHistory()
  const { t: transLabel } = useTranslation('Label')
  const { validateClaimActions } = useUserClaimActions()
  const [paging, setPaging] = useState<GridPageChangeParams>({
    page: 0,
    pageCount: 0,
    pageSize: 10,
    paginationMode: 'server',
    rowCount: 10,
  })
  const actions = validateClaimActions('configuration.enterprises', [
    ClaimActions.CanCreate,
    ClaimActions.CanView,
    ClaimActions.CanSearch,
  ])

  // Filter values
  const [searchRequest, setSearchRequest] = useState<SearchRequest>({
    filter: {},
    page: 1,
    pageSize: 20,
  })

  const { results, loading, getEnterprises } = useEnterprises()

  const handlePageChange = (params: GridPageChangeParams) => {
    setPaging(params)
  }

  const handlePageSizeChange = (param: GridPageChangeParams) => {
    setPaging(param)
  }

  useEffect(() => {
    const customFilters = {
      and: [
        { name: { ...generateFilterGraphql('', 'contains') } },
        { active: { ...generateFilterGraphql(true, 'eq') } },
      ],
    }

    getEnterprises({ where: customFilters, skip: paging.page * paging.pageSize, take: paging.pageSize })
  }, [paging])

  const handleFilters = (values: EnterprisesFilters) => {
    let customFilters = null
    if (values.companyId > 0) {
      customFilters = {
        and: [
          { name: { ...generateFilterGraphql(values.searchBy, 'contains') } },
          { active: { ...generateFilterGraphql(values.active, 'eq') } },
          { companyId: { ...generateFilterGraphql(values.companyId, 'eq') } },
        ],
      }
    } else {
      customFilters = {
        and: [
          { name: { ...generateFilterGraphql(values.searchBy, 'contains') } },
          { active: { ...generateFilterGraphql(values.active, 'eq') } },
        ],
      }
    }

    getEnterprises({ where: customFilters, skip: paging.page * paging.pageSize, take: paging.pageSize })
  }

  const getColumnDefinitions = useCallback((): DataGridColumns => {
    return {
      NOMBRE: { field: 'name', contentType: 'string' },
      ESTADO: { field: 'active', contentType: 'boolean' },
    }
  }, [])

  const rowSelectHandler = (params: GridRowParams) => {
    if (params.row) {
      history.push(`/admin/entities/enterprises/${params.row.id}`)
    }
  }

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <AppBreadcrumbsBar
              title={transLabel('CONSORCIO_TITULO')}
              description={transLabel('CONSORCIO_SUB_TITULO')}
              colTitleSize={10}
              colActionSize={2}
              actions={
                actions.canCreate ? (
                  <CreateButton onClick={() => history.push(`/admin/entities/enterprises/create`)} />
                ) : null
              }
            />
          </Grid>
          {actions.canSearch && (
            <Grid item xs={12} className={classes.grid}>
              <EnterprisesFilters onSearch={handleFilters} />
            </Grid>
          )}
          <Grid item xs={12}>
            <Card>
              <CardHeader component={() => <HeaderCardTitle title={transLabel('LISTA_DE_CONSORCIOS')} />} />
              <Divider />
              <CardContent>
                <Loader loading={loading}>
                  <div style={{ minHeight: '70vh', width: '100%' }}>
                    <CustomDataGrid
                      data={results}
                      columns={getColumnDefinitions()}
                      page={paging.page}
                      pageSize={paging.pageSize}
                      onPageChange={handlePageChange}
                      onRowClick={rowSelectHandler}
                      onPageSizeChange={handlePageSizeChange}
                    />
                  </div>
                </Loader>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
