import { gql, useMutation } from '@apollo/client'

const REMOVE_RUNNER = gql`
  mutation RemoveProgramRunnerAsync($id: Int!) {
    removeProgramRunner(id: $id)
  }
`

export const useRemoveRunner = () => {
  const [removeRunner, { loading, error }] = useMutation(REMOVE_RUNNER)
  return { removeRunner, loading, error }
}
