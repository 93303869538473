import { Form, Field, useFormik, FormikProvider } from 'formik'
import { Card, CardContent, CardHeader, Divider, Grid, FormControl } from '@material-ui/core'
import {
  HeaderCardTitle,
  TextFieldDebounceFormik,
  SearchButton,
  CustomAutoCompleteSelectFormik,
} from '../../../../core/components'
import { useTranslation } from 'react-i18next'
import { useProgramTrainers } from '../hooks'
import { useEffect } from 'react'
import { generateFilterGraphql } from '../../../../core'

export type OwnerFiltersType = {
  name: string
  trainerId: number | undefined
}

interface EventsFilterIProps {
  onFilterChange: (filter: OwnerFiltersType) => void
}

export const OwnerFilters = ({ onFilterChange }: EventsFilterIProps) => {
  const { t: translationLabel } = useTranslation('Label')
  const { results: trainers, loading, getProgramTrainers } = useProgramTrainers()

  useEffect(() => {
    const customFilters = {
      and: [
        {
          name: { ...generateFilterGraphql('', 'contains') },
        },
      ],
    }

    getProgramTrainers({ where: customFilters, take: 500, skip: 0 })
  }, [])

  const formik = useFormik({
    initialValues: {
      name: '',
      trainerId: undefined,
    },
    onSubmit: onFilterChange,
  })

  return (
    <Card>
      <CardHeader component={() => <HeaderCardTitle title={translationLabel('CRITERIOS_DE_BUSQUEDA')} />} />
      <Divider />
      <CardContent>
        <FormikProvider value={formik}>
          <Form>
            <Grid container item alignItems="center" spacing={3} xs={12} sm={12}>
              <Grid item xs={12} sm={12} md={5} lg={5}>
                <FormControl fullWidth>
                  <Field
                    component={TextFieldDebounceFormik}
                    type="text"
                    label={translationLabel('NOMBRE')}
                    name="name"
                    fullWidth
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={5} lg={5}>
                <FormControl fullWidth>
                  <Field
                    component={CustomAutoCompleteSelectFormik}
                    keyValue={'id'}
                    keyLabel={'name'}
                    data={trainers.results}
                    label={translationLabel('ENTRENADOR')}
                    name="trainerId"
                    fullWidth
                    type="text"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <SearchButton onClick={formik.submitForm} />
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </CardContent>
    </Card>
  )
}
