import React, { useCallback } from 'react'
import { Checkbox } from '@material-ui/core'
import { FormProps } from '../../models'

export interface CheckboxFormikProps extends FormProps {
  value?: string
  defaultValue?: string
  disabled?: boolean
}

export const CheckboxFormik = ({ defaultValue, value, disabled, ...props }: CheckboxFormikProps) => {
  const {
    form: { setFieldValue, values },
    field: { name },
  } = props

  /**
   * @description change date selected
   * @param date
   */
  const handleChange = useCallback(
    (event: React.ChangeEvent<any>) => {
      const value = event.target.checked
      setFieldValue(name, value)
    },
    [setFieldValue, name],
  )

  return <Checkbox {...props} checked={values[name]} onChange={handleChange} disabled={disabled} />
}
