import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { generateFilterGraphql } from '../../../core'
import { CustomAutoCompleteSelect } from '../../../core/components'
import { FormProps } from '../../../core/models'
import { useProviders } from './hooks'
import { Provider } from './models'

export interface ProviderSelectProps extends FormProps {
  label?: string
  onChangeProvider?: (Provider: Provider) => void
  disabled?: boolean
}

export const ProviderSelect = ({ onChangeProvider, disabled, ...props }: ProviderSelectProps) => {
  const {
    form: { setFieldValue, values },
    field: { name },
  } = props

  const { getProviders, results, loading } = useProviders()
  const { t: transLabel } = useTranslation('Label')

  useEffect(() => {
    const customFilters = {
      and: [{ name: { ...generateFilterGraphql('', 'contains') } }],
    }

    getProviders({ where: customFilters, skip: 0, take: 100 })
  }, [])

  const handleFilter = () => {}

  const handleChange = useCallback(
    (value: Provider | null) => {
      setFieldValue(name, value ? value.id : '')
      if (onChangeProvider instanceof Function && value) {
        onChangeProvider(value)
      }
    },
    [setFieldValue, name],
  )

  let defaultValue = null
  if (results.results.length > 0) {
    const filter = results.results.filter((item: any) => item.id === values[name])
    if (filter.length > 0) {
      defaultValue = filter[0]
    }
  }

  return (
    <CustomAutoCompleteSelect
      disabled={disabled}
      defaultValue={defaultValue}
      data={results.results}
      keyValue={'name'}
      isLoading={loading}
      label={transLabel('PROVEEDOR')}
      onChangeValue={handleChange}
      onInputValueChange={handleFilter}
    />
  )
}
