import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from 'react-i18next'
import { Checkbox, FormControlLabel, Grid } from '@material-ui/core'
import { RadioButtonChecked, RadioButtonUnchecked } from '@material-ui/icons'
import { AllowedClaimValues } from '../models'
import { ClaimActions, useUserClaimActions } from '../../../../core'
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      display: 'contents',
    },
    content: {
      display: 'contents',
    },
    headingTitle: {
      fontSize: theme.typography.pxToRem(15),
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
      paddingRight: '5%',
      marginTop: -10,
    },
    checkbox: {
      marginLeft: 0,
    },
    grid: {
      minWidth: 200,
      textAlign: 'right',
    },
  }),
)

type PermissionAllowedClaimsProps = {
  title: string
  allowedClaimValues: Array<AllowedClaimValues> | undefined
}

export const PermissionAllowedClaims = ({ title, allowedClaimValues }: PermissionAllowedClaimsProps) => {
  const classes = useStyles()
  const { t: transLabel } = useTranslation('Label')
  const { t: transMenu } = useTranslation('Menu')
  const { validateClaimActions } = useUserClaimActions()
  const actions = validateClaimActions('security.profiles', [ClaimActions.CanEdit])

  return (
    <Grid container>
      <Grid item sm={12} xs={12} md={2} lg={2}>
        <Typography className={classes.headingTitle}>{transMenu(title)}</Typography>
      </Grid>

      {allowedClaimValues !== undefined && (
        <>
          <Grid item sm={12} xs={12} md={1} lg={1} className={classes.grid}>
            <Typography className={classes.secondaryHeading}>
              {transLabel(allowedClaimValues[0].value)}
              <FormControlLabel
                className={classes.checkbox}
                control={
                  <Checkbox
                    disabled={!actions.canEdit}
                    icon={<RadioButtonUnchecked fontSize="small" />}
                    checkedIcon={<RadioButtonChecked fontSize="small" />}
                    name="checkedH"
                  />
                }
                label=""
              />
            </Typography>
          </Grid>
          <Grid item sm={12} xs={12} md={1} lg={1} className={classes.grid}>
            <Typography className={classes.secondaryHeading}>
              {transLabel(allowedClaimValues[1].value)}
              <FormControlLabel
                className={classes.checkbox}
                control={
                  <Checkbox
                    disabled={!actions.canEdit}
                    icon={<RadioButtonUnchecked fontSize="small" />}
                    checkedIcon={<RadioButtonChecked fontSize="small" />}
                    name="checkedH"
                  />
                }
                label=""
              />
            </Typography>
          </Grid>
          <Grid item sm={12} xs={12} md={1} lg={1} className={classes.grid}>
            <Typography className={classes.secondaryHeading}>
              {transLabel(allowedClaimValues[2].value)}
              <FormControlLabel
                className={classes.checkbox}
                control={
                  <Checkbox
                    disabled={!actions.canEdit}
                    icon={<RadioButtonUnchecked fontSize="small" />}
                    checkedIcon={<RadioButtonChecked fontSize="small" />}
                    name="checkedH"
                  />
                }
                label=""
              />
            </Typography>
          </Grid>
          <Grid item sm={12} xs={12} md={1} lg={1} className={classes.grid}>
            <Typography className={classes.secondaryHeading}>
              {transLabel(allowedClaimValues[3].value)}
              <FormControlLabel
                className={classes.checkbox}
                control={
                  <Checkbox
                    disabled={!actions.canEdit}
                    icon={<RadioButtonUnchecked fontSize="small" />}
                    checkedIcon={<RadioButtonChecked fontSize="small" />}
                    name="checkedH"
                  />
                }
                label=""
              />
            </Typography>
          </Grid>
          <Grid item sm={12} xs={12} md={1} lg={1} className={classes.grid}>
            <Typography className={classes.secondaryHeading}>
              {transLabel(allowedClaimValues[4].value)}
              <FormControlLabel
                className={classes.checkbox}
                control={
                  <Checkbox
                    disabled={!actions.canEdit}
                    icon={<RadioButtonUnchecked fontSize="small" />}
                    checkedIcon={<RadioButtonChecked fontSize="small" />}
                    name="checkedH"
                  />
                }
                label=""
              />
            </Typography>
          </Grid>
          <Grid item sm={12} xs={12} md={1} lg={1} className={classes.grid}>
            <Typography className={classes.secondaryHeading}>
              {transLabel(allowedClaimValues[5].value)}
              <FormControlLabel
                className={classes.checkbox}
                control={
                  <Checkbox
                    disabled={!actions.canEdit}
                    icon={<RadioButtonUnchecked fontSize="small" />}
                    checkedIcon={<RadioButtonChecked fontSize="small" />}
                    name="checkedH"
                  />
                }
                label=""
              />
            </Typography>
          </Grid>
          <Grid item sm={12} xs={12} md={1} lg={1} className={classes.grid}>
            <Typography className={classes.secondaryHeading}>
              {transLabel(allowedClaimValues[6].value)}
              <FormControlLabel
                className={classes.checkbox}
                control={
                  <Checkbox
                    disabled={!actions.canEdit}
                    icon={<RadioButtonUnchecked fontSize="small" />}
                    checkedIcon={<RadioButtonChecked fontSize="small" />}
                    name="checkedH"
                  />
                }
                label=""
              />
            </Typography>
          </Grid>
        </>
      )}
    </Grid>
  )
}
