import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import XHR from 'i18next-xhr-backend'
import { getAppConfiguration } from './core'

// the translations
// (tip move them in a JSON file and import them)
// const resources = {
//   en: {
//     translation: {
//       key_1: 'Placebet'
//     }
//   }
// }

const xhrBackendOptions = {
  // path where resources get loaded from, or a function
  // returning a path:
  // function(lngs, namespaces) { return customPath; }
  // the returned path will interpolate lng, ns if provided like giving a static path
  loadPath: () => {
    const { graphBaseUrl } = getAppConfiguration()
    return `${graphBaseUrl}/locales/{{lng}}/{{ns}}`
  },

  // path to post missing resources
  addPath: 'locales/add/{{lng}}/{{ns}}',

  // your backend server supports multiloading
  // /locales/resources.json?lng=de+en&ns=ns1+ns2
  // Adapter is needed to enable MultiLoading https://github.com/i18next/i18next-multiload-backend-adapter
  // Returned JSON structure in this case is
  // {
  //  lang : {
  //   namespaceA: {},
  //   namespaceB: {},
  //   ...etc
  //  }
  // }
  allowMultiLoading: true, // set loadPath: '/locales/resources.json?lng={{lng}}&ns={{ns}}' to adapt to multiLoading

  // allow cross domain requests
  crossDomain: true,

  // allow credentials on cross domain requests
  withCredentials: true,

  // overrideMimeType sets request.overrideMimeType("application/json")
  overrideMimeType: true,

  // custom request headers sets request.setRequestHeader(key, value)
  // customHeaders: {
  //   authorization: 'foo'
  //   // ...
  // }
}

i18n
  .use(XHR)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: 'es',
    fallbackLng: 'es',
    debug: process.env.NODE_ENV !== 'production',
    defaultNS: 'Label',
    preload: ['es'],
    // resources,

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },

    backend: xhrBackendOptions,

    react: {
      useSuspense: false,
    },
  })

export default i18n
