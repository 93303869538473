import React from 'react'
import lottie from 'lottie-web'
import animation from './dashboard.json'

export const AdminAnimation = () => {
  React.useEffect(() => {
    lottie.loadAnimation({
      container: document.querySelector('#animation-admin') as HTMLDivElement,
      animationData: animation,
    })
  }, [])

  return <div id="animation-admin" />
}
