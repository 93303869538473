import React, { useContext, useEffect, useState } from 'react'
import Badge from '@material-ui/core/Badge'
import Avatar from '@material-ui/core/Avatar'
import { Theme, makeStyles, withStyles, createStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Popover from '@material-ui/core/Popover'
import { ProfileCard } from './ProfileCard'
import { AuthContext } from 'pb-shared'
import { Enterprise } from '../../../../scenes/entities/enterprises/models'
import { useDispatch, useSelector } from 'react-redux'
import { AppState, setUserEnterprise } from '../../../redux'
import { DialogAlerts } from '../../alerts'
import { useHistory } from 'react-router-dom'

const StyledBadge = withStyles((theme: Theme) =>
  createStyles({
    badge: {
      backgroundColor: '#44b700',
      color: '#44b700',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: '$ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }),
)(Badge)

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& .MuiBadge-anchorOriginBottomRightCircle': {
        bottom: '25%',
      },
    },
    popover: {
      marginTop: 10,
    },
  }),
)

const getInitialLettersFromName = (name: string) => {
  if (name === '') return ''

  const splitName = name.split(' ')

  return `${splitName.length > 0 ? splitName[0][0] : ''} ${splitName.length > 1 ? splitName[1][0] : ''}`
}

export interface Profile {
  fullName: string
  name: string
  family_name: string
  idp: string
}

export const ProfileHeader = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const userContext = useContext(AuthContext)
  const [profile, setProfile] = useState<Profile>({ name: '', family_name: '', idp: '', fullName: '' })
  const [alertForm, setAlertForm] = useState(false)
  const [selectedEnterprise, setSelectedEnterprise] = useState<Enterprise | undefined>(undefined)
  const history = useHistory()
  const { enterprises } = useSelector((state: AppState) => state.appBar)

  useEffect(() => {
    userContext.getUserProfile().then((user) => {
      setProfile({
        ...profile,
        fullName: `${user.nickname ? user.nickname : ''} ${user.family_name ? user.family_name : ''}`,
        name: user.name !== undefined ? user.name : '',
        family_name: user.family_name !== undefined ? user.family_name : '',
        idp: user.idp !== undefined ? user.idp : '',
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userContext])

  useEffect(() => {
    if (enterprises.length > 0) {
      setSelectedEnterprise(enterprises[0])
      dispatch(setUserEnterprise(enterprises[0]))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enterprises, setSelectedEnterprise])

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const handleSelectEnterprise = (enterprise: Enterprise) => {
    const hasFormChanges = window.sessionStorage.getItem('hasFormChanges')
    setSelectedEnterprise(enterprise)
    if (hasFormChanges === 'true') {
      setAlertForm(true)
    } else {
      window.sessionStorage.setItem('selectedEnterprise', JSON.stringify(enterprise))
      dispatch(setUserEnterprise(enterprise))
      history.push(`/admin`)
    }
  }

  const handleAlertOption = (shouldChange: boolean) => {
    if (shouldChange && selectedEnterprise !== undefined) {
      dispatch(setUserEnterprise(selectedEnterprise))
      window.sessionStorage.setItem('selectedEnterprise', JSON.stringify(selectedEnterprise))
      window.sessionStorage.setItem('hasFormChanges', 'false')
      history.push(`/admin`)
    }
    setAlertForm(false)
  }

  const id = open ? 'custom-popover' : undefined

  return (
    <div className={classes.root}>
      <StyledBadge
        overlap="circle"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        variant="dot"
      >
        <Avatar alt={profile.name}>{getInitialLettersFromName(profile.name)}</Avatar>
      </StyledBadge>

      <IconButton
        aria-label="user profile"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        <ExpandMoreIcon />
        <Popover
          className={classes.popover}
          id={id}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <ProfileCard profile={profile} onSelectEnterprise={handleSelectEnterprise} />
        </Popover>
      </IconButton>
      <DialogAlerts
        state={alertForm}
        titulo={'SE_HAN_DETECTADO_CAMBIOS_EN_EL_FORMULARIO'}
        contenido={'SI_CONTINUA_PERDERA_LOS_CAMBIOS_EN_EL_FORMULARIO_DESEA_CONTINUAR'}
        tipo="PREGUNTA"
        onSecondaryClick={() => handleAlertOption(false)}
        handleClose={() => handleAlertOption(false)}
        onPrimaryClick={() => handleAlertOption(true)}
      />
    </div>
  )
}
