import { gql, useLazyQuery, useQuery } from '@apollo/client'
import { Metadata, ResultsList, SearchGraphql } from '../../../../../core/models'
import { Event } from '../../models'

const GET_EVENTS = gql`
  query GetProgramEvents($where: ProgramEventFilterInput!, $skip: Int, $take: Int) {
    programEvents(where: $where, skip: $skip, take: $take, order: { createdDate: DESC }) {
      items {
        id
        name
        date
        performanceCode
        numberOfRaces
        isActive
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`

interface EventsData {
  programEvents: {
    totalCount: number
    items: Event[]
  }
}

export const useEvents = () => {
  const [getResults, { loading, error, data }] = useLazyQuery<EventsData>(GET_EVENTS, {
    fetchPolicy: 'network-only',
  })

  const getEvents = ({ where, take, skip }: SearchGraphql) => {
    getResults({
      variables: {
        where: { ...where },
        skip: skip ? skip : 0,
        take: take ? take : 100,
      },
    })
  }

  const results: ResultsList<Event, Metadata> = {
    results: data?.programEvents.items || [],
    metadata: {
      Count: data?.programEvents.totalCount,
    },
  }

  return { getEvents, loading, error, results }
}
