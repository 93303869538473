import { useEffect, useState } from 'react'
import { Grid, FormControl, FormControlLabel } from '@material-ui/core'
import { CustomFormikTimePicker, CheckboxFormik, CustomAutoCompleteSelectFormik } from '../../../../core/components'
import { useTranslation } from 'react-i18next'
import { Field, FormikValues } from 'formik'
import { TextField } from 'formik-material-ui'
import { ProgramSelectOptions, Race } from '../models'
import { WagerAutoCompleteSelectFormik } from './WagerAutoCompleteSelectFormik'
import { DistanceSelectFormik } from './DistanceSelectFormik'

type RaceFormProps = {
  onSave: (values: Race) => void
  isFormDisabled: boolean
  programSelectOptions: [] | ProgramSelectOptions[]
  race?: Race | undefined
  formState: 'CREATE' | 'EDIT' | 'CANCEL'
  onFilterProgramOptions: (filter: string) => void
  formik: FormikValues
  possibleWagers: string
}

let typeBetOptions: [] | ProgramSelectOptions[] = []

export const RaceForm = ({
  race,
  isFormDisabled,
  programSelectOptions,
  formState,
  onFilterProgramOptions,
  formik,
  possibleWagers,
}: RaceFormProps) => {
  const [isDisabled, setIsDisabled] = useState(isFormDisabled)
  const { t: transLabel } = useTranslation('Label')
  const [fieldFiltering, setFieldFiltering] = useState<undefined | string>(undefined)
  useEffect(() => {
    setIsDisabled(isFormDisabled)
  }, [isFormDisabled])

  useEffect(() => {
    if (formState === 'CANCEL') {
      formik.setFieldValue('distanceId', { id: '', name: '' })
    }
  }, [formState])

  const getProgramSelectOptionsByEntity = (entity: string): [] | ProgramSelectOptions[] => {
    if (programSelectOptions && programSelectOptions.length > 0) {
      return programSelectOptions.filter((item: ProgramSelectOptions) => item.entity === entity)
    } else {
      return []
    }
  }

  const typeOfRaceOptions = getProgramSelectOptionsByEntity('TypeOfRace')
  let typeOfRaceDefaultValue = undefined
  if (race) {
    const code = typeOfRaceOptions.filter((item) => item.code == race.typeOfRace)
    if (code.length > 0) {
      typeOfRaceDefaultValue = code[0]
    } else {
      typeOfRaceDefaultValue = {
        code: '',
        name: '',
      }
    }
  } else if (formState === 'CANCEL') {
    typeOfRaceDefaultValue = {
      code: '',
      name: '',
    }
  }

  const distanceOptions = getProgramSelectOptionsByEntity('RaceDistance')
  if (race) {
    const code = distanceOptions.filter((item) => Number(item.code) == race.distanceId)
  }

  typeBetOptions = getProgramSelectOptionsByEntity('TypeBet')
  let typeBetsString: string[] = []

  if (typeBetOptions.length > 0) {
    if (possibleWagers.length > 0) {
      const possibleWagersList = possibleWagers.split('|')
      const possibleTypeBetOptions = typeBetOptions.filter((item) => possibleWagersList.includes(item.code))
      possibleTypeBetOptions.map((item: ProgramSelectOptions) => {
        typeBetsString.push(item.name)
      })
    } else {
      typeBetOptions.map((item: ProgramSelectOptions) => {
        typeBetsString.push(item.name)
      })
    }
  }

  const handleFilterOptions = (filter: string, fieldName: string, action: string) => {
    setFieldFiltering(action === 'typing' ? fieldName : '')
    if (['typeOfRace', 'distanceId'].includes(fieldName)) {
      onFilterProgramOptions(filter)
    }
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Field
            InputProps={{ inputProps: { min: 1, max: 99 } }}
            component={TextField}
            type="number"
            label={transLabel('NUMERO')}
            name="raceNumber"
            fullWidth
            disabled={true}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Field
            component={CustomFormikTimePicker}
            label={transLabel('HORA')}
            name="postTime"
            fullWidth
            disabled={!isDisabled}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Field
            InputProps={{ inputProps: { min: 2, max: 99 } }}
            component={TextField}
            type="number"
            label={transLabel('CORREDORES')}
            name="runners"
            fullWidth
            disabled={!isDisabled}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Field
            component={DistanceSelectFormik}
            keyValue={'code'}
            keyLabel={'name'}
            data={distanceOptions}
            label={transLabel('DISTANCIA')}
            name="distanceId"
            fullWidth
            disabled={!isDisabled}
            onInputValueChange={handleFilterOptions}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Field
            component={TextField}
            type="text"
            label={transLabel('CARTERA')}
            name="purse"
            fullWidth
            disabled={!isDisabled}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Field
            component={CustomAutoCompleteSelectFormik}
            keyValue={'code'}
            keyLabel={'name'}
            data={typeOfRaceOptions}
            label={transLabel('TIPO')}
            name="typeOfRace"
            fullWidth
            disabled={!isDisabled}
            onInputValueChange={handleFilterOptions}
            defaultValue={typeOfRaceDefaultValue}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Field
            component={TextField}
            type="text"
            label={transLabel('RESTRICCION_DE_EDAD')}
            name="ageRestriction"
            fullWidth
            disabled={!isDisabled}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Field
            component={WagerAutoCompleteSelectFormik}
            label={transLabel('APUESTA')}
            name="wagersId"
            fullWidth
            disabled={!isDisabled}
            data={typeBetsString}
            multiple={true}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Field component={CheckboxFormik} name="isActive" color="primary" disabled={true} />}
              label={transLabel('ESTADO') as string}
            />
          </FormControl>
        </Grid>
      </Grid>
    </>
  )
}
