import { ClientAccount } from '../models'
import { gql, useLazyQuery } from '@apollo/client'
import { useState, useEffect, useCallback } from 'react'
import { ResultsList, Metadata, SearchGraphql } from '../../../../core/models'

const GET_CLIENT_ACCOUNTS = gql`
  query getAllClientAccountsQuery($where: ClientAccountFilterInput, $skip: Int, $take: Int) {
    clientAccounts(where: $where, skip: $skip, take: $take) {
      totalCount
      items {
        id
        enterpriseId
        accountName
        currencyId
        currencyName
        currencyAcronym
        accountPin
        balance
        noPayed
        margen
        creationdDate
        lastActivity
        accountManagerId
        accountManagerName
      }
    }
  }
`
interface ClientAccountData {
  clientAccounts: {
    totalCount: number
    items: ClientAccount[]
  }
}

export const useClientAccounts = () => {
  const [getResults, { loading, error, data }] = useLazyQuery<ClientAccountData>(GET_CLIENT_ACCOUNTS, {
    fetchPolicy: 'network-only',
  })

  const [results, setResults] = useState<ResultsList<ClientAccount, Metadata>>({
    results: [],
    metadata: {},
  })

  const getClientAccounts = useCallback(
    ({ where, take, skip }: SearchGraphql) => {
      getResults({ variables: { where, take, skip } })
    },
    [getResults],
  )

  useEffect(() => {
    setResults({
      results: data?.clientAccounts.items || [],
      metadata: {
        Count: data?.clientAccounts.totalCount,
      },
    })
  }, [data?.clientAccounts.items, loading])

  return { loading, error, results, getClientAccounts }
}
