import React, { useCallback } from 'react'
import { TicketPlay } from './models'
// My Components
import { CustomDataGrid, DataGridColumns } from '../../../core/components/dataGrid/CustomDataGrid'
import { Metadata, ResultsList } from '../../../core/models'

type TicketPlaysProps = {
  plays: TicketPlay[]
}

export const TicketPlays = ({ plays }: TicketPlaysProps) => {
  const getColumnDefinitions = useCallback((): DataGridColumns => {
    return {
      NUMERO: { field: 'id', contentType: 'id' },
      DESCRIPCION: { field: 'lineDescription', contentType: 'largeString' },
      PRECIO: { field: 'price', contentType: 'number' },
      RESULTADO: { field: 'result', contentType: 'localized' },
      ANOTACION_FINAL: { field: 'resultNote', contentType: 'string' },
    }
  }, [])

  const results: ResultsList<TicketPlay, Metadata> = {
    results: plays,
    metadata: {},
  }

  return (
    <div style={{ minHeight: '70vh', width: '100%' }}>
      <CustomDataGrid data={results} columns={getColumnDefinitions()} pageSize={10} disableColumnMenu={true} />
    </div>
  )
}
