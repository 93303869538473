import React from 'react'
import { AuthConsumer } from 'pb-shared'
import { ApolloConsumer } from '@apollo/client'
import { LoadingAnimation } from '../../core/components'

export const LogoutCallback = () => (
  <ApolloConsumer>
    {({ resetStore }) => (
      <AuthConsumer>
        {({ signoutRedirectCallback }) => {
          resetStore()
          signoutRedirectCallback()
          return <LoadingAnimation />
        }}
      </AuthConsumer>
    )}
  </ApolloConsumer>
)
