import { Enterprise } from './../../scenes/entities/enterprises/models'
import { createReducer } from 'typesafe-actions'

import { Actions, globalSearchAction, clearAction, setUserEnterprise, setEnterprises, setUserClaims } from './actions'
import { Dictionary } from '../models'

export interface AppBarState {
  searchQuery?: string
  userEnterprise?: Enterprise
  enterprises: Array<Enterprise>
  userClaims: Dictionary
}

export const appBarReducer = createReducer<AppBarState, Actions>({
  searchQuery: '',
  userEnterprise: undefined,
  enterprises: [],
  userClaims: {},
})
  .handleAction(globalSearchAction, (state, action) => {
    return {
      ...state,
      searchQuery: action.payload,
    }
  })
  .handleAction(clearAction, (state) => {
    return {
      ...state,
      searchQuery: '',
    }
  })
  .handleAction(setUserEnterprise, (state, action) => {
    return {
      ...state,
      userEnterprise: action.payload,
    }
  })
  .handleAction(setEnterprises, (state, action) => {
    return {
      ...state,
      enterprises: action.payload,
    }
  })
  .handleAction(setUserClaims, (state, action) => {
    return {
      ...state,
      userClaims: action.payload,
    }
  })
