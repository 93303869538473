import React from 'react'
import lottie from 'lottie-web'
import animation from './password-reset.json'

export const PasswordResetAnimation = () => {
  React.useEffect(() => {
    lottie.loadAnimation({
      container: document.querySelector('#animation-id') as HTMLDivElement,
      animationData: animation,
    })
  }, [])

  return <div id="animation-id" />
}
