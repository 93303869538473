import { Route, Switch, Redirect } from 'react-router-dom'
import { PruebaJugadaHipica, SalesProfitDashboard, Calculator, PasswordReset, Admin } from '../../scenes'
import { routes } from './routeList'
import { AppContainer, Loader, LoadingAnimation, MenuGroup, PageError } from '../components'
import { useUserEnterprises } from '../../scenes/entities/enterprises/hooks'
import { useDispatch } from 'react-redux'

import {
  Receipt as TicketsIcon,
  AccountBalanceWallet as WeeklyBalanceIcon,
  AccountBalance as ProfitLossIcon,
  SupervisorAccount as AccountsIcon,
  InsertDriveFile as AccountTransactionIcon,
  NoteAdd as CreateAccountTransactionIcon,
  Business as EnterpriseIcon,
  BusinessCenter as AgencyIcon,
  Dashboard as DashboardIcon,
  Receipt as TransactionIcon,
  FindInPage as ConsultsIcon,
  Settings as SettingsIcon,
  Assignment as BasicRegistrationPlaysIcon,
  Star as StarOutlineIcon,
  Chat as MachineDetailsIcon,
  ChatBubble as MachineSummarizedIcon,
  Security as SecurityIcon,
  VerifiedUser as VerifiedUserIcon,
  Poll as MachineChartIcon,
  PictureInPicture as OperationPartnershipsIcon,
  RecentActors as ProvidersIcon,
} from '@material-ui/icons'
import { AuthContext, ProtectedRoute } from 'pb-shared'
import { extractUserClaims, extractUserModules, isUserAdmin, getApolloClient, parseJwt } from '../services'
import { ApolloProvider } from '@apollo/client'
import { Callback, Logout, LogoutCallback, SilentRenew } from '../../scenes/authentication'
import { setEnterprises, setUserClaims } from '../redux'
import { useContext, useEffect, useState } from 'react'
import { Dictionary } from '../models'
import { GraphqlTest } from '../../scenes/sandbox/graphqltest'
import Alert from '../../scenes/sandbox/Alert'
import ValuePlusIncreaseDecreased from '../../scenes/sandbox/ValuePlusIncreaseDecreased'
import _ from 'lodash'

export const AppContainerRoutes = () => {
  const userContext = useContext(AuthContext)
  const [userClaimsData, setUserClaimsData] = useState<Dictionary>({})
  const [userAllowedModules, setUserAllowedModules] = useState<string[]>([])
  const [isAdmin, setIsAdmin] = useState(false)
  const { results, loading, getUserEnterprises } = useUserEnterprises()
  const [userIsReady, setUserIsReady] = useState(false)
  const [enterpriseIsReady, setEnterpriseIsReady] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    getUserEnterprises()
  }, [])

  useEffect(() => {
    userContext
      .getUserManager()
      .getUser()
      .then((user) => {
        if (user) {
          const claims = parseJwt(user?.access_token)
          if (claims) {
            setIsAdmin(isUserAdmin(claims))
            const userClaims = extractUserClaims(claims)

            dispatch(setUserClaims(userClaims))
            setUserClaimsData(userClaims)
            setUserAllowedModules(extractUserModules(claims))
          }
          setUserIsReady(true)
        }
      })
  }, [userContext, dispatch])

  useEffect(() => {
    if (results.length > 0) {
      dispatch(setEnterprises(results))

      const defaultEnterprise = results[0]
      const enterprise = window.sessionStorage.getItem('selectedEnterprise')
      if ((enterprise && enterprise === 'none') || !enterprise) {
        window.sessionStorage.setItem('selectedEnterprise', JSON.stringify(defaultEnterprise))
      }
    }
    setEnterpriseIsReady(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results])

  const menuGroups: MenuGroup[] = [
    {
      allowedModules: 'dashboard',
      enable: true,
      name: 'INICIO',
      icon: <DashboardIcon />,
      path: '/admin',
    },
    {
      allowedModules: 'dashboard',
      enable: true,
      name: 'TABLERO',
      icon: <DashboardIcon />,
      public: true,
      options: [
        {
          allowedClaims: 'dashboard',
          option: 'TABLERO_VENTAS_GANANCIAS',
          icon: <DashboardIcon />,
          path: '/admin/statistics/sales-profit-dashboard',
          public: true,
        },
      ],
    },
    {
      allowedModules: 'transactions',
      enable: true,
      name: 'TRANSACCIONES',
      icon: <TransactionIcon />,
      options: [
        {
          option: 'CUENTAS_CLIENTES',
          path: '/admin/entities/client-accounts',
          icon: <AccountsIcon />,
          allowedClaims: 'entities.client-accounts',
        },
        {
          option: 'TRANSACCIONES_CUENTAS',
          path: '/admin/transactions/client-account',
          icon: <AccountTransactionIcon />,
          allowedClaims: 'transactions.client-accounts',
        },
        {
          option: 'AJUSTE_DE_CUENTAS_DE_CLIENTE',
          path: '/admin/transactions/client-account/create?hiddenButtonBack=true',
          icon: <CreateAccountTransactionIcon />,
          allowedClaims: 'transactions.client-accounts',
        },
      ],
    },
    {
      allowedModules: 'consults',
      enable: true,
      name: 'CONSULTAS',
      icon: <ConsultsIcon />,
      options: [
        {
          option: 'TICKETS',
          path: '/admin/consults/tickets',
          icon: <TicketsIcon />,
          allowedClaims: 'consults.tickets',
        },
        {
          option: 'BALANCES_SEMANALES',
          path: '/admin/consults/weekly-balances',
          icon: <WeeklyBalanceIcon />,
          allowedClaims: 'consults.weekly-balances',
        },
        {
          option: 'GANANCIAS_Y_PERDIDAS',
          path: '/admin/consults/profit-and-loss',
          icon: <ProfitLossIcon />,
          allowedClaims: 'consults.profit-and-loss',
        },
        {
          option: 'EMPADRONAMIENTO_BASICO',
          path: '/admin/consults/basic-registration-plays',
          icon: <BasicRegistrationPlaysIcon />,
          allowedClaims: 'consults.basic-registration-plays',
        },
        {
          option: 'ESTADO_SITUACION_AGENCIAS_TITULO',
          path: '/admin/consults/agency-situation',
          icon: <StarOutlineIcon />,
          allowedClaims: 'consults.agency-situation',
        },
        {
          option: 'RESUMEN_OPERACIONES_TITULO',
          path: '/admin/consults/agencies-operations',
          icon: <StarOutlineIcon />,
          allowedClaims: 'consults.agency-situation',
        },
        {
          option: 'OPERACIONES_POR_CONSORCIOS',
          path: '/admin/consults/partnership-operations',
          icon: <OperationPartnershipsIcon />,
          allowedClaims: 'consults.agencies-operations',
        },
        {
          option: 'OPERACIONES_POR_LOCALIDADES',
          path: '/admin/consults/locations-operations',
          icon: <OperationPartnershipsIcon />,
          allowedClaims: 'consults.agencies-operations',
        },
        {
          option: 'MAQUINAS_DETALLADAS',
          path: '/admin/consults/machine-details',
          icon: <MachineDetailsIcon />,
          allowedClaims: 'consults.machine-details.view',
        },
        {
          option: 'MAQUINAS_RESUMIDA',
          path: '/admin/consults/machine-summarized',
          icon: <MachineSummarizedIcon />,
          allowedClaims: 'consults.machine-summarized',
        },
        {
          option: 'RESUMEN_DE_CUENTA',
          path: '/admin/consults/account-summary',
          icon: <MachineSummarizedIcon />,
          allowedClaims: 'consults.profit-and-loss',
        },
        {
          option: 'MAQUINA_GRAFICA',
          path: '/admin/consults/machine-chart',
          icon: <MachineChartIcon />,
          allowedClaims: 'consults.machine-summarized',
        },
      ],
    },
    {
      allowedModules: 'security',
      enable: true,
      name: 'SEGURIDAD',
      icon: <SecurityIcon />,
      options: [
        {
          option: 'ROLES_PERMISOS',
          path: '/admin/security/roles',
          icon: <VerifiedUserIcon />,
          allowedClaims: 'security.profiles',
        },
        {
          option: 'USUARIOS_EMPLEADOS',
          path: '/admin/security/users',
          icon: <VerifiedUserIcon />,
          allowedClaims: 'security.users',
        },
      ],
    },
    {
      allowedModules: 'configuration',
      enable: true,
      name: 'CONFIGURACION',
      icon: <SettingsIcon />,
      doesNotRequiredEnterprise: true,
      options: [
        {
          option: 'CONSORCIOS',
          path: '/admin/entities/enterprises',
          icon: <EnterpriseIcon />,
          doesNotRequiredEnterprise: true,
          allowedClaims: 'configuration.enterprises',
        },
        {
          option: 'AGENCIAS',
          path: '/admin/entities/agencies',
          icon: <AgencyIcon />,
          allowedClaims: 'configuration.agencies',
        },
        {
          option: 'PROVEEDORES',
          path: '/admin/entities/providers',
          icon: <ProvidersIcon />,
          allowedClaims: 'configuration.providers',
        },
        {
          option: 'CONEXIONES',
          path: '/admin/entities/provider-connections',
          icon: <ProvidersIcon />,
          allowedClaims: 'configuration.connections',
        },
        {
          option: 'SUB_HEADER',
          name: 'PROGRAMA_HIPICO',
          allowedClaims: 'configuration.events',
        },
        {
          option: 'EVENTOS',
          path: '/admin/entities/events',
          icon: <VerifiedUserIcon />,
          allowedClaims: 'configuration.events',
        },
        {
          option: 'COMPETIDORES',
          path: '/admin/entities/events-competitors',
          icon: <VerifiedUserIcon />,
          allowedClaims: 'configuration.competitors',
        },
        {
          option: 'JINETES',
          path: '/admin/entities/events-jockeyOrKennels',
          icon: <VerifiedUserIcon />,
          allowedClaims: 'configuration.jockey',
        },
        {
          option: 'ENTRENADORES',
          path: '/admin/entities/events-trainers',
          icon: <VerifiedUserIcon />,
          allowedClaims: 'configuration.trainers',
        },
        {
          option: 'PROPIETARIOS',
          path: '/admin/entities/events-owners',
          icon: <VerifiedUserIcon />,
          allowedClaims: 'configuration.owners',
        },
      ],
    },
  ]

  const allowRoutes: Array<MenuGroup> = menuGroups.filter((item) => {
    if (!isAdmin) {
      if (!item.public) {
        // Filter modules
        if (!userAllowedModules.includes(item.allowedModules)) {
          return null
        }
        // Filter options
        if (item.options && item.options.length > 0) {
          const options = item.options.filter(
            (option) =>
              userClaimsData[option.allowedClaims ? option.allowedClaims : ''] !== undefined ||
              item.doesNotRequiredEnterprise,
          )
          item.options = options
        }
      }
    }
    return item
  })

  const appIsReady = userIsReady && allowRoutes.length > 0 && enterpriseIsReady
  return (
    <Loader loading={loading}>
      {appIsReady && (
        <AppContainer menuGroups={allowRoutes}>
          <Switch>
            <Route path="/admin" component={Admin} exact={true} />

            <Route path="/admin/statistics/sales-profit-dashboard" component={SalesProfitDashboard} />
            {!_.isEmpty(userClaimsData) &&
              routes.map((item, index) => {
                return isAdmin ? (
                  <Route key={index} path={item.path} component={item.Component} />
                ) : userClaimsData[item.allowedClaims] !== undefined ? (
                  <Route key={index} path={item.path} component={item.Component} />
                ) : (
                  <Route key={index} path={item.path}>
                    <PageError code={403} message={'ERROR_403'} />
                  </Route>
                )
              })}
            <Route path="/admin/sandbox" component={GraphqlTest} />
            <Route path="/admin/alerts" component={Alert} />
            <Route path="/admin/value-plus" component={ValuePlusIncreaseDecreased} />
            <Route path="/admin/calculator" component={Calculator} />

            <Route>
              <PageError code={404} message={'ERROR_404'} />
            </Route>
          </Switch>
        </AppContainer>
      )}
    </Loader>
  )
}

const apolloClient = getApolloClient()
apolloClient.resetStore()

export const Routes = (
  <ApolloProvider client={apolloClient}>
    <Switch>
      <Route exact={true} path="/signin-oidc" component={Callback} />
      <Route exact={true} path="/logout" component={Logout} />
      <Route exact={true} path="/logout/callback" component={LogoutCallback} />
      {/* <Route exact={true} path="/register" component={Register} /> */}
      <Route exact={true} path="/silentrenew" component={SilentRenew} />
      <Route exact={true} path="/prueba-jugada-hipica" component={PruebaJugadaHipica} />
      <ProtectedRoute path="/admin" component={AppContainerRoutes} loadingComponent={LoadingAnimation} />
      {/* <Route path="/" component={PublicPage} /> */}
      <Route path="/passwordreset" component={PasswordReset} />

      <Redirect from="/" to="/admin" />
    </Switch>
  </ApolloProvider>
)
