import { SalesSummary } from './models'
import { gql, useLazyQuery } from '@apollo/client'
import { Metadata, ResultsList, SearchGraphql } from '../../../core/models'
import { useState, useEffect, useCallback } from 'react'

const GET_SALES_SUMMARY = gql`
  query GetSalesSummary($where: GlobalSalesSummaryFilterInput, $skip: Int, $take: Int) {
    globalSalesSummary(where: $where, order: { salesDate: DESC }, take: $take, skip: $skip) {
      items {
        id
        totalAccounts
        totalLocations
        newAccounts
        accountIncrease
        salesDate
        totalTickets
        ticketsIncrease
        totalSales
        salesIncrease
        totalProfit
        profitIncrease
      }
    }
  }
`

// const GET_SALES_SUMMARY = gql`
//   query GetSalesSummary {
//     globalSalesSummary(order: { salesDate: DESC }, take: 12, skip: 0) {
//       items {
//         id
//         totalAccounts
//         totalLocations
//         newAccounts
//         accountIncrease
//         salesDate
//         totalTickets
//         ticketsIncrease
//         totalSales
//         salesIncrease
//         totalProfit
//         profitIncrease
//       }
//     }
//   }
// `

interface SalesSummaryData {
  globalSalesSummary: {
    items: SalesSummary[]
  }
}

export const useSalesSummary = (isLocation: boolean) => {
  const [getResults, { loading, error, data }] = useLazyQuery<SalesSummaryData>(GET_SALES_SUMMARY, {
    fetchPolicy: 'network-only',
  })

  const [results, setResults] = useState<ResultsList<SalesSummary, Metadata>>({
    results: [],
    metadata: {},
  })

  const getSalesSummary = useCallback(
    ({ where, take, skip }: SearchGraphql) => {
      const customWhere = {
        // currencyId: { eq: 1 },
        // periodicityId: { eq: 1 },
        isLocation: { eq: isLocation },
        ...where,
      }

      getResults({
        variables: {
          where: { ...customWhere },
          skip: skip ? skip : 0,
          take: take ? take : 12,
        },
      })
    },
    [getResults],
  )

  useEffect(() => {
    setResults({
      results: data?.globalSalesSummary.items || [],
      metadata: {},
    })
  }, [data?.globalSalesSummary.items, loading])

  return { loading, error, results, getSalesSummary }
}

const GET_PRODUCTS_NEXT_PERIOD = gql`
  query GetLastSale($where: SalesSummaryFilterInput, $skip: Int, $take: Int) {
    salesSummary(where: $where, order: { salesDate: ASC }, take: $take, skip: $skip) {
      items {
        id
        salesDate
        productName
        totalProfit
      }
    }
  }
`

interface ProductsNextPeriodData {
  salesSummary: {
    items: SalesSummary[]
  }
}

export const useProductNextPeriod = (periodicity: number) => {
  const [getResults, { loading, error, data }] = useLazyQuery<ProductsNextPeriodData>(GET_PRODUCTS_NEXT_PERIOD, {
    fetchPolicy: 'network-only',
  })

  const [results, setResults] = useState<ResultsList<SalesSummary, Metadata>>({
    results: [],
    metadata: {},
  })

  const getProduct = useCallback(
    ({ where, take, skip }: SearchGraphql) => {
      const customWhere = {
        // currencyId: { eq: 1 },
        // periodicityId: { eq: periodicity },
        isLocation: { eq: true },
        ...where,
      }

      getResults({
        variables: {
          where: { ...customWhere },
          skip: skip ? skip : 0,
          take: take ? take : 28,
        },
      })
    },
    [getResults],
  )

  useEffect(() => {
    setResults({
      results: data?.salesSummary.items || [],
      metadata: {},
    })
  }, [data?.salesSummary.items, loading])

  return { loading, error, results, getProduct }
}
