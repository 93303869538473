import _lodash from 'lodash'
import { Dictionary } from '../models'

export const differenceBetweenTwoObjects = (object: any, base: any) => {
  function changes(object: any, base: any) {
    return _lodash.transform(object, function (result: any, value, key) {
      if (!_lodash.isEqual(value, base[key])) {
        result[key] = _lodash.isObject(value) && _lodash.isObject(base[key]) ? changes(value, base[key]) : value
      }
    })
  }
  return changes(object, base)
}

export const parseJwt = (token: string): Dictionary | null => {
  try {
    return JSON.parse(atob(token.split('.')[1]))
  } catch (e) {
    return null
  }
}

export const getRegretToNotAllowSpecialsCharacters = () => {
  return /^([a-zA-Z0-9\u0600-\u06FF\u0660-\u0669\u06F0-\u06F9 _.-]+)$/
}

export const normalizeSpaces = (value: string) => {
  if (!value) return value
  return value.replace(/  +/g, ' ')
}

export const groupArrayOfObjectsByKey = (list: any[], keyGetter: any) => {
  const map = new Map()
  list.forEach((item) => {
    const key = keyGetter(item)
    const collection = map.get(key)
    if (!collection) {
      map.set(key, [item])
    } else {
      collection.push(item)
    }
  })
  return map
}
