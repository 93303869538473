import { gql, useMutation } from '@apollo/client'

const UPDATE_LOCATION = gql`
  mutation UpdateWorkstation($id: Int!, $input: UpdateLocationInput!) {
    updateLocation(id: $id, input: $input) {
      location {
        id
        name
        typeLocationId
        enterpriseId
        address
        phone
        countryId
        provinceId
        municipalityId
        sectorId
        restrictedByGeography
        active
      }
    }
  }
`

export interface UpdateLocationInput {
  name: string
  typeLocationId: string
  address: string
  phone: string
  countryId: string
  provinceId: string
  municipalityId: string
  sectorId: number
  restrictedByGeography: boolean
}

export const useUpdateLocation = () => {
  const [updateLocation, { loading, error }] = useMutation<UpdateLocationInput>(UPDATE_LOCATION)
  return { updateLocation, loading, error }
}
