import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ProgramCompetitor } from '../models'
import { useHistory, useParams } from 'react-router-dom'
import { CompetitorForm } from './CompetitorForm'
import { useAddProgramCompetitor, useProgramCompetitor, useUpdateProgramCompetitor } from '../hooks'
import { useNotification } from '../../../../core'
import { differenceBetweenTwoObjects as difference } from '../../../../core/services/genericFunctions'
import { Loader } from '../../../../core/components'

export const Competitor = () => {
  const { id } = useParams<{ id: string }>()
  const { t: transSystem } = useTranslation('System')

  const history = useHistory()
  const { competitor, loading: loadingCompetitor, getProgramCompetitor } = useProgramCompetitor()
  const { addProgramCompetitor } = useAddProgramCompetitor()
  const { updateProgramCompetitor } = useUpdateProgramCompetitor()
  const { successNotification, errorNotification } = useNotification()

  useEffect(() => {
    if (id !== undefined) {
      getProgramCompetitor(Number.parseInt(id))
    }
  }, [id])

  const handleSave = (competitorToSave: ProgramCompetitor) => {
    if (competitorToSave.id == 0) {
      delete competitorToSave.id
      const response = addProgramCompetitor({
        variables: {
          input: { ...competitorToSave },
        },
      })
      response
        .then(() => {
          successNotification(transSystem('DATO_REGISTRADO'))
          history.push(`/admin/entities/events-competitors`)
        })
        .catch(() => {
          errorNotification(transSystem('ERROR_REGISTRANDO_EL_DATO'))
        })
    } else {
      const changes = difference(competitorToSave, competitor)
      const response = updateProgramCompetitor({
        variables: {
          id: competitorToSave.id,
          input: { ...changes },
        },
      })
      response
        .then(() => {
          successNotification(transSystem('DATO_ACTUALIZADO'))
          history.push(`/admin/entities/events-competitors`)
        })
        .catch(() => {
          errorNotification(transSystem('ERROR_ACTUALIZANDO_EL_DATO'))
        })
    }
  }

  return (
    <Loader loading={loadingCompetitor}>
      <CompetitorForm onSubmit={handleSave} competitor={competitor} />
    </Loader>
  )
}
