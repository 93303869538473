import React, { useState } from 'react'
import { Button, Typography } from '@material-ui/core'
import { green } from '@material-ui/core/colors'
import SearchIcon from '@material-ui/icons/Search'
import WorkOutline from '@material-ui/icons/WorkOutline'
import SettingsIcon from '@material-ui/icons/Settings'

import {
  Card,
  CardContent,
  CardHeader,
  createStyles,
  Divider,
  Grid,
  makeStyles,
  Theme,
  TextField,
} from '@material-ui/core'

// My Components
import { HeaderCardTitle } from '../../../../core/components'
import { MoneyCard } from '../../../../core/components/cards/MoneyCard'
import { Loader } from '../../../../core/components/Loaders/Loader'

// Models

// Hooks
import { useTranslation } from 'react-i18next'
import { formatAsNumber } from '../../../../core'
import { ClientAccount } from '../models'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardRoot: {
      minWidth: '100%',
    },
    description: {
      fontSize: 15,
      padding: theme.spacing(0.5),
    },
    filterGrid: {
      maxWidth: '80%',
      display: 'flex',
    },
    searchText: {
      minWidth: '95%',
    },
    accountNameLabel: {
      marginTop: '15px',
      color: '#969696',
    },
    accountDebit: {
      marginTop: '15px',
      color: '#969696',
      textDecoration: 'underline',
    },
    balance: {
      color: '#0a2ee5',
      fontWeight: 'bold',
    },
    receivable: {
      color: green[500],
      fontWeight: 'bold',
    },
  }),
)

type ClientAccountFiltersProps = {
  onSearch: (params: string) => void
  account: ClientAccount | undefined
  initialPin?: string | undefined
}

export const ClientAccountConfirmationSearch = ({ onSearch, account, initialPin }: ClientAccountFiltersProps) => {
  const classes = useStyles()
  const [accountPin, setAccountPin] = useState<string | undefined>('')
  const { t: translation } = useTranslation('Label')

  const handleSearch = () => {
    if (accountPin) {
      onSearch(accountPin)
    }
  }

  const changeAccountPin = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number.isNaN(parseInt(event.target.value)) ? '' : event.target.value.toString()
    setAccountPin(value)
  }

  return (
    <Loader loading={false}>
      <Card className={classes.cardRoot}>
        <CardHeader component={() => <HeaderCardTitle title={translation('CRITERIOS_DE_BUSQUEDA')} />} />
        <Divider />
        <CardContent>
          <Grid container item spacing={3} lg={12} xs={12} sm={12}>
            <Grid item lg={3} xs={12} sm={12}>
              <div className={classes.filterGrid}>
                <TextField
                  className={classes.searchText}
                  type="number"
                  label={translation('CUENTA')}
                  name="searchBy"
                  value={accountPin}
                  onChange={changeAccountPin}
                />
                <Button
                  color="primary"
                  component="span"
                  variant="contained"
                  size="small"
                  onClick={handleSearch}
                  disabled={accountPin === undefined || accountPin === null}
                >
                  <SearchIcon />
                </Button>
              </div>
            </Grid>
            <Grid item lg={2} xs={12} sm={12}>
              <Typography variant="h6" component="h6" className={classes.accountNameLabel}>
                {account ? account.accountName : ''}
                {/* {translation('ACCOUNT_NAME')} */}
              </Typography>
            </Grid>
            <Grid item lg={2} xs={12} sm={12}>
              <Typography variant="h6" component="h6" className={classes.accountDebit}>
                {account && `${translation(account.typePaymentName)} ${account.currencyAcronym}`}
              </Typography>
            </Grid>
            <Grid item lg={2} xs={12} sm={12}>
              <MoneyCard
                title={translation('BALANCE')}
                balance={
                  <span className={classes.balance}>{`$ ${formatAsNumber(account ? account.balance : 0, 0)}`}</span>
                }
                icon={<WorkOutline />}
              />
            </Grid>
            <Grid item lg={2} xs={12} sm={12}>
              <MoneyCard
                title={translation('POR_COBRAR')}
                balance={
                  <span className={classes.receivable}>{`$ ${formatAsNumber(account ? account.noPayed : 0, 0)}`}</span>
                }
                icon={<SettingsIcon />}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Loader>
  )
}
