import React from 'react'
import clsx from 'clsx'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import CssBaseline from '@material-ui/core/CssBaseline'
import Typography from '@material-ui/core/Typography'
import PlaceBetIcon from '../../svgIcons/PlaceBet/PlaceBetIcon'
import { ManageDrawer } from './sidebar/ManageDrawer'
import { MenuGroup, MenuItems } from './sidebar/MenuItems'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import MenuOpenIcon from '@material-ui/icons/MenuOpen'
import { ProfileHeader } from './profile/ProfileHeader'
import { useSelector } from 'react-redux'
import { AppState } from '../../redux/store'
import { isMobile } from '../..'

const drawerWidth = 240

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 200,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      // width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    iconPlaceBet: {
      width: '9rem !important',
      // marginTop: 10,
    },
    grow: {
      flexGrow: 1,
      marginTop: 5,
    },
    menuButton: {
      marginRight: 10,
    },
    logout: {
      marginRight: '-20px',
    },
    enterpriseTitle: {
      backgroundColor: '#6675cc',
      opacity: 0.8,
      padding: 70,
      paddingTop: 10,
      paddingBottom: 10,
      borderRadius: 12,
    },
  }),
)

export interface AppContainerProps {
  children: React.ReactNode
  menuGroups: MenuGroup[]
}

export function AppContainer({ menuGroups, children }: AppContainerProps) {
  const classes = useStyles()
  const [drawerType, setDrawerType] = React.useState<'mobile' | 'mini' | 'desktop'>(isMobile() ? 'mobile' : 'mini')
  const [open, setOpen] = React.useState(isMobile() ? false : true)

  const { userEnterprise } = useSelector((state: AppState) => {
    return state.appBar
  })

  const toggleDrawer = (allowClose: boolean) => {
    if (allowClose) {
      setOpen(!open)
      if (!isMobile()) {
        setDrawerType(drawerType === 'mini' ? 'desktop' : 'mini')
      }
    }
  }

  let name = userEnterprise !== undefined ? userEnterprise.name : ''

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => toggleDrawer(true)}
            edge="start"
            className={clsx(classes.menuButton)}
          >
            {isMobile() ? <MenuIcon /> : drawerType === 'desktop' ? <MenuOpenIcon /> : <MenuIcon />}
          </IconButton>

          <Typography className={classes.grow} variant="h6" noWrap>
            <PlaceBetIcon classes={classes.iconPlaceBet} />
          </Typography>
          <Typography className={classes.grow} variant="h6" noWrap>
            {name.length > 0 && <span className={classes.enterpriseTitle}>{name}</span>}

            {/* {userEnterprise !== undefined && <span className={classes.enterpriseTitle}>{userEnterprise.name}</span>} */}
          </Typography>
          <div className={classes.logout}>
            <ProfileHeader />
          </div>
        </Toolbar>
      </AppBar>
      <ManageDrawer type={drawerType} open={open}>
        {menuGroups.map((group, index) => (
          <React.Fragment key={index}>
            <MenuItems onSelectItem={() => toggleDrawer(isMobile())} menu={group} index={index} />
          </React.Fragment>
        ))}
      </ManageDrawer>

      <main className={classes.content}>
        <div className={classes.toolbar} />
        <React.Fragment>{children}</React.Fragment>
      </main>
    </div>
  )
}
