import { User } from '../models'
import { gql, useLazyQuery } from '@apollo/client'
import { SearchGraphql, PageInfo } from '../../../../core/models'

const GET_USERS = gql`
  query GetUsers($skip: Int, $take: Int, $where: UserWithClaimsFilterInput) {
    users(where: $where, order: { userName: DESC }, take: $take, skip: $skip) {
      items {
        id
        userName
        email
        givenName
        familyName
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`

interface UsersData {
  users: {
    totalCount: number
    items: User[]
    pageInfo: PageInfo
  }
}

export const useUsers = () => {
  const [getResults, { loading, error, data }] = useLazyQuery<UsersData>(GET_USERS, {
    fetchPolicy: 'network-only',
  })

  const getUsers = ({ where, take, skip }: SearchGraphql) => {
    getResults({
      variables: {
        where: { ...where },
        skip: skip ? skip : 0,
        take: take ? take : 100,
      },
    })
  }

  const results = {
    results: data?.users.items || [],
    metadata: {
      Count: data?.users.totalCount,
    },
  }
  return { loading, error, results, getUsers }
}
