import { useCallback } from 'react'
import { Card, CardContent, Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { AppBreadcrumbsBar, Loader, CustomDataGrid, DataGridColumns } from '../../../core/components'
import AccountSummaryFilter from './AccountSummaryFilter'
import { useProfitAndLosses } from '../profitAndLoss'
import { ClaimActions, SearchGraphql } from '../../../core/models'
import { useUserClaimActions } from '../../../core'

export const AccountSummary = () => {
  const { t: translationLabel } = useTranslation('Label')
  const { results, loading, getProfitAndLosses } = useProfitAndLosses()
  const { validateClaimActions } = useUserClaimActions()
  const actions = validateClaimActions('consults.profit-and-loss', [ClaimActions.CanSearch, ClaimActions.CanView])
  const getColumnDefinitions = useCallback((): DataGridColumns => {
    return {
      ENTIDAD: { field: 'entityName', contentType: 'id' },
      FECHA: { field: 'eventDate', contentType: 'date' },
      INICIO: { field: 'beginning', contentType: 'number' },
      APOSTADO: { field: 'amountBet', contentType: 'number' },
      GANADO: { field: 'winner', contentType: 'number' },
      RESULTADO: { field: 'riskWin', contentType: 'number' },
      PENDIENTE: { field: 'ticketPeding', contentType: 'number' },
      DEBITO: { field: 'debit', contentType: 'number' },
      CREDITO: { field: 'credit', contentType: 'number' },
      BALANCE: { field: 'balance', contentType: 'number' },
    }
  }, [])

  function onChangeFilter(filter: SearchGraphql) {
    filter = {
      ...filter,

      skip: 0,
      take: 20,
    }
    getProfitAndLosses(filter)
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid item xs={12}>
          <AppBreadcrumbsBar
            title={translationLabel('RESUMEN_DE_CUENTA')}
            description={translationLabel('INFORMACION_DE_GANACIAS_Y_PERDIDAS')}
          />
        </Grid>
        {actions.canSearch && (
          <Grid item xs={12}>
            <AccountSummaryFilter onChange={onChangeFilter} />
          </Grid>
        )}

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Loader loading={loading}>
                <div style={{ minHeight: '35vh', width: '100%', overflow: 'auto' }}>
                  <CustomDataGrid
                    // onRowClick={rowSelectHandler}
                    data={results}
                    columns={getColumnDefinitions()}
                    pageSize={20}
                    // onPageChange={handlePageChange}
                  />
                </div>
              </Loader>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  )
}
