import { gql, useMutation } from '@apollo/client'
import { User } from '../models'

const UPDATE_USER = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      result {
        givenName
        familyName
        middleName
        nickname
        email
        address
        profile
        gender
        birthdate
        phoneNumber
      }
    }
  }
`
export interface UpdateUserInput {
  id: string
  claims: User
}

export const useUpdateUser = () => {
  const [updateUser, { loading, error }] = useMutation<UpdateUserInput>(UPDATE_USER)
  return { updateUser, loading, error }
}
