import { gql, useMutation } from '@apollo/client'

const REMOVE_USER_ROLE = gql`
  mutation RemoveUserFromRole($input: AssignUserInput!) {
    removeUserFromRole(input: $input) {
      succeeded
      errors {
        code
        description
      }
    }
  }
`
export interface RemoveUserInput {
  role: string
  userName: string
}

export const useRemoveUserRole = () => {
  const [removeUserRole, { loading, error }] = useMutation<RemoveUserInput>(REMOVE_USER_ROLE)
  return { removeUserRole, loading, error }
}
