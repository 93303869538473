import React, { useState, useEffect } from 'react'
import * as Yup from 'yup'

import {
  Card,
  CardContent,
  CardHeader,
  createStyles,
  Divider,
  Grid,
  makeStyles,
  Theme,
  Radio,
  FormControl,
  FormLabel,
  Select,
  InputLabel,
} from '@material-ui/core'

import { HeaderCardTitle, BooleanRadioButton, EntitySelect } from '../../../core/components'
import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-material-ui'

// My Components
import { SearchButton, ClearButton } from '../../../core/components/buttons'
// Models
import { Filter, KeyValuePair, MatchType, SearchRequest } from '../../../core/models'

// Hooks
import { useTranslation } from 'react-i18next'
import { generateFilterGraphql, isMobile } from '../../../core'
import { useCompanies } from '../companies/hooks'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardRoot: {
      minWidth: '100%',
    },
    description: {
      fontSize: 15,
      padding: theme.spacing(0.5),
    },
    formControl: {
      minWidth: '100%',
    },
    dates: {
      marginTop: '0px !important',
    },
    buttonGrid: {
      marginTop: '10px',
    },
    searchText: {
      minWidth: '100%',
    },
    accountTitle: {
      fontSize: '12px',
    },
    select: {
      minWidth: '100%',
      marginTop: !isMobile() ? '15px' : 'unset',
    },
  }),
)

export interface EnterprisesFilters {
  active: boolean
  searchBy: string
  companyId: string | number
}
const initialValues: EnterprisesFilters = {
  active: true,
  searchBy: '',
  companyId: '',
}

const formSchema = Yup.object().shape({
  active: Yup.boolean(),
  searchBy: Yup.string(),
})

type EnterprisesFiltersProps = {
  onSearch: (params: EnterprisesFilters) => void
}

export const EnterprisesFilters = ({ onSearch }: EnterprisesFiltersProps) => {
  const classes = useStyles()
  const { t: translation } = useTranslation('Label')
  const { results: companiesData, loading, getCompanies } = useCompanies()

  useEffect(() => {
    const customFilters = {
      and: [
        { name: { ...generateFilterGraphql('', 'contains') } },
        { active: { ...generateFilterGraphql(true, 'eq') } },
      ],
    }

    getCompanies({ where: customFilters, skip: 0, take: 20 })
  }, [])

  return (
    <Card className={classes.cardRoot}>
      <CardHeader component={() => <HeaderCardTitle title={translation('CRITERIOS_DE_BUSQUEDA')} />} />
      <Divider />
      <CardContent>
        <Formik
          validationSchema={formSchema}
          validate={(values: EnterprisesFilters) => {
            // Validate filters here
          }}
          initialValues={{ ...initialValues }}
          onSubmit={(values: EnterprisesFilters, { setSubmitting }) => {
            let searchFilters: SearchRequest = {}
            setSubmitting(false)
            onSearch(values)
          }}
        >
          {({ errors, submitForm, isSubmitting, isValid }) => {
            return (
              <Form>
                <Grid container item spacing={3} lg={12} xs={12} sm={12}>
                  <Grid item lg={2} xs={12} sm={2}>
                    <Field
                      component={BooleanRadioButton}
                      title="ESTADO"
                      name="active"
                      label1={'ACTIVO'}
                      label2={'INACTIVO'}
                      value1={'active'}
                      value2={'inactive'}
                    />
                  </Grid>
                  <Grid item lg={2} xs={12} sm={2}>
                    <FormControl className={classes.formControl}>
                      <InputLabel>{translation('COMPANIA')}</InputLabel>
                      <Field
                        component={EntitySelect}
                        className={classes.select}
                        data={companiesData.results}
                        name="companyId"
                        showNoneOption
                      />
                    </FormControl>
                  </Grid>
                  <Grid item lg={4} xs={12} sm={4}>
                    <Field
                      className={classes.searchText}
                      component={TextField}
                      type="text"
                      label={translation('MOSTRAR_LOS_QUE_COINCIDAD_CON')}
                      name="searchBy"
                    />
                  </Grid>
                  <Grid item lg={4} xs={12} sm={4} className={classes.buttonGrid}>
                    <SearchButton onClick={submitForm} />
                  </Grid>
                </Grid>
              </Form>
            )
          }}
        </Formik>
      </CardContent>
    </Card>
  )
}
