import React, { useEffect, useState } from 'react'
import {
  Grid,
  makeStyles,
  createStyles,
  Theme,
  FormControl,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Button,
  FormControlLabel,
} from '@material-ui/core'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import {
  CountrySelect,
  ProvinceSelect,
  MunicipalitySelect,
  SectorSelect,
  HeaderCardTitle,
  SaveButton,
  TextAreaFieldFormik,
  PhoneTextFieldMask,
  CustomAutoCompleteSelect,
  AppBreadcrumbsBar,
  ModifyButton,
  DialogAlerts,
  CheckboxFormik,
  CustomAutoCompleteSelectFormik,
} from '../../../core/components'
import { useTranslation } from 'react-i18next'
import { useFormik, Field, Form, FormikProvider } from 'formik'
import { TextField } from 'formik-material-ui'
import { Location } from './models'
import * as Yup from 'yup'
import { useLocationTypes, useLocation } from './hooks'
import { Country, Province, Municipality } from '../../../core/components/geography/model'
import { useHistory, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { AppState } from '../../../core/redux/store'
import { phoneRegExp, useUserClaimActions, ClaimActions } from '../../../core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiAutocomplete-root': {
        width: 'auto',
      },
    },
    fieldItem: {
      marginBottom: theme.spacing(2),
    },
    buttonActions: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    labelEditActive: {
      color: '#ff6600',
    },
    labelEditDisabled: {
      color: '#000000',
    },
    divider: {
      marginTop: -22,
    },
    addressDiv: {
      paddingTop: 5,
    },
  }),
)

const initialFormValues: Location = {
  id: 0,
  name: '',
  typeLocationId: '',
  address: '',
  phone: '',
  countryId: '',
  provinceId: '',
  municipalityId: '',
  sectorId: '',
  restrictedByGeography: false,
  active: true,
}

type AgencyFormProps = {
  onSaveAgency: (formValues: Location) => void
  location?: Location | undefined
}

export const AgencyForm = ({ location, onSaveAgency }: AgencyFormProps) => {
  const classes = useStyles()
  const [isEditing, setIsEditing] = useState(true)
  const [alertForm, setAlertForm] = useState(false)
  const { id } = useParams<{ id: string }>()
  const { t: transLabel } = useTranslation('Label')
  const { results: locationTypesData, getLocationTypes } = useLocationTypes()
  const { getLocation } = useLocation()
  const [countryId, setCountryId] = useState<number | string>(0)
  const [provinceId, setProvinceId] = useState<number | string>(0)
  const [municipalityId, setMunicipalityId] = useState<number | string>(0)
  const history = useHistory()
  const { validateClaimActions } = useUserClaimActions()
  const actions = validateClaimActions('configuration.agencies', [ClaimActions.CanCreate, ClaimActions.CanEdit])

  const formSchema = Yup.object().shape({
    typeLocationId: Yup.string().nullable().required(transLabel('REQUERIDO')),
    countryId: Yup.string().nullable().required(transLabel('REQUERIDO')),
    provinceId: Yup.string().nullable().required(transLabel('REQUERIDO')),
    municipalityId: Yup.string().nullable().required(transLabel('REQUERIDO')),
    sectorId: Yup.string().nullable().required(transLabel('REQUERIDO')),
    name: Yup.string().required(transLabel('REQUERIDO')),
    phone: Yup.string().matches(phoneRegExp, 'VALOR_NO_VALIDO'),
  })

  const handleValidateBeforeSave = (formValues: Location) => {
    if (!formValues.phone || formValues.phone === '') {
      formik.setFieldError('phone', transLabel('REQUERIDO'))
    } else {
      onSaveAgency(formValues)
    }
  }

  const formik = useFormik({
    validationSchema: formSchema,
    initialValues: location || initialFormValues,
    onSubmit: handleValidateBeforeSave,
    enableReinitialize: true,
  })

  window.sessionStorage.setItem('hasFormChanges', formik.dirty ? 'true' : 'false')

  useEffect(() => {
    if (id !== undefined && Number.parseInt(id) > 0) {
      getLocation(Number.parseInt(id))
      setIsEditing(false)
    }
  }, [id])

  useEffect(() => {
    getLocationTypes()
  }, [])

  // Initialize geographic data
  useEffect(() => {
    if (location && location.countryId !== undefined) {
      setCountryId(location.countryId)
    }
    if (location && location.provinceId !== undefined) {
      setProvinceId(location.provinceId)
    }
    if (location && location.municipalityId !== undefined) {
      setMunicipalityId(location.municipalityId)
    }
  }, [location, id])

  const handleCountryChange = (country: Country) => {
    if (country) {
      setCountryId(country.key)
    }
  }

  const handleProvinceChange = (province: Province) => {
    if (province) {
      setProvinceId(province.key)
    }
  }

  const handleMunicipalityChange = (municipality: Municipality) => {
    if (municipality) {
      setMunicipalityId(municipality.key)
    }
  }

  // Set Location types
  const locationTypes = locationTypesData.results
  let locationTypeDefaultValue: any = null
  if (location && locationTypes.length > 0) {
    const filter = locationTypes.filter((item: any) => item.key == location.typeLocationId)
    if (filter.length > 0) {
      locationTypeDefaultValue = filter[0]
    }
  }

  const handleChangeLocationType = (value: any) => {
    formik.setFieldValue('typeLocationId', value?.key)
  }

  const goToAgencies = () => {
    if (formik.dirty === false) {
      history.push(`/admin/entities/agencies`)
    } else {
      setAlertForm(true)
    }
  }

  return (
    <FormikProvider value={formik}>
      <Form>
        <Grid item xs={12}>
          <AppBreadcrumbsBar
            title={transLabel('AGENCIA_TITULO')}
            description={transLabel('AGENCIA_SUB_TITULO')}
            actions={
              <React.Fragment>
                {isEditing === false && actions.canEdit ? (
                  <ModifyButton className={classes.buttonActions} onClick={() => setIsEditing(true)} />
                ) : (
                  actions.canCreate && (
                    <SaveButton className={classes.buttonActions} onClick={formik.submitForm} isLoading={false} />
                  )
                )}
                <Button
                  variant="contained"
                  onClick={goToAgencies}
                  startIcon={<KeyboardBackspaceIcon className={classes.buttonActions} />}
                >
                  {transLabel('REGRESAR')}
                </Button>
              </React.Fragment>
            }
          />
        </Grid>

        <Card>
          <CardHeader title={<HeaderCardTitle title={transLabel('DATOS_DE_LA_AGENCIA')} />} />
          <Divider className={classes.divider} />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormControl fullWidth>
                  <Field
                    defaultValue={locationTypeDefaultValue}
                    onChangeValue={handleChangeLocationType}
                    label={transLabel('TIPO_DE_LOCALIDAD')}
                    name={'typeLocationId'}
                    component={CustomAutoCompleteSelectFormik}
                    keyLabel={'value'}
                    keyValue={'value'}
                    data={locationTypes}
                    disabled={!isEditing}
                  />
                </FormControl>
                <Field
                  component={TextField}
                  type="text"
                  label={transLabel('NOMBRE')}
                  name="name"
                  fullWidth
                  disabled={!isEditing}
                />
                <Field
                  disabled={!isEditing}
                  component={PhoneTextFieldMask}
                  type="text"
                  label={transLabel('TELEFONO')}
                  name="phone"
                  fullWidth
                />

                <FormControl fullWidth className={classes.addressDiv}>
                  <Field
                    rowsMin={9}
                    component={TextAreaFieldFormik}
                    name="address"
                    placeholder={transLabel('DIRECCION')}
                    disabled={!isEditing}
                  />
                </FormControl>

                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <FormControl fullWidth>
                      <FormControlLabel
                        control={
                          <Field component={CheckboxFormik} name="active" color="primary" disabled={!isEditing} />
                        }
                        label={transLabel('ESTADO') as string}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormControl fullWidth>
                  <Field
                    component={CountrySelect}
                    onChangeCountry={handleCountryChange}
                    name={'countryId'}
                    disabled={!isEditing}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <Field
                    component={ProvinceSelect}
                    name={'provinceId'}
                    countryId={countryId}
                    onChangeProvince={handleProvinceChange}
                    disabled={!isEditing}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <Field
                    component={MunicipalitySelect}
                    name={'municipalityId'}
                    countryId={countryId}
                    provinceId={provinceId}
                    onChangeMunicipality={handleMunicipalityChange}
                    disabled={!isEditing}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <Field
                    component={SectorSelect}
                    name={'sectorId'}
                    countryId={countryId}
                    provinceId={provinceId}
                    municipalityId={municipalityId}
                    disabled={!isEditing}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <DialogAlerts
          state={alertForm}
          titulo={'SE_HAN_DETECTADO_CAMBIOS_EN_EL_FORMULARIO'}
          contenido={'SI_CONTINUA_PERDERA_LOS_CAMBIOS_EN_EL_FORMULARIO_DESEA_CONTINUAR'}
          tipo="PREGUNTA"
          onSecondaryClick={() => setAlertForm(false)}
          handleClose={() => setAlertForm(false)}
          onPrimaryClick={() => history.push(`/admin/entities/agencies`)}
        />
      </Form>
    </FormikProvider>
  )
}
