import { useCallback, useState } from 'react'
import { Grid, CardContent, Card } from '@material-ui/core'
import { AppBreadcrumbsBar, CustomDataGrid, DataGridColumns, Loader } from '../../../core/components'
import { useTranslation } from 'react-i18next'
import { OperationLocationsFilter } from './OperationLocationsFilter'
import { OperationLocationsResults } from './OperationLocationsResults'
import { useProviderTransaction } from './hooks'
import { Metadata, ResultsList, Dictionary, SearchGraphql, ClaimActions } from '../../../core/models'
import { ProviderTransaction as providerModel } from './models'
import { GridPageChangeParams } from '@material-ui/data-grid'
import { useUserClaimActions } from '../../../core'

export const OperationLocations = () => {
  const { t: translationLabel } = useTranslation('Label')
  const { results: agencyData, loading, getProviderTransaction } = useProviderTransaction()
  const { validateClaimActions } = useUserClaimActions()
  const actions = validateClaimActions('consults.agencies-operations', [ClaimActions.CanSearch, ClaimActions.CanView])
  const [searchRequest, setSearchRequest] = useState<SearchGraphql>({})

  const getColumnDefinitions = useCallback((): DataGridColumns => {
    return {
      // PROVEEDOR: { field: 'locationName', contentType: 'string' },
      // CONSORCIO: { field: 'lastBalance', contentType: 'currency' },
      AGENCIAS: { field: 'locationName', contentType: 'currency' },
      PRODUCTO: { field: 'product', contentType: 'currency' },
      TICKETS: { field: 'totalTickets', contentType: 'currency' },
      APOSTADO: { field: 'totalBetsAmount', contentType: 'currency' },
      // A_PAGAR: { field: 'outcomeConfirmedTransference', contentType: 'currency' },
      GANANCIA: { field: 'riskWin', contentType: 'currency' },
      GANANCIA_: { field: 'riskWinPercentage', contentType: 'currency' },
      IMPUESTOS: { field: 'tax', contentType: 'currency' },
      IMPUESTOS_: { field: 'taxPercentage', contentType: 'currency' },
    }
  }, [])

  const getOperationData = (data: ResultsList<providerModel, Metadata>): Dictionary | undefined => {
    if (data.results !== undefined) {
      let totalTickets = 0
      let totalBetsAmount = 0
      let totalPaidAmount = 0
      let riskWin = 0
      let tax = 0

      data.results.forEach((item) => {
        // product = item.product + product
        totalTickets = item.totalTickets + totalTickets
        totalBetsAmount = item.totalBetsAmount + totalBetsAmount
        totalPaidAmount = item.totalPaidAmount + totalPaidAmount
        riskWin = item.riskWin + riskWin
        tax = item.tax + tax
      })
      return {
        // CONSORCIO: 0,
        AGENCIAS: 0,
        // PRODUCTO: product,
        TICKETS: totalTickets,
        APOSTADO: totalBetsAmount,
        // A_PAGAR: totalPaidAmount,
        GANANCIA: riskWin,
        GANANCIA_: 0,
        IMPUESTOS: tax,
        IMPUESTOS_: 0,
      }
    }
    return undefined
  }

  const operationData = getOperationData(agencyData)

  function onChangeFilter(filter: SearchGraphql) {
    filter = {
      ...filter,
      skip: 0,
      take: 20,
    }
    setSearchRequest(filter)
    getProviderTransaction(filter)
  }

  const handlePageChange = (params: GridPageChangeParams) => {
    const { pageSize, page } = params
    const newFilter = {
      ...searchRequest,
      take: pageSize,
      skip: pageSize * (page - 1),
    }

    setSearchRequest(newFilter)
    getProviderTransaction(newFilter)
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <AppBreadcrumbsBar
          title={translationLabel('OPERACIONES_POR_LOCALIDADES')}
          description={`${translationLabel('OPERACIONES_POR_LOCALIDADES')}`}
        />
      </Grid>
      {actions.canSearch && (
        <Grid item xs={12}>
          {/*  onChange={handleChangeFilter} getWeeksDate={getWeeksDate} */}
          <OperationLocationsFilter onChange={onChangeFilter} />
        </Grid>
      )}
      <Grid item xs={12} lg={1} />
      <Grid
        item
        xs={12}
        lg={11}
        style={{
          width: '95vw',
        }}
      >
        {/*  onChange={handleChangeFilter} getWeeksDate={getWeeksDate} */}
        {operationData !== undefined && <OperationLocationsResults data={operationData} />}
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Loader loading={loading}>
              <div style={{ minHeight: '70vh', width: '100%' }}>
                <CustomDataGrid
                  // onRowClick={rowSelectHandler}
                  columns={getColumnDefinitions()}
                  pageSize={20}
                  data={agencyData}
                  onPageChange={handlePageChange}
                />
              </div>
            </Loader>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}
