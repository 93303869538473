import { ClientAccount } from '../models'
import { gql, useMutation, useQuery } from '@apollo/client'
import { useState, useEffect } from 'react'

const GET_CLIENT_ACCOUNT = gql`
  query GetClientAccount($id: Int!) {
    clientAccount(id: $id) {
      id
      enterpriseId
      accountName
      currencyId
      currencyName
      currencyAcronym
      accountPin
      balance
      noPayed
      margen
      creationdDate
      lastActivity
      accountManagerId
      accountManagerName
    }
  }
`

// const UPDATE_CLIENT_ACCOUNT = gql`
//   mutation UpdateClientAccount($accountPin: Int!) {
//     updateClientAccount(accountPin: $accountPin, input: {{ FIELDS }}) {
//       company {
//         id,
//         enterpriseId,
//         accountName,
//         currencyId,
//         currencyName,
//         currencyAcronym,
//         accountPhone,
//         accountPin,
//         balance,
//         noPayed,
//         margen,
//         creationdDate,
//         lastActivity,
//         salesEnvironmentstr,
//         accountManagerId,
//         accountManagerName
//       }
//     }
//   }
// `;

interface ClientAccountData {
  clientAccount: ClientAccount
}

export const useClientAccount = (id: number) => {
  const { loading, error, data } = useQuery<ClientAccountData>(GET_CLIENT_ACCOUNT, {
    variables: { id },
  })

  const [clientAccount, setClientAccount] = useState<ClientAccount>()

  useEffect(() => {
    setClientAccount(data?.clientAccount)
  }, [data?.clientAccount, loading])

  return { loading, error, clientAccount }
}

const ADD_CLIENT_ACCOUNT = gql`
  mutation AddClientAccount($clientAccount: AddClientAccountInput!) {
    addClientAccount(input: $clientAccount) {
      clientAccount {
        accountManagerId
        accountManagerName
        accountName
        accountPin
        balance
        creationdDate
        currencyAcronym
        currencyId
        currencyName
        enterpriseId
        id
        lastActivity
        margen
        noPayed
      }
    }
  }
`

export const useAddClientAccount = () => {
  const [addClientAccount, { loading, error }] = useMutation(ADD_CLIENT_ACCOUNT)
  return { addClientAccount, loading, error }
}

const UPDATE_CLIENT_ACCOUNT = gql`
  mutation UpdateClientAccount($updateClientAccount: UpdateClientAccountInput!, $id: Int!) {
    updateClientAccount(input: $updateClientAccount, id: $id) {
      clientAccount {
        accountManagerId
        accountManagerName
        accountName
        accountPhone
        accountPin
        balance
        creationdDate
        currencyAcronym
        currencyId
        currencyName
        enterpriseId
        id
        lastActivity
        margen
        noPayed
        salesEnvironmentsStr
      }
    }
  }
`

export const useUpdateClientAccount = () => {
  const [updateClientAccount, { loading, error }] = useMutation(UPDATE_CLIENT_ACCOUNT)
  return { updateClientAccount, loading, error }
}

const REMOVE_CLIENT_ACCOUNT = gql`
  mutation RemoveClientAccount($id: Int!) {
    removeClientAccount(id: $id)
  }
`

export const useRemoveClientAccount = () => {
  const [removeClientAccount, { loading, error }] = useMutation(REMOVE_CLIENT_ACCOUNT)
  return { removeClientAccount, loading, error }
}
