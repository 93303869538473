export interface AppConfiguration {
  graphBaseUrl: string
  // placebetGraphBaseUrl: string,
  // idmGraphBaseUrl: string
  authenticationServiceUrl: string
  authenticationClientId: string
  requestTimeout: number
}

export const getAppConfiguration = (): AppConfiguration => {
  var settings = (window as any)['_env_'] as any
  const graphBaseUrl = settings[`PB_GRAPH_BASE_URL`] as string | undefined

  return {
    authenticationServiceUrl: settings['PB_AUTH_URL'],
    authenticationClientId: settings['PB_AUTH_CLIENT_ID'],
    graphBaseUrl: graphBaseUrl || `https://placebet-graph.${settings['PB_SERVICES_DOMAIN']}`,
    requestTimeout: Number.parseInt(settings['PB_API_TIMEOUT']),
  }
}

export const isMobile = (): boolean => {
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    return true
  } else {
    return false
  }
}
