import { gql, useMutation } from '@apollo/client'

const REMOVE_ROLE_CLAIMS = gql`
  mutation RemoveRoleClaims($removeRoleClaims: RemoveRoleClaimsInput!) {
    removeRoleClaims(input: $removeRoleClaims) {
      succeeded
    }
  }
`

export const useRemoveRoleClaims = () => {
  const [removeRoleClaims, { loading, error }] = useMutation(REMOVE_ROLE_CLAIMS)
  return { removeRoleClaims, loading, error }
}
