import React, { useCallback, useState } from 'react'
import { Card, CardContent, createStyles, Grid, makeStyles, Theme } from '@material-ui/core'
import { ClaimActions, SearchGraphql } from '../../../core/models'
import ProfitAndLossFilter from './ProfitAndLossFilter'
import { CustomDataGrid, DataGridColumns, AppBreadcrumbsBar, Loader } from '../../../core/components'
import { useProfitAndLosses } from './useProfitAndLosses'
import { useTranslation } from 'react-i18next'
import { GridPageChangeParams } from '@material-ui/data-grid'
import { useUserClaimActions } from '../../../core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridFilter: {
      marginBottom: theme.spacing(2),
    },
    buttonsActions: {
      marginLeft: theme.spacing(2),
    },
  }),
)

export const ProfitAndLosses = () => {
  const { validateClaimActions } = useUserClaimActions()
  const actions = validateClaimActions('consults.profit-and-loss', [ClaimActions.CanSearch, ClaimActions.CanView])
  const classes = useStyles()
  const { t: translationLabel } = useTranslation('Label')

  const [searchRequest, setSearchRequest] = useState<SearchGraphql>({})
  const { results, loading, getProfitAndLosses } = useProfitAndLosses()

  const getColumnDefinitions = useCallback((): DataGridColumns => {
    return {
      ENTITY_NAME: { field: 'entityName', contentType: 'string' },
      BEGINNING: { field: 'beginning', contentType: 'number' },
      AMOUNT_BET: { field: 'amountBet', contentType: 'number' },
      IN_OUT: { field: 'inOut', contentType: 'number' },
      BALANCE: { field: 'balance', contentType: 'number' },
      RISK: { field: 'risk', contentType: 'number' },
      NOT_PAYED: { field: 'notPayed', contentType: 'number' },
      COMISSION: { field: 'comission', contentType: 'number' },
    }
  }, [])

  function onChangeFilter(filter: SearchGraphql) {
    filter = {
      ...filter,
      skip: 0,
      take: 20,
    }
    setSearchRequest(filter)
    getProfitAndLosses(filter)
  }
  const handlePageChange = (params: GridPageChangeParams) => {
    const { pageSize, page } = params
    const newFilter = {
      ...searchRequest,
      take: pageSize,
      skip: pageSize * (page - 1),
    }

    setSearchRequest(newFilter)
    getProfitAndLosses(newFilter)
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid item xs={12}>
          <AppBreadcrumbsBar
            title={translationLabel('BALANCE_PERDIDAS_TITULO')}
            description={translationLabel('BALANCE_PERDIDAS_SUBTITULO')}
          />
        </Grid>
        {actions.canSearch && (
          <Grid item xs={12} className={classes.gridFilter}>
            <ProfitAndLossFilter onChange={onChangeFilter} />
          </Grid>
        )}
        <Card>
          <CardContent>
            <Loader loading={loading}>
              <div style={{ minHeight: '35vh', width: '100%', overflow: 'auto' }}>
                <CustomDataGrid
                  // onRowClick={rowSelectHandler}
                  columns={getColumnDefinitions()}
                  pageSize={20}
                  data={results}
                  onPageChange={handlePageChange}
                />
              </div>
            </Loader>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}
