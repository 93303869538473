import { gql, useQuery } from '@apollo/client'
import { useState, useEffect } from 'react'
import { Ticket } from './models'

const GET_TICKET = gql`
  query getTicket($id: Int!) {
    ticket(id: $id) {
      id
      currency
      currencyAcronym
      enterpriseId
      expireDate
      iFRisk
      iFRiskWin
      locationName
      possibleWin
      risk
      riskWin
      salesDate
      salesPeople
      salesTime
      state
      ticketMasterConditionDate
      ticketMasterConditionEstatus
      ticketMasterConditionLocation
      typeTicket
      plays {
        id
        league
        lineDescription
        period
        result
        resultNote
      }
    }
  }
`

interface TicketData {
  ticket: Ticket
}

export const EMPTY_TICKET: Ticket = {
  id: 0,
  risk: 0,
  riskWin: 0,
  salesDate: new Date(),
  salesTime: new Date(),
  state: '',
  typeTicket: '',

  plays: [],
}

export const useTicket = (id: number) => {
  const { loading, error, data } = useQuery<TicketData>(GET_TICKET, {
    variables: { id },
  })

  const [ticket, setTicket] = useState<Ticket>(EMPTY_TICKET)

  useEffect(() => {
    setTicket(data?.ticket || EMPTY_TICKET)
  }, [data?.ticket, loading])

  return { loading, error, ticket }
}
