import { useState } from 'react'
import * as Yup from 'yup'
import moment from 'moment'

import {
  Card,
  CardContent,
  CardHeader,
  CardActions,
  createStyles,
  Divider,
  Grid,
  makeStyles,
  Theme,
  FormControl,
} from '@material-ui/core'

import { HeaderCardTitle } from '../../../core/components'
import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-material-ui'

// My Components
import { SearchButton, LocalizedContentSelect } from '../../../core/components'

// Models
import { Filter, KeyValuePair, MatchType, SearchRequest } from '../../../core/models'

// Hooks
import { useLocalizedContent } from '../../../core/hooks/useLocalizedContent'
import { useTranslation } from 'react-i18next'
import { formatToShortLocalDate, isDateToday } from '../../../core'
import { isMobile } from '../../../core/services/configurationServices'
import { CustomFormikDatePicker } from '../../../core/components/dates/CustomFormikDatePicker'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardRoot: {
      minWidth: '100%',
    },
    formControl: {
      minWidth: '100%',
    },
    select: {
      minWidth: '100%',
    },
    textField: {
      minWidth: '100%',
    },
    dates: {
      marginTop: '0px !important',
      width: '100%',
    },
    searchButton: {
      marginRight: '10px',
      marginTop: isMobile() ? '0px' : '10px',
    },
    numberMessage: {
      color: '#f44336',
      fontSize: '16px',
    },
    searchText: {
      minWidth: '100%',
    },
  }),
)

interface BasicRegistrationPlaysFiltersI {
  date?: string | Date | undefined
  from?: string | Date | undefined
  to?: string | Date | undefined
  ticketCondition?: string
  ticketId?: string | number
  period?: number | string
  todos?: number | string
  typeScore?: number | string
  searchBy: string
}

const initialValues: BasicRegistrationPlaysFiltersI = {
  ticketId: '',
  ticketCondition: '',
  date: new Date(),
  from: new Date(),
  to: new Date(),
  period: '',
  todos: '',
  typeScore: '',
  searchBy: '',
}

interface BasicRegistrationPlaysFiltersErrors {
  from?: string
  to?: string
  ticketCondition?: string
  ticketId?: string
}

type BasicRegistrationPlaysFiltersProps = {
  onSearch: (params: SearchRequest) => void
}

const supportedFilterMap: KeyValuePair = {
  ticketCondition: ['state'],
  ticketId: ['Id'],
  from: ['From'],
  to: ['To'],
}

const formSchema = Yup.object().shape({
  from: Yup.date(),
  to: Yup.date(),
  ticketCondition: Yup.string(),
  ticketId: Yup.number()
    .min(0, 'El número debe de ser mayor a cero.')
    .max(99999999, 'El número debe de ser menor o igual a 99999999.'),
})

export const BasicRegistrationPlaysFilters = ({ onSearch }: BasicRegistrationPlaysFiltersProps) => {
  const classes = useStyles()
  const [message, setMessage] = useState('')
  const { t: translation } = useTranslation('Label')

  // LocalizedContent
  const { results: currencyResults } = useLocalizedContent({ entityType: 'System', entityName: 'Currency' })
  const { results: periodicityResults } = useLocalizedContent({ entityType: 'System', entityName: 'Periodicity' })

  const handleFilters = (value: any, filterKey: string, matchType: MatchType) => {
    if (value) {
      const fields = supportedFilterMap[filterKey] as string[]

      const filter = fields.reduce(
        (currentFilter, field) => addFilter(currentFilter, field, value, matchType, filterKey),
        {},
      )
      return filter
    } else {
      const columns = supportedFilterMap[filterKey] as string[]

      const filter = columns.reduce(
        (currentFilter, column) => addFilter(currentFilter, column, '', matchType, filterKey),
        {},
      )
      return filter
    }
  }

  const addFilter = (
    currentFilter: Filter,
    field: string,
    value: string | number | Date | boolean,
    matchType: MatchType,
    group?: string,
  ) => {
    let result: Filter = {
      ...currentFilter,
      [field]: {
        matchType: matchType,
        value: value,
        group,
      },
    }

    return result
  }

  const prepareFilters = (values: BasicRegistrationPlaysFiltersI) => {
    let searchFilters: SearchRequest = {}

    // When is filtering by number
    if (values.ticketId !== undefined && values.ticketId > 0) {
      const filter = handleFilters(values.ticketId, 'ticketId', 'Exact')
      searchFilters.filter = { ...filter, ...searchFilters.filter }
    } else {
      if (values.from !== undefined && values.to !== undefined) {
        searchFilters.restrictions = {
          ...{ from: formatToShortLocalDate(values.from as Date) },
          ...searchFilters.restrictions,
        }
        searchFilters.restrictions = {
          ...{ to: formatToShortLocalDate(values.to as Date) },
          ...searchFilters.restrictions,
        }
      }
      if (values.ticketCondition !== '') {
        searchFilters.filter = {
          ...handleFilters(values.ticketCondition, 'ticketCondition', 'Exact'),
          ...searchFilters.filter,
        }
      }
    }

    return searchFilters
  }

  return (
    <Card className={classes.cardRoot}>
      <CardHeader component={() => <HeaderCardTitle title={translation('CRITERIOS_DE_BUSQUEDA')} />} />
      <Divider />
      <CardContent>
        <Formik
          validationSchema={formSchema}
          validate={(values: BasicRegistrationPlaysFiltersI) => {
            const errors: Partial<BasicRegistrationPlaysFiltersErrors> = {}
            if (moment(values.from).isAfter(values.to)) {
              errors.to = 'Fecha Hasta debe ser mayor a la fecha Desde.'
            }
            if (values.ticketId && (values.ticketCondition || !isDateToday(values.from) || !isDateToday(values.to))) {
              setMessage('La búsqueda solo aplicará por Número.')
            } else {
              setMessage('')
            }
            return errors
          }}
          initialValues={{ ...initialValues }}
          onSubmit={(values: BasicRegistrationPlaysFiltersI, { setSubmitting }) => {
            setSubmitting(false)
            onSearch(prepareFilters(values))
          }}
        >
          {({ submitForm }) => {
            return (
              <Form>
                <Grid container item spacing={3} lg={12} xs={12} sm={12}>
                  <Grid item lg={2} xs={12} sm={2}>
                    <Field
                      component={CustomFormikDatePicker}
                      name="date"
                      label="Fecha"
                      format={'dd/MM/yyyy'}
                      disableFuture={true}
                      customClassName={classes.dates}
                    />
                  </Grid>
                  <Grid item lg={1} xs={12} sm={2}>
                    <FormControl className={classes.formControl}>
                      <Field
                        component={LocalizedContentSelect}
                        name="currencyCondition"
                        label={translation('DIVISA')}
                        data={currencyResults}
                        className={classes.select}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item lg={2} xs={12} sm={12}>
                    <Field
                      component={TextField}
                      type="number"
                      label={translation('MONTO_MINIMO')}
                      name="ticketId"
                      className={classes.textField}
                    />
                  </Grid>
                  <Grid item lg={1} sm={12} xs={12}>
                    <FormControl className={classes.formControl}>
                      <Field
                        component={LocalizedContentSelect}
                        name="todos"
                        label={translation('TODOS')}
                        data={periodicityResults}
                        className={classes.select}
                        showNoneOption
                      />
                    </FormControl>
                  </Grid>
                  <Grid item lg={1} sm={12} xs={12}>
                    <FormControl className={classes.formControl}>
                      <Field
                        component={LocalizedContentSelect}
                        name="typeScore"
                        label={translation('TYPE_SCORE')}
                        data={periodicityResults}
                        className={classes.select}
                        showNoneOption
                      />
                    </FormControl>
                  </Grid>
                  <Grid item lg={1} xs={12} sm={2}>
                    <FormControl className={classes.formControl}>
                      <Field
                        component={LocalizedContentSelect}
                        name="period"
                        label={translation('PERIODO')}
                        data={periodicityResults}
                        className={classes.select}
                        showNoneOption
                      />
                    </FormControl>
                  </Grid>
                  <Grid item lg={2} xs={12} sm={12}>
                    <Field
                      className={classes.searchText}
                      component={TextField}
                      type="text"
                      label={translation('MOSTRAR_LOS_QUE_COINCIDAD_CON')}
                      name="searchBy"
                    />
                  </Grid>
                  <Grid item lg={1} xs={12} sm={12} className={classes.searchButton}>
                    <SearchButton onClick={submitForm} />
                  </Grid>
                </Grid>
              </Form>
            )
          }}
        </Formik>
      </CardContent>
      <CardActions>
        <span className={classes.numberMessage}>{message}</span>
      </CardActions>
    </Card>
  )
}
