import React from 'react'
import * as Yup from 'yup'

import { Card, CardContent, CardHeader, createStyles, Divider, Grid, makeStyles, Theme } from '@material-ui/core'

import { Formik, Form, Field } from 'formik'

// My Components
import { HeaderCardTitle, SearchButton } from '../../../../core/components'

// Hooks
import { useTranslation } from 'react-i18next'
import { CustomFormikDatePicker } from '../../../../core/components/dates/CustomFormikDatePicker'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardRoot: {
      minWidth: '100%',
    },
    description: {
      fontSize: 15,
      padding: theme.spacing(0.5),
    },
    filterGrid: {
      maxWidth: '90%',
      display: 'flex',
    },
    searchText: {
      minWidth: '95%',
    },
    accountNameLabel: {
      marginTop: '15px',
      color: '#969696',
    },
    accountDebit: {
      marginTop: '15px',
      color: '#969696',
      textDecoration: 'underline',
    },
    buttonGrid: {
      marginTop: '10px',
    },
    dates: {
      marginTop: '0px !important',
    },
    dateValidation: {
      color: '#f44336',
      fontSize: '12px',
    },
  }),
)

export interface AccountTransactionFilters {
  from: Date
  to: Date
}
const initialValues: AccountTransactionFilters = {
  from: new Date(),
  to: new Date(),
}

const formSchema = Yup.object().shape({
  from: Yup.date(),
  to: Yup.date(),
})

type AccountTransactionFiltersProps = {
  onSearch: (params: AccountTransactionFilters) => void
  disabled: boolean
}

export const ClientAccountTransactionsFilters = ({ onSearch, disabled }: AccountTransactionFiltersProps) => {
  const classes = useStyles()
  const { t: translation } = useTranslation('Label')

  return (
    <Card className={classes.cardRoot}>
      <CardHeader component={() => <HeaderCardTitle title={translation('CRITERIOS_DE_BUSQUEDA')} />} />
      <Divider />
      <CardContent>
        <Formik
          validationSchema={formSchema}
          validate={(values: AccountTransactionFilters) => {
            // Validate filters here
          }}
          initialValues={{ ...initialValues }}
          onSubmit={(values: AccountTransactionFilters, { setSubmitting }) => {
            setSubmitting(false)
            onSearch(values)
          }}
        >
          {({ errors, submitForm }) => {
            return (
              <Form>
                <Grid container item spacing={3} xs={12} sm={9}>
                  <Grid item lg={2} xs={12} sm={2}>
                    <Field
                      component={CustomFormikDatePicker}
                      name="from"
                      label={translation('DESDE')}
                      format={'dd/MM/yyyy'}
                      disableFuture={true}
                      customClassName={classes.dates}
                      disabled={disabled}
                    />
                  </Grid>
                  <Grid item lg={2} xs={12} sm={2}>
                    <Field
                      component={CustomFormikDatePicker}
                      name="to"
                      label={translation('HASTA')}
                      format={'dd/MM/yyyy'}
                      disableFuture={true}
                      customClassName={classes.dates}
                      disabled={disabled}
                    />
                    <br />
                    {/* <span className={classes.dateValidation}>{errors.to ? errors.to : ''}</span> */}
                  </Grid>
                  <Grid item lg={4} xs={12} sm={4} className={classes.buttonGrid}>
                    <SearchButton onClick={submitForm} disabled={disabled} />
                  </Grid>
                </Grid>
              </Form>
            )
          }}
        </Formik>
      </CardContent>
    </Card>
  )
}
