import { gql, useMutation } from '@apollo/client'

const UPDATE_PROGRAM_JOCKEY_OR_KENNEL = gql`
  mutation UpdateProgramJockeyOrKennel($id: Int!, $input: UpdateProgramEntityInput!) {
    updateProgramJockeyOrKennel(id: $id, input: $input) {
      programJockeyOrKennel {
        id
        name
        isActive
      }
    }
  }
`

export interface UpdateProgramJockeyOrKennelInput {
  id: number
  name: string
  isActive: boolean
}

export const useUpdateProgramJockeyOrKennel = () => {
  const [updateProgramJockeyOrKennel, { loading, error }] = useMutation<UpdateProgramJockeyOrKennelInput>(
    UPDATE_PROGRAM_JOCKEY_OR_KENNEL,
  )
  return { updateProgramJockeyOrKennel, loading, error }
}
