import { gql, useLazyQuery, useQuery } from '@apollo/client'
import { Metadata, ResultsList, SearchGraphql } from '../../../../../core/models'
import { ProgramCompetitor } from '../../models'

const GET_COMPETITORS = gql`
  query GetProgramCompetitors($where: ProgramCompetitorWithOwnerFilterInput!, $skip: Int, $take: Int) {
    programCompetitors(where: $where, skip: $skip, take: $take, order: { name: ASC }) {
      items {
        id
        name
        ownerId
        owner
        medication
        isActive
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`

interface CompetitorsData {
  programCompetitors: {
    totalCount: number
    items: ProgramCompetitor[]
  }
}

export const useProgramCompetitors = () => {
  const [getResults, { loading, error, data }] = useLazyQuery<CompetitorsData>(GET_COMPETITORS, {
    fetchPolicy: 'network-only',
  })

  const getProgramCompetitors = ({ where, take, skip }: SearchGraphql) => {
    getResults({
      variables: {
        where: { ...where },
        skip: skip ? skip : 0,
        take: take ? take : 100,
      },
    })
  }

  const results: ResultsList<ProgramCompetitor, Metadata> = {
    results: data?.programCompetitors.items || [],
    metadata: {
      Count: data?.programCompetitors.totalCount,
    },
  }

  return { getProgramCompetitors, loading, error, results }
}
