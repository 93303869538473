import React, { useCallback, useEffect, useState } from 'react'
import { TextField } from 'formik-material-ui'

import Autocomplete, { AutocompleteChangeReason } from '@material-ui/lab/Autocomplete'
import CircularProgress from '@material-ui/core/CircularProgress'
import { debounce } from 'lodash'
import { FormProps } from '../../../../core'

export interface DistanceSelectFormikProps extends FormProps {
  keyValue: string
  keyLabel?: string
  data: any[]
  isLoading?: boolean
  label?: string
  defaultValue?: any
  onChangeValue?: (value: any, action: string) => void
  onInputValueChange?: (value: string, fieldName: string, action: string) => void
  onSelectOpen?: () => void
  disabled?: boolean
  multiple?: boolean
  props?: any
}

export const DistanceSelectFormik = ({
  keyValue,
  keyLabel,
  data,
  isLoading,
  label,
  onChangeValue,
  onInputValueChange,
  onSelectOpen,
  defaultValue,
  disabled,
  multiple,
  ...props
}: DistanceSelectFormikProps) => {
  const {
    form: { setFieldValue, values },
    field: { name },
  } = props
  const [open, setOpen] = useState(false)
  // const [selectedOption, setSelectedOption] = useState(multiple ? [] : values[name] ? values[name] : null)

  // useEffect(() => {
  //   if (values[name]) {
  //     setSelectedOption(values[name])
  //   }
  // }, [values])

  const onSelect = (event: React.ChangeEvent<any>, value: any, reason: AutocompleteChangeReason) => {
    try {
      if (reason === 'select-option') {
        // setSelectedOption(value)
        setFieldValue(name, value)
        if (onChangeValue instanceof Function) {
          onChangeValue(value, reason)
        }
      }
      if (reason === 'clear') {
        // setSelectedOption(value)
        setFieldValue(name, value)
        if (onChangeValue instanceof Function) {
          onChangeValue(value, reason)
        }
      }
    } catch (e) {}
  }

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    debouncedDispatchChangeQuery(value)
  }

  const debouncedDispatchChangeQuery = useCallback(
    debounce((value: string) => {
      if (onInputValueChange instanceof Function) {
        if (!checkIfValueExistsInLocalData(value)) {
          onInputValueChange(value, name, 'typing')
        }
      }
    }, 500),
    [onInputValueChange],
  )

  const checkIfValueExistsInLocalData = (value: string): boolean => {
    if (value.length > 0 && data.length > 0) {
      return data.some((item) => item[keyValue].includes(value))
    } else {
      console.log(value)
      return false
    }
  }

  const handleClose = () => {
    setOpen(false)
    if (onInputValueChange instanceof Function) {
      onInputValueChange('', name, 'close')
    }
  }

  const handleOpen = () => {
    setOpen(true)
    if (onSelectOpen instanceof Function) {
      onSelectOpen()
    }
  }

  return (
    <Autocomplete
      {...props}
      disabled={disabled}
      value={values[name]}
      onChange={onSelect}
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      getOptionSelected={(option, value) => (value ? option[keyValue] === value[keyValue] : false)}
      getOptionLabel={(option) => (keyLabel && option[keyLabel] ? option[keyLabel] : '')}
      options={data}
      loading={isLoading}
      multiple={multiple}
      renderInput={(params) => (
        <TextField
          {...params}
          {...props}
          onChange={onInputChange}
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      ListboxProps={{
        onScroll: (event: React.SyntheticEvent) => {
          const listboxNode = event.currentTarget
          if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
            console.log('More data')
          }
        },
      }}
    />
  )
}
