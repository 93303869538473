import React from 'react'
import Lottie from '../Lottie'
import animation from './gears.json'

export const LoadingAnimation = () => {
  return (
    <div
      style={{
        display: 'flex',
        height: '100vh',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'transparent'
      }}
    >
      <Lottie
        style={{
          height: '16rem',
          width: 'auto',
          backgroundColor: 'transparent'
        }}
        options={{
          animationData: animation,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid meet'
          }
        }}
      />
    </div>
  )
}

