import { useState } from 'react'
import moment from 'moment'
import { Card, CardContent, CardHeader, Divider, Grid, createStyles, makeStyles } from '@material-ui/core'
import { Formik, Form, Field } from 'formik'
import { useTranslation } from 'react-i18next'
import { HeaderCardTitle } from '../../../core/components'
import { CustomFormikDatePicker } from '../../../core/components/dates/CustomFormikDatePicker'

const useStyles = makeStyles(() =>
  createStyles({
    formControl: {
      minWidth: '100%',
    },
    dateValidation: {
      color: '#f44336',
      fontSize: '12px',
    },
    dates: {
      marginTop: '0px !important',
      width: '100%',
    },
  }),
)

export interface IMachineSummarizedFilters {
  from?: Date
  to?: Date
}

interface MachineSummarizedFiltersProps {
  onFilter: (filters: IMachineSummarizedFilters) => void
  // getWeeksDate: (weeks: currentWeekType) => void
}

interface MachineSummarizedFiltersErrors {
  from?: string
  to?: string
}

export const MachineSummarizedFilters = ({ onFilter }: MachineSummarizedFiltersProps) => {
  const classes = useStyles()

  const [currency] = useState<string>('')
  const { t: translationLabel } = useTranslation('Label')

  return (
    <Card>
      <CardHeader component={() => <HeaderCardTitle title={translationLabel('CRITERIOS_DE_BUSQUEDA')} />} />
      <Divider />
      <CardContent>
        <Formik
          validate={(values: IMachineSummarizedFilters) => {
            const errors: Partial<MachineSummarizedFiltersErrors> = {}
            if (moment(values.from).isAfter(values.to)) {
              errors.to = 'Fecha Hasta debe ser mayor a la fecha Desde.'
            }
            return errors
          }}
          initialValues={{
            from: new Date(),
            to: new Date(),
          }}
          onSubmit={(values: IMachineSummarizedFilters, { setSubmitting }) => {
            setSubmitting(false)
            const filters = {
              ...values,
              currency,
            }
            onFilter(filters)
          }}
        >
          {({ errors, submitForm }) => {
            const onDateChange = () => {
              submitForm()
            }

            return (
              <Form>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item lg={2} xs={12} sm={12}>
                    <Field
                      component={CustomFormikDatePicker}
                      name="from"
                      label={translationLabel('DESDE')}
                      format="dd/MM/yyyy"
                      customClassName={classes.dates}
                      fullWidth
                      disableFuture={true}
                      onDateChange={onDateChange}
                    />
                  </Grid>
                  <Grid item lg={2} xs={12} sm={12}>
                    <Field
                      component={CustomFormikDatePicker}
                      name="to"
                      label={translationLabel('HASTA')}
                      format="dd/MM/yyyy"
                      customClassName={classes.dates}
                      disableFuture={true}
                      onDateChange={onDateChange}
                    />
                    <br />
                    <span className={classes.dateValidation}>{errors.to ? errors.to : ''}</span>
                  </Grid>
                </Grid>
              </Form>
            )
          }}
        </Formik>
      </CardContent>
    </Card>
  )
}
