import React, { useCallback, useState } from 'react'
import { GridCellParams, GridPageChangeParams, GridRowParams } from '@material-ui/data-grid'
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core'
import { Metadata, ResultsList } from '../../../../core/models'
import { CustomDataGrid, DataGridColumns } from '../../../../core/components'
import { useTranslation } from 'react-i18next'
import { Runner, Race, Event } from '../models'
import { IconButton } from '@material-ui/core'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      '& .MuiDataGrid-columnsContainer': {
        background: '#bdbdbd',
      },
    },
    grid: {
      paddingBottom: theme.spacing(2),
    },
  }),
)

type RunnersProps = {
  onSelectRow: (runner: Runner) => void
  runners: ResultsList<Runner, Metadata>
  isSelectionDisabled?: boolean
  race: Race
  onSelectToRemove: (row: GridCellParams) => void
  event: Event
}

export const Runners = ({ onSelectRow, runners, isSelectionDisabled, race, onSelectToRemove, event }: RunnersProps) => {
  const { t: transLabel } = useTranslation('Label')
  const classes = useStyles()

  const [paging, setPaging] = useState<GridPageChangeParams>({
    page: 0,
    pageCount: 0,
    pageSize: 10,
    paginationMode: 'server',
    rowCount: 10,
  })

  const getColumnDefinitions = useCallback((): DataGridColumns => {
    return {
      DE_CARRERA_NO: { field: 'number', contentType: 'string' },
      DESCRIPCION: { field: 'description', contentType: 'string' },
      ESTADO: { field: 'isActive', contentType: 'boolean' },
      // ACTION: {
      //   field: '',
      //   contentType: 'custom',
      //   renderCell: (params: GridCellParams) => {
      //     return (
      //       <React.Fragment>
      //         <IconButton onClick={() => onSelectToRemove(params)} color={'secondary'} disabled={!event.isActive}>
      //           <DeleteForeverIcon fontSize="small" />
      //         </IconButton>
      //       </React.Fragment>
      //     )
      //   },
      // },
    }
  }, [])

  const handleRowSelected = (params: GridRowParams) => {
    if (!isSelectionDisabled) {
      onSelectRow(params.row as Runner)
    }
  }

  return (
    <div className={classes.root}>
      <Typography variant="h5">{`${transLabel('LISTA_DE_CORREDORES_DE_LA_CARRERA_NO')} ${race.raceNumber}`}</Typography>
      <div style={{ minHeight: '30vh', width: '100%' }}>
        <CustomDataGrid
          data={runners}
          columns={getColumnDefinitions()}
          pageSize={25}
          onRowClick={handleRowSelected}
          hideFooterPagination={true}
        />
      </div>
    </div>
  )
}
