import { gql, useMutation } from '@apollo/client'

const PASSWORD_RESET_CODE = gql`
  mutation GetPasswordResetCode($input: GetPasswordResetCodeInput) {
    passwordResetCode(input: $input) {
      passwordResetCode
    }
  }
`

export const usePasswordResetCode = () => {
  const [passwordResetCode, { loading, error }] = useMutation(PASSWORD_RESET_CODE, {
    errorPolicy: 'all',
  })
  return { passwordResetCode, loading, error }
}
