import React, { useState } from 'react'
import moment from 'moment'
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core'
import { Formik, Form, Field } from 'formik'

import { useTranslation } from 'react-i18next'
import { useLocalizedContent } from '../../../core/hooks/useLocalizedContent'
import { HeaderCardTitle } from '../../../core/components'
import { CustomFormikDatePicker } from '../../../core/components/dates/CustomFormikDatePicker'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      minWidth: '100%',
    },
    select: {
      minWidth: '100%',
    },
    dateValidation: {
      color: '#f44336',
      fontSize: '12px',
    },
    dates: {
      marginTop: '0px !important',
      width: '100%',
    },
  }),
)
export interface IMachineDetailsFilters {
  from?: Date
  to?: Date
  currency?: string
}

interface IMachineDetailsFiltersErrors {
  from?: string
  to?: string
}

interface MachineDetailsFiltersProps {
  onFilter: (filters: IMachineDetailsFilters) => void
  // getWeeksDate: (weeks: currentWeekType) => void
}

export const MachineDetailsFilters = ({ onFilter }: MachineDetailsFiltersProps) => {
  const classes = useStyles()

  const [currency, setCurrency] = useState<string>('SEMANAL')
  const { t: translationLabel } = useTranslation('Label')

  // LocalizedContent
  const { results } = useLocalizedContent({ entityType: 'System', entityName: 'Periodicity' })

  return (
    <Card>
      <CardHeader component={() => <HeaderCardTitle title={translationLabel('CRITERIOS_DE_BUSQUEDA')} />} />
      <Divider />
      <CardContent>
        <Formik
          validate={(values: IMachineDetailsFilters) => {
            const errors: Partial<IMachineDetailsFiltersErrors> = {}
            if (moment(values.from).isAfter(values.to)) {
              errors.to = 'Fecha Hasta debe ser mayor a la fecha Desde.'
            }
            return errors
          }}
          initialValues={{
            from: new Date(),
            to: new Date(),
          }}
          onSubmit={(values: IMachineDetailsFilters, { setSubmitting }) => {
            setSubmitting(false)
            const filters = {
              ...values,
              currency,
            }
            onFilter(filters)
          }}
        >
          {({ errors, submitForm, handleReset }) => {
            const onDateChange = () => {
              submitForm()
            }

            const onSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
              setCurrency(event.target.value as string)
              submitForm()
            }

            return (
              <Form>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item lg={2} xs={12} sm={12}>
                    <Field
                      component={CustomFormikDatePicker}
                      name="from"
                      label={translationLabel('DESDE')}
                      format="dd/MM/yyyy"
                      customClassName={classes.dates}
                      fullWidth
                      disableFuture={true}
                      onDateChange={onDateChange}
                    />
                  </Grid>
                  <Grid item lg={2} xs={12} sm={12}>
                    <Field
                      component={CustomFormikDatePicker}
                      name="to"
                      label={translationLabel('HASTA')}
                      format="dd/MM/yyyy"
                      customClassName={classes.dates}
                      disableFuture={true}
                      onDateChange={onDateChange}
                    />
                    <br />
                    <span className={classes.dateValidation}>{errors.to ? errors.to : ''}</span>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={2}>
                    <InputLabel id={'currency'}>{translationLabel('DIVISA')}</InputLabel>
                    <Select labelId={'currency'} id={'currency'} value={currency} onChange={onSelectChange}>
                      {results
                        ? results.map((item) => (
                            <MenuItem key={item.entityId} value={item.grupal}>
                              {translationLabel(item.grupal)}
                            </MenuItem>
                          ))
                        : null}
                    </Select>
                  </Grid>
                </Grid>
              </Form>
            )
          }}
        </Formik>
      </CardContent>
    </Card>
  )
}
