import React, { ReactElement, useState } from 'react'
import { Checkbox, CheckboxProps } from '@material-ui/core'
import { withStyles, makeStyles, createStyles } from '@material-ui/core/styles'
import { green, grey } from '@material-ui/core/colors'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      marginLeft: 10,
    },
  }),
)

export type RoleClaim = {
  claimValue: string
  claimType: string
  roleName: string
}

interface CustomCheckboxColorProps extends CheckboxProps {
  checked?: boolean
  icon: ReactElement
  checkedIcon: ReactElement
  name: string
  customColor: string
  isClaimed: boolean
  size?: 'medium' | 'small'
  roleClaim: RoleClaim
  addClaim: (roleClaim: RoleClaim) => void
  removeClaim: (roleClaim: RoleClaim) => void
}

export const ClaimCheckbox = withStyles({
  root: {
    color: ({ customColor }: CustomCheckboxColorProps) =>
      `${customColor === 'green' ? green[400] : customColor === 'grey' ? grey[400] : ''}`,
    '&$checked': {
      color: ({ customColor }: CustomCheckboxColorProps) =>
        `${customColor === 'green' ? green[400] : customColor === 'grey' ? grey[400] : ''}`,
    },
  },
  checked: {},
})(({ ...props }: CustomCheckboxColorProps) => {
  const { isClaimed, addClaim, removeClaim, size, roleClaim, disabled } = props

  const progressClasses = useStyles()
  const [isProgressing, setIsProgressing] = useState<boolean>(false)

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked
    setIsProgressing(true)
    if (value) {
      await addClaim(roleClaim)
    } else {
      await removeClaim(roleClaim)
    }
    setIsProgressing(false)
  }

  return isProgressing ? (
    <CircularProgress size={20} className={progressClasses.root} />
  ) : (
    <Checkbox {...props} color="default" checked={isClaimed} onChange={handleChange} size={size} disabled={disabled} />
  )
})
