import { gql, useMutation } from '@apollo/client'

const UPLOAD_PROGRAM_FILE = gql`
  mutation UploadProgramFile($eventId: Int!) {
    uploadProgramFile(eventId: $eventId) {
      date
      fileName
      result {
        description
        statusCode
      }
    }
  }
`
export interface uploadProgramFileInput {
  eventId: number
}

export const useUploadProgramFile = () => {
  const [uploadProgramFile, { loading, error }] = useMutation<uploadProgramFileInput>(UPLOAD_PROGRAM_FILE, {
    errorPolicy: 'all',
  })

  return { uploadProgramFile, loading, error }
}
