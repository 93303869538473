import React, { useCallback, useState } from 'react'
import { Theme, createStyles, makeStyles, TextField } from '@material-ui/core'
import { debounce } from 'lodash'
import { FormProps } from '../../models'
const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    top: {
      zIndex: 4,
    },
    search: {
      margin: '0',
      paddingTop: '7px',
      paddingBottom: '7px',
      [theme.breakpoints.down('sm')]: {
        margin: '10px 15px',
        float: 'none !important',
        paddingTop: '1px',
        paddingBottom: '1px',
        padding: '10px 15px',
        width: 'auto',
      },
      color: theme.palette.primary.contrastText,
    },
    searchInput: {
      paddingTop: '2px',
    },
  }),
)
export interface TextFieldDebouncePropsFormik extends FormProps {
  onChange?: (value: string) => void
}
export const TextFieldDebounceFormik = ({ onChange, ...props }: TextFieldDebouncePropsFormik) => {
  const {
    form: { setFieldValue },
    field: { name },
  } = props

  const classes = useStyle()
  const [searchQuery, setSearchQuery] = useState<string>('')
  const debouncedDispatchChangeQuery = useCallback(
    debounce((value: string) => {
      if (onChange) {
        onChange(value)
      }
    }, 500),
    [onChange],
  )
  const handleChangeQuery = (event: any) => {
    const value = event.target.value
    setSearchQuery(value)
    debouncedDispatchChangeQuery(value)
    setFieldValue(name, event.target.value)
  }
  return (
    <TextField
      {...props}
      value={searchQuery}
      onChange={handleChangeQuery}
      className={classes.searchInput}
      aria-label={'Buscar'}
    />
  )
}
