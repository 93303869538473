import { Metadata, ProfitAndLoss, ResultsList, SearchGraphql } from '../../../core/models'
import { gql, useLazyQuery } from '@apollo/client'
import { useState, useEffect, useCallback } from 'react'

const GET_PROFIT_AND_LOSSES = gql`
  query getAllProfitsAndLossesQuery($where: ProfitAndLossFilterInput, $skip: Int, $take: Int) {
    profitAndLosses(where: $where, skip: $skip, take: $take) {
      totalCount
      items {
        id
        enterpriseId
        amountBet
        balance
        beginning
        comission
        currencyName
        entityId
        entityName
        eventDate
        inOut
      }
    }
  }
`

interface ProfitAndLossData {
  profitAndLosses: {
    totalCount: number
    items: ProfitAndLoss[]
  }
}

export const useProfitAndLosses = () => {
  const [getResults, { loading, error, data }] = useLazyQuery<ProfitAndLossData>(GET_PROFIT_AND_LOSSES, {
    fetchPolicy: 'network-only',
  })

  const [results, setResults] = useState<ResultsList<ProfitAndLoss, Metadata>>({
    results: [],
    metadata: {},
  })

  const getProfitAndLosses = useCallback(
    ({ where, take, skip }: SearchGraphql) => {
      getResults({ variables: { where, take, skip } })
    },
    [getResults],
  )

  useEffect(() => {
    setResults({
      results: data?.profitAndLosses.items || [],
      metadata: {
        Count: data?.profitAndLosses.totalCount,
      },
    })
  }, [data, loading])

  return { loading, error, results, getProfitAndLosses }
}
