// import { ClientAccount } from './models'
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { useState, useEffect, useCallback } from 'react'
import { ResultsList, Metadata, Dictionary, SearchGraphql } from '../../../core/models'
import { Enterprise } from './models'

const GET_ENTERPRISES = gql`
  query getAllEnterprisesQuery($where: EnterpriseFilterInput, $skip: Int, $take: Int) {
    enterprises(where: $where, skip: $skip, take: $take) {
      items {
        id
        name
        active
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`

interface EnterprisesData {
  enterprises: {
    items: Enterprise[]
    totalCount: number
  }
}

export const useEnterprises = () => {
  const [getResults, { loading, error, data }] = useLazyQuery<EnterprisesData>(GET_ENTERPRISES, {
    fetchPolicy: 'network-only',
  })

  const [results, setResults] = useState<ResultsList<Enterprise, Metadata>>({
    results: [],
    metadata: {},
  })

  const getEnterprises = useCallback(
    ({ where, take, skip }: any) => {
      getResults({
        variables: {
          where: { ...where },
          skip: skip ? skip : 0,
          take: take ? take : 20,
        },
      })
    },
    [getResults],
  )

  useEffect(() => {
    setResults({
      results: data?.enterprises.items || [],
      metadata: {
        Count: data?.enterprises.totalCount,
      },
    })
  }, [data?.enterprises.items, loading])

  return { loading, error, results, getEnterprises }
}

const GET_USER_ENTERPRISES = gql`
  query getUserEnterprisesQuery {
    enterprises(skip: 0, take: 100, order: { name: ASC }) {
      items {
        id
        name
        subdomain
      }
    }
  }
`

interface UserEnterprisesData {
  enterprises: {
    items: Enterprise[]
  }
}

export const useUserEnterprises = () => {
  const [getResults, { loading, error, data }] = useLazyQuery<UserEnterprisesData>(GET_USER_ENTERPRISES, {
    fetchPolicy: 'network-only',
  })

  const getUserEnterprises = () => {
    getResults({
      variables: {
        where: { active: { eq: true } },
      },
    })
  }

  return { getUserEnterprises, loading, error, results: data?.enterprises.items || [] }
}

const CREATE_ENTERPRISE = gql`
  mutation AddEnterprise($input: AddEnterpriseInput!) {
    addEnterprise(input: $input) {
      enterprise {
        id
        name
        subdomain
        active
      }
    }
  }
`

export interface CreateEnterpriseInput {
  name: string
  active?: boolean
}

export const useCreateEnterprise = () => {
  const [createEnterprise, { loading, error }] = useMutation(CREATE_ENTERPRISE)
  return { createEnterprise, loading, error }
}

const UPDATE_ENTERPRISE = gql`
  mutation UpdateEnterprise($id: Int!, $input: UpdateEnterpriseInput!) {
    updateEnterprise(id: $id, input: $input) {
      enterprise {
        id
        name
        subdomain
        active
      }
    }
  }
`

export interface UpdateEnterpriseInput extends Dictionary {
  name?: string
  active?: boolean
}

export const useUpdateEnterprise = () => {
  const [updateEnterprise, { loading, error }] = useMutation(UPDATE_ENTERPRISE)
  return { updateEnterprise, loading, error }
}

const GET_ENTERPRISE = gql`
  query GetEnterprise($id: Int!) {
    enterprise(id: $id) {
      name
      domain
      subdomain
      active
    }
  }
`

export const useEnterprise = () => {
  const [getResults, { loading, error, data }] = useLazyQuery(GET_ENTERPRISE, {
    fetchPolicy: 'network-only',
  })

  const getEnterprise = (id: number) => {
    getResults({
      variables: {
        id,
      },
    })
  }

  return { getEnterprise, loading, error, data }
}
