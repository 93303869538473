import { gql, useLazyQuery, useQuery } from '@apollo/client'
import { useCallback, useEffect, useState } from 'react'
import { Metadata, ResultsList, SearchGraphql } from '../../../../core/models'
import { Location } from '../../stations/models'

const GET_LOCATIONS = gql`
  query GetLocations($where: LocationFilterInput!, $skip: Int, $take: Int) {
    locations(where: $where, skip: $skip, take: $take) {
      items {
        id
        name
        typeLocationId
        enterpriseId
        address
        phone
        countryId
        municipalityId
        sectorId
        restrictedByGeography
        active
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`

interface LocationsData {
  locations: {
    totalCount: number
    items: Location[]
  }
}

export const useLocations = () => {
  const [getResults, { loading, error, data }] = useLazyQuery<LocationsData>(GET_LOCATIONS, {
    fetchPolicy: 'network-only',
  })

  const [results, setResults] = useState<ResultsList<Location, Metadata>>({
    results: [],
    metadata: {},
  })

  const getLocations = useCallback(
    ({ where, take, skip }: SearchGraphql) => {
      getResults({
        variables: {
          where: { ...where },
          skip: skip ? skip : 0,
          take: take ? take : 100,
        },
      })
    },
    [getResults],
  )

  useEffect(() => {
    setResults({
      results: data?.locations.items || [],
      metadata: {
        Count: data?.locations.totalCount,
      },
    })
  }, [data?.locations.items, loading])

  return { getLocations, loading, error, results }
}
