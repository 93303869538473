import { TicketCensusTypeBet, TicketCensusTypeBetGlobal } from './models'
import { gql, useLazyQuery } from '@apollo/client'
import { Metadata, ResultsList, SearchGraphql } from '../../../core/models'
import { useState, useEffect, useCallback } from 'react'

const GET_TICKET_CENSUS_TYPE_BET = gql`
  query GetTicketCensusTypeBet {
    ticketCensusTypeBet(take: 20, skip: 0) {
      items {
        id
        leagueAbbreviation
        eventDate
        nameAway
        nameHome
        mLHome
        mLAway
        rLAway
        rLHome
        mLAwayAmountBet
        mLHomeAmountBet
        rLAwayAmountBet
        rLHomeAmountBet
        totalOver
        totalOverAmountBet
        totalUnder
        totalUnderAmountBet
        solomasAwayAmountBet
        solomasAway
        solomenosAwayAmountBet
        solomenosAway
      }
    }
  }
`

// const GET_TICKET_CENSUS_TYPE_BET = gql`
//   query GetTicketCensusTypeBet($where: TicketCensusTypeBetFilterInput!, $skip: Int, $take: Int) {
//     ticketCensusTypeBet(where: $where, take: $take, skip: $skip) {
//       items {
//         id
//         leagueAbbreviation
//         eventDate
//         nameAway
//         nameHome
//         mLHome
//         mLAway
//         rLAway
//         rLHome
//         mLAwayAmountBet
//         mLHomeAmountBet
//         rLAwayAmountBet
//         rLHomeAmountBet
//         totalOver
//         totalOverAmountBet
//         totalUnder
//         totalUnderAmountBet
//         solomasAwayAmountBet
//         solomasAway
//         solomenosAwayAmountBet
//         solomenosAway
//       }
//     }
//   }
// `

interface TicketCensusTypeBetData {
  ticketCensusTypeBet: {
    items: TicketCensusTypeBet[]
  }
}

export const useTicketCensusTypeBet = () => {
  const [getResults, { loading, error, data }] = useLazyQuery<TicketCensusTypeBetData>(GET_TICKET_CENSUS_TYPE_BET)

  const [results, setResults] = useState<ResultsList<TicketCensusTypeBet, Metadata>>({
    results: [],
    metadata: {},
  })

  const getTicketCensusTypeBet = useCallback(
    ({ where, take, skip }: SearchGraphql) => {
      getResults({ variables: { where, take, skip } })
    },
    [getResults],
  )

  useEffect(() => {
    setResults({
      results: data?.ticketCensusTypeBet.items || [],
      metadata: {},
    })
  }, [data?.ticketCensusTypeBet.items, loading])

  return { loading, error, results, getTicketCensusTypeBet }
}

const GET_TICKET_CENSUS_TYPE_BET_GLOBAL = gql`
  query GetTicketCensusTypeBetGlobal {
    ticketCensusTypeBetGlobal(take: 2, skip: 0) {
      items {
        productId
        isStraightBet
        currencyName
        enterpriseId
      }
    }
  }
`

// const GET_TICKET_CENSUS_TYPE_BET_GLOBAL = gql`
//   query GetTicketCensusTypeBetGlobal($where: TicketCensusTypeBetGlobalFilterInput!, $skip: Int, $take: Int) {
//     ticketCensusTypeBetGlobal(where: $where, take: $take, skip: $skip) {
//       items {
//         productId
//         isStraightBet
//         currencyName
//         enterpriseId
//         eventDate
//         tickets
//         amount
//       }
//     }
//   }
// `

interface TicketCensusTypeBetGlobalData {
  ticketCensusTypeBetGlobal: {
    items: TicketCensusTypeBetGlobal[]
  }
}

export const useTicketCensusTypeBetGlobal = () => {
  const [getResults, { loading, error, data }] = useLazyQuery<TicketCensusTypeBetGlobalData>(
    GET_TICKET_CENSUS_TYPE_BET_GLOBAL,
  )

  const [results, setResults] = useState<ResultsList<TicketCensusTypeBetGlobal, Metadata>>({
    results: [],
    metadata: {},
  })

  const getTicketCensusTypeBetGlobal = useCallback(
    ({ where, take, skip }: SearchGraphql) => {
      getResults({ variables: { where, take, skip } })
    },
    [getResults],
  )

  useEffect(() => {
    setResults({
      results: data?.ticketCensusTypeBetGlobal.items || [],
      metadata: {},
    })
  }, [data?.ticketCensusTypeBetGlobal.items, loading])

  return { loading, error, results, getTicketCensusTypeBetGlobal }
}
