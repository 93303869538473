import { useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { Location } from './models'
import { useLocation, useAddLocation, useUpdateLocation } from './hooks'
import { useParams, useHistory } from 'react-router-dom'
import { AgencyForm } from './AgencyForm'
import { StationList } from '../stations/StationList'
import { useNotification } from '../../../core'
import { useTranslation } from 'react-i18next'
import { differenceBetweenTwoObjects as difference } from '../../../core/services/genericFunctions'
import _ from 'lodash'

export const AgencyDetails = () => {
  const { id } = useParams<{ id: string }>()
  const { data: locationData, getLocation } = useLocation()
  const { addLocation } = useAddLocation()
  const { updateLocation } = useUpdateLocation()
  const location = locationData ? locationData.location : undefined
  const { successNotification, errorNotification } = useNotification()
  const { t: transSystem } = useTranslation('System')
  const history = useHistory()

  useEffect(() => {
    if (id !== undefined) {
      getLocation(Number.parseInt(id))
    }
  }, [id])

  const handleSaveAgency = (locationToSave: Location) => {
    if (locationToSave.id === 0) {
      const response = addLocation({
        variables: {
          input: {
            name: locationToSave.name,
            typeLocationId: locationToSave.typeLocationId,
            address: locationToSave.address,
            phone: locationToSave.phone,
            countryId: locationToSave.countryId,
            provinceId: locationToSave.provinceId,
            municipalityId: locationToSave.municipalityId,
            sectorId: locationToSave.sectorId,
            restrictedByGeography: locationToSave.restrictedByGeography,
          },
        },
      })
      response
        .then((res: any) => {
          const id = _.get(res, 'data.addLocation.location.id', 0)
          if (id > 0) {
            successNotification(transSystem('DATO_REGISTRADO'))
            history.push(`/admin/entities/agencies/${id}`)
          }
        })
        .catch(() => {
          errorNotification(transSystem('ERROR_REGISTRANDO_EL_DATO'))
        })
    } else {
      const locationChanges = difference(locationToSave, location)

      const response = updateLocation({
        variables: {
          id: locationToSave.id,
          input: { ...locationChanges },
        },
      })
      response
        .then((res: any) => {
          successNotification(transSystem('DATO_ACTUALIZADO'))
        })
        .catch(() => {
          errorNotification(transSystem('ERROR_ACTUALIZANDO_EL_DATO'))
        })
    }
  }

  const handleNewStation = () => {
    history.push(`/admin/entities/agencies/${id}/station`)
  }

  const handleEditStation = (station: any) => {
    history.push(`/admin/entities/agencies/${id}/station/${station.id}`)
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <AgencyForm location={location} onSaveAgency={handleSaveAgency} />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        {location && (
          <StationList locationId={location.id} onNewStation={handleNewStation} onSelectedStation={handleEditStation} />
        )}
      </Grid>
    </Grid>
  )
}
