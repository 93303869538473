import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Box, Divider, Grid, Typography } from '@material-ui/core'
import { ReportProblem, NotificationImportant, Info, HelpOutline } from '@material-ui/icons'

// Hooks
import { useTranslation } from 'react-i18next'

type TipoAlerta = 'INFORMACION' | 'ALERTA' | 'ERROR' | 'PREGUNTA'
interface IProps {
  titulo: string
  contenido: string
  tipo: TipoAlerta
  state: boolean
  onPrimaryClick: () => void
  onSecondaryClick?: () => void
  handleClose: () => void
}

export const DialogAlerts = ({
  titulo,
  contenido,
  tipo,
  state,
  onPrimaryClick,
  onSecondaryClick,
  handleClose,
}: IProps) => {
  const { t: translationLabel } = useTranslation('Label')
  const { t: transSystem } = useTranslation('System')

  return (
    <div>
      <Dialog
        open={state}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box padding={1}>
          <Grid container>
            <Grid item xs={2}>
              <Box display="flex" justifyContent="center" alignItems="center" marginTop={3}>
                {tipo === 'ALERTA' && <ReportProblem style={{ fontSize: '4.5rem', color: '#ff8d00' }} />}
                {tipo === 'ERROR' && <NotificationImportant style={{ fontSize: '4.5rem', color: '#e71212' }} />}
                {tipo === 'INFORMACION' && <Info style={{ fontSize: '4.5rem', color: '#00aeff' }} />}
                {tipo === 'PREGUNTA' && <HelpOutline style={{ fontSize: '4.5rem', color: '#818181' }} />}
              </Box>
            </Grid>
            <Grid item xs={10}>
              <DialogTitle id="alert-dialog-title">
                <Typography>
                  <b>{transSystem(titulo)}</b>
                </Typography>
              </DialogTitle>
              <DialogTitle id="alert-dialog-title" style={{ paddingTop: 0, paddingBottom: 0 }}>
                <Divider />
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">{transSystem(contenido)}</DialogContentText>
              </DialogContent>
              <DialogActions>
                {tipo === 'INFORMACION' && (
                  <Button onClick={onPrimaryClick} variant="outlined" color="primary">
                    {translationLabel('ACEPTAR')}
                  </Button>
                )}
                {tipo === 'ALERTA' && (
                  <>
                    <Button onClick={onSecondaryClick} variant="outlined" color="primary" autoFocus>
                      {translationLabel('CANCELAR')}
                    </Button>
                    <Button onClick={onPrimaryClick} variant="outlined" color="secondary">
                      {translationLabel('CONTINUAR')}
                    </Button>
                  </>
                )}
                {tipo === 'ERROR' && (
                  <Button onClick={onSecondaryClick} variant="outlined" color="secondary" autoFocus>
                    {translationLabel('ACEPTAR')}
                  </Button>
                )}
                {tipo === 'PREGUNTA' && (
                  <>
                    <Button onClick={onSecondaryClick} variant="outlined" color="primary" autoFocus>
                      {translationLabel('NO')}
                    </Button>
                    <Button onClick={onPrimaryClick} variant="outlined" color="secondary">
                      {translationLabel('SI')}
                    </Button>
                  </>
                )}
              </DialogActions>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    </div>
  )
}
