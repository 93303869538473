import { Dictionary } from './../models/shared'
import { Enterprise } from './../../scenes/entities/enterprises/models'
import { ActionType, createAction } from 'typesafe-actions'

export const clearAction = createAction('CLEAR')()
export const globalSearchAction = createAction('GLOBAL_SEARCH')<string>()
export const tokenExpiredAction = createAction('TOKEN_EXPIRED')()
export const setUserEnterprise = createAction('SET_USER_ENTERPRISE')<Enterprise>()
export const setEnterprises = createAction('SET_ENTERPRISES')<Array<Enterprise>>()
export const setUserClaims = createAction('SET_USER_CLAIMS')<Dictionary>()

export type Actions =
  | ActionType<typeof clearAction>
  | ActionType<typeof globalSearchAction>
  | ActionType<typeof setUserEnterprise>
  | ActionType<typeof setEnterprises>
  | ActionType<typeof setUserClaims>
