import { useState } from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from 'react-i18next'
import { FormControlLabel, Grid } from '@material-ui/core'
import { RadioButtonChecked, RadioButtonUnchecked } from '@material-ui/icons'
import { AllowedClaimValues, Role, AllowedKey } from '../models'
import { ClaimCheckbox, RoleClaim } from './ClaimCheckbox'
import { useNotification } from '../../../../core/hooks'
import { useAddRoleClaim, useRemoveRoleClaim } from '../hooks'
import { useRemoveRoleClaims } from '../hooks_dir'
import { ClaimActions, useUserClaimActions } from '../../../../core'

import _ from 'lodash'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headingTitle: {
      fontSize: theme.typography.pxToRem(15),
      flexShrink: 0,
    },
    checkbox: {
      marginTop: -10,
    },
  }),
)

interface RemovedClaim {
  type: string
  value: string
}

type PermissionRoleScopeClaimsProps = {
  role: Role
  title: string
  claimType: string
  allowed: Array<AllowedClaimValues> | undefined
  values: Array<AllowedKey>
}

export const PermissionRoleScopeClaims = ({
  title,
  allowed,
  values,
  claimType,
  role,
}: PermissionRoleScopeClaimsProps) => {
  const classes = useStyles()
  const { validateClaimActions } = useUserClaimActions()
  const actions = validateClaimActions('security.profiles', [ClaimActions.CanEdit])
  const { t: transMenu } = useTranslation('Menu')
  const { t: systemLabel } = useTranslation('System')

  const [claimValues, setClaimValues] = useState<Array<AllowedKey>>(values)
  const { addRoleClaim } = useAddRoleClaim()
  const { removeRoleClaim } = useRemoveRoleClaim()
  const { removeRoleClaims } = useRemoveRoleClaims()
  const { successNotification, errorNotification, warningNotification } = useNotification()

  const checkIfAllowed = (allowedClaim: string): boolean => {
    return allowed !== undefined
      ? allowed?.some((item) => {
          return item.key === allowedClaim
        })
      : false
  }

  const getClaimCheckbox = (allowedClaim: string) => {
    const isClaimed =
      claimValues !== undefined &&
      claimValues.some((item) => {
        return item === allowedClaim
      })
        ? true
        : false

    return (
      <ClaimCheckbox
        disabled={!actions.canEdit}
        roleClaim={{ claimValue: allowedClaim, claimType: claimType, roleName: role.name }}
        customColor={checkIfAllowed(allowedClaim) ? 'green' : isClaimed ? 'grey' : 'primary'}
        isClaimed={isClaimed}
        icon={<RadioButtonUnchecked />}
        checkedIcon={<RadioButtonChecked />}
        name="checkedH"
        size={'small'}
        addClaim={addClaim}
        removeClaim={removeClaim}
      />
    )
  }

  const addClaim = async (roleClaim: RoleClaim) => {
    try {
      const { data } = await addRoleClaim({
        variables: {
          addRoleClaim: { ...roleClaim },
        },
      })
      if (data.addRoleClaim.succeeded) {
        successNotification(systemLabel('ROLE_CLAIM_AGREGADO'))
        if (roleClaim.claimValue !== 'None') {
          await removeNoneClaim(roleClaim)
        } else {
          await removeClaims(roleClaim)
          refreshCheckbox(true, roleClaim)
        }
      } else {
        errorNotification(systemLabel('ERROR_AGREGANDO_EL_ROLE'))
      }
    } catch (err) {
      errorNotification(systemLabel('ERROR_INESPERADO_SALVANDO_EL_ROLE'))
    }
    return Promise.resolve(true)
  }

  const removeClaim = async (roleClaim: RoleClaim) => {
    try {
      const { data } = await removeRoleClaim({
        variables: {
          removeRoleClaim: { ...roleClaim },
        },
      })
      if (data.removeRoleClaim.succeeded) {
        warningNotification(systemLabel('ROLE_CLAIM_ELIMINADO'))
      } else {
        errorNotification(systemLabel('ERROR_ELIMINADO_EL_ROLE'))
      }

      refreshCheckbox(false, roleClaim)
    } catch (err) {
      errorNotification(systemLabel('ERROR_INESPERADO_SALVANDO_EL_ROLE'))
    }
    return Promise.resolve(true)
  }

  const removeClaims = async (roleClaim: RoleClaim) => {
    try {
      const claims: RemovedClaim[] = []
      claimValues.forEach((item) => {
        if (item !== 'None') {
          claims.push({ type: roleClaim.claimType, value: item })
        }
      })

      await removeRoleClaims({
        variables: {
          removeRoleClaims: {
            roleName: roleClaim.roleName,
            claims: claims,
          },
        },
      })
    } catch (err) {
      errorNotification(systemLabel('ERROR_INESPERADO_SALVANDO_EL_ROLE'))
    }
    return Promise.resolve(true)
  }

  const removeNoneClaim = async (roleClaim: RoleClaim) => {
    const { data } = await removeRoleClaim({
      variables: {
        removeRoleClaim: {
          claimType: roleClaim.claimType,
          claimValue: 'None',
          roleName: roleClaim.roleName,
        },
      },
    })
    if (data.removeRoleClaim.succeeded) {
      let claimValuesCloned = _.cloneDeep(claimValues)
      _.pull(claimValuesCloned, 'None')
      claimValuesCloned.push(roleClaim.claimValue as AllowedKey)
      setClaimValues(claimValuesCloned)
    }

    return Promise.resolve(true)
  }

  const refreshCheckbox = (value: boolean, roleClaim: RoleClaim) => {
    let claimValuesCloned = _.cloneDeep(claimValues)
    if (value) {
      if (roleClaim.claimValue === 'None') {
        claimValuesCloned = ['None']
      } else {
        claimValuesCloned.push(roleClaim.claimValue as AllowedKey)
      }
    } else {
      _.pull(claimValuesCloned, roleClaim.claimValue)
    }
    setClaimValues(claimValuesCloned)
  }

  return (
    <Grid container>
      <Grid item sm={12} xs={12} md={2} lg={2}>
        <Typography className={classes.headingTitle}>{transMenu(title)}</Typography>
      </Grid>
      <Grid item sm={12} xs={12} md={1} lg={1} style={{ textAlign: 'end', marginLeft: 38 }}>
        <FormControlLabel className={classes.checkbox} control={getClaimCheckbox('CanView')} label="" />
      </Grid>
      <Grid item sm={12} xs={12} md={1} lg={1} style={{ textAlign: 'end', marginLeft: 53 }}>
        <FormControlLabel className={classes.checkbox} control={getClaimCheckbox('CanEdit')} label="" />
      </Grid>
      <Grid item sm={12} xs={12} md={1} lg={1} style={{ textAlign: 'end', marginLeft: 55 }}>
        <FormControlLabel className={classes.checkbox} control={getClaimCheckbox('CanCreate')} label="" />
      </Grid>
      <Grid item sm={12} xs={12} md={1} lg={1} style={{ textAlign: 'end', marginLeft: 52 }}>
        <FormControlLabel className={classes.checkbox} control={getClaimCheckbox('CanDelete')} label="" />
      </Grid>
      <Grid item sm={12} xs={12} md={1} lg={1} style={{ textAlign: 'end', marginLeft: 55 }}>
        <FormControlLabel className={classes.checkbox} control={getClaimCheckbox('CanPrint')} label="" />
      </Grid>
      <Grid item sm={12} xs={12} md={1} lg={1} style={{ textAlign: 'end', marginLeft: 55 }}>
        <FormControlLabel className={classes.checkbox} control={getClaimCheckbox('CanSearch')} label="" />
      </Grid>
      <Grid item sm={12} xs={12} md={1} lg={1} style={{ textAlign: 'end', marginLeft: 52 }}>
        <FormControlLabel className={classes.checkbox} control={getClaimCheckbox('None')} label="" />
      </Grid>
    </Grid>
  )
}
