import React from 'react'
import { MoneyCard } from '../../../core/components'
import { useTranslation } from 'react-i18next'
import { Dictionary } from '../../../core/models'
import { formatAsCurrency } from '../../../core'
import { Table, TableBody, TableCell, TableRow, TableContainer, Paper } from '@material-ui/core'

type OperationLocationsResultsProps = {
  data: Dictionary
}

export const OperationLocationsResults = ({ data }: OperationLocationsResultsProps) => {
  const { t: translationLabel } = useTranslation('Label')

  let totals = []
  for (let [key, value] of Object.entries(data)) {
    totals.push(
      <TableCell component="th" scope="row" key={key}>
        <MoneyCard
          title={translationLabel(key)}
          balance={<span>{formatAsCurrency(value !== undefined ? Number.parseInt(value.toString()) : 0, 0)}</span>}
        />
      </TableCell>,
    )
  }

  return (
    <TableContainer component={Paper}>
      <Table aria-label="custom pagination table">
        <TableBody>
          <TableRow key={1}>{totals}</TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}
