import React, { ReactElement } from 'react'
import { Box, Card, makeStyles, createStyles, Theme, Typography } from '@material-ui/core'
import { ArrowDownward, ArrowUpward } from '@material-ui/icons'

// Hooks
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardStyle: {
      padding: theme.spacing(2),
      width: '100%',
    },
  }),
)
interface IProps {
  value: string | number
  colorValue?: string
  Icon?: ReactElement
  percentage?: number
  titleValue: string
  percentageTitle: string
}

export const ValuePlusIncreaseDecreased = ({ value, percentage, titleValue, percentageTitle, Icon }: IProps) => {
  const classes = useStyles()
  const { t: translation } = useTranslation('Label')

  return (
    <Card className={classes.cardStyle}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Typography
            style={{
              color: '#9e9e9e',
              fontSize: '14px',
              marginBottom: 5,
            }}
          >
            {translation(titleValue)}
          </Typography>
          <Typography
            variant="h4"
            component="h3"
            style={{
              marginBottom: 15,
              fontSize: '1.5rem',
              fontWeight: 500,
            }}
          >
            {value}
          </Typography>
        </Box>
        {Icon}
      </Box>
      {percentage !== undefined ? (
        <Box
          display="flex"
          alignItems="center"
          style={{
            color: percentage <= 0 ? '#ff0000' : '#20c248',
          }}
        >
          {percentage <= 0 ? <ArrowDownward /> : <ArrowUpward />}

          <Typography
            style={{
              marginRight: '10px',
            }}
          >
            {percentage}%
          </Typography>
          <Typography
            style={{
              color: '#8d8d8d',
              fontSize: '14px',
            }}
          >
            {translation(percentageTitle)}
          </Typography>
        </Box>
      ) : null}
    </Card>
  )
}
