import { Fragment, ChangeEvent, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Button,
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Slider,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { useNotification } from '../../../core'
import _lodash from 'lodash'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
import { generateFilterGraphql } from '../../../core'
import { User } from '../../../scenes/security/users/models'
import { useUsers } from '../../security/users/hooks/useUsers'
import { useTranslation } from 'react-i18next'
import { WorkstationRegistrationCode } from './models'
import { useAddGenerateWorkstationCode } from './hooks'
import { CustomAutoCompleteSelect } from '../../../core/components'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import _ from 'lodash'

const useStyles = makeStyles({
  root: {
    '& .MuiPaper-root': {
      minWidth: 800,
    },
  },
  userErrorText: {
    color: '#f44336',
  },
  copyButton: {
    marginTop: -5,
  },
  footerButton: {
    textAlign: 'end',
    marginTop: 'auto',
  },
  codeContent: {
    display: 'inline-flex',
  },
})

// const marks = [
//   {
//     value: 1,
//     label: '1M',
//   },
//   {
//     value: 15,
//     label: '15M',
//   },
//   {
//     value: 30,
//     label: '30M',
//   },
//   {
//     value: 60,
//     label: '1 Hora',
//   },
// ]

const marks = [
  {
    value: 1440,
    label: '1D',
  },
  {
    value: 7200,
    label: '5D',
  },
  {
    value: 14400,
    label: '10D',
  },
  {
    value: 21600,
    label: '15D',
  },
]
type StationCodeGeneratorProps = {
  isOpen: boolean
  workstationId: number
  onClose: () => void
  afterGenerateCode: (code: string) => void
}

const generatorInitialValues = {
  owner: '',
  workstationId: 0,
  lifetimeInSeconds: 60,
}

interface GeneratedCodeResponse {
  generateWorstationRegistrationCode: {
    registrationCode: string
  }
}

export const StationCodeGenerator = ({
  isOpen,
  onClose,
  afterGenerateCode,
  workstationId,
}: StationCodeGeneratorProps) => {
  const classes = useStyles()
  const [isUnselectedUser, setIsUnselectedUser] = useState(false)
  const [generatorValues, setGenerator] = useState<WorkstationRegistrationCode>(generatorInitialValues)
  const { getUsers, results, loading: loadingUsers } = useUsers()
  const { t: translationLabel } = useTranslation('Label')
  const { addGenerateWorkstationCode } = useAddGenerateWorkstationCode()
  const { successNotification, errorNotification } = useNotification()
  const [generatedCode, setGeneratedCode] = useState('')
  const [open, setOpen] = useState(false)

  useEffect(() => {
    let generatorFormClone = _lodash.cloneDeep(generatorValues)
    generatorFormClone.workstationId = workstationId
    setGenerator(generatorFormClone)
  }, [workstationId])

  useEffect(() => {
    filterUsers('')
  }, [])

  const filterUsers = (user: string, skip = 0, take = 10) => {
    const customFilters = {
      and: [{ userName: { ...generateFilterGraphql(user, 'contains') } }],
    }

    getUsers({ where: customFilters, skip, take })
  }

  const userData = results.results.map((item: User) => item)

  const onSelectUser = (user: User, action: string) => {
    if (action == 'clear') {
      filterUsers('')
    }
    if (action === 'select-option') {
      let generatorFormClone = _lodash.cloneDeep(generatorValues)
      generatorFormClone.owner = user.id ? user.id : ''
      setGenerator(generatorFormClone)
      setIsUnselectedUser(true)
      setGeneratedCode('')
    }
  }

  const onInputChangeUser = (value: string) => {
    filterUsers(value)
  }

  const onSliderChange = (event: ChangeEvent<{}>, value: number | number[]) => {
    const generatorFormClone = _lodash.cloneDeep(generatorValues)
    generatorFormClone.lifetimeInSeconds = (value as number) * 60
    setGenerator(generatorFormClone)
  }

  const valuetext = (value: number) => {
    return `${value} Min`
  }

  const labelText = (value: number) => {
    return `${value < 1 ? '1' : value}M`
  }

  const onGenerate = async () => {
    try {
      const response = await addGenerateWorkstationCode({
        variables: {
          input: generatorValues,
        },
      })

      const code = (response.data as GeneratedCodeResponse).generateWorstationRegistrationCode.registrationCode
      setGeneratedCode(code)
      if (afterGenerateCode instanceof Function) {
        afterGenerateCode(code)
      }
    } catch (er) {
      errorNotification(translationLabel('ERROR_GENERANDO_EL_CODIGO'))
    }
  }

  const closeGenerator = () => {
    setGeneratedCode('')
    onClose()
  }

  return (
    <Dialog open={isOpen} onClose={onClose} aria-labelledby="form-dialog-title" className={classes.root}>
      <DialogTitle id="form-dialog-title">{translationLabel('GENERAR_CODIGO')}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6} lg={6} md={6}>
            <CustomAutoCompleteSelect
              data={userData}
              keyValue={'userName'}
              isLoading={loadingUsers}
              label={'Usuarios'}
              onChangeValue={onSelectUser}
              onInputValueChange={onInputChangeUser}
            />
          </Grid>
          <Grid item xs={6} sm={6} lg={6} md={6}>
            <Typography id="discrete-slider-restrict" gutterBottom>
              {translationLabel('TIEMPO_EXPIRACION')}
            </Typography>
            <Slider
              onChange={onSliderChange}
              defaultValue={1}
              getAriaValueText={valuetext}
              valueLabelFormat={labelText}
              aria-labelledby="discrete-slider-custom"
              step={7200}
              valueLabelDisplay="auto"
              marks={marks}
              min={1440}
              max={21600}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} lg={8} md={8}>
            {generatedCode.length > 0 && (
              <Fragment>
                <Typography color="textSecondary" gutterBottom>
                  {translationLabel('CODIGO')}:
                </Typography>
                <div className={classes.codeContent}>
                  <Typography color="textSecondary" gutterBottom>
                    {generatedCode}
                  </Typography>
                  <ClickAwayListener onClickAway={() => setOpen(false)}>
                    <Tooltip
                      PopperProps={{
                        disablePortal: true,
                      }}
                      onClose={() => setOpen(false)}
                      open={open}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      title={translationLabel('COPIADO') as string}
                    >
                      <CopyToClipboard text={generatedCode ? generatedCode : ''} onCopy={closeGenerator}>
                        <Button className={classes.copyButton}>
                          <FileCopyOutlinedIcon color={'primary'} />
                        </Button>
                      </CopyToClipboard>
                    </Tooltip>
                  </ClickAwayListener>
                </div>
              </Fragment>
            )}
          </Grid>

          <Grid item xs={12} sm={12} lg={4} md={4} className={classes.footerButton}>
            <Button onClick={closeGenerator} color="primary">
              Cancel
            </Button>
            <Button onClick={onGenerate} color="primary" disabled={!isUnselectedUser}>
              {translationLabel('GENERAR_CODIGO')}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}
