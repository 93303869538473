import { useEffect } from 'react'
import { Grid, makeStyles, createStyles, Typography } from '@material-ui/core'
import { PasswordResetAnimation, SimpleHeader } from '../../../../core/components'
import { useTranslation } from 'react-i18next'
import { PasswordResetForm } from './PasswordResetForm'
import { Link } from 'react-router-dom'
import { PasswordReset as PasswordResetModel } from '../models'
import { usePasswordReset } from '../hooks'
import _ from 'lodash'
import { Alert, AlertTitle } from '@material-ui/lab'

const useStyles = makeStyles(() =>
  createStyles({
    animation: {
      '& #animation-id': {
        marginLeft: 'auto',
        maxWidth: 'inherit',
      },
    },
    formContainer: {
      marginTop: 50,
    },
    succeededChangePasswordLabel: {
      color: '#3f51b5',
    },
    errorList: {
      listStyleType: 'square',
      wordBreak: 'break-all',
    },
  }),
)

export const PasswordReset = () => {
  const { t: transLabel } = useTranslation('Label')
  const { resetPassword, response } = usePasswordReset()
  const classes = useStyles()

  useEffect(() => {
    if (response.result === 'Success') {
      setTimeout(() => {
        window.location.pathname = '/admin/statistics/sales-profit-dashboard'
      }, 3000)
    }
  }, [response])

  const handleSave = (data: PasswordResetModel) => {
    resetPassword({
      password: data.password,
      passwordConfirmation: data.passwordConfirmation,
      userName: data.userName,
      passwordResetCode: data.passwordResetCode,
    })
  }

  return (
    <SimpleHeader>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6} className={classes.animation}>
          <PasswordResetAnimation />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} className={classes.formContainer}>
          {response.result !== 'Success' ? (
            <PasswordResetForm onSave={handleSave} />
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6} className={classes.animation}>
                <Typography variant="h5" className={classes.succeededChangePasswordLabel}>
                  {transLabel('CAMBIO_CLAVE_EXITOSO')}
                </Typography>
              </Grid>
            </Grid>
          )}

          {response.errors.length > 0 && (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6} className={classes.animation}>
                <Alert severity="error">
                  <AlertTitle>{transLabel('ALERTA')}</AlertTitle>
                  <ul className={classes.errorList}>
                    {response.errors.map((err: string, index: number) => (
                      <li key={index}>{transLabel(err)}</li>
                    ))}
                  </ul>
                </Alert>
              </Grid>
            </Grid>
          )}

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6} className={classes.animation}>
              <Link to={'/admin/statistics/sales-profit-dashboard'}>{transLabel('INICIAR_SESION')}</Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </SimpleHeader>
  )
}
