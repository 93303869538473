import React, { useEffect, useState } from 'react'
import {
  Grid,
  makeStyles,
  createStyles,
  Theme,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Button,
} from '@material-ui/core'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import {
  HeaderCardTitle,
  SaveButton,
  AppBreadcrumbsBar,
  ModifyButton,
  DialogAlerts,
  CustomFormikTimePicker,
  CustomAutoCompleteSelectFormik,
} from '../../../core/components'
import { useTranslation } from 'react-i18next'
import { useFormik, Field, Form, FormikProvider } from 'formik'
import { TextField } from 'formik-material-ui'
// import { provider } from './models'
import * as Yup from 'yup'
// import { useproviderTypes, useprovider } from './hooks'
import { useHistory } from 'react-router-dom'
import { useLocalizedContent, useTimeZones, useUserClaimActions, ClaimActions } from '../../../core'
import { Provider } from './models'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiAutocomplete-root': {
        width: 'auto',
      },
    },
    fieldItem: {
      marginBottom: theme.spacing(2),
    },
    buttonActions: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    labelEditActive: {
      color: '#ff6600',
    },
    labelEditDisabled: {
      color: '#000000',
    },
    divider: {
      marginTop: -22,
    },
    addressDiv: {
      paddingTop: 5,
    },
  }),
)

const today = new Date()
today.setHours(0, 0, 0, 0)

const initialFormValues = {
  id: 0,
  name: '',
  acronym: '',
  description: '',
  generalInfo: '',
  openingTime: today,
  closingTime: today,
  currencyId: 0,
  timeZone: '',
  isVirtualGame: true,
  isActive: false,
}

type ProviderFormProps = {
  onSave: (values: Provider) => void
  provider?: Provider | undefined
}

export const ProviderForm = ({ provider, onSave }: ProviderFormProps) => {
  const isCreating = provider && provider.id && provider.id > 0 ? false : true
  const classes = useStyles()
  const [isEditing, setIsEditing] = useState(isCreating)
  const [alertForm, setAlertForm] = useState(false)
  const { t: transLabel } = useTranslation('Label')
  const { getTimeZones, results: timeZones } = useTimeZones()
  const history = useHistory()
  const { validateClaimActions } = useUserClaimActions()
  const actions = validateClaimActions('configuration.connections', [ClaimActions.CanCreate, ClaimActions.CanEdit])

  const formSchema = Yup.object().shape({
    name: Yup.string().required(transLabel('REQUERIDO')),
    timeZone: Yup.string().nullable().required(transLabel('REQUERIDO')),
  })

  useEffect(() => {
    getTimeZones(0, 300)
  }, [])

  const formik = useFormik({
    validationSchema: formSchema,
    initialValues: provider || initialFormValues,
    onSubmit: onSave,
    enableReinitialize: true,
  })

  window.sessionStorage.setItem('hasFormChanges', formik.dirty ? 'true' : 'false')

  const goToProviders = () => {
    if (formik.dirty === false) {
      history.push(`/admin/entities/providers`)
    } else {
      setAlertForm(true)
    }
  }

  let defaultTimeZoneValue = null
  if (provider && timeZones.results.length > 0) {
    const timeZoneList = timeZones.results.filter((item) => item.code == provider.timeZone)
    defaultTimeZoneValue = timeZoneList[0]
  }

  return (
    <FormikProvider value={formik}>
      <Form>
        <Grid item xs={12}>
          <AppBreadcrumbsBar
            title={transLabel('PROVEEDOR_TITULO')}
            description={transLabel('PROVEEDOR_SUB_TITULO')}
            actions={
              <React.Fragment>
                {isEditing === false && actions.canEdit ? (
                  <ModifyButton className={classes.buttonActions} onClick={() => setIsEditing(true)} />
                ) : (
                  (actions.canCreate || actions.canEdit) && (
                    <SaveButton
                      className={classes.buttonActions}
                      onClick={formik.submitForm}
                      isLoading={false}
                      disabled={!(formik.dirty && formik.dirty)}
                    />
                  )
                )}
                <Button
                  variant="contained"
                  onClick={goToProviders}
                  startIcon={<KeyboardBackspaceIcon className={classes.buttonActions} />}
                >
                  {transLabel('REGRESAR')}
                </Button>
              </React.Fragment>
            }
          />
        </Grid>

        <Card>
          <CardHeader title={<HeaderCardTitle title={transLabel('DATOS_DEL_PROVEEDOR')} />} />
          <Divider className={classes.divider} />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Field
                      component={TextField}
                      type="text"
                      label={transLabel('NOMBRE')}
                      name="name"
                      fullWidth
                      disabled={!isEditing}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Field
                      component={TextField}
                      type="text"
                      label={transLabel('ACRONIMO')}
                      name="acronym"
                      fullWidth
                      disabled={!isEditing}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Field
                      component={TextField}
                      type="text"
                      label={transLabel('DESCRIPCION')}
                      name="description"
                      fullWidth
                      disabled={!isEditing}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Field
                      fullWidth
                      component={CustomAutoCompleteSelectFormik}
                      label={transLabel('ZONA_HORARIA')}
                      data={timeZones.results}
                      name="timeZone"
                      keyValue={'code'}
                      keyLabel={'code'}
                      defaultValue={defaultTimeZoneValue}
                      disabled={!isEditing}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Field
                      component={TextField}
                      type="text"
                      label={transLabel('INFORMACION_GENERAL')}
                      name="generalInfo"
                      fullWidth
                      disabled={!isEditing}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Field
                      component={CustomFormikTimePicker}
                      type="text"
                      label={transLabel('HORA_DE_APERTURA')}
                      name="openingTime"
                      fullWidth
                      disabled={!isEditing}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Field
                      component={CustomFormikTimePicker}
                      type="text"
                      label={transLabel('HORA_DE_CIERRE')}
                      name="closingTime"
                      fullWidth
                      disabled={!isEditing}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <DialogAlerts
          state={alertForm}
          titulo={'SE_HAN_DETECTADO_CAMBIOS_EN_EL_FORMULARIO'}
          contenido={'SI_CONTINUA_PERDERA_LOS_CAMBIOS_EN_EL_FORMULARIO_DESEA_CONTINUAR'}
          tipo="PREGUNTA"
          onSecondaryClick={() => setAlertForm(false)}
          handleClose={() => setAlertForm(false)}
          onPrimaryClick={() => history.push(`/admin/entities/providers`)}
        />
      </Form>
    </FormikProvider>
  )
}
