import { gql, useLazyQuery, useQuery } from '@apollo/client'
import { ProgramTrainer } from '../../models'

const GET_TRAINER = gql`
  query GetProgramTrainer($id: Int!) {
    programTrainer(id: $id) {
      id
      name
      isActive
    }
  }
`

interface TrainerData {
  programTrainer: ProgramTrainer
}

export const useProgramTrainer = () => {
  const [get, { loading, error, data }] = useLazyQuery<TrainerData>(GET_TRAINER, {
    fetchPolicy: 'network-only',
  })

  const getProgramTrainer = (id: number) => {
    get({
      variables: {
        id: id,
      },
    })
  }

  const trainer = data?.programTrainer || undefined
  return { getProgramTrainer, loading, error, trainer }
}
