import { CustomDrawerFloating } from './CustomDrawerFloating'
import { CustomMiniDrawer } from './CustomMiniDrawer'

type ManageDrawerProps = {
  children: JSX.Element | JSX.Element[]
  type: 'mini' | 'desktop' | 'mobile'
  open: boolean
}

export const ManageDrawer = ({ type, open, children }: ManageDrawerProps) => {
  switch (type) {
    case 'desktop':
      return <CustomMiniDrawer open={open}>{children}</CustomMiniDrawer>
    case 'mini':
      return <CustomMiniDrawer open={open}>{children}</CustomMiniDrawer>
    case 'mobile':
      return <CustomDrawerFloating open={open}>{children}</CustomDrawerFloating>
    default:
      return <CustomDrawerFloating open={open}>{children}</CustomDrawerFloating>
  }
}
