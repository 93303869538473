import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
  makeStyles,
  createStyles,
  Theme,
  Button,
} from '@material-ui/core'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'

import { useTranslation } from 'react-i18next'
import {
  HeaderCardTitle,
  AppBreadcrumbsBar,
  SaveButton,
  AddTitle,
  DialogAlerts,
  BooleanRadioButton,
} from '../../../core/components'
import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-material-ui'

import { Enterprise as EnterpriseModel } from './models'
import { useCreateEnterprise, useUserEnterprises } from './hooks'
import { useHistory } from 'react-router-dom'
import { AppState, useNotification, useUserClaimActions, ClaimActions, setEnterprises } from '../../../core'
import { useDispatch, useSelector } from 'react-redux'

const initialValues: EnterpriseModel = {
  name: '',
  active: true,
  lineMasterId: 1,
  languageId: 1,
  isLimitOpenningControl: true,
  domain: '',
  subdomain: '',
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonActions: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    labelEditActive: {
      color: '#ff6600',
    },
    labelEditDisabled: {
      color: '#000000',
    },
  }),
)

export const Enterprise = () => {
  const classes = useStyles()
  const history = useHistory()
  const { createEnterprise, loading, error } = useCreateEnterprise()
  const [alertForm, setAlertForm] = useState(false)
  const { t: transSystem } = useTranslation('Label')
  const { t: translationLabel } = useTranslation('Label')
  const { t: translationAction } = useTranslation('Action')
  const { successNotification, errorNotification } = useNotification()
  const { validateClaimActions } = useUserClaimActions()
  const actions = validateClaimActions('configuration.enterprises', [ClaimActions.CanCreate, ClaimActions.CanView])
  const { results: userEnterprises, getUserEnterprises } = useUserEnterprises()
  const dispatch = useDispatch()

  useEffect(() => {
    if (userEnterprises.length > 0) {
      dispatch(setEnterprises(userEnterprises))
    }
  }, [userEnterprises])

  const formSchema = Yup.object().shape({
    name: Yup.string().required(translationLabel('REQUERIDO')),
    domain: Yup.string()
      .matches(
        /^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/,
        translationLabel('VALOR_NO_VALIDO'),
      )
      .required(translationLabel('REQUERIDO')),
    subdomain: Yup.string().required(translationLabel('REQUERIDO')),
    active: Yup.boolean(),
  })

  return (
    <Grid container>
      <Formik
        validationSchema={formSchema}
        initialValues={{ ...initialValues }}
        onSubmit={(values: EnterpriseModel, { setSubmitting }) => {
          setSubmitting(true)
          const response = createEnterprise({
            variables: {
              input: {
                name: values.name,
                active: values.active,
                domain: values.domain,
                subdomain: values.subdomain,
              },
            },
          })
          response
            .then((res) => {
              getUserEnterprises()
              setTimeout(() => {
                history.push(`/admin/entities/enterprises`)
              }, 1000)
              successNotification(transSystem('DATO_ACTUALIZADO'))
            })
            .catch((e) => {
              errorNotification(transSystem('ERROR_REGISTRANDO_EL_DATO'))
            })
        }}
        enableReinitialize
      >
        {({ submitForm, isValid, dirty, isSubmitting }) => {
          const goToEnterprises = () => {
            if (dirty === false) {
              history.push(`/admin/entities/enterprises`)
            } else {
              setAlertForm(true)
            }
          }
          return (
            <Grid item xs={12}>
              <Grid item xs={12}>
                <AppBreadcrumbsBar
                  title={translationLabel('CONSORCIO_TITULO')}
                  description={translationLabel('CONSORCIO_SUB_TITULO')}
                  actions={
                    <React.Fragment>
                      {actions.canCreate && (
                        <SaveButton
                          className={classes.buttonActions}
                          onClick={submitForm}
                          disabled={!(dirty && isValid)}
                          isLoading={false}
                        />
                      )}
                      <Button
                        className={classes.buttonActions}
                        variant="contained"
                        onClick={goToEnterprises}
                        startIcon={<KeyboardBackspaceIcon />}
                      >
                        {translationAction('REGRESAR')}
                      </Button>
                    </React.Fragment>
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <Card>
                  <Box display="flex" justifyContent="space-between" alignItems="center" mr={1}>
                    <CardHeader component={() => <HeaderCardTitle title={<AddTitle title={'CONSORCIO_TITULO'} />} />} />

                    <Typography variant="h6" component="h6">
                      <b>
                        {loading
                          ? translationLabel('CREANDO')
                          : isSubmitting === true
                          ? error === undefined
                            ? translationLabel('DATO_REGISTRADO')
                            : translationLabel('ERROR_REGISTRANDO_EL_DATO')
                          : ''}
                      </b>
                    </Typography>
                  </Box>

                  <Divider />
                  <CardContent>
                    <Form>
                      <Grid container spacing={3}>
                        <Grid item lg={6} xs={12} sm={12}>
                          <Field
                            component={TextField}
                            type="text"
                            label={translationLabel('CONSORCIO')}
                            name="name"
                            fullWidth
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={3}>
                        <Grid item lg={6} xs={12} sm={12}>
                          <Field
                            component={TextField}
                            type="text"
                            label={translationLabel('SUB_DOMINIO')}
                            name="subdomain"
                            fullWidth
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={3}>
                        <Grid item lg={6} xs={12} sm={12}>
                          <Field
                            component={TextField}
                            type="text"
                            label={translationLabel('DOMINIO')}
                            name="domain"
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item lg={2} xs={12} sm={2}>
                          <Field
                            title="ESTADO"
                            component={BooleanRadioButton}
                            name="active"
                            label1={'ACTIVO'}
                            label2={'INACTIVO'}
                            value1={'active'}
                            value2={'inactive'}
                          />
                        </Grid>
                      </Grid>
                    </Form>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          )
        }}
      </Formik>
      <DialogAlerts
        state={alertForm}
        titulo={'SE_HAN_DETECTADO_CAMBIOS_EN_EL_FORMULARIO'}
        contenido={'SI_CONTINUA_PERDERA_LOS_CAMBIOS_EN_EL_FORMULARIO_DESEA_CONTINUAR'}
        tipo="PREGUNTA"
        onSecondaryClick={() => setAlertForm(false)}
        handleClose={() => setAlertForm(false)}
        onPrimaryClick={() => history.push(`/admin/entities/enterprises`)}
      />
    </Grid>
  )
}
