import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'

/**
 * ---------------------------------------
 * This demo was created using amCharts 4.
 *
 * For more information visit:
 * https://www.amcharts.com/
 *
 * Documentation is available at:
 * https://www.amcharts.com/docs/v4/
 * ---------------------------------------
 */

// Themes begin
am4core.useTheme(am4themes_animated)
// Themes end

export function amChartRace(chartData, keyName, keyValue, keyStart) {
  // var allData = merge(chartData)
  var allData = chartData

  var count = 0
  var currentKey = 0
  var dataKeys = Object.keys(allData)

  var min = keyStart
  var chart = am4core.create('chartdiv', am4charts.XYChart)
  chart.padding(40, 40, 40, 40)

  chart.numberFormatter.bigNumberPrefixes = [
    { number: 1e3, suffix: 'K' },
    { number: 1e6, suffix: 'M' },
    { number: 1e9, suffix: 'B' },
  ]

  var label = chart.plotContainer.createChild(am4core.Label)
  label.x = am4core.percent(97)
  label.y = am4core.percent(95)
  label.horizontalCenter = 'right'
  label.verticalCenter = 'middle'
  label.dx = -15
  label.fontSize = 50

  var playButton = chart.plotContainer.createChild(am4core.PlayButton)
  playButton.x = am4core.percent(97)
  playButton.y = am4core.percent(95)
  playButton.dy = -2
  playButton.verticalCenter = 'middle'
  playButton.events.on('toggled', function (event) {
    if (event.target.isActive) {
      play()
    } else {
      stop()
    }
  })

  var stepDuration = 4000

  var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis())
  categoryAxis.renderer.grid.template.location = 0
  categoryAxis.dataFields.category = keyName
  categoryAxis.renderer.minGridDistance = 1
  categoryAxis.renderer.inversed = true
  categoryAxis.renderer.grid.template.disabled = true

  var valueAxis = chart.xAxes.push(new am4charts.ValueAxis())
  valueAxis.min = 0
  valueAxis.rangeChangeEasing = am4core.ease.linear
  valueAxis.rangeChangeDuration = stepDuration
  valueAxis.extraMax = 0.1

  var series = chart.series.push(new am4charts.ColumnSeries())
  series.dataFields.categoryY = keyName
  series.dataFields.valueX = keyValue
  series.tooltipText = '{valueX.value}'
  series.columns.template.strokeOpacity = 0
  series.columns.template.column.cornerRadiusBottomRight = 5
  series.columns.template.column.cornerRadiusTopRight = 5
  series.interpolationDuration = stepDuration
  series.interpolationEasing = am4core.ease.linear

  var labelBullet = series.bullets.push(new am4charts.LabelBullet())
  labelBullet.label.horizontalCenter = 'right'
  labelBullet.label.text = "{values.valueX.workingValue.formatNumber('#.0as')}"
  labelBullet.label.textAlign = 'end'
  labelBullet.label.dx = -10

  chart.zoomOutButton.disabled = true

  // as by default columns of the same series are of the same color, we add adapter which takes colors from chart.colors color set
  series.columns.template.adapter.add('fill', function (fill, target) {
    return chart.colors.getIndex(target.dataItem.index)
  })

  var year = min
  label.text = allData[min][0].label

  var interval
  var restart = false

  function play() {
    if (restart === true) {
      count = 0
      clearInterval(interval)
    }
    interval = setInterval(function () {
      nextYear()
    }, stepDuration)
    nextYear()
  }

  function stop() {
    if (interval) {
      clearInterval(interval)
    }
  }

  function nextYear() {
    currentKey = dataKeys[count]
    if (currentKey === Object.keys(chartData).pop()) {
      playButton.isActive = false
    }

    var newData = allData[currentKey]
    var itemsWithNonZero = 0

    if (newData !== undefined) {
      for (var i = 0; i < chart.data.length; i++) {
        chart.data[i][keyValue] = newData[i][keyValue]
        if (chart.data[i][keyValue] > 0) {
          label.text = newData[i].label.toString()
          itemsWithNonZero++
        }
      }
      count++
      restart = false
    } else {
      restart = true
      series.interpolationDuration = stepDuration / 4
      valueAxis.rangeChangeDuration = stepDuration / 4
    }

    // if (dataKeys[count] == lastKey) {
    //   series.interpolationDuration = stepDuration / 4
    //   valueAxis.rangeChangeDuration = stepDuration / 4
    // } else {
    //   series.interpolationDuration = stepDuration
    //   valueAxis.rangeChangeDuration = stepDuration
    // }

    series.interpolationDuration = stepDuration
    valueAxis.rangeChangeDuration = stepDuration

    chart.invalidateRawData()

    categoryAxis.zoom({ start: 0, end: itemsWithNonZero / categoryAxis.dataItems.length })
  }

  // function nextYear() {
  //   year++

  //   if (year > max) {
  //     year = min
  //   }

  //   var newData = allData[year]
  //   var itemsWithNonZero = 0
  //   for (var i = 0; i < chart.data.length; i++) {
  //     chart.data[i][keyValue] = newData[i][keyValue]
  //     if (chart.data[i][keyValue] > 0) {
  //       label.text = newData[i].label.toString()
  //       itemsWithNonZero++
  //     }
  //   }

  //   if (year == min) {
  //     series.interpolationDuration = stepDuration / 4
  //     valueAxis.rangeChangeDuration = stepDuration / 4
  //   } else {
  //     series.interpolationDuration = stepDuration
  //     valueAxis.rangeChangeDuration = stepDuration
  //   }

  //   chart.invalidateRawData()

  //   categoryAxis.zoom({ start: 0, end: itemsWithNonZero / categoryAxis.dataItems.length })
  // }

  categoryAxis.sortBySeries = series

  chart.data = JSON.parse(JSON.stringify(allData[year]))
  categoryAxis.zoom({ start: 0, end: 1 / chart.data.length })

  series.events.on('inited', function () {
    setTimeout(function () {
      playButton.isActive = true // this starts interval
    }, 2000)
  })
}
