import React from 'react'
import { AuthConsumer } from 'pb-shared'
import { LoadingAnimation } from '../../core/components'
import { ApolloConsumer } from '@apollo/client'

export const Callback = () => (
  <ApolloConsumer>
    {({ resetStore }) => (
      <AuthConsumer>
        {({ signinRedirectCallback }) => {
          resetStore()
          signinRedirectCallback()
          return <LoadingAnimation />
        }}
      </AuthConsumer>
    )}
  </ApolloConsumer>
)
