import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
  Select,
  Radio,
  makeStyles,
  createStyles,
  Theme,
  MenuItem,
  InputLabel,
  FormControl,
  RadioGroup,
} from '@material-ui/core'
import React, { useState } from 'react'
import { ArrowBack, Edit, Save } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import { HeaderCardTitle, AppBreadcrumbsBar, Loader } from '../../../core/components'
import { Formik } from 'formik'
import { ClientAccount as ClientAccountModel } from './models'
import { RouterProps, useParams, Link } from 'react-router-dom'
import { useClientAccount } from './hooks/useClientAccount'

const initialValues: ClientAccountModel = {
  accountManagerId: 0,
  accountName: '',
  accountPin: 0,
  isActive: false,
  accountPhone: 0,
  accountManagerName: '',
  balance: 0,
  currencyAcronym: '',
  currencyId: 0,
  currencyName: '',
  margen: 0,
  noPayed: 0,
  salesEnvironmentstr: '',
  enterpriseId: 0,
  id: 0,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fieldItem: {
      marginBottom: theme.spacing(2),
    },
    buttonAcctions: {
      marginLeft: theme.spacing(2),
    },
    labelEditActive: {
      color: '#ff6600',
    },
    labelEditDisabled: {
      color: '#000000',
    },
  }),
)

export interface ClientAccountProps extends RouterProps {}

export const ClientAccount = () => {
  const { id } = useParams<{ id?: string | undefined }>()
  const { clientAccount, loading } = useClientAccount(Number.parseInt(id || '0'))

  const [isEditing, setIsEditing] = useState<boolean>(false)
  const { t: translationLabel } = useTranslation('Label')
  const { t: translationAction } = useTranslation('Action')
  const classes = useStyles()

  const form = (clientAccount as ClientAccountModel) || initialValues

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid item xs={12}>
          <AppBreadcrumbsBar
            title={translationLabel('CUENTAS_DE_CLIENTE')}
            description={translationLabel('CUENTAS_Y_BALANCES_DE_LOS_CLIENTES')}
            actions={
              <React.Fragment>
                {isEditing ? (
                  <Button
                    className={classes.buttonAcctions}
                    variant="contained"
                    color="primary"
                    startIcon={<Save />}
                    onClick={() => setIsEditing(false)}
                  >
                    {translationAction('GUARDAR')}
                  </Button>
                ) : (
                  <Button
                    className={classes.buttonAcctions}
                    variant="contained"
                    color="primary"
                    startIcon={<Edit />}
                    onClick={() => setIsEditing(true)}
                  >
                    {translationAction('MODIFICAR')}
                  </Button>
                )}
                <Button className={classes.buttonAcctions} variant="contained" startIcon={<ArrowBack />}>
                  <Link to="/admin/entities/client-accounts">{translationAction('REGRESAR')}</Link>
                </Button>
              </React.Fragment>
            }
          />
        </Grid>

        <Grid item xs={12}>
          <Card>
            <Box display="flex" justifyContent="space-between" alignItems="center" mr={1}>
              <CardHeader component={() => <HeaderCardTitle title={translationLabel('DETALLE_DE_LA_CUENTA')} />} />
              <Typography
                variant="h6"
                component="h6"
                className={isEditing ? classes.labelEditActive : classes.labelEditDisabled}
              >
                <b>{isEditing ? translationAction('MODIFICANDO') : translationAction('VISTA')}</b>
              </Typography>
            </Box>
            <Divider />
            <Loader loading={loading}>
              <CardContent>
                <Formik
                  // validationSchema={formSchema}
                  initialValues={form}
                  onSubmit={() => {}}
                  enableReinitialize
                >
                  {({ handleChange, values }) => (
                    <form>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                          <TextField
                            name="pink"
                            onChange={handleChange}
                            label="Pin"
                            fullWidth
                            value={values.accountPin}
                            disabled
                            className={classes.fieldItem}
                          />
                          <TextField
                            name="name"
                            onChange={handleChange}
                            label="Nombre"
                            fullWidth
                            disabled
                            className={classes.fieldItem}
                            value={values.accountName}
                          />
                          <TextField
                            name="name"
                            onChange={handleChange}
                            label="Teléfono"
                            fullWidth
                            disabled={!isEditing}
                            className={classes.fieldItem}
                            value={values.accountPhone}
                          />
                          <FormControl fullWidth className={classes.fieldItem}>
                            <InputLabel>Divisa</InputLabel>
                            <Select disabled onChange={handleChange}>
                              <MenuItem value={10}>Ten</MenuItem>
                              <MenuItem value={20}>Twenty</MenuItem>
                              <MenuItem value={30}>Thirty</MenuItem>
                            </Select>
                          </FormControl>
                          <FormControl fullWidth className={classes.fieldItem}>
                            <InputLabel>TIPO</InputLabel>
                            <Select disabled onChange={handleChange}>
                              <MenuItem value={10}>Ten</MenuItem>
                              <MenuItem value={20}>Twenty</MenuItem>
                              <MenuItem value={30}>Thirty</MenuItem>
                            </Select>
                          </FormControl>
                          <FormControl fullWidth className={classes.fieldItem}>
                            <InputLabel>ADMINISTRADOR</InputLabel>
                            <Select disabled={!isEditing} onChange={handleChange}>
                              <MenuItem value={10}>Ten</MenuItem>
                              <MenuItem value={20}>Twenty</MenuItem>
                              <MenuItem value={30}>Thirty</MenuItem>
                            </Select>
                          </FormControl>

                          <RadioGroup onChange={handleChange} row>
                            <FormControlLabel
                              value="activo"
                              control={<Radio color="primary" disabled={!isEditing} />}
                              label="Activo"
                            />
                            {isEditing && (
                              <FormControlLabel
                                value="inactivo"
                                control={<Radio color="primary" disabled={!isEditing} />}
                                label="Inactivo"
                              />
                            )}
                          </RadioGroup>
                        </Grid>
                        <Grid container item xs={12} md={6}>
                          <TextField
                            name="name"
                            onChange={handleChange}
                            label="Balance"
                            fullWidth
                            disabled
                            className={classes.fieldItem}
                            value={values.balance}
                          />
                          <TextField
                            name="name"
                            onChange={handleChange}
                            label="No Pagado"
                            fullWidth
                            disabled
                            className={classes.fieldItem}
                            value={values.noPayed}
                          />
                          <TextField
                            name="name"
                            onChange={handleChange}
                            label="Margen"
                            fullWidth
                            disabled
                            className={classes.fieldItem}
                            value={values.margen}
                          />
                        </Grid>
                      </Grid>
                    </form>
                  )}
                </Formik>
              </CardContent>
            </Loader>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  )
}
