import { Grid } from '@material-ui/core'
import { PersonOutline, ArrowBack } from '@material-ui/icons'
import React from 'react'

import { ValuePlusIncreaseDecreased } from '../../core/components'

const ValuePlusIncreaseDecreasedPage = () => {
  return (
    <Grid container sm={12} spacing={2}>
      <Grid item sm={3}>
        <ValuePlusIncreaseDecreased
          Icon={<PersonOutline />}
          value={'586,356'}
          percentage={0}
          titleValue="NEW_ACCOUNT"
          percentageTitle="TITLE_PORCENTAJE"
        />
      </Grid>
      <Grid item sm={3}>
        <ValuePlusIncreaseDecreased
          Icon={<ArrowBack />}
          value={'586'}
          percentage={10}
          titleValue="NEW_ACCOUNT"
          percentageTitle="TITLE_PORCENTAJE"
        />
      </Grid>
    </Grid>
  )
}

export default ValuePlusIncreaseDecreasedPage
