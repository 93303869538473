import { useCallback, useEffect, useState } from 'react'
import { GridPageChangeParams, GridRowParams } from '@material-ui/data-grid'
import { createStyles, Grid, makeStyles, Theme, Card, CardContent, CardHeader, Divider } from '@material-ui/core'
import { useProviders } from './hooks'
import { generateFilterGraphql, useUserClaimActions, ClaimActions } from '../../../core'
import {
  AppBreadcrumbsBar,
  CreateButton,
  CustomDataGrid,
  DataGridColumns,
  HeaderCardTitle,
  Loader,
} from '../../../core/components'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { ProvidersFilters } from './ProvidersFilters'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    grid: {
      paddingBottom: theme.spacing(2),
    },
  }),
)

export const Providers = () => {
  const classes = useStyles()
  const history = useHistory()
  const { results, loading, getProviders } = useProviders()
  const { t: transLabel } = useTranslation('Label')
  const [name, setName] = useState('')
  const { validateClaimActions } = useUserClaimActions()
  const actions = validateClaimActions('configuration.connections', [ClaimActions.CanCreate, ClaimActions.CanSearch])
  const [paging, setPaging] = useState<GridPageChangeParams>({
    page: 0,
    pageCount: 0,
    pageSize: 10,
    paginationMode: 'server',
    rowCount: 10,
  })

  useEffect(() => {
    const customFilters = {
      and: [{ name: { ...generateFilterGraphql(name, 'contains') } }],
    }

    getProviders({ where: customFilters, skip: paging.page * paging.pageSize, take: paging.pageSize })
  }, [, paging, name])

  const getColumnDefinitions = useCallback((): DataGridColumns => {
    return {
      NOMBRE: { field: 'name', contentType: 'string' },
      ACRONIMO: { field: 'acronym', contentType: 'string' },
      DESCRIPCION: { field: 'description', contentType: 'string' },
      ESTADO: { field: 'active', contentType: 'boolean' },
    }
  }, [])

  const handleRowSelected = (params: GridRowParams) => {
    history.push(`/admin/entities/providers/${params.id}`)
  }

  const handlePageChange = (param: GridPageChangeParams) => {
    setPaging(param)
  }

  const handlePageSizeChange = (param: GridPageChangeParams) => {
    setPaging(param)
  }

  const handleFilters = (value: string) => {
    setName(value)
  }

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <AppBreadcrumbsBar
              colTitleSize={10}
              colActionSize={2}
              title={transLabel('PROVEEDOR_TITULO')}
              description={transLabel('PROVEEDOR_SUB_TITULO')}
              actions={
                actions.canCreate ? (
                  <CreateButton onClick={() => history.push(`/admin/entities/providers/create`)} />
                ) : null
              }
            />
          </Grid>
          {actions.canSearch && (
            <Grid item xs={12} className={classes.grid}>
              <ProvidersFilters onFiltersChange={handleFilters} />
            </Grid>
          )}
          <Grid item xs={12}>
            <Card>
              <CardHeader component={() => <HeaderCardTitle title={transLabel('LISTA_DE_PROVEEDORES')} />} />
              <Divider />
              <CardContent>
                <Loader loading={loading}>
                  <div style={{ minHeight: '70vh', width: '100%' }}>
                    <CustomDataGrid
                      data={results}
                      columns={getColumnDefinitions()}
                      page={paging.page}
                      pageSize={paging.pageSize}
                      onRowClick={handleRowSelected}
                      onPageChange={handlePageChange}
                      onPageSizeChange={handlePageSizeChange}
                    />
                  </div>
                </Loader>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
