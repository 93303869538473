import { gql, useMutation } from '@apollo/client'

const UPDATE_PROGRAM_TRAINER = gql`
  mutation UpdateProgramTrainer($id: Int!, $input: UpdateProgramEntityInput!) {
    updateProgramTrainer(id: $id, input: $input) {
      programTrainer {
        id
        name
        isActive
      }
    }
  }
`

export interface UpdateProgramTrainerInput {
  id: number
  name: string
  isActive: boolean
}

export const useUpdateProgramTrainer = () => {
  const [updateProgramTrainer, { loading, error }] = useMutation<UpdateProgramTrainerInput>(UPDATE_PROGRAM_TRAINER)
  return { updateProgramTrainer, loading, error }
}
