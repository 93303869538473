import { createStyles, makeStyles } from '@material-ui/core'

type ProgressBarProps = {
  value: number
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      backgroundColor: '#f1f1f1',
      width: '100%',
      textAlign: 'center',
    },
    bar: {
      backgroundColor: ({ value }: ProgressBarProps) => `${value < 0 ? '#ff9c9c' : '#72e5ff'}`,
      width: ({ value }: ProgressBarProps) => `${value}%`,
      height: '20px',
      lineHeight: '20px',
    },
    value: {
      marginLeft: 120,
      marginRight: 120,
    },
  }),
)

export const PercentBar = ({ value }: ProgressBarProps) => {
  const classes = useStyles({ value })

  return (
    <div className={classes.root}>
      <div className={classes.bar}>
        <span className={classes.value}>{`${value}%`}</span>
      </div>
    </div>
  )
}
