import { gql, useMutation } from '@apollo/client'

const REMOVE_RACE = gql`
  mutation RemoveProgramRaceAsync($id: Int!) {
    removeProgramRace(id: $id)
  }
`
export const useRemoveRace = () => {
  const [removeRace, { loading, error }] = useMutation(REMOVE_RACE)
  return { removeRace, loading, error }
}
