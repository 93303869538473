import { gql, useLazyQuery, useQuery } from '@apollo/client'
import { useCallback, useEffect, useState } from 'react'
import { Metadata, ResultsList, SearchGraphql } from '../../../../core/models'
import { ProviderConnection } from '../models'

const GET_PROVIDER_CONNECTIONS = gql`
  query GetProviderApiConnections($skip: Int, $take: Int, $where: ProviderApiConnectionListFilterInput!) {
    providerApiConnections(where: $where, skip: $skip, take: $take) {
      items {
        id
        providerName
        providerId
        apiBaseUrl
        accessKey
        mapping
        lastSyncDate
        version
        isActive
      }
    }
  }
`

interface ProviderConnectionData {
  providerApiConnections: {
    totalCount: number
    items: ProviderConnection[]
  }
}

export const useProviderConnections = () => {
  const [getResults, { loading, error, data }] = useLazyQuery<ProviderConnectionData>(GET_PROVIDER_CONNECTIONS, {
    fetchPolicy: 'network-only',
  })

  const getProviderConnections = ({ where, take, skip }: SearchGraphql) => {
    getResults({
      variables: {
        where: { ...where },
        skip: skip ? skip : 0,
        take: take ? take : 100,
      },
    })
  }

  const results = {
    results: data?.providerApiConnections.items || [],
    metadata: {
      Count: data?.providerApiConnections.totalCount,
    },
  }
  return { getProviderConnections, loading, error, results }
}
