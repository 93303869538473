import { useLayoutEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import { AppBreadcrumbsBar, amChartRace, Loader } from '../../../core/components'
import { useSaleSummary, MachineChartModel } from './hooks'
import _lodash from 'lodash'

export const MachineChartRace = () => {
  const { t: translationLabel } = useTranslation('Label')
  const { results } = useSaleSummary()

  useLayoutEffect(() => {
    buildData(results.results)
    // amChartRace(amChartData, 'network', 'MAU')

    // amChartRace()
  }, [results])

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid item xs={12}>
          <AppBreadcrumbsBar
            title={translationLabel('MAQUINAS_RESUMIDO_POR_LOCALIDAD')}
            description={translationLabel('INFORMACION_DE_GANANCIAS_Y_PERDIDAS')}
          />
        </Grid>

        <Loader loading={false}>
          <div id="chartdiv" style={{ width: '100%', height: '500px' }}></div>
        </Loader>
      </Grid>
    </Grid>
  )
}

interface DataJsonModel {
  name: string
  value: string
  label: string
}

type DataChart = {
  date: string
  data: DataJsonModel[]
}

const buildData = (data: any) => {
  let object: any = {}

  data.forEach((item: MachineChartModel, index: number) => {
    const dataJson: Array<DataChart> = JSON.parse(item.dataJson)
    const salesDate: string = item.salesdate
    const chartValues = dataJson[0].data

    const result = chartValues.map((item, index) => {
      item.label = salesDate
      return item
    })

    object[index] = result
  })

  if (!_lodash.isEmpty(object)) {
    let [keyStart] = Object.keys(object)
    amChartRace(object, 'name', 'value', keyStart)
  }
}
