import React from 'react'
import { Button } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import { useTranslation } from 'react-i18next'

type buttonProps = {
  onClick: () => void
  className?: string
  disabled?: boolean
}
export const ModifyButton = ({ onClick, ...props }: buttonProps) => {
  const { t: transAction } = useTranslation('Action')

  return (
    <Button {...props} variant="contained" color="primary" onClick={onClick} startIcon={<EditIcon />}>
      {transAction('MODIFICAR')}
    </Button>
  )
}
