import { useState, useEffect } from 'react'
import { gql, useQuery } from '@apollo/client'
import { Entity } from '../models'

const GET_LOCATIONS = gql`
  query GetLocations {
    locations {
      items {
        id
        name
      }
    }
  }
`

interface LocationData {
  locations: {
    items: Entity[]
  }
}

export const useLocation = () => {
  const { loading, error, data } = useQuery<LocationData>(GET_LOCATIONS)

  const [results, setResults] = useState<Entity[]>([])

  useEffect(() => {
    setResults(data?.locations.items || [])
  }, [data?.locations.items, loading])

  return { loading, error, results }
}
