import { useState, useCallback } from 'react'
import _lodash from 'lodash'
import { createStyles, Grid, makeStyles, Theme, Card, CardContent, CardHeader, Divider } from '@material-ui/core'
// My Components
import { ClientAccountConfirmationSearch } from './Filters/ClientAccountConfirmationSearch'
import { ClientAccountTransactionsFilters } from './Filters/ClientAccountTransactionsFilters'
import { HeaderCardTitle } from '../../../core/components'
import {
  CustomDataGrid,
  DataGridColumns,
  AppBreadcrumbsBar,
  Loader,
  CreateButton,
  DialogAlerts,
} from '../../../core/components'
import { AccountTransactionFilters } from './Filters/ClientAccountTransactionsFilters'

// Hooks
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

// Models
import { useClientAccount, useAccountTransactionsSearch, useAccountTransactions } from './hooks'
import { ClaimActions, formatToShortLocalDate, useUserClaimActions } from '../../../core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    grid: {
      paddingBottom: theme.spacing(2),
    },
  }),
)

export const ClientAccountTransactions = () => {
  const classes = useStyles()
  const [isSearchingAccount, setIsSearchingAccount] = useState(false)
  const [accountPin, setAccountPing] = useState<string | undefined>(undefined)
  const [openAlertTransaction, setOpenAlertTransaction] = useState(false)
  const history = useHistory()
  // App Label Translation
  const { t: translationLabel } = useTranslation('Label')
  const { validateClaimActions } = useUserClaimActions()
  const actions = validateClaimActions('transactions.client-accounts', [
    ClaimActions.CanCreate,
    ClaimActions.CanSearch,
    ClaimActions.CanView,
  ])

  // Fetch Account
  const { getClientAccount, clientAccountData, loading } = useClientAccount()
  const { accountTransactionsSearch } = useAccountTransactionsSearch()
  const { getAccountTransactions, transactionsData, loading: loadingTransactions } = useAccountTransactions()

  const addAccountTransaction = (pin: string | undefined, from: Date, to: Date) => {
    accountTransactionsSearch({
      variables: {
        accountPin: pin !== undefined ? Number.parseInt(pin) : 0,
        startDate: formatToShortLocalDate(from, 'YYYY/MM/DD'),
        endDate: formatToShortLocalDate(to, 'YYYY/MM/DD'),
      },
    })
      .then((response) => {
        const data = _lodash.get(response, 'data.accountTransactionsSearch.response', undefined)

        if (data !== undefined && data.totalCount > 0) {
          getAccountTransactions(false)
        } else {
          getAccountTransactions(true)
          setOpenAlertTransaction(true)
        }
      })
      .catch((error) => {})
  }

  // Executes this logic after click in search account button icon
  // Only if clientAccountData has values and isSearchingAccount is true
  // isSearchingAccount is set true after initialize account filters
  if (clientAccountData !== undefined && isSearchingAccount === true) {
    addAccountTransaction(accountPin, new Date(), new Date())
    setIsSearchingAccount(false)
  }

  const handleAccountFilters = (value: string) => {
    getClientAccount(value)
    setAccountPing(value)
    setIsSearchingAccount(true)
  }

  const handleSearchAccountTransaction = (filters: AccountTransactionFilters) => {
    addAccountTransaction(accountPin, filters.from, filters.to)
  }

  const getColumnDefinitions = useCallback((): DataGridColumns => {
    return {
      FECHA_TRANS: { field: 'eventDate', contentType: 'date' },
      NUMERO_TICKET: { field: 'ticketId', contentType: 'number' },
      CONCEPTO: { field: 'conceptName', contentType: 'string' },
      CREDITO: { field: 'credit', contentType: 'number' },
      DEBITO: { field: 'debit', contentType: 'number' },
      BALANCE: { field: 'balance', contentType: 'number' },
      GANADO: { field: 'win', contentType: 'number' },
      NO_COBRADO: { field: 'noPayed', contentType: 'number' },
    }
  }, [])

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <AppBreadcrumbsBar
              title={translationLabel('TRANSACCIONES_CUENTA_CLIENTE')}
              description={translationLabel('TRANSACCIONES_CUENTA_CLIENTE_DETALLE')}
              actions={
                actions.canCreate ? (
                  <CreateButton
                    onClick={() =>
                      history.push({
                        pathname: `/admin/transactions/client-account/create`,
                        state: accountPin,
                      })
                    }
                    disabled={clientAccountData === undefined}
                  />
                ) : null
              }
            />
          </Grid>
          <Grid item xs={12} className={classes.grid}>
            <ClientAccountConfirmationSearch onSearch={handleAccountFilters} account={clientAccountData} />
          </Grid>

          {actions.canSearch && (
            <Grid item xs={12} className={classes.grid}>
              <ClientAccountTransactionsFilters
                onSearch={handleSearchAccountTransaction}
                disabled={clientAccountData === undefined}
              />
            </Grid>
          )}

          <Grid item xs={12} className={classes.grid}>
            <Card>
              <CardHeader component={() => <HeaderCardTitle title={translationLabel('CRITERIOS_DE_BUSQUEDA')} />} />
              <Divider />
              <CardContent>
                <Loader loading={loadingTransactions}>
                  <div style={{ minHeight: '35vh', width: '100%' }}>
                    <CustomDataGrid
                      data={transactionsData}
                      columns={getColumnDefinitions()}
                      pageSize={20}
                      disableColumnMenu={true}
                    />
                  </div>
                </Loader>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <DialogAlerts
        state={openAlertTransaction}
        titulo="ACCOUNT_TRANSACTIONS"
        contenido="NO_EXISTEN_TRANSACCIONES_PARA_ESTA_CUENTA"
        tipo="INFORMACION"
        handleClose={() => {}}
        onPrimaryClick={() => setOpenAlertTransaction(false)}
      />
    </div>
  )
}
