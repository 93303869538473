import { LocationTypes } from '../models'
import { gql, useLazyQuery } from '@apollo/client'
import { Metadata, ResultsList } from '../../../../core/models'
import { useState, useEffect, useCallback } from 'react'

const GET_LOCATION_TYPES = gql`
  query GetLocationTypes {
    locationTypes {
      key
      value
    }
  }
`

interface LocationTypesData {
  locationTypes: LocationTypes[]
}

export const useLocationTypes = () => {
  const [getResults, { loading, error, data }] = useLazyQuery<LocationTypesData>(GET_LOCATION_TYPES, {
    fetchPolicy: 'network-only',
  })

  const [results, setResults] = useState<ResultsList<LocationTypes, Metadata>>({
    results: [],
    metadata: {},
  })

  const getLocationTypes = useCallback(() => {
    getResults()
  }, [getResults])

  useEffect(() => {
    setResults({
      results: data?.locationTypes || [],
      metadata: {
        Count: data?.locationTypes.length,
      },
    })
  }, [data?.locationTypes, loading])
  return { loading, error, results, getLocationTypes }
}
