import React from 'react'
import { IconButton } from '@material-ui/core'
import { GridCellParams } from '@material-ui/data-grid'

import { VisibilityRounded as Details } from '@material-ui/icons'

export type ColumnAction = 'Edit' | 'Delete' | 'Custom'

export interface ActionsColumnProps {
  onActionSelected: (row: any, action: ColumnAction) => void
}

export const ActionsColumn = () => {}

export const getActionsColumn = (headerName: string) => {
  return {
    field: '',
    headerName: headerName,
    sortable: false,
    width: 200,
    disableClickEventBubbling: true, // Stop event propagation
    renderCell: (params: GridCellParams) => {
      return (
        <React.Fragment>
          <IconButton onClick={() => console.log('DD')}>
            <Details fontSize="small" />
          </IconButton>
        </React.Fragment>
      )
    },
  }
}
