import { TimeZone } from './../models/search'
import { gql, useLazyQuery } from '@apollo/client'

const GET_TIME_ZONES = gql`
  query GetTimeZones($skip: Int, $take: Int) {
    timeZones(skip: $skip, take: $take) {
      items {
        id
        code
        description
      }
    }
  }
`

interface TimeZoneData {
  timeZones: {
    totalCount: number
    items: TimeZone[]
  }
}

export const useTimeZones = () => {
  const [getResults, { loading, error, data }] = useLazyQuery<TimeZoneData>(GET_TIME_ZONES, {
    fetchPolicy: 'network-only',
  })

  const getTimeZones = (skip?: number, take?: number) => {
    getResults({
      variables: {
        skip: skip ? skip : 0,
        take: take ? take : 300,
      },
    })
  }

  const results = {
    results: data?.timeZones.items || [],
    metadata: {
      Count: data?.timeZones.totalCount,
    },
  }
  return { getTimeZones, loading, error, results }
}
