import React, { useState, useCallback, useEffect } from 'react'
import { GridPageChangeParams, GridRowParams } from '@material-ui/data-grid'
import {
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Button,
} from '@material-ui/core'
// My Components
import { CompaniesFilters } from './CompaniesFilters'
import { HeaderCardTitle } from '../../../core/components'
import { CustomDataGrid, CreateButton, DataGridColumns, AppBreadcrumbsBar, Loader } from '../../../core/components'

// Hooks
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// Models
import { SearchRequest } from '../../../core/models'
import { useCompanies, useUpdateCompany } from './hooks'
import { generateFilterGraphql } from '../../../core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    grid: {
      paddingBottom: theme.spacing(2),
    },
  }),
)

export const Companies = () => {
  const classes = useStyles()

  // React router navigation
  const history = useHistory()
  // App Label Translation
  const { t: transLabel } = useTranslation('Label')

  // Filter values
  const [searchRequest, setSearchRequest] = useState<SearchRequest>({
    filter: {},
    page: 1,
    pageSize: 20,
  })

  useEffect(() => {
    const customFilters = {
      and: [
        { name: { ...generateFilterGraphql('', 'contains') } },
        { active: { ...generateFilterGraphql(true, 'eq') } },
      ],
    }

    getCompanies({ where: customFilters, skip: 0, take: 20 })
  }, [])

  const { results, loading, getCompanies } = useCompanies()

  const handlePageChange = (params: GridPageChangeParams) => {
    const { page, ...rest } = searchRequest
  }

  const handleCompaniesFilters = (values: CompaniesFilters) => {
    const customFilters = {
      and: [
        { name: { ...generateFilterGraphql(values.searchBy, 'contains') } },
        { active: { ...generateFilterGraphql(values.companies === 'active' ? true : false, 'eq') } },
      ],
    }

    getCompanies({ where: customFilters, skip: 0, take: 20 })
  }

  const getColumnDefinitions = useCallback((): DataGridColumns => {
    return {
      NOMBRE: { field: 'name', contentType: 'string' },
      ESTADO: { field: 'active', contentType: 'boolean' },
    }
  }, [])

  const rowSelectHandler = (params: GridRowParams) => {
    if (params.row) {
      history.push(`/admin/entities/companies/${params.row.id}`)
    }
  }

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <AppBreadcrumbsBar
              title={transLabel('COMPANIA_TITULO')}
              description={transLabel('COMPANIA_SUB_TITULO')}
              actions={<CreateButton onClick={() => history.push(`/admin/entities/companies/create`)} />}
            />
          </Grid>
          <Grid item xs={12} className={classes.grid}>
            <CompaniesFilters onSearch={handleCompaniesFilters} />
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardHeader component={() => <HeaderCardTitle title={transLabel('LISTA_DE_COMPANIAS')} />} />
              <Divider />
              <CardContent>
                <Loader loading={loading}>
                  <div style={{ minHeight: '70vh', width: '100%' }}>
                    <CustomDataGrid
                      data={results}
                      columns={getColumnDefinitions()}
                      pageSize={20}
                      onPageChange={handlePageChange}
                      onRowClick={rowSelectHandler}
                    />
                  </div>
                </Loader>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
