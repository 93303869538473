import { gql, useMutation } from '@apollo/client'

const UPDATE_PROGRAM_COMPETITOR = gql`
  mutation UpdateProgramCompetitor($id: Int!, $input: UpdateProgramEntityWithOwnerInput!) {
    updateProgramCompetitor(id: $id, input: $input) {
      programCompetitor {
        id
        name
        ownerId
        isActive
      }
    }
  }
`

export interface UpdateProgramCompetitorInput {
  id: number
  name: string
  ownerId?: number
  isActive: boolean
}

export const useUpdateProgramCompetitor = () => {
  const [updateProgramCompetitor, { loading, error }] =
    useMutation<UpdateProgramCompetitorInput>(UPDATE_PROGRAM_COMPETITOR)
  return { updateProgramCompetitor, loading, error }
}
