import { gql, useMutation } from '@apollo/client'

const UPDATE_PROVIDER = gql`
  mutation UpdateProvider($id: Int!, $input: UpdateProviderInput!) {
    updateProvider(id: $id, input: $input) {
      provider {
        id
        name
        acronym
        description
        generalInfo
        openingTime
        closingTime
        currencyId
        timeZone
        isVirtualGame
        isActive
      }
    }
  }
`

export interface UpdateProviderInput {
  id: number
  acronym: string
  name: string
  closingTime?: Date
  openingTime?: Date
  description?: string
  generalInfo?: string
  currencyId?: number
  isActive: boolean
  isVirtualGame: boolean
  timeZone?: string
}

export const useUpdateProvider = () => {
  const [updateProvider, { loading, error }] = useMutation<UpdateProviderInput>(UPDATE_PROVIDER)
  return { updateProvider, loading, error }
}
