import { useState, useEffect } from 'react'
import { Grid, makeStyles, createStyles, Theme, FormControl, FormControlLabel } from '@material-ui/core'
import {
  CustomAutoCompleteSelectFormik,
  CheckboxFormik,
  StringAutoCompleteSelectFormik,
} from '../../../../core/components'
import { MedicationAutoCompleteSelectFormik } from './MedicationAutoCompleteSelectFormik'
import { useTranslation } from 'react-i18next'
import { Field, FormikValues } from 'formik'
import { TextField } from 'formik-material-ui'
// import { provider } from './models'
// import { useproviderTypes, useprovider } from './hooks'
import { ProgramCompetitor, ProgramJockeyOrKennel, ProgramOwner, ProgramSelectOptions, Runner } from '../models'
import { useJockeyOrKennels, useProgramCompetitors, useProgramOwners } from '../hooks'
import { generateFilterGraphql } from '../../../../core'
import { CompetitorSelectFormik } from '../competitors/CompetitorSelectFormik'
import { JockeySelectFormik } from '../jockeyOrKennels/JockeySelectFormik'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiAutocomplete-root': {
        width: 'auto',
      },
    },
    fieldItem: {
      marginBottom: theme.spacing(2),
    },
    buttonActions: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    labelEditActive: {
      color: '#ff6600',
    },
    labelEditDisabled: {
      color: '#000000',
    },
    divider: {
      marginTop: -22,
    },
    addressDiv: {
      paddingTop: 5,
    },
  }),
)

type RunnerFormProps = {
  runner?: Runner | undefined
  isFormDisabled: boolean
  programSelectOptions: [] | ProgramSelectOptions[]
  formik: FormikValues
  formState: 'CREATE' | 'EDIT' | 'CANCEL'
  onFilterProgramOptions: (filter: string) => void
  serializeMedication: (codes: string) => string[]
}

interface ProgramOptionsI {
  id: number | string | undefined
  name: string | undefined
}

export const RunnerForm = ({
  runner,
  isFormDisabled,
  programSelectOptions,
  formik,
  formState,
  onFilterProgramOptions,
  serializeMedication,
}: RunnerFormProps) => {
  const [isDisabled, setIsDisabled] = useState(!runner)
  const { t: transLabel } = useTranslation('Label')
  const { results: resultsCompetitor, loading: loadingCompetitor, getProgramCompetitors } = useProgramCompetitors()
  const { results: owners, getProgramOwners, loading: loadingOwners } = useProgramOwners()
  const [ownerId, setOwnerId] = useState<number | undefined>(undefined)
  const { results: jockeyOrKennels, loading: loadingJockey, getJockeyOrKennels } = useJockeyOrKennels()
  const [ownerDefaultValue, setOwnerDefaultValue] = useState<ProgramOptionsI | null>({ id: '', name: '' })
  const [medicationData, setMedicationData] = useState<string[]>([])

  useEffect(() => {
    fetchJockeyOrKernels()
    getProgramOwners({
      where: {
        and: [
          {
            name: { ...generateFilterGraphql('', 'contains') },
            isActive: { ...generateFilterGraphql(true, 'eq') },
          },
        ],
      },
      skip: 0,
      take: 500,
    })
  }, [])

  useEffect(() => {
    if (ownerId !== undefined && ownerId > 0) {
      fetchProgramCompetitors(ownerId)
    }
  }, [ownerId])

  const fetchProgramCompetitors = (ownerId: number | undefined) => {
    if (ownerId) {
      const customFilters = {
        and: [
          {
            ownerId: { ...generateFilterGraphql(ownerId, 'eq') },
          },
        ],
      }

      getProgramCompetitors({ where: customFilters, skip: 0, take: 500 })
    }
  }

  useEffect(() => {
    setIsDisabled(isFormDisabled)
  }, [isFormDisabled])

  const fetchJockeyOrKernels = () => {
    getJockeyOrKennels({
      where: {
        and: [
          {
            isActive: { ...generateFilterGraphql(true, 'eq') },
          },
        ],
      },
      skip: 0,
      take: 500,
    })
  }

  const getProgramSelectOptionsByEntity = (entity: string): [] | ProgramSelectOptions[] => {
    if (programSelectOptions && programSelectOptions.length > 0) {
      return programSelectOptions.filter((item: ProgramSelectOptions) => item.entity === entity)
    } else {
      return []
    }
  }

  let jockeyOptions = jockeyOrKennels.results

  useEffect(() => {
    const medicationOptions = getProgramSelectOptionsByEntity('Medication')
    // let medicationDefaultValue: any[] = []
    let medicationString: string[] = []
    if (medicationOptions.length > 0) {
      medicationOptions.map((item: ProgramSelectOptions) => {
        medicationString.push(item.name)
      })
    }
    setMedicationData(medicationString)
  }, [programSelectOptions])

  useEffect(() => {
    if (runner) {
      // owner
      formik.setFieldValue('ownerName', runner.ownerName)
      setOwnerDefaultValue({ id: runner.ownerId, name: runner.ownerName })
      setOwnerId(runner.ownerId)
    }
  }, [runner])

  useEffect(() => {
    if (formState === 'CANCEL') {
      jockeyOptions = []
      setOwnerDefaultValue({ id: '', name: '' })
      formik.setFieldValue('competitorId', { id: '', name: '' })
      setOwnerId(undefined)
    }
  }, [formState])

  const handleChangeCompetitor = (competitor: ProgramCompetitor) => {
    if (competitor !== null) {
      formik.setFieldValue('name', competitor.name)
      formik.setFieldValue('ownerName', competitor.owner)
      formik.setFieldValue('ownerId', competitor.ownerId)
      setOwnerId(competitor.ownerId)

      if (formState === 'CREATE' && competitor.medication) {
        const medication = serializeMedication(competitor.medication)
        formik.setFieldValue('medication', medication.join(''))
      }
    } else {
      formik.setFieldValue('ownerName', '')
    }
  }

  const handleChangeJockey = (jockey: ProgramJockeyOrKennel) => {
    formik.setFieldValue('jockeyOrKennelName', jockey.name)
  }

  const handleFilterOptions = (filter: string, fieldName: string) => {
    if (['medication'].includes(fieldName)) {
      onFilterProgramOptions(filter)
    }
  }

  const handleChangeOwner = (value: ProgramOwner) => {
    setOwnerId(value.id)
    formik.setFieldValue('trainerName', value.trainer)
    formik.setFieldValue('trainerId', value.trainerId)
    formik.setFieldValue('weight', '')
    formik.setFieldValue('competitorId', { id: '', name: '' })
    formik.setFieldValue('jockeyOrKennelId', { id: '', name: '' })
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Field
            InputProps={{ inputProps: { min: 1, max: 999 } }}
            component={TextField}
            type="text"
            label={transLabel('NUMERO')}
            name="number"
            fullWidth
            disabled={true}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Field
            component={TextField}
            type="text"
            label={transLabel('PUNTOS')}
            name="odds"
            fullWidth
            disabled={!isDisabled}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Field
            defaultValue={ownerDefaultValue}
            component={CustomAutoCompleteSelectFormik}
            keyValue={'id'}
            keyLabel={'name'}
            data={owners.results}
            isLoading={loadingOwners}
            label={transLabel('PROPIETARIO')}
            name="ownerName"
            onChangeValue={handleChangeOwner}
            disabled={!isDisabled}
            onSelectOpen={() => onFilterProgramOptions('')}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Field
            component={TextField}
            type="text"
            label={transLabel('ENTRENADOR')}
            name="trainerName"
            fullWidth
            disabled={true}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Field
            label={transLabel('COMPETIDOR')}
            name="competitorId"
            component={CompetitorSelectFormik}
            keyValue={'id'}
            keyLabel={'name'}
            data={resultsCompetitor.results}
            type="text"
            fullWidth
            disabled={!isDisabled}
            onChangeValue={handleChangeCompetitor}
            isLoading={loadingCompetitor}
            onSelectOpen={() => fetchProgramCompetitors(ownerId)}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Field
            component={JockeySelectFormik}
            keyValue={'id'}
            keyLabel={'name'}
            data={jockeyOptions}
            type="text"
            label={transLabel('JINETE')}
            name="jockeyOrKennelId"
            isLoading={loadingJockey}
            onChangeValue={handleChangeJockey}
            disabled={!isDisabled}
            onSelectOpen={fetchJockeyOrKernels}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Field
            InputProps={{ inputProps: { min: 1, max: 9999 } }}
            component={TextField}
            type="number"
            label={transLabel('PESO')}
            name="weight"
            fullWidth
            disabled={!isDisabled}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Field
            component={MedicationAutoCompleteSelectFormik}
            label={transLabel('MEDICACION')}
            name="medication"
            fullWidth
            disabled={!isDisabled}
            data={medicationData}
            multiple={true}
            // defaultValue={medicationDefaultValue}
            onInputValueChange={handleFilterOptions}
            onSelectOpen={() => onFilterProgramOptions('')}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Field component={CheckboxFormik} name="isActive" color="primary" disabled={true} />}
              label={transLabel('ESTADO') as string}
            />
          </FormControl>
        </Grid>
      </Grid>
    </>
  )
}
