import React, { Fragment } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Container, TextField, Grid, FormLabel, Checkbox, Button } from '@material-ui/core'
import { SexSwitcher } from './SexSwitcher'
import { Title } from './Title'
import { CountrySelect } from './CountrySelect'

import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    switchDiv: {
      textAlign: 'end',
    },
    sexDiv: {
      margin: 10,
    },
    conyugeDiv: {
      margin: 10,
    },
    conyugeCheck: {
      textAlign: 'end',
    },
    ConyugeTitle: {
      marginTop: -30,
    },
    footer: {
      position: 'absolute',
      bottom: 0,
      width: '92%',
      backgroundColor: '#cdd5dc',
      color: 'white',
      textAlign: 'center',
    },
    continueBtnDiv: {
      textAlign: 'right',
    },
    continueBtn: {
      textTransform: 'capitalize',
      marginTop: 10,
    },
  }),
)

export type PersonalDataProps = {
  onContinue?: () => void
}

export const PersonalData = ({ onContinue }: PersonalDataProps) => {
  const classes = useStyles()

  return (
    <Fragment>
      <Container>
        <form className={classes.root} noValidate autoComplete="off">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Title text={'Titular'} />
            </Grid>
            <Grid item xs={12} sm={12}>
              <CountrySelect />
            </Grid>

            <Grid item xs={12} sm={12}>
              <TextField label="Nombre" fullWidth />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Select
                fullWidth
                value={40}
                onChange={() => {}}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem value={20}>20 Años</MenuItem>
                <MenuItem value={30}>30 Años</MenuItem>
                <MenuItem value={40}>40 Años</MenuItem>
              </Select>
            </Grid>
            <Grid container xs={12} sm={12} className={classes.sexDiv}>
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <FormLabel component="legend">Sexo</FormLabel>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} className={classes.switchDiv}>
                <SexSwitcher />
              </Grid>
            </Grid>
            <Grid container xs={12} sm={12} className={classes.conyugeDiv}>
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <FormLabel component="legend">Incluir Conyuge?</FormLabel>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} className={classes.conyugeCheck}>
                <Checkbox defaultChecked color="primary" inputProps={{ 'aria-label': 'secondary checkbox' }} />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} className={classes.ConyugeTitle}>
              <Title text={'Cónyuge'} />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Select
                fullWidth
                value={40}
                onChange={() => {}}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem value={20}>20 Años</MenuItem>
                <MenuItem value={30}>30 Años</MenuItem>
                <MenuItem value={40}>40 Años</MenuItem>
              </Select>
            </Grid>
            <Grid container xs={12} sm={12} className={classes.sexDiv}>
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <FormLabel component="legend">Sexo</FormLabel>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} className={classes.switchDiv}>
                <SexSwitcher />
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Container>
      <div className={classes.footer}>
        <Grid container xs={12} sm={12}>
          <Grid item xs={12} sm={12} md={12} lg={12} className={classes.continueBtnDiv}>
            <Button color="primary" className={classes.continueBtn} onClick={onContinue}>
              Continuar
            </Button>
          </Grid>
        </Grid>
      </div>
    </Fragment>
  )
}
