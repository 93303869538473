import { gql, useMutation } from '@apollo/client'

const ADD_PROGRAM_RUNNER = gql`
  mutation CreateProgramRunner($input: AddProgramRunnerInput!) {
    addProgramRunner(input: $input) {
      programRunner {
        id
      }
    }
  }
`
export interface AddProgramRunnerInput {
  programRaceId: number
  number: string
  odds: string
  position: number
  competitorId: number
  jockeyOrKennelId: number
  trainerId: number
  ownerId: number
  weight: number
  isActive: boolean
}

export const useAddProgramRunner = () => {
  const [addProgramRunner, { loading, error }] = useMutation<AddProgramRunnerInput>(ADD_PROGRAM_RUNNER)
  return { addProgramRunner, loading, error }
}
