import { gql, useMutation } from '@apollo/client'
import { User } from '../models'

const CREATE_USER = gql`
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      id
      userName
      password
    }
  }
`
export interface CreateUserInput {
  role: string
  claims: User
}

export const useCreateUser = () => {
  const [createUser, { loading, error }] = useMutation<CreateUserInput>(CREATE_USER)
  return { createUser, loading, error }
}
