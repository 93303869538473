import React, { useCallback, useEffect, useState } from 'react'
import { makeStyles, createStyles, Theme, Card, CardHeader, CardContent, Divider, Grid } from '@material-ui/core'
import _lodash from 'lodash'
import { useTranslation } from 'react-i18next'
import {
  HeaderCardTitle,
  CustomDataGrid,
  DataGridColumns,
  Loader,
  AppBreadcrumbsBar,
  CreateButton,
  CustomDialog,
} from '../../../../core/components'
import { useProviderConnections } from '../hooks'
import { GridPageChangeParams, GridRowParams } from '@material-ui/data-grid'
import { useHistory } from 'react-router-dom'
import ReactJson from 'react-json-view'
import { generateFilterGraphql, useUserClaimActions, ClaimActions } from '../../../../core'
import { ConnectionFilters } from './ConnectionFilters'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    grid: {
      paddingBottom: theme.spacing(2),
    },
    accordionContainer: {
      paddingTop: 20,
    },
    divider: {
      marginTop: -22,
    },
    saveButton: {
      marginRight: 5,
    },
  }),
)

export const Connections = () => {
  const classes = useStyles()
  const history = useHistory()
  const { results, loading, getProviderConnections } = useProviderConnections()
  const { t: transLabel } = useTranslation('Label')
  const [showEvents, setShowEvents] = React.useState(false)
  const [baseUrlFilter, setBaseUrlFilter] = useState('')
  const { validateClaimActions } = useUserClaimActions()
  const actions = validateClaimActions('configuration.connections', [ClaimActions.CanCreate, ClaimActions.CanEdit])

  const [paging, setPaging] = useState<GridPageChangeParams>({
    page: 0,
    pageCount: 0,
    pageSize: 10,
    paginationMode: 'server',
    rowCount: 10,
  })

  useEffect(() => {
    const customFilters = {
      and: [{ apiBaseUrl: { ...generateFilterGraphql(baseUrlFilter, 'contains') } }],
    }

    getProviderConnections({ where: customFilters, skip: paging.page * paging.pageSize, take: paging.pageSize })
  }, [, paging, baseUrlFilter])

  const rowSelectHandler = (params: GridRowParams) => {
    history.push(`/admin/entities/provider-connections/${params.id}`)
  }

  const handleFilters = (value: string) => {
    setBaseUrlFilter(value)
  }

  const getColumnDefinitions = useCallback((): DataGridColumns => {
    return {
      PROVEEDOR: { field: 'providerName', contentType: 'string' },
      BASE_URL: { field: 'apiBaseUrl', contentType: 'string' },
      SYNC_DATE: { field: 'lastSyncDate', contentType: 'date' },
      VERSION: { field: 'version', contentType: 'string' },
      ESTADO: { field: 'isActive', contentType: 'boolean' },
    }
  }, [])

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid item xs={12}>
          <AppBreadcrumbsBar
            colTitleSize={10}
            colActionSize={2}
            title={transLabel('PROVEEDOR_CONEXIONES_TITULO')}
            description={transLabel('PROVEEDOR_CONEXIONES_SUB_TITULO')}
            actions={
              actions.canCreate ? (
                <CreateButton onClick={() => history.push(`/admin/entities/provider-connections/create`)} />
              ) : null
            }
          />
        </Grid>
        {actions.canSearch && (
          <Grid item xs={12} className={classes.grid}>
            <ConnectionFilters onFiltersChange={handleFilters} />
          </Grid>
        )}
        <Grid item xs={12}>
          <Card>
            <CardHeader title={<HeaderCardTitle title={transLabel('CONEXIONES')} />} />
            <Divider className={classes.divider} />
            <CardContent>
              <Loader loading={loading}>
                <div style={{ minHeight: '70vh', width: '100%' }}>
                  <CustomDataGrid
                    data={results}
                    columns={getColumnDefinitions()}
                    pageSize={20}
                    onRowClick={rowSelectHandler}
                  />
                </div>
              </Loader>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <CustomDialog
        title={transLabel('EVENTOS')}
        isOpen={showEvents}
        onClose={() => setShowEvents(false)}
        content={
          <ReactJson
            src={{
              id: 10,
              event: '{}',
              eventType: 'SUCCESS',
              providerApiConnectionId: 1,
              syncDate: '2021-08-05T02:26:00.100Z',
            }}
          />
        }
      />
    </Grid>
  )
}
