import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ProgramTrainer } from '../models'
import { useHistory, useParams } from 'react-router-dom'
import { TrainerForm } from './TrainerForm'
import { useAddProgramTrainer, useProgramTrainer, useUpdateProgramTrainer } from '../hooks'
import { useNotification } from '../../../../core'
import { differenceBetweenTwoObjects as difference } from '../../../../core/services/genericFunctions'

export const Trainer = () => {
  const { id } = useParams<{ id: string }>()
  const { trainer, loading: loadingTrainer, getProgramTrainer } = useProgramTrainer()
  const { t: transSystem } = useTranslation('System')
  const history = useHistory()
  const { addProgramTrainer } = useAddProgramTrainer()
  const { updateProgramTrainer } = useUpdateProgramTrainer()
  const { successNotification, errorNotification } = useNotification()

  useEffect(() => {
    if (id !== undefined) {
      getProgramTrainer(Number.parseInt(id))
    }
  }, [id])

  const handleSave = (trainerToSave: ProgramTrainer) => {
    if (trainerToSave.id == 0) {
      delete trainerToSave.id
      const response = addProgramTrainer({
        variables: {
          input: { ...trainerToSave },
        },
      })
      response
        .then(() => {
          successNotification(transSystem('DATO_REGISTRADO'))
          history.push(`/admin/entities/events-trainers`)
        })
        .catch(() => {
          errorNotification(transSystem('ERROR_REGISTRANDO_EL_DATO'))
        })
    } else {
      const changes = difference(trainerToSave, trainer)
      const response = updateProgramTrainer({
        variables: {
          id: trainerToSave.id,
          input: { ...changes },
        },
      })
      response
        .then(() => {
          successNotification(transSystem('DATO_ACTUALIZADO'))
          history.push(`/admin/entities/events-trainers`)
        })
        .catch(() => {
          errorNotification(transSystem('ERROR_ACTUALIZANDO_EL_DATO'))
        })
    }
  }

  return <TrainerForm onSubmit={handleSave} trainer={trainer} />
}
