import { gql, useMutation } from '@apollo/client'

const UPDATE_PROGRAM_OWNER = gql`
  mutation UpdateProgramOwner($id: Int!, $input: UpdateProgramOwnerInput!) {
    updateProgramOwner(id: $id, input: $input) {
      programOwner {
        id
        name
        trainerId
        isActive
      }
    }
  }
`

export interface UpdateProgramOwnerInput {
  id: number
  name: string
  trainerId?: number
  isActive: boolean
}

export const useUpdateProgramOwner = () => {
  const [updateProgramOwner, { loading, error }] = useMutation<UpdateProgramOwnerInput>(UPDATE_PROGRAM_OWNER)
  return { updateProgramOwner, loading, error }
}
