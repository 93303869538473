import React from 'react'
import NoteAddOutlinedIcon from '@material-ui/icons/NoteAddOutlined'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

type TitleProps = {
  title: string
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      marginBottom: '-5px',
      fontSize: 'x-large',
    },
  }),
)

export const AddTitle = ({ title }: TitleProps) => {
  const classes = useStyles()
  const { t: translation } = useTranslation('Label')

  return (
    <span>
      {' '}
      <NoteAddOutlinedIcon className={classes.root} /> {translation(title)}{' '}
    </span>
  )
}
