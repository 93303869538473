import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Card, Grid } from '@material-ui/core'
import CardHeader from '@material-ui/core/CardHeader'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { PercentBatteryBar } from '../../../core/components'
import { isMobile, useUserClaimActions, ClaimActions } from '../../../core'
import { Role } from './models'
import IconButton from '@material-ui/core/IconButton'
import { CheckCircleOutline, CheckCircle, Edit } from '@material-ui/icons'

const useStyles = makeStyles({
  root: {
    minWidth: isMobile() === true ? 275 : 375,
    '& .MuiSvgIcon-colorDisabled:hover': {
      color: '#3f51b5',
      cursor: 'pointer',
    },
    '& .MuiSvgIcon-colorPrimary:hover': {
      color: '#d8d8d8',
      cursor: 'pointer',
    },
    '& .MuiCardActions-root': {
      display: 'flow-root',
    },
  },
  selectedCard: {
    minWidth: isMobile() === true ? 275 : 375,
    '& .MuiSvgIcon-colorDisabled:hover': {
      color: '#3f51b5',
      cursor: 'pointer',
    },
    '& .MuiSvgIcon-colorPrimary:hover': {
      color: '#d8d8d8',
      cursor: 'pointer',
    },
    backgroundColor: 'cornsilk',
  },
  name: {
    fontWeight: 600,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  editButton: {
    backgroundColor: '#dedede',
  },
  header: {
    paddingBottom: 0,
  },
  content: {
    paddingTop: 0,
  },
  action: {
    paddingLeft: 'inherit',
    // display: 'flex',
  },
  actionTitle: {
    marginRight: 5,
  },
  actionButton: {
    marginTop: 'inherit',
  },
  actionSelectButton: {
    marginTop: 'inherit',
  },
})

type RoleCardProps = {
  role: Role
  selectToEdit: () => void
  onSelectRole: () => void
  isSelected?: boolean
}

export const RolesCard = ({ role, selectToEdit, onSelectRole, isSelected }: RoleCardProps) => {
  const classes = useStyles()
  const { validateClaimActions } = useUserClaimActions()
  const actions = validateClaimActions('security.profiles', [ClaimActions.CanEdit])

  return (
    <Card className={`${isSelected === true ? classes.selectedCard : classes.root}`} onClick={onSelectRole}>
      <CardHeader
        className={classes.header}
        action={
          actions.canEdit && (
            <IconButton aria-label="edit" className={classes.actionButton} onClick={selectToEdit}>
              <Edit color={'disabled'} />
            </IconButton>
          )
        }
        title={
          <Typography className={classes.title} color="textSecondary" gutterBottom>
            3 Users
          </Typography>
        }
      />
      <CardContent className={classes.content}>
        <Typography variant="h5" component="h2" className={classes.name}>
          {role.name}
        </Typography>
      </CardContent>
      <CardActions>
        <Grid container spacing={2}>
          <Grid item xs={10} sm={10} md={10} lg={10}>
            <Typography component="h6" className={classes.actionTitle}>
              Low
            </Typography>
            <PercentBatteryBar value={20} />
          </Grid>
          <Grid item xs={2} sm={2} md={2} lg={2}>
            {isSelected === true ? (
              <IconButton aria-label="Select" className={classes.actionSelectButton} onClick={onSelectRole}>
                <CheckCircle color={'primary'} />
              </IconButton>
            ) : (
              <IconButton aria-label="Select" className={classes.actionSelectButton} onClick={onSelectRole}>
                <CheckCircleOutline color={'disabled'} />
              </IconButton>
            )}
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  )
}
