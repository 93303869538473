import { Button } from '@material-ui/core'
import React, { useState } from 'react'
import { DialogAlerts } from '../../core/components'

const Alert = () => {
  const [stateInfo, setStateInfo] = useState<boolean>(false)
  const [stateAlert, setStateAlert] = useState<boolean>(false)
  const [stateErr, setStateErr] = useState<boolean>(false)
  const [statePreg, setStatePreg] = useState<boolean>(false)

  function openAlertInfo() {
    setStateInfo(true)
  }
  function closeAlertInfo() {
    setStateInfo(false)
  }

  function openAlertAlert() {
    setStateAlert(true)
  }
  function closeAlertAlert() {
    setStateAlert(false)
  }

  function openAlertErr() {
    setStateErr(true)
  }
  function closeAlertErr() {
    setStateErr(false)
  }

  function openAlertPreg() {
    setStatePreg(true)
  }
  function closeAlertPreg() {
    setStatePreg(false)
  }

  return (
    <div>
      <Button variant="contained" color="primary" onClick={openAlertInfo}>
        INFO
      </Button>
      <Button variant="contained" color="primary" onClick={openAlertAlert}>
        ALER
      </Button>
      <Button variant="contained" color="primary" onClick={openAlertErr}>
        ERRO
      </Button>
      <Button variant="contained" color="primary" onClick={openAlertPreg}>
        PREG
      </Button>
      <DialogAlerts
        state={stateInfo}
        titulo="Use Google's location service?"
        contenido="Let Google help apps determine location. This means sending anonymous location data to Google, even when no apps are running."
        tipo="INFORMACION"
        onSecondaryClick={closeAlertInfo}
        handleClose={closeAlertInfo}
        onPrimaryClick={() => {}}
      />
      <DialogAlerts
        state={stateAlert}
        titulo="Use Google's location service?"
        contenido="Let Google help apps determine location. This means sending anonymous location data to Google, even when no apps are running."
        tipo="ALERTA"
        handleClose={closeAlertAlert}
        onPrimaryClick={() => {}}
      />
      <DialogAlerts
        state={stateErr}
        titulo="Use Google's location service?"
        contenido="Let Google help apps determine location. This means sending anonymous location data to Google, even when no apps are running."
        tipo="ERROR"
        handleClose={closeAlertErr}
        onPrimaryClick={() => {}}
      />
      <DialogAlerts
        state={statePreg}
        titulo="Use Google's location service?"
        contenido="Let Google help apps determine location. This means sending anonymous location data to Google, even when no apps are running."
        tipo="PREGUNTA"
        handleClose={closeAlertPreg}
        onPrimaryClick={() => {}}
      />
    </div>
  )
}

export default Alert
