import React, { useState } from 'react'
import { Box, Button, Card, CardContent, Grid, Typography } from '@material-ui/core'

import { useTranslation } from 'react-i18next'
import { Formik, Field } from 'formik'
import { TextField } from 'formik-material-ui'
import { Search } from '@material-ui/icons'
import { useSubmitHorsesPlay } from './useSubmitHorsesPlay'
import { AppBreadcrumbsBar } from '../../../core/components'
import * as yup from 'yup'

interface ResultData {
  submitHorsesPlay: {
    response: {
      message: string
    }
  }
}

const schema = yup.object().shape({
  jugada: yup.string().max(20, 'Máximo 20 caracteres'),
})

export const PruebaJugadaHipica = () => {
  const { t: translationLabel } = useTranslation('Label')
  const { t: translationAction } = useTranslation('Action')
  const { submitHorsesPlay } = useSubmitHorsesPlay()
  const [result, setResult] = useState<ResultData>()

  return (
    <Box margin={'1rem'}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <AppBreadcrumbsBar
            title={translationLabel('PRUEBA_JUGADA_HIPICA_TITULO')}
            description={translationLabel('PRUEBA_JUGADA_HIPICA_SUBTITULO')}
          />
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant={'h6'}>{translationLabel('APLICAR_JUGADA')}</Typography>
            </CardContent>
            <hr />
            <CardContent>
              <form onSubmit={(e) => e.preventDefault()}>
                <Formik
                  validationSchema={schema}
                  initialValues={{ jugada: '' }}
                  onSubmit={async (values, { setSubmitting }) => {
                    setSubmitting(false)
                    try {
                      let { data } = await submitHorsesPlay({ variables: { command: values.jugada } })
                      setResult(data)
                    } catch (err) {}
                  }}
                >
                  {({ submitForm, errors }) => (
                    <Grid container spacing={2}>
                      <Grid item xs={7} sm={4}>
                        <Field
                          fullWidth
                          component={TextField}
                          name="jugada"
                          label={translationLabel('INSERTE_LA_JUGADA')}
                          disableFuture={true}
                          error={!!errors.jugada}
                          helperText={errors.jugada}
                        />
                      </Grid>
                      <Grid item xs={5} sm={4}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          startIcon={<Search />}
                          onClick={submitForm}
                        >
                          {translationAction('CONSULTAR')}
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Field
                          fullWidth
                          component={TextField}
                          multiline
                          rows={4}
                          name="from"
                          label={translationLabel('RESULTADO')}
                          format={'dd/MM/yyyy'}
                          disableFuture={true}
                          variant="outlined"
                          value={result?.submitHorsesPlay.response.message || ''}
                          disabled
                        />
                      </Grid>
                    </Grid>
                  )}
                </Formik>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  )
}
