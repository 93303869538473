import React from 'react'
import { Dictionary } from '../../models'
import { Card, CardContent, CardHeader, createStyles, makeStyles, Theme, Typography } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    title: {
      padding: theme.spacing(2, 1),
      fontSize: 18,
    },
    description: {
      fontSize: 15,
      padding: theme.spacing(0.5),
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }),
)

export interface InfoCardProps {
  title: string
  fields: Dictionary
}

export const InfoCard = ({ title, fields }: InfoCardProps) => {
  const classes = useStyles()
  const { t: translation } = useTranslation('Label')

  const HeaderTitle = () => {
    return (
      <Typography className={classes.title} gutterBottom variant="h5" component="h2">
        {title}
      </Typography>
    )
  }

  const CardContentBody = () => {
    return Object.keys(fields).map((key, arr) => {
      return (
        <React.Fragment key={key}>
          {arr !== 0 && <Divider />}
          <Typography className={classes.description} variant="h5" component="h2">
            {translation(key)}
          </Typography>
          <Typography className={classes.description} variant="body1" color="textSecondary">
            {/* {translation(fields[key] as string)} */}
            {fields[key]}
          </Typography>
        </React.Fragment>
      )
    })
  }

  return (
    <Card>
      <CardHeader component={HeaderTitle} />
      <Divider />
      <CardContent>{CardContentBody()}</CardContent>
    </Card>
  )
}
