import { gql, useLazyQuery } from '@apollo/client'
import { Metadata, ResultsList, SearchGraphql } from '../../../../../core/models'
import { ProgramJockeyOrKennel } from '../../models'

const GET_JOCKEY_OR_KENNELS = gql`
  query GetProgramJockeyOrKennels($where: ProgramJockeyOrKennelFilterInput!, $skip: Int, $take: Int) {
    programJockeyOrKennels(where: $where, skip: $skip, take: $take, order: { name: ASC }) {
      items {
        id
        name
        isActive
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`

interface JockeyOrKennelsData {
  programJockeyOrKennels: {
    totalCount: number
    items: ProgramJockeyOrKennel[]
  }
}

export const useJockeyOrKennels = () => {
  const [getResults, { loading, error, data }] = useLazyQuery<JockeyOrKennelsData>(GET_JOCKEY_OR_KENNELS, {
    fetchPolicy: 'network-only',
  })

  const getJockeyOrKennels = ({ where, take, skip }: SearchGraphql) => {
    getResults({
      variables: {
        where: { ...where },
        skip: skip ? skip : 0,
        take: take ? take : 100,
      },
    })
  }

  const results: ResultsList<ProgramJockeyOrKennel, Metadata> = {
    results: data?.programJockeyOrKennels.items || [],
    metadata: {
      Count: data?.programJockeyOrKennels.totalCount,
    },
  }

  return { getJockeyOrKennels, loading, error, results }
}
