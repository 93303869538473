import React from 'react'
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      padding: theme.spacing(2, 1),
      fontSize: 18,
    },
  }),
)

export type HeaderCardTitleProps = {
  title: String | React.ReactElement
  classStyle?: string
}

export const HeaderCardTitle = ({ title, classStyle }: HeaderCardTitleProps) => {
  const classes = useStyles()

  return (
    <Typography className={classStyle || classes.title} gutterBottom variant="h5" component="h2">
      {title}
    </Typography>
  )
}
