import React from 'react'
import { Card, CardContent, CardHeader, createStyles, Divider, Grid, makeStyles, Theme } from '@material-ui/core'
import { HeaderCardTitle, TextFieldDebounce } from '../../../../core/components'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardRoot: {
      minWidth: '100%',
    },
    description: {
      fontSize: 15,
      padding: theme.spacing(0.5),
    },
    formControl: {
      minWidth: '100%',
    },
    dates: {
      marginTop: '0px !important',
    },
    buttonGrid: {
      marginTop: '10px',
    },
    searchText: {
      minWidth: '100%',
    },
    accountTitle: {
      fontSize: '12px',
    },
  }),
)

type ConnectionFiltersProps = {
  onFiltersChange: (filter: string) => void
}

export const ConnectionFilters = ({ onFiltersChange }: ConnectionFiltersProps) => {
  const classes = useStyles()
  const { t: translation } = useTranslation('Label')

  return (
    <Card className={classes.cardRoot}>
      <CardHeader component={() => <HeaderCardTitle title={translation('CRITERIOS_DE_BUSQUEDA')} />} />
      <Divider />
      <CardContent>
        <Grid item lg={4} xs={12} sm={4}>
          <TextFieldDebounce
            fullWidth
            label={translation('MOSTRAR_LOS_QUE_COINCIDAD_CON')}
            onChange={onFiltersChange}
          />
        </Grid>
      </CardContent>
    </Card>
  )
}
