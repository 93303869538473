import { gql, useMutation } from '@apollo/client'

const ADD_WORKSTATION = gql`
  mutation AddWorkstation($input: AddWorkstationInput!) {
    addWorkstation(input: $input) {
      workstation {
        name
        workStationTypeId
        locationId
        canRegister
      }
    }
  }
`
export interface AddWorkstationInput {
  name: string
  workStationTypeId?: number
  locationId?: number
  canRegister?: boolean
}

export const useAddWorkstation = () => {
  const [addWorkstation, { loading, error }] = useMutation(ADD_WORKSTATION)
  return { addWorkstation, loading, error }
}
