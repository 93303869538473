import React from 'react'
import {
  Button,
  Grid,
  makeStyles,
  Theme,
  createStyles,
  Card,
  Box,
  CardHeader,
  Typography,
  Divider,
  CardContent,
  FormControl,
} from '@material-ui/core'
import { ArrowBack, Save } from '@material-ui/icons'
import { Form, Formik, Field, FieldProps } from 'formik'
import { TextField } from 'formik-material-ui'

import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import {
  AppBreadcrumbsBar,
  HeaderCardTitle,
  LocalizedContentSelect,
  AccountManagersSelect,
  SaveButton,
  PhoneTextFieldMask,
} from '../../../core/components'
import { Link } from 'react-router-dom'
import { useLocalizedContent, useAccountManagers } from '../../../core/hooks'
import { useAddClientAccount } from './hooks/useClientAccount'
import { ClientAccount } from '../../../core/models'
import { phoneRegExp, useNotification } from '../../../core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fieldItem: {
      marginBottom: theme.spacing(2),
    },
    buttonActions: {
      marginLeft: theme.spacing(2),
    },
  }),
)

export const CreateClientAccount = () => {
  const { t: translationLabel } = useTranslation('Label')
  const { t: translationAction } = useTranslation('Action')
  const classes = useStyles()
  const { results: resultLocalizedContent } = useLocalizedContent({ entityType: 'System', entityName: 'Currency' })
  const { results: resultAccountManagers } = useAccountManagers()
  const { addClientAccount, loading: isSaving } = useAddClientAccount()
  const { successNotification, errorNotification } = useNotification()

  const formSchema = Yup.object().shape({
    name: Yup.string().required(translationLabel('REQUERIDO')),
    phone: Yup.string()
      .required(translationLabel('REQUERIDO'))
      .matches(phoneRegExp, translationLabel('VALOR_NO_VALIDO')),
    confirmPhone: Yup.string()
      .required(translationLabel('REQUERIDO'))
      .oneOf([Yup.ref('phone'), null], translationLabel('EL_NUMBER_NO_COINCIDE')),
    currencyId: Yup.string().required(translationLabel('REQUERIDO')),
    amount: Yup.number().required(translationLabel('REQUERIDO')),
    confirmAmount: Yup.number()
      .required(translationLabel('INGRESE_UNA_CONFIRMACION_DE_SU_NUMERO'))
      .oneOf([Yup.ref('amount'), null], translationLabel('EL_MONTO_NO_COINCIDE')),
  })

  return (
    <Formik
      validationSchema={formSchema}
      initialValues={{
        name: '',
        phone: '',
        confirmPhone: '',
        currencyId: '',
        amount: '',
        confirmAmount: '',
        accountManagerId: '',
      }}
      onSubmit={async (values) => {
        const { name, phone, currencyId, amount, accountManagerId } = values
        const clientAccount: ClientAccount = {
          name,
          phone: `${phone}`,
          currencyId: parseInt(currencyId),
          amount: parseInt(amount),
          locationId: 1,
          accountManagerId: parseInt(accountManagerId),
        }

        try {
          const { data } = await addClientAccount({
            variables: {
              clientAccount: clientAccount,
            },
          })
          successNotification(translationLabel('CLIENTE_CREADO'))
        } catch (err) {
          errorNotification(translationLabel('CLIENTE_NO_CREADO'))
        }
      }}
      enableReinitialize
    >
      {({ submitForm, handleBlur, handleChange, values, errors, isValid, dirty }) => {
        window.sessionStorage.setItem('hasFormChanges', dirty ? 'true' : 'false')

        return (
          <Grid container>
            <Grid item xs={12}>
              <Grid item xs={12}>
                <AppBreadcrumbsBar
                  title={translationLabel('CUENTAS_DE_CLIENTE')}
                  description={translationLabel('CUENTAS_Y_BALANCES_DE_LOS_CLIENTES')}
                  actions={
                    <React.Fragment>
                      <SaveButton
                        className={classes.buttonActions}
                        onClick={submitForm}
                        disabled={!(dirty && isValid)}
                        isLoading={isSaving}
                      />
                      <Link to="/admin/entities/client-accounts">
                        <Button variant="contained" className={classes.buttonActions} startIcon={<ArrowBack />}>
                          {translationAction('REGRESAR')}
                        </Button>
                      </Link>
                    </React.Fragment>
                  }
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <Box display="flex" justifyContent="space-between" alignItems="center" mr={1}>
                  <CardHeader component={() => <HeaderCardTitle title={translationLabel('DETALLE_DE_LA_CUENTA')} />} />
                  <Typography variant="h6" component="h6">
                    <b>{translationAction('CREANDO')}</b>
                  </Typography>
                </Box>
                <Divider />
                <CardContent>
                  <Form id="create-client-account">
                    <Grid container>
                      <Grid item container xs={12} spacing={3}>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <Field
                            fullWidth
                            component={TextField}
                            type="text"
                            label={translationLabel('NOMBRE')}
                            name="name"
                          />
                        </Grid>
                      </Grid>
                      <Grid item container xs={12} spacing={3}>
                        <Grid item xs={12} md={6}>
                          <Field
                            fullWidth
                            component={PhoneTextFieldMask}
                            label={translationLabel('TELEFONO')}
                            name="phone"
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Field
                            fullWidth
                            component={PhoneTextFieldMask}
                            label={translationLabel('CONFIRMAR_TELEFONO')}
                            name="confirmPhone"
                          />
                        </Grid>
                      </Grid>
                      <Grid item container xs={12} spacing={3}>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <FormControl fullWidth className={classes.fieldItem}>
                            <Field
                              component={LocalizedContentSelect}
                              name="currencyId"
                              label="Divisa"
                              data={resultLocalizedContent}
                              fullWidth
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <FormControl fullWidth className={classes.fieldItem}>
                            <Field
                              component={AccountManagersSelect}
                              name="accountManagerId"
                              label={translationLabel('ENCARGADO_CUENTA')}
                              data={resultAccountManagers}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>

                      <Grid item container xs={12} spacing={3}>
                        <Grid item xs={12} md={6}>
                          <Field
                            fullWidth
                            component={TextField}
                            type="number"
                            label={translationLabel('MONTO')}
                            name="amount"
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Field
                            fullWidth
                            component={TextField}
                            type="number"
                            label={translationLabel('CONFIRMAR_MONTO')}
                            name="confirmAmount"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Form>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        )
      }}
    </Formik>
  )
}
