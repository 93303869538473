import { ReactElement, useCallback } from 'react'
import { FormProps } from '../../models'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'

export interface TextAreaFieldFormikProps extends FormProps {
  customClassName?: object
  onDateChange?: (date: string | Date | null) => void
  value?: string
  defaultValue?: string
  label: string
  fullWidth?: boolean
  Icon: ReactElement
}

export const TextAreaFieldFormik = ({
  customClassName,
  onDateChange,
  defaultValue,
  value,
  label,
  Icon,
  ...props
}: TextAreaFieldFormikProps) => {
  const {
    form: { setFieldValue, values },
    field: { name },
  } = props

  /**
   * @description change date selected
   * @param date
   */
  const handleChange = useCallback(
    (event: React.ChangeEvent<any>) => {
      const value = event.target.value
      setFieldValue(name, value)
    },
    [setFieldValue, name],
  )

  return <TextareaAutosize {...props} onChange={handleChange} value={values[name]} />
}
