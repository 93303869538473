export interface Dictionary {
  [key: string]: string | number | Date | Boolean | undefined | object[]
}

export interface InformationSale extends Dictionary {
  clientId?: number
  client?: string
  branchOffice?: string
  hour?: Date
  validUntil?: Date
}

export type RequestState =
  | 'Idle'
  | 'InProgress'
  | 'Success'
  | 'Failed'
  | 'Unauthorized'
  | 'Unknown'
  | 'None'
  | 'Forbidden'
  | 'Not Found'

export enum ClaimActions {
  CanCreate = 'CanCreate',
  CanEdit = 'CanEdit',
  CanView = 'CanView',
  CanDelete = 'CanDelete',
  None = 'None',
  CanSearch = 'CanSearch',
  CanPrint = 'CanPrint',
}

export interface WeeklyBalance {
  id: number
  enterpriseId: number
  beginningOfWeek: number
  comission: number
  currencyId: number
  currencyName: string
  dom: number
  domInfo: number
  jue: number
  jueInfo: number
  lun: number
  lunDate: Date
  lunInfo: string
  mar: number
  marInfo: string
  mie: number
  mieInfo: string
  peopleid: number
  productid: number
  productName: string
  risk: number
  sab: number
  sabInfo: string
  total: number
  vie: number
  vieInfo: string
}

export interface ProfitAndLoss {
  id: number
  enterpriseId: number
  amountBet: number
  balance: number
  beginning: number
  comission: number
  currencyName: number
  entityId: number
  entityName: number
  eventDate: number
  inOut: number
  winner: number
  riskWin: number
  ticketPending: number
  debit: number
  credit: number
}

export interface AccountSummary {
  entityName: string
  eventDate: Date
  beginning: number
  amountBet: number
  riskWin: number
  balance: number
}

export interface Entity {
  id: number
  name: string
}

export interface ClientAccount {
  name: string
  phone: string
  currencyId: number
  amount: number
  locationId: number
  accountManagerId: number
}

export interface AccountManager extends Entity {
  note: string
  creationDate: Date
  active: boolean
}

export interface InformationStatus extends Dictionary {
  status?: string
  branchOffice?: string
  date?: Date
}

export interface InformationTicket extends Dictionary {
  id?: number
  typeTicket?: string
  state?: string
  risk?: number
  ifRisk?: number
  riskWin?: number
  ifRiskWin?: number
  possibleWin?: number
  salesTime?: Date
  ticketMasterConditionDate?: Date
  ticketMasterConditionEstatus?: string
  ticketMasterConditionLocation?: string
  locationName?: string
  expireDate?: Date
  currency?: string
  salesPeople?: string
  currencyAcronym?: string
}

export interface FormProps {
  form: object | any
  field: object | any
  meta: any
}

export type WeekDay = 'SUNDAY' | 'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY'
export interface DailyCommission {
  balance: number
  cancelledAmount: number
  cashedAmount: number
  clientCode: string
  commission: number
  currency: string
  currencyId: number
  enterprise: string
  enterpriseId: number
  location: string
  locationId: number
  product: string
  productId: number
  salesDate: Date
  surcharge: number
  wagerAmount: number
  weekday: WeekDay
}
