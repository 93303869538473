import React, { useCallback } from 'react'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { useTranslation } from 'react-i18next'
import { FormProps } from '../../models'
import { LocalizedContentItem } from '../../hooks'

export interface CustomSelectProps extends FormProps {
  label: string
  data: LocalizedContentItem[]
  isGrupalValue?: boolean
  onChange?: (value: string) => void
  showNoneOption?: boolean
}
export const LocalizedContentSelect = ({
  label,
  data,
  isGrupalValue,
  onChange,
  showNoneOption,
  ...props
}: CustomSelectProps) => {
  const {
    form: { setFieldValue, values },
    field: { name },
  } = props

  // App Label Translation
  const { t: translation } = useTranslation('Label')

  const handleChange = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      setFieldValue(name, event.target.value)
      if (onChange instanceof Function) {
        onChange(`${event.target.value}`)
      }
    },
    [setFieldValue, name, onChange],
  )

  return (
    <React.Fragment>
      <InputLabel id={name}>{label}</InputLabel>
      <Select {...props} labelId={name} id={name} value={values[name]} onChange={handleChange}>
        {showNoneOption && (
          <MenuItem value="">
            <em>{translation('NINGUNO')}</em>
          </MenuItem>
        )}
        {data
          ? data.map((item) => (
              <MenuItem key={item.entityId} value={isGrupalValue ? item.grupal : item.entityId}>
                {translation(item.grupal)}
              </MenuItem>
            ))
          : null}
      </Select>
    </React.Fragment>
  )
}
