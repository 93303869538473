import React from 'react'
import { useHistory } from 'react-router-dom'
import { createStyles, makeStyles, Button } from '@material-ui/core'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() =>
  createStyles({
    backButtonStyle: {
      backgroundColor: '#e0e0e0',
    },
  }),
)

export const BackButton = () => {
  const classes = useStyles()
  const { t: transAction } = useTranslation('Action')

  // React router navigation
  const history = useHistory()
  return (
    <Button
      className={classes.backButtonStyle}
      variant="contained"
      onClick={() => history.goBack()}
      startIcon={<KeyboardBackspaceIcon />}
    >
      {transAction('REGRESAR')}
    </Button>
  )
}
