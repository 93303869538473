import { AppState } from '../redux/store'
import { Dictionary, ClaimActions } from '../models/shared'
import { useSelector } from 'react-redux'
import { isAdminSelector, canDoesActionSelector, getAppBarState } from '../redux'

interface Actions extends Dictionary {
  canCreate: boolean
  canEdit: boolean
  canDelete: boolean
  canView: boolean
  canSearch: boolean
  canPrint: boolean
  none: boolean
  isAdmin: boolean
}

export const useUserClaimActions = () => {
  const validateClaimActions = (module: string, actions: string[]): Actions => {
    const isAdmin = isAdminSelector(getAppBarState().appBar)

    // Initialize allowed actions
    const allowedActions = {
      canCreate: isAdmin,
      canEdit: isAdmin,
      canDelete: isAdmin,
      canView: isAdmin,
      canSearch: isAdmin,
      canPrint: isAdmin,
      none: false,
      isAdmin: isAdmin,
    } as Actions

    if (isAdmin) {
      return allowedActions
    }

    // Validate actions
    actions.forEach((action: string) => {
      switch (action) {
        case ClaimActions.CanCreate:
          allowedActions.canCreate = canDoesActionSelector(getAppBarState().appBar, module, ClaimActions.CanCreate)
          break
        case ClaimActions.CanEdit:
          allowedActions.canEdit = canDoesActionSelector(getAppBarState().appBar, module, ClaimActions.CanEdit)
          break
        case ClaimActions.CanDelete:
          allowedActions.canDelete = canDoesActionSelector(getAppBarState().appBar, module, ClaimActions.CanDelete)
          break
        case ClaimActions.CanSearch:
          allowedActions.canSearch = canDoesActionSelector(getAppBarState().appBar, module, ClaimActions.CanSearch)
          break
        case ClaimActions.CanView:
          allowedActions.canView = canDoesActionSelector(getAppBarState().appBar, module, ClaimActions.CanView)
          break
        case ClaimActions.CanPrint:
          allowedActions.canPrint = canDoesActionSelector(getAppBarState().appBar, module, ClaimActions.CanPrint)
          break
        case ClaimActions.None:
          allowedActions.none = canDoesActionSelector(getAppBarState().appBar, module, ClaimActions.None)
          break
      }
    })

    return allowedActions
  }

  return { validateClaimActions }
}
