import { useState, useEffect } from 'react'
import { gql, useQuery } from '@apollo/client'

export interface GetLocalizedContentParams {
  entityType: string
  entityName: string
}

export interface LocalizedContentItem {
  entityId: number
  grupal: string
}

const GET_LOCALIZED_CONTENT = gql`
  query GetLocalizedContent($entityType: String, $entityName: String ) {
    localizedContent(where: {
      entityType: { eq: $entityType}
      entityName: { eq: $entityName}
    }) {
      items {
        entityId
        grupal
        abbreviation
      }
    }
  }
`
interface LocalizedContentData {
  localizedContent: {
    items: LocalizedContentItem[]
  }
}

export const useLocalizedContent = ({ entityType, entityName }: GetLocalizedContentParams) => {
  const { loading, error, data } = useQuery<LocalizedContentData>(GET_LOCALIZED_CONTENT, {
    variables: { entityType, entityName },
  })

  const [results, setResults] = useState<LocalizedContentItem[]>([])

  useEffect(() => {
    setResults(data?.localizedContent.items || [])
  }, [data?.localizedContent.items, loading])

  return { loading, error, results }
}