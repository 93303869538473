import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ProgramOwner } from '../models'
import { useHistory, useParams } from 'react-router-dom'
import { OwnerForm } from './OwnerForm'
import { useAddProgramOwner, useProgramOwner, useUpdateProgramOwner } from '../hooks'
import { useNotification } from '../../../../core'
import { differenceBetweenTwoObjects as difference } from '../../../../core/services/genericFunctions'
import { Loader } from '../../../../core/components'

export const Owner = () => {
  const { id } = useParams<{ id: string }>()
  const { owner, loading: loadingOwner, getProgramOwner } = useProgramOwner()
  const { t: transSystem } = useTranslation('System')
  const history = useHistory()
  const { addProgramOwner } = useAddProgramOwner()
  const { updateProgramOwner } = useUpdateProgramOwner()
  const { successNotification, errorNotification } = useNotification()

  useEffect(() => {
    if (id !== undefined) {
      getProgramOwner(Number.parseInt(id))
    }
  }, [id])

  const handleSave = (ownerToSave: ProgramOwner) => {
    if (ownerToSave.id == 0) {
      delete ownerToSave.id
      const response = addProgramOwner({
        variables: {
          input: { ...ownerToSave },
        },
      })
      response
        .then(() => {
          successNotification(transSystem('DATO_REGISTRADO'))
          history.push(`/admin/entities/events-owners`)
        })
        .catch(() => {
          errorNotification(transSystem('ERROR_REGISTRANDO_EL_DATO'))
        })
    } else {
      const changes = difference(ownerToSave, owner)
      const response = updateProgramOwner({
        variables: {
          id: ownerToSave.id,
          input: { ...changes },
        },
      })
      response
        .then(() => {
          successNotification(transSystem('DATO_ACTUALIZADO'))
          history.push(`/admin/entities/events-owners`)
        })
        .catch(() => {
          errorNotification(transSystem('ERROR_ACTUALIZANDO_EL_DATO'))
        })
    }
  }

  return (
    <Loader loading={loadingOwner}>
      <OwnerForm onSubmit={handleSave} owner={owner} />
    </Loader>
  )
}
