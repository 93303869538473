import React, { Fragment } from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Collapse from '@material-ui/core/Collapse'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { useTranslation } from 'react-i18next'
import { MenuGroup } from './MenuItems'
import { NavLink } from 'react-router-dom'
import { ListSubheader } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 360,
    },
    groupHeader: {
      fontSize: '1rem',
      marginLeft: '27px',
    },
    itemIcon: {
      minWidth: '30px',
    },
    menuIcon: {
      minWidth: '30px',
    },
    option: {
      textDecoration: 'none',
      color: theme.palette.grey[700],
    },
    activeOption: {
      backgroundColor: theme.palette.grey[700],
      color: theme.palette.primary.dark,
      fontWeight: 'bolder',
    },
    subMenuText: {
      marginLeft: 27,
    },
  }),
)

type ListItemWithExpandableMenuProps = {
  menuGroup: MenuGroup
  onChangeRoute?: (path: string) => void
}

export const ListItemWithExpandableMenu = ({ menuGroup, onChangeRoute }: ListItemWithExpandableMenuProps) => {
  const [open, setOpen] = React.useState(false)
  const classes = useStyles({ open })
  const { t: transMenu } = useTranslation('Menu')

  const onSelectedItem = () => {
    setOpen(!open)
  }

  const onSelectedPath = (path: string | undefined) => {
    if (onChangeRoute instanceof Function && path !== undefined) {
      onChangeRoute(path)
    }
  }

  return (
    <div className={classes.root}>
      <ListItem button key={menuGroup.name}>
        <ListItemIcon className={classes.menuIcon} onClick={onSelectedItem}>
          {menuGroup.icon}
        </ListItemIcon>
        <ListItemText
          onClick={onSelectedItem}
          primary={menuGroup.name}
          primaryTypographyProps={{ variant: 'h5', className: classes.groupHeader }}
        />
        {open ? <ExpandLess onClick={onSelectedItem} /> : <ExpandMore onClick={onSelectedItem} />}
      </ListItem>
      <Fragment>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {menuGroup.options?.map((route, index: number) => {
              return route.path !== undefined ? (
                <NavLink
                  onClick={() => onSelectedPath(route.path)}
                  key={route.option}
                  to={route.path || ''}
                  className={classes.option}
                  activeClassName={classes.activeOption}
                >
                  <ListItem button>
                    <ListItemIcon className={classes.itemIcon}>{route.icon}</ListItemIcon>
                    <ListItemText primary={transMenu(route.option)} className={classes.subMenuText} disableTypography />
                  </ListItem>
                </NavLink>
              ) : route.option === 'SUB_HEADER' ? (
                <ListSubheader key={index}>{transMenu(route.name as string)}</ListSubheader>
              ) : null
            })}
          </List>
        </Collapse>
      </Fragment>
    </div>
  )
}
