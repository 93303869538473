// import { ClientAccount } from './models'
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { useState, useEffect, useCallback } from 'react'
import { ResultsList, Metadata, Dictionary } from '../../../core/models'
import { Company } from './models'

const GET_COMPANIES = gql`
  query getAllCompaniesQuery($where: CompanyFilterInput, $skip: Int, $take: Int) {
    companies(where: $where, take: $take, skip: $skip) {
      items {
        id
        name
        active
      }
    }
  }
`
interface CompaniesData {
  companies: {
    items: Company[]
  }
}

export const useCompanies = () => {
  const [getResults, { loading, error, data }] = useLazyQuery<CompaniesData>(GET_COMPANIES, {
    fetchPolicy: 'network-only',
  })

  const [results, setResults] = useState<ResultsList<Company, Metadata>>({
    results: [],
    metadata: {},
  })

  const getCompanies = useCallback(
    ({ where, take, skip }: any) => {
      getResults({
        variables: {
          where: { ...where },
          skip: skip ? skip : 0,
          take: take ? take : 20,
        },
      })
    },
    [getResults],
  )

  useEffect(() => {
    setResults({
      results: data?.companies.items || [],
      metadata: {},
    })
  }, [data?.companies.items, loading])

  return { loading, error, results, getCompanies }
}

const CREATE_COMPANY = gql`
  mutation AddCompany($input: AddCompanyInput!) {
    addCompany(input: $input) {
      company {
        id
        name
        active
      }
    }
  }
`

export interface CreateCompanyInput {
  name: string
  active?: boolean
}

export const useCreateCompany = () => {
  const [createCompany, { loading, error }] = useMutation(CREATE_COMPANY)
  return { createCompany, loading, error }
}

const UPDATE_COMPANY = gql`
  mutation UpdateCompany($id: Int!, $input: UpdateCompanyInput!) {
    updateCompany(id: $id, input: $input) {
      company {
        id
        name
        active
      }
    }
  }
`

export interface UpdateCompanyInput extends Dictionary {
  name?: string
  active?: boolean
}

export const useUpdateCompany = () => {
  const [updateCompany, { loading, error }] = useMutation(UPDATE_COMPANY)
  return { updateCompany, loading, error }
}

const GET_COMPANY = gql`
  query GetCompany($id: Int!) {
    company(id: $id) {
      name
      active
    }
  }
`

export const useCompany = () => {
  const [id, setId] = useState<number | undefined>(undefined)

  const { loading, error, data } = useQuery<UpdateCompanyInput>(GET_COMPANY, {
    variables: { id },
  })

  const getCompany = (id: number) => {
    setId(id)
  }

  return { getCompany, loading, error, data }
}

const DELETE_COMPANY = gql`
  mutation RemoveCompany($id: Int!) {
    removeCompany(id: $id) {
      company {
        id
      }
    }
  }
`

export const useDeleteCompany = () => {
  const [deleteCompany, { loading, error }] = useMutation(DELETE_COMPANY)
  return { deleteCompany, loading, error }
}
