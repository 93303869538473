import { useCallback, useState } from 'react'
import { Card, CardContent, Grid } from '@material-ui/core'
import { MachineSummarizedFilters } from './MachineSummarizedFilters'
import { MachineTotalResults } from './MachineTotalResults'
import { CustomDataGrid, DataGridColumns, AppBreadcrumbsBar, Loader } from '../../../core/components'
import { useProfitAndLosses } from './hooks'
import { useTranslation } from 'react-i18next'
import { IMachineSummarizedFilters } from './MachineSummarizedFilters'
import { ClaimActions, formatToShortDateUs, generateFilterGraphql, useUserClaimActions } from '../../../core'
import { GridPageChangeParams } from '@material-ui/data-grid'

export const MachineLocationSummarized = () => {
  const { t: translationLabel } = useTranslation('Label')
  const { results, loading, getProfitAndLosses } = useProfitAndLosses()
  const { validateClaimActions } = useUserClaimActions()
  const actions = validateClaimActions('consults.machine-summarized', [ClaimActions.CanSearch, ClaimActions.CanView])
  const [paging, setPaging] = useState<GridPageChangeParams>({
    page: 0,
    pageCount: 0,
    pageSize: 10,
    paginationMode: 'server',
    rowCount: 10,
  })

  const getColumnDefinitions = useCallback((): DataGridColumns => {
    return {
      ENTITY_NAME: { field: 'entityName', contentType: 'string' },
      FECHA: { field: 'eventDate', contentType: 'date' },
      BEGINNING: { field: 'beginning', contentType: 'number' },
      AMOUNT_BET: { field: 'amountBet', contentType: 'number' },
      GANADO: { field: 'winner', contentType: 'number' },
      RESULTADO: { field: 'riskWin', contentType: 'number' },
      PENDIENTE: { field: 'ticketPending', contentType: 'number' },
      DEBITO: { field: 'debit', contentType: 'number' },
      CREDITO: { field: 'credit', contentType: 'number' },
      BALANCE: { field: 'balance', contentType: 'number' },
    }
  }, [])

  // function handleChangeFilter({ filter }: SearchRequest): void {
  //   const newSearchRequest = {
  //     ...searchRequest,
  //     filter,
  //   }
  //   setSearchRequest(newSearchRequest)
  // }

  function handleChangeFilter(filters: IMachineSummarizedFilters): void {
    const customFilters = {
      and: [
        { eventDate: { ...generateFilterGraphql(formatToShortDateUs(filters.from), 'gte') } },
        { eventDate: { ...generateFilterGraphql(formatToShortDateUs(filters.to), 'lte') } },
      ],
    }
    getProfitAndLosses({ where: customFilters, skip: 0, take: 20 })
  }

  const total = {
    AMOUNT_BET: 0,
    GANADO: 0,
    RESULTADO: 0,
    PENDIENTE: 0,
    DEBITO: 0,
    CREDITO: 0,
    BALANCE: 0,
  }

  if (results.results.length > 0) {
    results.results.forEach((item) => {
      total.AMOUNT_BET = total.AMOUNT_BET + item.amountBet
      total.GANADO = total.GANADO + item.winner
      total.RESULTADO = total.RESULTADO + item.riskWin
      total.PENDIENTE = total.PENDIENTE + item.ticketPending
      total.DEBITO = total.DEBITO + item.debit
      total.CREDITO = total.CREDITO + item.credit
      total.BALANCE = total.BALANCE + item.balance
    })
  }

  const handlePageChange = (param: GridPageChangeParams) => {
    setPaging(param)
  }

  const handlePageSizeChange = (param: GridPageChangeParams) => {
    setPaging(param)
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <AppBreadcrumbsBar
          title={translationLabel('MAQUINAS_RESUMIDO_POR_LOCALIDAD')}
          description={translationLabel('INFORMACION_DE_GANANCIAS_Y_PERDIDAS')}
        />
      </Grid>
      {actions.canSearch && (
        <Grid item xs={12}>
          <MachineSummarizedFilters onFilter={handleChangeFilter} />
        </Grid>
      )}
      <Grid item xs={12} lg={3} />
      <Grid
        item
        xs={12}
        lg={9}
        style={{
          width: '95vw',
        }}
      >
        <MachineTotalResults data={total} />
      </Grid>

      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Loader loading={loading}>
              <div style={{ minHeight: '35vh', width: '100%', overflow: 'auto' }}>
                <CustomDataGrid
                  data={results}
                  columns={getColumnDefinitions()}
                  page={paging.page}
                  pageSize={paging.pageSize}
                  onPageChange={handlePageChange}
                  onPageSizeChange={handlePageSizeChange}
                />
              </div>
            </Loader>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}
