import React from 'react'
import { useParams } from 'react-router-dom'
import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core'

// My Components
import { TicketPlays } from './TicketPlays'
import { TicketInformation } from './TicketInformation'
import { BackButton, AppBreadcrumbsBar, Loader } from '../../../core/components'

// Hooks
import { useTranslation } from 'react-i18next'
import { useTicket } from './useTicket'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    grid: {
      paddingBottom: theme.spacing(2),
    },
  }),
)

export const TicketDetails = () => {
  const classes = useStyles()
  const { id } = useParams<{ id: string }>()
  const { t: transLabel } = useTranslation('Label')

  const { loading, ticket } = useTicket(Number.parseInt(id))
  const { plays, ...rest } = ticket

  return (
    <div className={classes.root}>
      <Loader loading={loading}>
        <Grid container>
          <Grid item xs={12}>
            <AppBreadcrumbsBar
              title={transLabel('LISTA_DE_APUESTAS')}
              description={transLabel('LISTA_DE_APUESTAS')}
              actions={<BackButton />}
            />
          </Grid>
          <Grid item xs={12} className={classes.grid}>
            <TicketInformation model={rest} />
          </Grid>
          <Grid item xs={12}>
            <TicketPlays plays={plays || []} />
          </Grid>
        </Grid>
      </Loader>
    </div>
  )
}
