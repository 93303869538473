import { useState } from 'react'
import { ClientAccount } from './models'
import { gql, useMutation, useLazyQuery } from '@apollo/client'

const GET_CLIENT_ACCOUNT = gql`
  query getClientAccount($accountPin: Long!) {
    clientAccounts(where: { accountPin: { eq: $accountPin } }) {
      items {
        id
        accountName
        balance
        currencyAcronym
        noPayed
        typePaymentName
      }
    }
  }
`

export interface ClientAccountData {
  clientAccount: ClientAccount
}

export const useClientAccount = () => {
  const [fetchClientAccount, { loading, error, data }] = useLazyQuery(GET_CLIENT_ACCOUNT, {
    fetchPolicy: 'network-only',
  })

  const getClientAccount = (accountPin: string) => {
    fetchClientAccount({
      variables: {
        accountPin: parseInt(accountPin),
      },
    })
  }

  const clientAccountData = data?.clientAccounts.items[0]
  return { loading, error, getClientAccount, clientAccountData }
}

const GET_ACCOUNT_TRANSACTIONS = gql`
  query getAccountTransactions($skip: Int, $take: Int) {
    accountTransactions(skip: $skip, take: $take) {
      items {
        id
        balance
        eventDate
        ticketId
        conceptName
        credit
        debit
        win
        notPayed
      }
    }
  }
`

export const useAccountTransactions = () => {
  const [fetchAccountTransactions, { loading, error, data }] = useLazyQuery(GET_ACCOUNT_TRANSACTIONS)
  const [resetData, setResetData] = useState(false)

  const getAccountTransactions = (resetData: boolean) => {
    setResetData(resetData)
    if (resetData === false) {
      fetchAccountTransactions({
        variables: {
          skip: 0,
          take: 10,
        },
      })
    }
  }
  let transactionsData = {
    results: [],
    metadata: {},
  }

  if (resetData === false) {
    transactionsData =
      data !== undefined
        ? {
            results: data.accountTransactions.items,
            metadata: {},
          }
        : {
            results: [],
            metadata: {},
          }
  }

  return { loading, error, getAccountTransactions, transactionsData }
}

const ADD_CLIENT_ACCOUNT_TRANSACTION_SEARCH = gql`
  mutation AddAccountTransactions($accountPin: Int!, $startDate: DateTime!, $endDate: DateTime!) {
    accountTransactionsSearch(input: { accountPin: $accountPin, startDate: $startDate, endDate: $endDate }) {
      response {
        totalCount
        actualBalance
        pending
      }
    }
  }
`

export const useAccountTransactionsSearch = () => {
  const [accountTransactionsSearch, { loading, error }] = useMutation(ADD_CLIENT_ACCOUNT_TRANSACTION_SEARCH)
  return { accountTransactionsSearch, loading, error }
}
