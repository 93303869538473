import { gql, useQuery } from '@apollo/client'
import { useState } from 'react'

const GET_LOCATION = gql`
  query GetLocation($id: Int!) {
    location(id: $id) {
      id
      name
      typeLocationId
      enterpriseId
      address
      phone
      countryId
      provinceId
      municipalityId
      sectorId
      restrictedByGeography
      active
    }
  }
`

export const useLocation = () => {
  const [id, setId] = useState<number | undefined>(undefined)

  const { loading, error, data } = useQuery(GET_LOCATION, {
    variables: { id },
  })

  const getLocation = (id: number) => {
    setId(id)
  }

  return { getLocation, loading, error, data }
}
