import { gql, useMutation } from '@apollo/client'

const UPDATE_PROGRAM_RUNNER = gql`
  mutation UpdateProgramRunner($id: Int!, $input: UpdateProgramRunnerInput!) {
    updateProgramRunner(id: $id, input: $input) {
      programRunner {
        id
        programRaceId
        number
        odds
        position
        weight
        isActive
      }
    }
  }
`

export interface UpdateProgramRunnerInput {
  id: number
  programRaceId: number
  number: string
  odds: string
  position: number
  name: string
  jockeyOrKennel: string
  trainer: string
  owner: string
  weight: number
  isActive: boolean
}

export const useUpdateProgramRunner = () => {
  const [updateProgramRunner, { loading, error }] = useMutation<UpdateProgramRunnerInput>(UPDATE_PROGRAM_RUNNER)
  return { updateProgramRunner, loading, error }
}
