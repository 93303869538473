import { gql, useMutation } from '@apollo/client'

const SUBMIT_HORSES_PLAY = gql`
  mutation SubmitHorsesPlay($command: String!) {
    submitHorsesPlay(input: { command: $command }) {
      response {
        message
      }
    }
  }
`

export const useSubmitHorsesPlay = () => {
  const [submitHorsesPlay, { loading, error }] = useMutation(SUBMIT_HORSES_PLAY)
  return { submitHorsesPlay, loading, error }
}
