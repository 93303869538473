import { gql, useLazyQuery } from '@apollo/client'

const GET_USER = gql`
  query GetUser($id: String!) {
    user(userId: $id) {
      id
      userName
      givenName
      familyName
      nickname
      middleName
      roles
      email
      gender
      phoneNumber
      address
      locale
      zoneinfo
      birthdate
    }
  }
`

export const useUser = () => {
  const [getResults, { loading, error, data }] = useLazyQuery(GET_USER, {
    fetchPolicy: 'network-only',
  })

  const getUser = (id: string) => {
    getResults({
      variables: {
        id,
      },
    })
  }

  return { getUser, loading, error, data }
}
