import { useState, useEffect } from 'react'
import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core'
import { AppBreadcrumbsBar, Loader, PrintButton, SalesTotalDetailsCard } from '../../../core/components'
import { BasicRegistrationPlaysFilters } from './BasicRegistrationPlaysFilters'
import { PlaysTable } from './PlaysTable'

import { useTranslation } from 'react-i18next'
import { useTicketCensusTypeBet, useTicketCensusTypeBetGlobal } from './hooks'

// Models
import { SearchRequest, ClaimActions } from '../../../core/models'
import { formatToShortDateUs } from '../../../core/services/dateFunctions'
import { generateFilterGraphql, useUserClaimActions } from '../../../core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    grid: {
      paddingBottom: theme.spacing(2),
    },
    selectRowIcon: {
      cursor: 'pointer',
    },
  }),
)

export const BasicRegistrationPlays = () => {
  const classes = useStyles()
  const { validateClaimActions } = useUserClaimActions()
  const actions = validateClaimActions('consults.basic-registration-plays', [
    ClaimActions.CanSearch,
    ClaimActions.CanView,
    ClaimActions.CanPrint,
  ])
  const { results: basicRegistrationData, getTicketCensusTypeBet } = useTicketCensusTypeBet()
  const { getTicketCensusTypeBetGlobal } = useTicketCensusTypeBetGlobal()

  // App Label Translation
  const { t: translation } = useTranslation('Label')

  useEffect(() => {
    getTicketCensusTypeBet({
      where: { isStraightBet: generateFilterGraphql(true, 'eq') },
      skip: 0,
      take: 20,
    })
    getTicketCensusTypeBetGlobal({
      where: { isStraightBet: generateFilterGraphql(true, 'eq') },
      skip: 0,
      take: 20,
    })
  }, [getTicketCensusTypeBet, getTicketCensusTypeBetGlobal])

  const [searchRequest, setSearchRequest] = useState<SearchRequest>({
    restrictions: {
      from: formatToShortDateUs(new Date()),
      to: formatToShortDateUs(new Date()),
    },
    filter: {},
    page: 1,
    pageSize: 10,
  })

  const handleFilters = (values: SearchRequest) => {
    const { filter, restrictions, ...rest } = searchRequest
    const newSearchRequest: SearchRequest = {
      restrictions: values.restrictions,
      filter: values.filter,
      ...rest,
    }
    setSearchRequest(newSearchRequest)
    //getTickets(newSearchRequest)
  }

  // const getDirectColumnDefinitions = useCallback((): DataGridColumns => {
  //   return {
  //     LIGA: { field: 'id', contentType: 'id' },
  //     DIRECTAS: { field: 'typeTicket', contentType: 'localized' },
  //     ML: { field: 'salesTime', contentType: 'date' },
  //     RL: { field: 'currency', contentType: 'localized' },
  //     JC_ML: { field: 'risk', contentType: 'number' },
  //     JC_RL: { field: 'possibleWin', contentType: 'number' },
  //     OVER_UNDER: { field: 'riskWin', contentType: 'number' },
  //     S_MAS: { field: 'state', contentType: 'localized' },
  //     S_MENOS: { field: 'state', contentType: 'localized' },
  //   }
  // }, [])

  // const getCombineColumnDefinitions = useCallback((): DataGridColumns => {
  //   return {
  //     COMBINADAS: { field: 'id', contentType: 'id' },
  //     JC_ML: { field: 'risk', contentType: 'number' },
  //     JC_RL: { field: 'possibleWin', contentType: 'number' },
  //     OVER_UNDER: { field: 'riskWin', contentType: 'number' },
  //     S_MAS: { field: 'state', contentType: 'localized' },
  //     S_MENOS: { field: 'state', contentType: 'localized' },
  //   }
  // }, [])

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <AppBreadcrumbsBar
              title={translation('EMPADRONAMIENTO_BASICO')}
              description={translation('EMPADRONAMIENTO_DE_JUGADA')}
              actions={actions.canPrint ? <PrintButton onClick={() => {}} /> : null}
            />
          </Grid>
          {actions.canSearch && (
            <Grid item xs={12} className={classes.grid}>
              <BasicRegistrationPlaysFilters onSearch={handleFilters} />
            </Grid>
          )}
          <Grid item lg={6} sm={12} xs={12} className={classes.grid}>
            <SalesTotalDetailsCard
              title={'Resumen de Ventas'}
              balanceTitle={'TOTALES'}
              balance={546}
              directDetailTitle={'DIRECTA'}
              directDetailTotal={46}
              directDetailBalance={986}
              combineDetailTitle={'COMBINADA'}
              combineDetailTotal={500}
              combineDetailBalance={4000}
            />
          </Grid>

          <Grid container item sm={12} spacing={2}>
            <Grid item lg={6} xs={12} sm={12} style={{ minHeight: '61vh', width: '100%' }}>
              <Loader loading={false}>
                <PlaysTable data={basicRegistrationData.results} />
                {/* <CustomDataGrid
                  data={basicRegistrationData}
                  columns={getDirectColumnDefinitions()}
                  pageSize={5}
                  onPageChange={() => {}}
                /> */}
              </Loader>
            </Grid>
            <Grid item lg={6} xs={12} sm={12} style={{ minHeight: '61vh', width: '100%' }}>
              <Loader loading={false}>
                <PlaysTable data={basicRegistrationData.results} />
                {/* <CustomDataGrid
                  data={basicRegistrationData}
                  columns={getCombineColumnDefinitions()}
                  pageSize={5}
                  onPageChange={() => {}}
                /> */}
              </Loader>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
