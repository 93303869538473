import React, { useEffect, useState } from 'react'
import { makeStyles, createStyles, Theme, Grid, Card, CardContent, Divider, CardHeader } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { RaceForm } from './RaceForm'
import {
  generateFilterGraphql,
  useNotification,
  useUserClaimActions,
  ClaimActions,
  addMinutesToDateTimeUTC,
  formatDateTimeToUTC,
  dateIsBeforeThanTodayMoment,
} from '../../../../core'
import { useTranslation } from 'react-i18next'
import {
  useAddProgramRace,
  useRacesInfo,
  useRemoveRace,
  useUpdateProgramRace,
  useRunners,
  useProgramDefaultWagers,
} from '../hooks'
import { Race as RaceModel, ProgramSelectOptions, Event, DefaultProgramRaceWagers } from '../models'
import { getRegretToNotAllowSpecialsCharacters } from '../../../../core/services/genericFunctions'
import _ from 'lodash'
import {
  CreateButton,
  HeaderCardTitle,
  Loader,
  SaveButton,
  ClearButton,
  DialogAlerts,
} from '../../../../core/components'
import { Races } from './Races'
import { Form, FormikProvider, useFormik } from 'formik'
import * as Yup from 'yup'
import { GridCellParams } from '@material-ui/data-grid'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      marginTop: -22,
    },
  }),
)

type FormState = 'CREATE' | 'EDIT' | 'CANCEL'

const today = new Date()
// today.setHours(0, 0, 0, 0)
const initialFormValues = {
  id: 0,
  programEventId: 0,
  raceNumber: '',
  typeOfRace: '',
  purse: '',
  distanceId: 0,
  runners: '',
  genderRestriction: 'H',
  ageRestriction: '',
  weight: '',
  wagers: '',
  wagersId: '',
  breed: 'TB',
  course: 'D',
  postTime: today,
  isActive: true,
}

type RaceProps = {
  getRaces?: (races: RaceModel[]) => void
  event: Event
  programSelectOptions: [] | ProgramSelectOptions[]
  handleRace: (race: RaceModel | undefined) => void
  onFilterProgramOptions: (filter: string) => void
  isEditable: boolean
  onRaceStatusChange: (state: string) => void
}

export const Race = ({
  getRaces,
  event,
  programSelectOptions,
  handleRace,
  onFilterProgramOptions,
  isEditable,
  onRaceStatusChange,
}: RaceProps) => {
  const classes = useStyles()
  const { addProgramRace, loading: addLoading } = useAddProgramRace()
  const { updateProgramRace, loading: updateLoading } = useUpdateProgramRace()

  const { results: racesInfo, loading, getRacesInfo } = useRacesInfo()
  const [race, setRace] = useState<RaceModel | undefined>(undefined)
  const [alertForm, setAlertForm] = useState(false)
  const [formState, setFormState] = useState<FormState>('CANCEL')
  const { successNotification, errorNotification } = useNotification()
  const { t: transLabel } = useTranslation('Label')
  const { t: transSystem } = useTranslation('System')
  const history = useHistory()
  const { validateClaimActions } = useUserClaimActions()
  const actions = validateClaimActions('configuration.events', [ClaimActions.CanCreate, ClaimActions.CanEdit])
  const [showAlertRemoveRace, setShowAlertRemoveRace] = useState(false)
  const [raceToRemove, setRaceToRemove] = useState<RaceModel | undefined>(undefined)
  const { removeRace, error } = useRemoveRace()
  const { results: runnerResults, getRunners } = useRunners()
  const { results: defaultWagers, getProgramDefaultWagers } = useProgramDefaultWagers()

  const defaultWagerResults: DefaultProgramRaceWagers[] = defaultWagers.results
  const [possibleWagers, setPossibleWagers] = useState('')

  useEffect(() => {
    if (!isEditable) {
      setFormState('CANCEL')
    }
  }, [isEditable])

  useEffect(() => {
    if (event.id) {
      fetchRacesByEventId(event.id)
    }
  }, [event])

  const fetchRacesByEventId = (eventId: number) => {
    const customFilters = {
      and: [{ programEventId: { ...generateFilterGraphql(eventId, 'eq') } }],
    }
    getRacesInfo({ where: customFilters, skip: 0, take: 100 })
  }

  const fetchRunnersByRaceId = (raceId: number) => {
    const customFilters = {
      and: [{ programRaceId: { ...generateFilterGraphql(raceId, 'eq') } }],
    }
    getRunners({ where: customFilters, skip: 0, take: 100 })
  }

  useEffect(() => {
    if (getRaces && racesInfo) {
      getRaces(racesInfo.results)
    }
  }, [racesInfo, getRaces])

  const handleSave = (raceToSave: RaceModel) => {
    if (raceToSave.wagersId.length === 0) {
      formik.setFieldError('wagersId', transLabel('REQUERIDO'))
      return
    }
    if (event.id) {
      raceToSave.programEventId = event.id
      if (raceToSave.wagers) {
        raceToSave.wagersId = getWagerIds(raceToSave.wagers)
      }

      if (raceToSave.raceNumber > event.numberOfRaces) {
        errorNotification(transSystem('ERROR_NUMERO_CARRERA_MAYOR_A_NUMERO_EVENTO'))
      } else {
        if (raceToSave.id === undefined || raceToSave.id == 0) {
          const response = addProgramRace({
            variables: {
              input: {
                programEventId: raceToSave.programEventId,
                raceNumber: raceToSave.raceNumber,
                typeOfRace: raceToSave.typeOfRace,
                purse: raceToSave.purse,
                distanceId: Number(raceToSave.distanceId?.code),
                wagersId: getWagerIds(raceToSave.wagersId),
                runners: raceToSave.runners,
                genderRestriction: raceToSave.genderRestriction,
                ageRestriction: raceToSave.ageRestriction,
                // weight: raceToSave.weight,
                breed: raceToSave.breed,
                course: raceToSave.course,
                postTime: formatDateTimeToUTC(raceToSave.postTime),
                isActive: raceToSave.isActive,
              },
            },
          })
          response
            .then((result: any) => {
              successNotification(transSystem('DATO_REGISTRADO'))
              handleCancel('CANCEL')
              if (event.id) {
                fetchRacesByEventId(event.id)
              }
            })
            .catch((e) => {
              errorNotification(transSystem(e.message))
            })
        } else {
          const response = updateProgramRace({
            variables: {
              id: raceToSave.id,
              input: {
                raceNumber: raceToSave.raceNumber,
                typeOfRace: raceToSave.typeOfRace,
                purse: raceToSave.purse,
                distanceId: Number(raceToSave.distanceId?.code),
                wagersId: getWagerIds(raceToSave.wagersId),
                runners: raceToSave.runners,
                genderRestriction: raceToSave.genderRestriction,
                ageRestriction: raceToSave.ageRestriction,
                // weight: raceToSave.weight,
                breed: raceToSave.breed,
                course: raceToSave.course,
                postTime: formatDateTimeToUTC(raceToSave.postTime),
                isActive: raceToSave.isActive,
              },
            },
          })
          response
            .then(() => {
              successNotification(transSystem('DATO_ACTUALIZADO'))
              if (event.id) {
                fetchRacesByEventId(event.id)
              }
              handleCancel('CANCEL')
            })
            .catch((e) => {
              errorNotification(transSystem(e.message))
            })
        }
      }
    } else {
      errorNotification(transSystem('ERROR_EVENTO_NO_ENCONTRADO'))
    }
  }

  const handleSelectedRace = (race: RaceModel) => {
    const wagersId = formatWagersId(race.wagersId)
    const raceClone = _.cloneDeep(race)
    raceClone.wagersId = wagersId.join('/')

    // Distance
    const distanceOptions = getProgramSelectOptionsByEntity('RaceDistance')
    const code = distanceOptions.filter((item) => Number(item.code) == race.distanceId)
    const distance = code[0]
    raceClone.distanceId = { id: distance.code, name: distance.name }

    setRace(raceClone)
    handleRace(raceClone)
    if (event.isActive) {
      setFormState('EDIT')
    }
  }

  // Return format string|string
  const formatWagersId = (wagers: String): string[] => {
    const wagersStringId: string[] = []
    const wagersId = wagers.split('|')
    const typeBetOptions = getProgramSelectOptionsByEntity('TypeBet')
    typeBetOptions.forEach((item) => {
      if (wagersId.includes(item.code)) {
        wagersStringId.push(item.name)
      }
    })
    return wagersStringId
  }

  const formSchema = Yup.object().shape({
    raceNumber: Yup.number()
      .min(1, transLabel('SOLO_VALORES_ENTRE') + ' (1-99)')
      .max(99, transLabel('SOLO_VALORES_ENTRE') + ' (1-99)')
      .required(transLabel('REQUERIDO')),
    purse: Yup.number()
      .min(1, transLabel('SOLO_VALORES_ENTRE') + ' (1-9999999999)')
      .max(9999999999, transLabel('SOLO_VALORES_ENTRE') + ' (1-9999999999)')
      .required(transLabel('REQUERIDO')),
    distanceId: Yup.object()
      .nullable()
      .required(transLabel('REQUERIDO'))
      .test('test_distanceId', '', function (distance) {
        return distance ? true : this.createError({ message: transLabel('REQUERIDO') })
      }),
    runners: Yup.number()
      .min(2, transLabel('SOLO_VALORES_ENTRE') + ' (2-99)')
      .max(99, transLabel('SOLO_VALORES_ENTRE') + ' (2-99)')
      .required(transLabel('REQUERIDO')),
    // genderRestriction: Yup.string().nullable().required(transLabel('REQUERIDO')),
    ageRestriction: Yup.string()
      .max(2, transLabel('SOLO_VALORES_ENTRE') + ' (1-2)')
      .required(transLabel('REQUERIDO'))
      .matches(getRegretToNotAllowSpecialsCharacters(), transLabel('CARACTERES_ESPECIALES_NO_PERMITIDOS')),
    postTime: Yup.date().nullable().required(transLabel('REQUERIDO')),
  })

  const formik = useFormik({
    validationSchema: formSchema,
    initialValues: race || initialFormValues,
    onSubmit: handleSave,
    enableReinitialize: true,
  })

  useEffect(() => {
    if (defaultWagerResults.length > 0) {
      setPossibleWagers(defaultWagerResults[0].possibleWagers)

      const wagers = formatWagersId(defaultWagerResults[0].wagersId)
      formik.setFieldValue('wagersId', wagers.join('/'))
    }
  }, [defaultWagerResults])

  window.sessionStorage.setItem('hasFormChanges', formik.dirty ? 'true' : 'false')

  const fetchProgramDefaultWagers = () => {
    onRaceStatusChange(formState)
    const raceNumber = racesInfo.results.length + 1

    const customFilters = {
      and: [
        {
          totalRaces: { ...generateFilterGraphql(event.numberOfRaces, 'eq') },
          raceNumber: { ...generateFilterGraphql(raceNumber, 'eq') },
        },
      ],
    }

    getProgramDefaultWagers({ where: customFilters, skip: 0, take: 1 })
  }

  const handleCancel = (formState: FormState) => {
    setFormState(formState)
    if (formState === 'CANCEL') {
      setRace(undefined)
      formik.resetForm()
    }
    handleRace(undefined)
    setPossibleWagers('')
    formik.setFieldValue('distanceId', { code: '', name: '' })
  }

  const handleCreate = () => {
    if (event && event.numberOfRaces <= racesInfo.results.length) {
      errorNotification(`${transSystem('ERROR_CARRERA_MAXIMA_PERMITIDA')} ${event.numberOfRaces}`)
    } else {
      const raceNumber = racesInfo.results.length + 1
      formik.setFieldValue('raceNumber', raceNumber)
      handleCancel('CREATE')
      setNewRaceTime()
      fetchProgramDefaultWagers()
    }
  }

  const setNewRaceTime = () => {
    if (racesInfo.results.length > 0) {
      const lastRace = racesInfo.results[racesInfo.results.length - 1]
      formik.setFieldValue('postTime', addMinutesToDateTimeUTC(lastRace.postTime, 15))
    }
  }

  const getWagerIds = (value: string): string => {
    const typeBetOptions = getProgramSelectOptionsByEntity('TypeBet')
    const values: string[] = value.split('/')
    const ids: string[] = []
    if (typeBetOptions.length > 0 && values.length > 0) {
      values.forEach((val: string) => {
        const bet = typeBetOptions.find((item: ProgramSelectOptions) => item.name.trim() === val.trim())
        if (bet !== undefined) {
          ids.push(bet.code)
        }
      })
    }
    if (ids.length > 0) {
      return ids.join('|')
    }
    return ''
  }

  const getProgramSelectOptionsByEntity = (entity: string): [] | ProgramSelectOptions[] => {
    if (programSelectOptions && programSelectOptions.length > 0) {
      return programSelectOptions.filter((item: ProgramSelectOptions) => item.entity === entity)
    } else {
      return []
    }
  }

  const handleRowSelectedToRemove = (params: GridCellParams) => {
    const raceToRemove = params.row as RaceModel
    setRaceToRemove(raceToRemove)
    setShowAlertRemoveRace(true)
    if (raceToRemove.id) {
      fetchRunnersByRaceId(raceToRemove.id)
    }
  }

  const handleRemoveRace = () => {
    if (runnerResults.results.length > 0) {
      errorNotification(transSystem('DEBES_ELIMINAR_LOS_CORREDORES_PARA_PODER_BORRAR_ESTA_CARRERA'))
    } else if (raceToRemove) {
      try {
        const response = removeRace({
          variables: {
            id: raceToRemove.id,
          },
        })
        setShowAlertRemoveRace(false)
        if (event.id) {
          fetchRacesByEventId(event.id)
        }
        successNotification(transSystem('DATO_BORRADO'))
      } catch (e) {
        console.log(e)
      }
    }
  }

  return (
    <Loader loading={false}>
      <FormikProvider value={formik}>
        <Form>
          <Card>
            <CardHeader
              title={<HeaderCardTitle title={transLabel('CARRERAS')} />}
              action={
                isEditable && event.isActive ? (
                  <React.Fragment>
                    {formState === 'CANCEL' && actions.canCreate && <CreateButton onClick={handleCreate} />}
                    {formState !== 'CANCEL' && actions.canCreate && actions.canEdit && (
                      <>
                        <SaveButton
                          onClick={formik.submitForm}
                          isLoading={updateLoading || addLoading}
                          disabled={!(formik.dirty && formik.dirty)}
                        />{' '}
                        <ClearButton onClick={() => handleCancel('CANCEL')} label={'CANCEL'} />
                      </>
                    )}
                  </React.Fragment>
                ) : null
              }
            />
            <Divider className={classes.divider} />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <RaceForm
                    formik={formik}
                    onFilterProgramOptions={onFilterProgramOptions}
                    formState={formState}
                    race={race}
                    onSave={handleSave}
                    isFormDisabled={formState !== 'CANCEL'}
                    programSelectOptions={programSelectOptions}
                    possibleWagers={possibleWagers}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Loader loading={loading}>
                    <Races
                      races={racesInfo}
                      onSelectRow={handleSelectedRace}
                      event={event}
                      onSelectToRemove={handleRowSelectedToRemove}
                    />
                  </Loader>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Form>
      </FormikProvider>
      <DialogAlerts
        state={alertForm}
        titulo={'SE_HAN_DETECTADO_CAMBIOS_EN_EL_FORMULARIO'}
        contenido={'SI_CONTINUA_PERDERA_LOS_CAMBIOS_EN_EL_FORMULARIO_DESEA_CONTINUAR'}
        tipo="PREGUNTA"
        onSecondaryClick={() => setAlertForm(false)}
        handleClose={() => setAlertForm(false)}
        onPrimaryClick={() => history.push(`/admin/entities/events`)}
      />
      <DialogAlerts
        state={showAlertRemoveRace}
        titulo={'ELIMINAR_ITEM'}
        contenido={'DESEAR_ELIMINAR_ESTE_ITEM'}
        tipo="PREGUNTA"
        onSecondaryClick={() => setShowAlertRemoveRace(false)}
        handleClose={() => setShowAlertRemoveRace(false)}
        onPrimaryClick={handleRemoveRace}
      />
    </Loader>
  )
}
