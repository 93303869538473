import { gql, useLazyQuery, useQuery } from '@apollo/client'
import { useCallback, useEffect, useState } from 'react'
import { Metadata, ResultsList, SearchGraphql } from '../../../../core/models'

const GET_PROVIDER_CONNECTION = gql`
  query GetProviderApiConnection($id: Int!) {
    providerApiConnection(providerApiConnectionId: $id) {
      id
      accessKey
      apiBaseUrl
      isActive
      lastSyncDate
      providerId
      version
    }
  }
`

export const useProviderConnection = () => {
  const [id, setId] = useState<number | undefined>(undefined)

  const { loading, error, data } = useQuery(GET_PROVIDER_CONNECTION, {
    variables: { id },
  })

  const getProviderConnection = (id: number) => {
    setId(id)
  }

  return { getProviderConnection, loading, error, data }
}
