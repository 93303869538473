import React from 'react'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'
import Chip from '@material-ui/core/Chip'
import { useTranslation } from 'react-i18next'

type StatusChipsProps = {
  status: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    active: {
      backgroundColor: '#E5F9ED',
      color: '#1BC943',
      height: '70%',
      borderRadius: '5px',
      fontWeight: 'bold',
      fontSize: '11px',
    },
    inActive: {
      backgroundColor: '#F5F5F7',
      color: '#7A7B97',
      height: '70%',
      borderRadius: '5px',
      fontWeight: 'bold',
      fontSize: '11px',
    },
  }),
)

export const StatusChips = ({ status }: StatusChipsProps) => {
  const classes = useStyles()
  const { t: translation } = useTranslation('Label')

  return status === true ? (
    <Chip label={translation('ACTIVO')} className={classes.active} />
  ) : (
    <Chip label={translation('INACTIVO')} className={classes.inActive} />
  )
}
