import React, { useCallback } from 'react'
import { Grid, CardContent, Card } from '@material-ui/core'
import { AppBreadcrumbsBar, CustomDataGrid, DataGridColumns, Loader } from '../../../core/components'
import { useTranslation } from 'react-i18next'
import { AgencyOperationsResults } from './AgencyOperationsResults'

export const AgencyOperations = () => {
  const { t: translationLabel } = useTranslation('Label')

  const getColumnDefinitions = useCallback((): DataGridColumns => {
    return {
      PRODUCTO: { field: 'id', contentType: 'id' },
      VENTA: { field: 'typeTicket', contentType: 'localized' },
      TICKETS: { field: 'salesTime', contentType: 'date' },
      PAGO: { field: 'currency', contentType: 'localized' },
    }
  }, [])

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <AppBreadcrumbsBar
          title={translationLabel('RESUMEN_OPERACIONES_TITULO')}
          description={`${translationLabel('RESUMEN_OPERACIONES_SUBTITULO')}`}
        />
      </Grid>
      <Grid item xs={12}>
        {/*  onChange={handleChangeFilter} getWeeksDate={getWeeksDate} */}
        <AgencyOperationsResults />
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Loader loading={false}>
              <div style={{ minHeight: '70vh', width: '100%' }}>
                <CustomDataGrid
                  // onRowClick={rowSelectHandler}
                  columns={getColumnDefinitions()}
                  pageSize={20}
                  data={{ results: [], metadata: {} }}
                  // onPageChange={handlePageChange}
                />
              </div>
            </Loader>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}
