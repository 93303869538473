import React from 'react'
import { Button } from '@material-ui/core'
import Search from '@material-ui/icons/Search'
import { useTranslation } from 'react-i18next'

type buttonProps = {
  onClick: () => void
  className?: string
  disabled?: boolean
}
export const SearchButton = ({ onClick, disabled, ...props }: buttonProps) => {
  const { t: transAction } = useTranslation('Action')

  return (
    <Button {...props} variant="contained" color="primary" onClick={onClick} disabled={disabled} startIcon={<Search />}>
      {transAction('CONSULTAR')}
    </Button>
  )
}
