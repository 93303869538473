import React from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete, { AutocompleteChangeReason } from '@material-ui/lab/Autocomplete'
import CircularProgress from '@material-ui/core/CircularProgress'

type CustomAutoCompleteSelectProps = {
  keyValue: string
  keyLabel?: string
  data: any[]
  isLoading?: boolean
  label?: string
  defaultValue?: any
  onChangeValue?: (value: any, action: string) => void
  onInputValueChange?: (value: string) => void
  disabled?: boolean
  multiple?: boolean
  props?: any
}

export const CustomAutoCompleteSelect = ({
  keyValue,
  keyLabel,
  data,
  isLoading,
  label,
  onChangeValue,
  onInputValueChange,
  defaultValue,
  disabled,
  multiple,
  ...props
}: CustomAutoCompleteSelectProps) => {
  const [open, setOpen] = React.useState(false)
  const [selectedOption, setSelectedOption] = React.useState(multiple ? [] : null)
  const [inputValue, setInputValue] = React.useState('')

  const onSelect = (event: React.ChangeEvent<any>, value: any, reason: AutocompleteChangeReason) => {
    setSelectedOption(value)
    if (onChangeValue instanceof Function) {
      onChangeValue(value, reason)
    }
  }

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setInputValue(value)
    if (onInputValueChange instanceof Function) {
      onInputValueChange(value)
    }
  }

  return (
    <Autocomplete
      {...props}
      disabled={disabled}
      defaultValue={multiple ? [] : null}
      value={selectedOption ? selectedOption : defaultValue}
      onChange={onSelect}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      getOptionSelected={(option, value) => option[keyValue] === value[keyValue]}
      getOptionLabel={(option) => option[keyLabel ? keyLabel : keyValue]}
      options={data}
      loading={isLoading}
      multiple={multiple}
      renderInput={(params) => (
        <TextField
          {...params}
          onChange={onInputChange}
          value={inputValue}
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      ListboxProps={{
        onScroll: (event: React.SyntheticEvent) => {
          const listboxNode = event.currentTarget
          if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
            console.log('More data')
          }
        },
      }}
    />
  )
}
