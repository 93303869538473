import { gql, useMutation } from '@apollo/client'

const ADD_PROGRAM_OWNER = gql`
  mutation CreateProgramOwner($input: AddProgramOwnerInput!) {
    addProgramOwner(input: $input) {
      programOwner {
        name
      }
    }
  }
`
export interface AddProgramOwnerInput {
  name: string
  trainerId: number
  isActive: boolean
}

export const useAddProgramOwner = () => {
  const [addProgramOwner, { loading, error }] = useMutation<AddProgramOwnerInput>(ADD_PROGRAM_OWNER)
  return { addProgramOwner, loading, error }
}
