import React, { useCallback } from 'react'
import { FormProps } from '../../models'
import { useTranslation } from 'react-i18next'
import { RadioGroup, FormControlLabel, FormLabel, Radio } from '@material-ui/core'

export interface StringRadioButtonProps extends FormProps {
  title: string
  label1: string
  label2: string
  value1: string
  value2: string
  disabled?: boolean
  onChange?: (value: string) => void
}

export const StringRadioButtonFormik = ({
  label1,
  label2,
  value1,
  value2,
  disabled,
  title,
  onChange,
  ...props
}: StringRadioButtonProps) => {
  const {
    form: { setFieldValue, values },
    field: { name },
  } = props

  // App Label Translation
  const { t: translation } = useTranslation('Label')

  const handleChange = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      setFieldValue(name, event.target.value === value1 ? value1 : value2)
      if (onChange instanceof Function) {
        onChange(event.target.value === value1 ? value1 : value2)
      }
    },
    [setFieldValue, name, onChange, value1, value2],
  )

  return (
    <React.Fragment>
      <FormLabel component="legend">{translation(title)}</FormLabel>
      <RadioGroup
        row
        aria-label={name}
        name={name}
        value={values[name] === value1 ? value1 : value2}
        onChange={handleChange}
      >
        <FormControlLabel
          value={value1}
          control={<Radio color="primary" disabled={disabled} />}
          label={translation(label1)}
        />
        <FormControlLabel
          value={value2}
          control={<Radio color="primary" disabled={disabled} />}
          label={translation(label2)}
        />
      </RadioGroup>
    </React.Fragment>
  )
}
