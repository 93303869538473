import { gql, useQuery } from '@apollo/client'
import { useState } from 'react'

const GET_PROVIDER = gql`
  query GetProvider($id: Int!) {
    provider(providerId: $id) {
      id
      acronym
      name
      closingTime

      description
      generalInfo
      isActive
      isVirtualGame
      openingTime
      timeZone
    }
  }
`

export const useProvider = () => {
  const [id, setId] = useState<number | undefined>(undefined)

  const { loading, error, data } = useQuery(GET_PROVIDER, {
    variables: { id },
  })

  const getProvider = (id: number) => {
    setId(id)
  }

  return { getProvider, loading, error, data }
}
