import { useSnackbar } from 'notistack'

interface EnqueueOptions {
  /** Type of the snackbar */
  variant: 'default' | 'error' | 'success' | 'warning' | 'info'
  /** Event fired when user clicks on action button (if any) */
  onClickAction(): void
  /**
   * You can pass material-ui Snackbar props here, and they will be applied to this individual snackbar.
   * for example, this particular snackbar will be dismissed after 1sec.
   */
  autoHideDuration: number
}

interface EnqueueSnackbarOptions {
  options?: Partial<EnqueueOptions>
}

/**
 * https://iamhosseindhv.com/notistack
 * @description This show notification in app
 * @example Wrapping notistick lib to manage all notifications
 * @returns [
 *              successNotification: show a green bar for success Notifications,
 *              errorNotification: show a red bar error Notifications,
 *           ]
 */
export const useNotification = () => {
  // Manage notifications
  const { enqueueSnackbar } = useSnackbar()

  const successNotification = async (message: string, options?: EnqueueSnackbarOptions) => {
    enqueueSnackbar(message, { variant: 'success', ...options })
  }

  const errorNotification = async (message: string, options?: EnqueueSnackbarOptions) => {
    enqueueSnackbar(message, { variant: 'error', ...options })
  }

  const warningNotification = async (message: string, options?: EnqueueSnackbarOptions) => {
    enqueueSnackbar(message, { variant: 'warning', ...options })
  }

  const infoNotification = async (message: string, options?: EnqueueSnackbarOptions) => {
    enqueueSnackbar(message, { variant: 'info', ...options })
  }

  return { successNotification, errorNotification, warningNotification, infoNotification }
}
