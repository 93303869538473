import { useState, useCallback, useEffect } from 'react'
import { GridPageChangeParams, GridRowParams } from '@material-ui/data-grid'
import { createStyles, Grid, makeStyles, Theme, Card, CardContent, CardHeader, Divider } from '@material-ui/core'
// My Components
import { UsersFilters, UsersFiltersModel } from './UsersFilters'
import { HeaderCardTitle } from '../../../core/components'
import { CustomDataGrid, CreateButton, DataGridColumns, AppBreadcrumbsBar, Loader } from '../../../core/components'

// Hooks
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// Models
import { useUsers } from './hooks'
import {
  generateFilterGraphql,
  isAdminSelector,
  canDoesActionSelector,
  getAppBarState,
  ClaimActions,
  useUserClaimActions,
} from '../../../core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    grid: {
      paddingBottom: theme.spacing(2),
    },
  }),
)

export const Users = () => {
  const classes = useStyles()
  const { validateClaimActions } = useUserClaimActions()
  const actions = validateClaimActions('security.users', [
    ClaimActions.CanCreate,
    ClaimActions.CanSearch,
    ClaimActions.CanView,
  ])

  // React router navigation
  const history = useHistory()
  // App Label Translation
  const { t: transLabel } = useTranslation('Label')
  const { results, loading, getUsers } = useUsers()

  // Filter values
  const [paging, setPaging] = useState<GridPageChangeParams>({
    page: 0,
    pageCount: 0,
    pageSize: 10,
    paginationMode: 'server',
    rowCount: 10,
  })

  useEffect(() => {
    const customFilters = {
      and: [{ userName: { ...generateFilterGraphql('', 'contains') } }],
    }

    getUsers({ where: customFilters, skip: paging.page * paging.pageSize, take: paging.pageSize })
  }, [paging])

  const handleUsersFilters = (values: UsersFiltersModel) => {
    getUsers({
      where: {
        or: [
          { userName: { ...generateFilterGraphql(values.searchBy, 'contains') } },
          { givenName: { ...generateFilterGraphql(values.searchBy, 'contains') } },
          { familyName: { ...generateFilterGraphql(values.searchBy, 'contains') } },
          { email: { ...generateFilterGraphql(values.searchBy, 'contains') } },
        ],
      },
      skip: paging.page * paging.pageSize,
      take: paging.pageSize,
    })
  }

  const getColumnDefinitions = useCallback((): DataGridColumns => {
    return {
      USUARIO: { field: 'userName', contentType: 'string' },
      NOMBRES: { field: 'givenName', contentType: 'string' },
      APELLIDOS: { field: 'familyName', contentType: 'string' },
      EMAIL: { field: 'email', contentType: 'string' },
    }
  }, [])

  const handlePageChange = (param: GridPageChangeParams) => {
    setPaging(param)
  }

  const handlePageSizeChange = (param: GridPageChangeParams) => {
    setPaging(param)
  }

  const handleRowSelected = (params: GridRowParams) => {
    history.push(`/admin/security/users/${params.id}`)
  }

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <AppBreadcrumbsBar
              title={transLabel('USUARIOS_EMPLEADOS')}
              description={transLabel('GESTION_DE_USUARIOS_Y_EMPLEADOS')}
              actions={
                actions.canCreate ? <CreateButton onClick={() => history.push(`/admin/security/users/create`)} /> : null
              }
            />
          </Grid>
          <Grid item xs={12} className={classes.grid}>
            {actions.canSearch ? <UsersFilters onSearch={handleUsersFilters} /> : null}
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardHeader component={() => <HeaderCardTitle title={transLabel('DATOS_DEL_USUARIO_EMPLEADO')} />} />
              <Divider />
              <CardContent>
                <Loader loading={loading}>
                  <div style={{ minHeight: '70vh', width: '100%' }}>
                    <CustomDataGrid
                      data={results}
                      columns={getColumnDefinitions()}
                      page={paging.page}
                      pageSize={paging.pageSize}
                      onRowClick={handleRowSelected}
                      onPageChange={handlePageChange}
                      onPageSizeChange={handlePageSizeChange}
                    />
                  </div>
                </Loader>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
