import React from 'react'
import { createStyles, makeStyles, Button } from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() =>
  createStyles({
    buttonStyle: {
      backgroundColor: '#e0e0e0',
    },
  }),
)

type buttonProps = {
  onClick: () => void
  className?: string
  label?: string
  disabled?: boolean
}
export const ClearButton = ({ onClick, label, disabled, ...props }: buttonProps) => {
  const classes = useStyles()
  const { t: transAction } = useTranslation('Action')

  return (
    <Button
      {...props}
      className={classes.buttonStyle}
      variant="contained"
      onClick={onClick}
      startIcon={<ClearIcon />}
      disabled={disabled}
    >
      {transAction(label ? label : 'LIMPIAR')}
    </Button>
  )
}
