import React from 'react'
import { Card, CardContent, CardHeader, createStyles, makeStyles, Typography, Grid, Theme } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { HeaderCardTitle } from './HeaderCardTitle'
import { formatAsCurrency } from '../../services'

import Divider from '@material-ui/core/Divider'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      color: '#969696',
      fontSize: 'large',
    },
    cardStyle: {
      padding: theme.spacing(2),
      width: '100%',
    },
    label: {
      color: '#969696',
      fontSize: '1rem',
      marginTop: 'inherit',
    },
    balance: {
      fontSize: '2em',
      marginTop: '-10px',
      marginBottom: '-10px',
    },
    balanceWithTitleGrid: {
      textAlign: 'center',
    },
    onlyBalanceGrid: {
      textAlign: 'center',
    },
    detailTitle: {
      fontSize: '1rem',
      color: '#969696',
    },
    detailTotal: {
      fontSize: 'large',
    },
    detailBalance: {
      fontSize: 'large',
      textAlign: 'right',
    },
    detailRoot: {
      marginTop: '-15px',
    },
  }),
)

export interface SalesTotalDetailsCardProps {
  title: string | React.ReactElement
  balance?: number
  balanceTitle: string
  directDetailTitle: string
  directDetailTotal?: number
  directDetailBalance?: number
  combineDetailTitle: string
  combineDetailTotal?: number
  combineDetailBalance?: number
}

export const SalesTotalDetailsCard = ({ title, balance, balanceTitle, ...props }: SalesTotalDetailsCardProps) => {
  const classes = useStyles()
  const { t: translation } = useTranslation('Label')
  const {
    directDetailTitle,
    directDetailBalance,
    directDetailTotal,
    combineDetailTitle,
    combineDetailBalance,
    combineDetailTotal,
  } = props

  return (
    <Card className={classes.cardStyle}>
      <CardHeader component={() => <HeaderCardTitle title={title} classStyle={classes.header} />} />
      <CardContent>
        <Grid container item lg={12} sm={12} spacing={2}>
          <Grid item lg={3} xs={12} sm={12} className={classes.balanceWithTitleGrid}>
            <Typography component="h6" variant="h6" className={classes.balance}>
              {balance}
            </Typography>
            <Typography variant="subtitle1" color="textSecondary" className={classes.label}>
              {translation(balanceTitle)}
            </Typography>
          </Grid>
          <Grid item lg={3} xs={12} sm={12} className={classes.onlyBalanceGrid}>
            <Typography component="h6" variant="h6" className={classes.balance}>
              {formatAsCurrency(balance, 0)}
            </Typography>
          </Grid>

          <Grid item lg={6} xs={12} sm={12} className={classes.detailRoot}>
            <Grid container item lg={12} xs={12} sm={12} spacing={2}>
              <Grid item lg={6} xs={6} sm={6} className={classes.detailTitle}>
                {translation(directDetailTitle)}
              </Grid>
              <Grid item lg={3} xs={3} sm={3} className={classes.detailTotal}>
                {directDetailTotal}
              </Grid>
              <Grid item lg={3} xs={3} sm={3} className={classes.detailBalance}>
                {formatAsCurrency(directDetailBalance, 0)}
              </Grid>
            </Grid>
            <Divider />
            <Grid container item lg={12} xs={12} sm={12} spacing={2}>
              <Grid item lg={6} xs={6} sm={6} className={classes.detailTitle}>
                {translation(combineDetailTitle)}
              </Grid>
              <Grid item lg={3} xs={3} sm={3} className={classes.detailTotal}>
                {combineDetailTotal}
              </Grid>
              <Grid item lg={3} xs={3} sm={3} className={classes.detailBalance}>
                {formatAsCurrency(combineDetailBalance, 0)}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
