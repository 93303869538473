import React from 'react'
import { AuthConsumer } from 'pb-shared'
import { Button } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { MeetingRoom } from '@material-ui/icons'

export const LogoutButton = () => {
  const { t } = useTranslation('Label')
  return (
    <AuthConsumer>
      {({ logout }) => (
        <Button onClick={logout} color="inherit" startIcon={<MeetingRoom />}>
          {t('SALIR')}
        </Button>
      )}
    </AuthConsumer>
  )
}
