import React, { useEffect, useState } from 'react'
import {
  Button,
  Grid,
  makeStyles,
  Theme,
  createStyles,
  Card,
  CardHeader,
  Divider,
  CardContent,
} from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'
import { Form, Formik, Field } from 'formik'
import { TextField } from 'formik-material-ui'
import { useHistory, RouteProps, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { isMobile, useUserClaimActions, ClaimActions } from '../../../core'
import { HeaderCardTitle, LocalizedContentSelect, SaveButton } from '../../../core/components'
import { AppBreadcrumbsBar } from '../../../core/components/appBar/AppBreadcrumbsBar'
import { useLocalizedContent } from '../../../core/hooks'
import { ClientAccountConfirmationSearch } from './Filters/ClientAccountConfirmationSearch'
import { useClientAccount } from './hooks'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fieldItem: {
      marginBottom: theme.spacing(2),
    },
    buttonActions: {
      marginLeft: theme.spacing(2),
    },
    fields: {
      minWidth: isMobile() ? '100%' : '50%',
    },
  }),
)
interface ClientAccountTransactions {
  concepto?: string
  monto?: number
  justificacion?: string
}

const initialValues: ClientAccountTransactions = {
  concepto: '',
  monto: undefined,
  justificacion: '',
}

const formSchema = Yup.object().shape({
  concepto: Yup.string().required('El concepto es requerido!'),
  justificacion: Yup.string().required('La justificacion es requerido!'),
  monto: Yup.number().required('El monto es requerido!'),
})

export const CreateClientAccountTransactions: React.FC<RouteProps> = () => {
  const [hiddenButtonBack, setHiddenButtonBack] = useState(false)
  const { t: translationLabel } = useTranslation('Label')
  const { t: translationAction } = useTranslation('Action')
  const history = useHistory()
  const classes = useStyles()
  // const { results } = useLocation()
  const [accountPin, setAccountPing] = useState<string | undefined | any>(history.location.state)
  const { results } = useLocalizedContent({ entityType: 'System', entityName: 'AccountTypePayment' })
  const { getClientAccount, clientAccountData } = useClientAccount()
  const { validateClaimActions } = useUserClaimActions()
  const actions = validateClaimActions('transactions.client-accounts', [
    ClaimActions.CanCreate,
    ClaimActions.CanSearch,
    ClaimActions.CanView,
  ])

  useEffect(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const optionButtonBack = urlParams.get('hiddenButtonBack')
    optionButtonBack ? setHiddenButtonBack(false) : setHiddenButtonBack(true)

    if (accountPin) {
      getClientAccount(accountPin)
    }
  }, [accountPin])

  const handleAccountFilters = (value: string) => {
    setAccountPing(value)
  }

  const disabledFields = clientAccountData === undefined
  return (
    <Formik
      validationSchema={formSchema}
      initialValues={{ ...initialValues }}
      onSubmit={() => {
        // save values
      }}
      enableReinitialize
    >
      {({ submitForm, handleBlur, handleChange, values, errors, isValid, dirty }) => {
        return (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <AppBreadcrumbsBar
                title={translationLabel('AJUSTE_DE_CUENTAS_DE_CLIENTE')}
                description={translationLabel('CUENTAS_Y_BALANCES_DE_LOS_CLIENTES')}
                actions={
                  <React.Fragment>
                    {actions.canCreate && (
                      <SaveButton
                        className={classes.buttonActions}
                        onClick={submitForm}
                        disabled={!(dirty && isValid)}
                        isLoading={false}
                      />
                    )}
                    {hiddenButtonBack && (
                      <Link to="/admin/transactions/client-account">
                        <Button className={classes.buttonActions} variant="contained" startIcon={<ArrowBack />}>
                          {translationAction('REGRESAR')}
                        </Button>
                      </Link>
                    )}
                  </React.Fragment>
                }
              />
            </Grid>
            {actions.canSearch && (
              <Grid item lg={12} xs={12}>
                <ClientAccountConfirmationSearch
                  onSearch={handleAccountFilters}
                  account={clientAccountData}
                  initialPin={accountPin}
                />
              </Grid>
            )}
            <Grid item lg={12} xs={12}>
              <Card>
                <CardHeader component={() => <HeaderCardTitle title={translationLabel('AJUSTE_DE_LA_CUENTA')} />} />
                <Divider />
                <CardContent>
                  <Form>
                    <Grid item container lg={12} sm={12} xs={12}>
                      <Grid item lg={12} sm={12} xs={12}>
                        <Field
                          disabled={disabledFields}
                          component={LocalizedContentSelect}
                          label={translationLabel('CONCEPTO')}
                          name="concepto"
                          className={classes.fields}
                          data={results}
                          isGrupalValue
                        />
                      </Grid>
                      <Grid item lg={12} sm={12} xs={12}>
                        <Field
                          disabled={disabledFields}
                          component={TextField}
                          type="number"
                          label={translationLabel('MONTO')}
                          name="monto"
                          className={classes.fields}
                        />
                      </Grid>
                      <Grid item lg={12} sm={12} xs={12}>
                        <Field
                          disabled={disabledFields}
                          component={TextField}
                          type="text"
                          label={translationLabel('JUSTIFICACION')}
                          name="justificacion"
                          multiline
                          className={classes.fields}
                        />
                      </Grid>
                    </Grid>
                  </Form>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        )
      }}
    </Formik>
  )
}
