import { applyMiddleware, combineReducers, createStore } from 'redux'
import thunk, { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { Actions } from './actions'
import { AppBarState, appBarReducer as appBar } from './reducer'

export interface AppState {
  appBar: AppBarState
}

export type AppThunk<R = void> = ThunkAction<R, AppState, {}, Actions>

export type AppDispatch = ThunkDispatch<AppState, {}, Actions>

const middleware = applyMiddleware<AppDispatch>(thunk)

const rootReducer = combineReducers<AppState, Actions>({
  appBar,
})

export const store = createStore(rootReducer, middleware)

export const getAppBarState = (): AppState => {
  return store.getState()
}
