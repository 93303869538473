import React, { useEffect, useState } from 'react'
import { makeStyles, createStyles, Theme, Grid, Card, CardContent, Divider, CardHeader } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { RunnerForm } from './RunnerForm'
import {
  generateFilterGraphql,
  useNotification,
  useUserClaimActions,
  ClaimActions,
  dateIsBeforeThanTodayMoment,
} from '../../../../core'
import { useTranslation } from 'react-i18next'
import { useAddProgramRunner, useRunners, useUpdateProgramRunner, useRace, useRemoveRunner } from '../hooks'
import { Runner as RunnerModel, ProgramSelectOptions, Event, Race } from '../models'
import { differenceBetweenTwoObjects as difference } from '../../../../core/services/genericFunctions'
import _ from 'lodash'
import {
  ClearButton,
  CreateButton,
  DialogAlerts,
  HeaderCardTitle,
  Loader,
  SaveButton,
} from '../../../../core/components'
import { Runners } from './Runners'
import { Form, FormikProvider, useFormik } from 'formik'
import * as Yup from 'yup'
import { GridCellParams } from '@material-ui/data-grid'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      marginTop: -22,
    },
  }),
)

type RunnerProps = {
  race: Race
  programSelectOptions: [] | ProgramSelectOptions[]
  onFilterProgramOptions: (filter: string) => void
  onRunnerStatusChange: (state: string) => void
  event: Event
}

type FormState = 'CREATE' | 'EDIT' | 'CANCEL'

const initialFormValues = {
  id: 0,
  programRaceId: 0,
  number: '',
  odds: '',
  position: '',
  name: '',
  weight: 90,
  competitorId: 0,
  jockeyOrKennelId: 0,
  trainerId: 0,
  ownerId: 0,
  medication: '',
  jockeyOrKennel: '',
  trainerName: '',
  ownerName: '',
  isActive: true,
}

export const Runner = ({
  race,
  programSelectOptions,
  onFilterProgramOptions,
  onRunnerStatusChange,
  event,
}: RunnerProps) => {
  const classes = useStyles()
  const [runner, setRunner] = useState<RunnerModel | undefined>(undefined)
  const { addProgramRunner, loading: addLoading } = useAddProgramRunner()
  const { updateProgramRunner, loading: updateLoading } = useUpdateProgramRunner()
  const { results, loading: loadingRunner, getRunners } = useRunners()
  // const { race, getRace } = useRace()
  const [alertForm, setAlertForm] = useState(false)
  const [formState, setFormState] = useState<FormState>('CANCEL')
  const { successNotification, errorNotification } = useNotification()
  const { t: transLabel } = useTranslation('Label')
  const { t: transSystem } = useTranslation('System')
  const { validateClaimActions } = useUserClaimActions()
  const actions = validateClaimActions('configuration.events', [ClaimActions.CanCreate, ClaimActions.CanEdit])
  const history = useHistory()
  const [showAlertRemove, setShowAlertRemove] = useState(false)
  const [runnerToRemove, setRunnerToRemove] = useState<RunnerModel | undefined>(undefined)
  const { removeRunner, error } = useRemoveRunner()

  useEffect(() => {
    resetForm()
    setFormState('CANCEL')
    if (race.id) {
      fetchRunnersByRaceId(race.id)
    }
  }, [race])

  useEffect(() => {
    onRunnerStatusChange(formState)
  }, [formState])

  const fetchRunnersByRaceId = (raceId: number) => {
    const customFilters = {
      and: [{ programRaceId: { ...generateFilterGraphql(raceId, 'eq') } }],
    }
    getRunners({ where: customFilters, skip: 0, take: 100 })
  }

  const handleSave = (runnerToSave: RunnerModel) => {
    if (race && race.id && isRunnerValid(runnerToSave)) {
      runnerToSave.programRaceId = race.id
      if (runnerToSave.number > race.runners) {
        errorNotification(transSystem('ERROR_NUMERO_CORREDOR_MAYOR_A_NUMERO_CARRERAS'))
      } else {
      }
      if (runnerToSave.id === undefined || runnerToSave.id == 0) {
        const response = addProgramRunner({
          variables: {
            input: {
              programRaceId: runnerToSave.programRaceId,
              number: String(runnerToSave.number),
              odds: runnerToSave.odds,
              position: runnerToSave.number,
              competitorId: runnerToSave.competitorId?.id,
              jockeyOrKennelId: runnerToSave.jockeyOrKennelId?.id,
              trainerId: runnerToSave.trainerId,
              ownerId: runnerToSave.ownerId,
              weight: runnerToSave.weight,
              medication: getMedicationCode(runnerToSave.medication),
              isActive: runnerToSave.isActive,
              programEventId: event.id,
            },
          },
        })
        response
          .then((result: any) => {
            successNotification(transSystem('DATO_REGISTRADO'))
            if (race.id) {
              fetchRunnersByRaceId(race.id)
            }
            resetForm()
            handleCancel('CANCEL')
          })
          .catch((e) => {
            errorNotification(transSystem(e.message))
          })
      } else if (runner) {
        const response = updateProgramRunner({
          variables: {
            id: runnerToSave.id,
            input: {
              number: String(runnerToSave.number),
              odds: runnerToSave.odds,
              position: Number.parseInt(runnerToSave.number),
              competitorId: runnerToSave.competitorId?.id,
              jockeyOrKennelId: runnerToSave.jockeyOrKennelId?.id,
              trainerId: runnerToSave.trainerId,
              ownerId: runnerToSave.ownerId,
              weight: runnerToSave.weight,
              medication: getMedicationCode(runnerToSave.medication),
              isActive: runnerToSave.isActive,
            },
          },
        })
        response
          .then(() => {
            successNotification(transSystem('DATO_ACTUALIZADO'))
            if (race.id) {
              fetchRunnersByRaceId(race.id)
            }
            handleCancel('CANCEL')
          })
          .catch((e) => {
            errorNotification(transSystem(e.message))
          })
      } else {
        errorNotification(transSystem('ERROR_CORREDOR_NO_ENCONTRADO'))
      }
    }
  }

  const isRunnerValid = (runnerToValidate: RunnerModel): boolean => {
    let isValid = true
    results.results.map((item: RunnerModel) => {
      if (runnerToValidate.id !== item.id) {
        if (runnerToValidate.competitorId === item.competitorId) {
          errorNotification(transSystem('ERROR_COMPETIDOR_EN_USO'))
          isValid = false
        }
        if (runnerToValidate.position === item.position) {
          errorNotification(transSystem('ERROR_POSICION_EN_USO_POR_OTRA_CARRERA'))
          isValid = false
        }
        if (runnerToValidate.number === item.number) {
          errorNotification(transSystem('ERROR_NUMERO_EN_USO'))
          isValid = false
        }
      }
    })
    return isValid
  }

  const handleSelectedRunner = (runner: RunnerModel) => {
    const runnerClone = _.cloneDeep(runner)
    // Medication
    const medication = medicationConvertFromCodeToName(runnerClone.medication)
    runnerClone.medication = medication.join('')

    // Competitor
    runnerClone.competitorId = {
      id: runner.competitorId,
      name: runner.competitorName,
    }

    // Jockey
    runnerClone.jockeyOrKennelId = {
      id: runner.jockeyOrKennelId,
      name: runner.jockeyOrKennelName,
    }

    setRunner(runnerClone)

    if (event.isActive) {
      setFormState('EDIT')
    }
  }

  const medicationConvertFromCodeToName = (codes: String): string[] => {
    const medicationOptions = getProgramSelectOptionsByEntity('Medication')
    const medicationValues: string[] = []
    const medicationsCodes = codes.split('')
    medicationOptions.forEach((item) => {
      if (medicationsCodes.includes(item.code)) {
        console.log(item)
        medicationValues.push(`${item.name},`)
      }
    })
    return medicationValues
  }

  const formSchema = Yup.object().shape({
    number: Yup.number()
      .min(1, transLabel('SOLO_VALORES_ENTRE') + ' 1-999')
      .max(999, transLabel('SOLO_VALORES_ENTRE') + ' 1-999')
      .required(transLabel('REQUERIDO')),
    odds: Yup.string().max(8, '(8) ' + transLabel('MAXIMO_CARACTERES')),
    // position: Yup.number()
    //   .min(1, transLabel('SOLO_VALORES_ENTRE') + ' 1-99')
    //   .max(99, transLabel('SOLO_VALORES_ENTRE') + ' 1-99')
    //   .required(transLabel('REQUERIDO')),
    competitorId: Yup.object()
      .nullable()
      .required(transLabel('REQUERIDO'))
      .test('test_competitorId', '', function (competitor) {
        return competitor ? true : this.createError({ message: transLabel('REQUERIDO') })
      }),
    weight: Yup.number()
      .min(90, transLabel('SOLO_VALORES_ENTRE') + ' 90-140')
      .max(140, transLabel('SOLO_VALORES_ENTRE') + ' 90-140')
      .required(transLabel('REQUERIDO')),
    medication: Yup.string()
      .required(transLabel('REQUERIDO'))
      .test('test_medication_length', '', function (medications) {
        return medications && medications.split(',').length > 3
          ? this.createError({ message: transLabel('MAX_MEDICAMENTOS') })
          : true
      }),
  })

  const formik = useFormik({
    validationSchema: formSchema,
    initialValues: runner || initialFormValues,
    onSubmit: handleSave,
    enableReinitialize: true,
  })

  useEffect(() => {
    if (race) {
      if (formik.values['number'] >= race.runners) {
        // errorNotification(transSystem('ERROR_NUMERO_CORREDOR_MAYOR_A_NUMERO_CARRERAS'))
      } else if (formState === 'CREATE' && formik.values['number'] === '') {
        formik.setFieldValue('number', results.results.length + 1)
      }
    }
  }, [formState, formik, race])

  window.sessionStorage.setItem('hasFormChanges', formik.dirty ? 'true' : 'false')

  const handleCancel = (formState: FormState) => {
    setFormState(formState)
    resetForm()
  }

  const resetForm = () => {
    setRunner(undefined)
    formik.resetForm()
    formik.setFieldValue('trainerName', '')
    formik.setFieldValue('trainerId', 0)
    formik.setFieldValue('ownerName', { id: '', name: '' })
    formik.setFieldValue('competitorId', { id: 0, name: '' })
    formik.setFieldValue('jockeyOrKennelId', { id: '', name: '' })
    formik.setFieldValue('jockeyOrKennelName', '')
  }

  const handleCreate = () => {
    if (!race) {
      errorNotification(transSystem('DEBE_SELECCIONAR_UNA_CARRERA'))
    } else if (race.runners <= results.results.length) {
      errorNotification(`${transSystem('ERROR_CORREDOR_MAXIMO_PERMITIDO')} ${race.runners}`)
    } else {
      resetForm()
      handleCancel('CREATE')
    }
  }

  const getMedicationCode = (value: string): string => {
    const medicationOptions = getProgramSelectOptionsByEntity('Medication')
    const values: string[] = value.split(',')
    const ids: string[] = []
    if (medicationOptions.length > 0 && values.length > 0) {
      values.forEach((val: string) => {
        const medication = medicationOptions.find((item: ProgramSelectOptions) => {
          return item.name == val
        })
        if (medication !== undefined) {
          ids.push(medication.code)
        }
      })
    }
    if (ids.length > 0) {
      return ids.join('')
    }
    return ''
  }

  const getProgramSelectOptionsByEntity = (entity: string): [] | ProgramSelectOptions[] => {
    if (programSelectOptions && programSelectOptions.length > 0) {
      return programSelectOptions.filter((item: ProgramSelectOptions) => item.entity === entity)
    } else {
      return []
    }
  }

  const handleRowSelectedToRemove = (params: GridCellParams) => {
    const runnerToRemove = params.row as RunnerModel
    setRunnerToRemove(runnerToRemove)
    setShowAlertRemove(true)
  }

  const handleRemoveRunner = () => {
    if (runnerToRemove) {
      try {
        const response = removeRunner({
          variables: {
            id: runnerToRemove.id,
          },
        })
        setShowAlertRemove(false)
        if (race.id) {
          fetchRunnersByRaceId(race.id)
        }
        successNotification('DATO_BORRADO')
        resetForm()
        setFormState('CANCEL')
      } catch (e) {
        console.log(e)
      }
    }
  }

  return (
    <Loader loading={false}>
      <FormikProvider value={formik}>
        <Form>
          <Card>
            <CardHeader
              title={<HeaderCardTitle title={transLabel('CORREDORES')} />}
              action={
                race.id && event.isActive && !dateIsBeforeThanTodayMoment(event?.date) ? (
                  <React.Fragment>
                    {formState === 'CANCEL' && actions.canCreate && <CreateButton onClick={handleCreate} />}
                    {formState !== 'CANCEL' && actions.canCreate && actions.canEdit && (
                      <>
                        <SaveButton
                          onClick={formik.submitForm}
                          isLoading={updateLoading || addLoading}
                          disabled={!(formik.dirty && formik.dirty)}
                        />{' '}
                        <ClearButton onClick={() => handleCancel('CANCEL')} label={'CANCEL'} />
                      </>
                    )}
                  </React.Fragment>
                ) : null
              }
            />
            <Divider className={classes.divider} />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <RunnerForm
                    onFilterProgramOptions={onFilterProgramOptions}
                    formState={formState}
                    formik={formik}
                    runner={runner}
                    isFormDisabled={formState !== 'CANCEL'}
                    programSelectOptions={programSelectOptions}
                    serializeMedication={medicationConvertFromCodeToName}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Loader loading={loadingRunner}>
                    <Runners
                      event={event}
                      runners={results}
                      onSelectRow={handleSelectedRunner}
                      race={race}
                      onSelectToRemove={handleRowSelectedToRemove}
                    />
                  </Loader>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <DialogAlerts
            state={alertForm}
            titulo={'SE_HAN_DETECTADO_CAMBIOS_EN_EL_FORMULARIO'}
            contenido={'SI_CONTINUA_PERDERA_LOS_CAMBIOS_EN_EL_FORMULARIO_DESEA_CONTINUAR'}
            tipo="PREGUNTA"
            onSecondaryClick={() => setAlertForm(false)}
            handleClose={() => setAlertForm(false)}
            onPrimaryClick={() => history.push(`/admin/entities/providers`)}
          />
          <DialogAlerts
            state={showAlertRemove}
            titulo={'ELIMINAR_ITEM'}
            contenido={'DESEAR_ELIMINAR_ESTE_ITEM'}
            tipo="PREGUNTA"
            onSecondaryClick={() => setShowAlertRemove(false)}
            handleClose={() => setShowAlertRemove(false)}
            onPrimaryClick={handleRemoveRunner}
          />
        </Form>
      </FormikProvider>
    </Loader>
  )
}
