import { gql, useMutation } from '@apollo/client'

const UPDATE_PROVIDER = gql`
  mutation UpdateProviderApiConnection($id: Int!, $input: UpdateProviderApiConnectionInput!) {
    updateProviderApiConnection(id: $id, input: $input) {
      providerApiConnection {
        id
        providerId
        apiBaseUrl
        accessKey
        mapping
        lastSyncDate
        version
        isActive
      }
    }
  }
`

export interface UpdateProviderApiConnectionInput {
  id: number
  accessKey: string
  apiBaseUrl: string
  isActive: boolean
  lastSyncDate?: Date
  version: string
}

export const useUpdateProviderConnection = () => {
  const [updateProviderConnection, { loading, error }] = useMutation<UpdateProviderApiConnectionInput>(UPDATE_PROVIDER)
  return { updateProviderConnection, loading, error }
}
