import React, { useState } from 'react'
import {
  Typography,
  ClickAwayListener,
  Tooltip,
  Button,
  makeStyles,
  createStyles,
  Theme,
  Grid,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { CustomAlert } from '../../../core/components'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'

type UserCredentialsBannerProps = {
  userName: string
  password?: string
  code?: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    copyButton: {
      marginTop: -5,
    },
  }),
)

export const UserCredentialsBanner = ({ userName, password, code }: UserCredentialsBannerProps) => {
  const classes = useStyles()
  const { t: transLabel } = useTranslation('Label')
  const [copyUsername, setCopyUsername] = useState(false)
  const [copyPassword, setCopyPassword] = useState(false)

  return (
    <React.Fragment>
      <CustomAlert
        variant="filled"
        title={transLabel('CREDENCIALES_DEL_USUARIO_UNICA_VEZ_MOSTRADO')}
        type={'success'}
        message={
          <Typography variant="h6" component="h5">
            <Grid container>
              <strong>{transLabel('USERNAME')}: </strong>
              {userName}
              <ClickAwayListener onClickAway={() => setCopyUsername(false)}>
                <div>
                  <Tooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={() => setCopyUsername(false)}
                    open={copyUsername}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={transLabel('DATO_COPIADO') as string}
                  >
                    <CopyToClipboard text={userName} onCopy={() => setCopyUsername(true)}>
                      <Button className={classes.copyButton}>
                        <FileCopyOutlinedIcon color={'primary'} />
                      </Button>
                    </CopyToClipboard>
                  </Tooltip>
                </div>
              </ClickAwayListener>
            </Grid>
            {password && (
              <Grid container>
                <strong>{transLabel('CLAVE')} : </strong>
                {atob(password)}
                <ClickAwayListener onClickAway={() => setCopyPassword(false)}>
                  <div>
                    <Tooltip
                      PopperProps={{
                        disablePortal: true,
                      }}
                      onClose={() => setCopyPassword(false)}
                      open={copyPassword}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      title={transLabel('DATO_COPIADO') as string}
                    >
                      <CopyToClipboard text={atob(password)} onCopy={() => setCopyPassword(true)}>
                        <Button className={classes.copyButton}>
                          <FileCopyOutlinedIcon color={'primary'} />
                        </Button>
                      </CopyToClipboard>
                    </Tooltip>
                  </div>
                </ClickAwayListener>
              </Grid>
            )}
            {code && (
              <Grid container>
                <strong>{transLabel('CODIGO')}: </strong>
                {code}
                <ClickAwayListener onClickAway={() => setCopyPassword(false)}>
                  <div>
                    <Tooltip
                      PopperProps={{
                        disablePortal: true,
                      }}
                      onClose={() => setCopyPassword(false)}
                      open={copyPassword}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      title={transLabel('DATO_COPIADO') as string}
                    >
                      <CopyToClipboard text={code} onCopy={() => setCopyPassword(true)}>
                        <Button className={classes.copyButton}>
                          <FileCopyOutlinedIcon color={'primary'} />
                        </Button>
                      </CopyToClipboard>
                    </Tooltip>
                  </div>
                </ClickAwayListener>
              </Grid>
            )}
          </Typography>
        }
      />
    </React.Fragment>
  )
}
