import { gql, useMutation } from '@apollo/client'

const UPDATE_PROGRAM_EVENT = gql`
  mutation UpdateProgramEvent($id: Int!, $input: UpdateProgramEventInput!) {
    updateProgramEvent(id: $id, input: $input) {
      programEvent {
        id
        name
        date
        performanceCode
        numberOfRaces
        isActive
      }
    }
  }
`

export interface UpdateProgramEventInput {
  id: number
  name: string
  date: Date
  performanceCode: string
  numberOfRaces: number
  isActive: boolean
}

export const useUpdateProgramEvent = () => {
  const [updateProgramEvent, { loading, error }] = useMutation<UpdateProgramEventInput>(UPDATE_PROGRAM_EVENT)
  return { updateProgramEvent, loading, error }
}
