import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { FormProps } from '../../models'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}))

export interface CustomTimePickerFormikProps extends FormProps {
  customClassName?: object
  onDateChange?: (date: string | Date | null) => void
  defaultValue?: string
}

export default function CustomTimePickerFormik({ onDateChange, defaultValue, ...props }: CustomTimePickerFormikProps) {
  const {
    form: { setFieldValue, values },
    field: { name },
  } = props
  const classes = useStyles()

  /**
   * @description change date selected
   * @param date
   */
  const handleDateChange = useCallback(
    (event: React.ChangeEvent<any>) => {
      const value = event.target.value
      setFieldValue(name, value)
      if (onDateChange instanceof Function) {
        onDateChange(value)
      }
    },
    [setFieldValue, name, onDateChange],
  )

  return (
    <form className={classes.container} noValidate>
      <TextField
        {...props}
        id="time"
        type="time"
        onChange={handleDateChange}
        className={classes.textField}
        value={values[name]}
        defaultValue={values[name]}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          step: 300, // 5 min
        }}
      />
    </form>
  )
}
