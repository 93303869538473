import { useCallback } from 'react'
import DateFnsUtils from '@date-io/date-fns'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import classname from 'classnames'
import { FormProps } from '../../models'
import { TextField } from 'formik-material-ui'
import { formatToShortLocalDate } from '../../services'

export interface DatesProps extends FormProps {
  customClassName?: string
  onDateChange?: (date: string | Date | null) => void
  defaultValue?: string
}

const WrapperTextField = (fieldProps: any) => <TextField {...fieldProps} fullWidth />

export const CustomFormikDatePicker = ({ customClassName, onDateChange, defaultValue, ...props }: DatesProps) => {
  const {
    form: { setFieldValue, values },
    field: { name },
  } = props

  /**
   * @description change date selected
   * @param date
   */
  const handleDateChange = useCallback(
    (date: Date | null) => {
      setFieldValue(name, date)
      if (onDateChange instanceof Function) {
        onDateChange(date)
      }
    },
    [setFieldValue, name, onDateChange],
  )

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        {...props}
        id="date-picker-dialog"
        cancelLabel={'Cancelar'}
        todayLabel={'Hoy'}
        className={classname('input-date', customClassName)}
        value={formatToShortLocalDate(values[name])}
        onChange={handleDateChange}
        showTodayButton
        margin="normal"
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        TextFieldComponent={WrapperTextField}
      />
    </MuiPickersUtilsProvider>
  )
}
