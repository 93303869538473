import { useEffect, useState } from 'react'
import { Grid, Card, CardContent, Typography, FormControl, makeStyles, createStyles, Divider } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { LocalizedContentSelect, CustomDatePicker, TextFieldDebounce, EntitySelect } from '../../../core/components'
import { Formik, Field } from 'formik'
import {
  formatToShortLocalDate,
  generateFilterGraphql,
  getCurrentShortDateUs,
  useLocalizedContent,
} from '../../../core'
import { SearchGraphql, GraphqlExpression } from '../../../core/models'
import { useEnterprises } from '../../../scenes/entities/enterprises/hooks'
import { useProviders } from '../../entities/providers/hooks'

const useStyles = makeStyles(() =>
  createStyles({
    datesClass: {
      marginTop: '0px !important',
    },
    dates: {
      marginTop: '0px !important',
      width: '100%',
    },
    dateValidation: {
      color: '#f44336',
      fontSize: '12px',
    },
  }),
)

export interface OperationLocations {
  from?: string | Date | undefined
  to?: string | Date | undefined
  currencyName: string
  matchString: string
}

interface OperationLocationsFilterProps {
  onChange: (newFilter: SearchGraphql) => void
}

export const OperationLocationsFilter = ({ onChange }: OperationLocationsFilterProps) => {
  const classes = useStyles()

  const { results: enterpriseData, getEnterprises } = useEnterprises()
  const { results: providers, getProviders } = useProviders()

  const { t: translationLabel } = useTranslation('Label')
  const { results: resultCurrency } = useLocalizedContent({ entityType: 'System', entityName: 'Currency' })

  const [currencyName, setCurrencyName] = useState<GraphqlExpression>({ eq: 'PESOS_DOMINICANOS' })
  const [fromDateSelected, setFromDateSelected] = useState<string>(getCurrentShortDateUs())
  const [toDateSelected, setToDateSelected] = useState<string>(getCurrentShortDateUs())
  const [matchString, setMatchString] = useState<GraphqlExpression>({ contains: '' })

  function onChangeFromDate(newDate: string) {
    setFromDateSelected(newDate)
  }

  function onChangeToDate(newDate: string) {
    setToDateSelected(newDate)
  }

  function onChangeCurrency(currencyName: string) {
    setCurrencyName(generateFilterGraphql(currencyName, 'eq'))
  }

  function onChangeMatchString(matchString: string) {
    setMatchString(generateFilterGraphql(matchString, 'contains'))
  }

  function onChangeProvider(matchString: string) {
    setMatchString(generateFilterGraphql(matchString, 'contains'))
  }

  useEffect(() => {
    onChange({
      where: {
        currencyType: currencyName,
        locationName: matchString,
        startDate: generateFilterGraphql(formatToShortLocalDate(fromDateSelected), 'eq'),
        endDate: generateFilterGraphql(formatToShortLocalDate(toDateSelected), 'eq'),
      },
    })
  }, [currencyName, matchString])

  useEffect(() => {
    getEnterprises({
      where: {
        active: generateFilterGraphql(true, 'eq'),
      },
    })

    getProviders({
      where: {
        name: generateFilterGraphql('', 'contains'),
      },
    })
  }, [])

  return (
    <Card>
      <CardContent>
        <Typography variant="h6">{translationLabel('CRITERIOS_DE_BUSQUEDA')}</Typography>
      </CardContent>
      <Divider />
      <CardContent>
        <Formik
          initialValues={{
            from: moment().format('MM-DD-YYYY'),
            to: moment().format('MM-DD-YYYY'),
            currencyName: 'PESOS_DOMINICANOS',
            matchString: '',
          }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(false)
          }}
        >
          {({ errors }) => (
            <Grid container spacing={3}>
              <Grid item lg={2} xs={12} sm={12}>
                <Field
                  component={CustomDatePicker}
                  name="from"
                  label={translationLabel('DESDE')}
                  format="dd/MM/yyyy"
                  customClassName={classes.dates}
                  fullWidth
                  onDateChange={onChangeFromDate}
                  value={fromDateSelected}
                  disableFuture={true}
                />
              </Grid>
              <Grid item lg={2} xs={12} sm={12}>
                <Field
                  component={CustomDatePicker}
                  name="to"
                  label={translationLabel('HASTA')}
                  format="dd/MM/yyyy"
                  customClassName={classes.dates}
                  onDateChange={(value: string) => {
                    onChangeToDate(value)
                  }}
                  value={toDateSelected}
                  disableFuture={true}
                />
                <br />
                <span className={classes.dateValidation}>{errors.to ? errors.to : ''}</span>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <FormControl fullWidth>
                  <Field
                    component={LocalizedContentSelect}
                    name="currencyName"
                    isGrupalValue
                    label={translationLabel('DIVISA')}
                    data={resultCurrency}
                    fullWidth
                    onChange={onChangeCurrency}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <FormControl fullWidth>
                  <Field
                    component={EntitySelect}
                    name="currencyName"
                    isGrupalValue
                    label={translationLabel('PROVEEDOR')}
                    data={providers.results}
                    fullWidth
                    onChange={onChangeProvider}
                    showNoneOption
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <FormControl fullWidth>
                  <Field
                    component={EntitySelect}
                    name="enterprise"
                    label={translationLabel('CONSORCIO')}
                    data={enterpriseData.results}
                    fullWidth
                    showNoneOption
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <FormControl fullWidth>
                  <Field
                    name="matchString"
                    component={TextFieldDebounce}
                    label={translationLabel('MOSTRAR_LOS_QUE_COINCIDAD_CON')}
                    fullWidth
                    onChange={onChangeMatchString}
                  />
                </FormControl>
              </Grid>
            </Grid>
          )}
        </Formik>
      </CardContent>
    </Card>
  )
}
