import { gql, useMutation } from '@apollo/client'

const ADD_PROVIDER_CONNECTION = gql`
  mutation AddProviderApiConnection($input: AddProviderApiConnectionInput!) {
    addProviderApiConnection(input: $input) {
      providerApiConnection {
        id
        providerId
        apiBaseUrl
        accessKey
        mapping
        lastSyncDate
        version
        isActive
      }
    }
  }
`

export const useAddProviderConnection = () => {
  const [addProviderConnection, { loading, error }] = useMutation(ADD_PROVIDER_CONNECTION)
  return { addProviderConnection, loading, error }
}
