import React, { useState } from 'react'
import {
  Grid,
  makeStyles,
  createStyles,
  Theme,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Button,
  FormControl,
  FormControlLabel,
} from '@material-ui/core'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import {
  HeaderCardTitle,
  SaveButton,
  AppBreadcrumbsBar,
  ModifyButton,
  DialogAlerts,
  CheckboxFormik,
} from '../../../../core/components'
import { useTranslation } from 'react-i18next'
import { useFormik, Field, Form, FormikProvider } from 'formik'
import { TextField } from 'formik-material-ui'
import { ProgramTrainer } from '../models'
import * as Yup from 'yup'
import { useHistory } from 'react-router-dom'
import { useUserClaimActions, ClaimActions, normalizeSpaces } from '../../../../core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiAutocomplete-root': {
        width: 'auto',
      },
    },
    fieldItem: {
      marginBottom: theme.spacing(2),
    },
    buttonActions: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    labelEditActive: {
      color: '#ff6600',
    },
    labelEditDisabled: {
      color: '#000000',
    },
    divider: {
      marginTop: -22,
    },
    addressDiv: {
      paddingTop: 5,
    },
  }),
)

const initialFormValues: ProgramTrainer = {
  id: 0,
  name: '',
  isActive: true,
}

type TrainerFormProps = {
  onSubmit: (values: ProgramTrainer) => void
  trainer?: ProgramTrainer | undefined
}

export const TrainerForm = ({ onSubmit, trainer }: TrainerFormProps) => {
  const { validateClaimActions } = useUserClaimActions()
  const actions = validateClaimActions('configuration.trainers', [ClaimActions.CanCreate, ClaimActions.CanEdit])
  const classes = useStyles()
  const [isEditing, setIsEditing] = useState(false)
  const disableFields = !isEditing && trainer
  const [alertForm, setAlertForm] = useState(false)
  const { t: transLabel } = useTranslation('Label')

  const history = useHistory()

  const formSchema = Yup.object().shape({
    name: Yup.string().required(transLabel('REQUERIDO')),
  })

  const formik = useFormik({
    validationSchema: formSchema,
    initialValues: trainer || initialFormValues,
    onSubmit: onSubmit,
    enableReinitialize: true,
  })

  window.sessionStorage.setItem('hasFormChanges', formik.dirty ? 'true' : 'false')

  const goToTrainers = () => {
    if (formik.dirty === false) {
      history.push(`/admin/entities/events-trainers`)
    } else {
      setAlertForm(true)
    }
  }

  return (
    <FormikProvider value={formik}>
      <Form>
        <Grid item xs={12}>
          <AppBreadcrumbsBar
            title={transLabel('ENTRENADOR_TITULO')}
            description={transLabel('ENTRENADOR_SUB_TITULO')}
            actions={
              <React.Fragment>
                {trainer && isEditing === false && actions.canEdit ? (
                  <ModifyButton className={classes.buttonActions} onClick={() => setIsEditing(true)} />
                ) : (
                  (actions.canCreate || actions.canEdit) && (
                    <SaveButton className={classes.buttonActions} onClick={formik.submitForm} isLoading={false} />
                  )
                )}
                <Button
                  variant="contained"
                  onClick={goToTrainers}
                  startIcon={<KeyboardBackspaceIcon className={classes.buttonActions} />}
                >
                  {transLabel('REGRESAR')}
                </Button>
              </React.Fragment>
            }
          />
        </Grid>

        <Card>
          <CardHeader title={<HeaderCardTitle title={transLabel('DATOS_DEL_ENTRENADOR')} />} />
          <Divider className={classes.divider} />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Field
                  component={TextField}
                  type="text"
                  label={transLabel('NOMBRE')}
                  name="name"
                  fullWidth
                  disabled={disableFields}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    formik.setFieldValue('name', normalizeSpaces(event.target.value))
                  }
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormControl fullWidth>
                  <FormControlLabel
                    control={
                      <Field component={CheckboxFormik} name="isActive" color="primary" disabled={disableFields} />
                    }
                    label={transLabel('ESTADO') as string}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <DialogAlerts
          state={alertForm}
          titulo={'SE_HAN_DETECTADO_CAMBIOS_EN_EL_FORMULARIO'}
          contenido={'SI_CONTINUA_PERDERA_LOS_CAMBIOS_EN_EL_FORMULARIO_DESEA_CONTINUAR'}
          tipo="PREGUNTA"
          onSecondaryClick={() => setAlertForm(false)}
          handleClose={() => setAlertForm(false)}
          onPrimaryClick={() => history.push(`/admin/entities/events-Trainers`)}
        />
      </Form>
    </FormikProvider>
  )
}
