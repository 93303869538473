import { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import { useParams, useHistory } from 'react-router-dom'
import { EventForm } from './EventForm'
import {
  dateIsBeforeThanTodayMoment,
  formatToShortLocalDate,
  generateFilterGraphql,
  useNotification,
  useUserClaimActions,
  ClaimActions,
} from '../../../../core'
import { useTranslation } from 'react-i18next'
import { useAddProgramEvent, useEvent, useUpdateProgramEvent, useProgramSelectOptions } from '../hooks'
import { Event as EventModel, Race as RaceModel } from '../models'
import _ from 'lodash'
import { Loader } from '../../../../core/components'
import { Race } from '../races/Race'
import { Runner } from '../runners/Runner'

export const Event = () => {
  const { id } = useParams<{ id: string | undefined }>()
  const { event, loading: loadingEvent, getEvent } = useEvent()
  const [raceId, setRaceId] = useState<number | undefined>(undefined)
  const { results: programSelectOptions, loading, getProgramSelectOptions } = useProgramSelectOptions()
  const { addProgramEvent, loading: addLoading } = useAddProgramEvent()
  const { updateProgramEvent, loading: updateLoading } = useUpdateProgramEvent()
  const [raceStatus, setRaceStatus] = useState('CANCEL')
  const [runnerStatus, setRunnerStatus] = useState('CANCEL')
  const { validateClaimActions } = useUserClaimActions()
  const actions = validateClaimActions('configuration.events', [ClaimActions.CanCreate, ClaimActions.CanEdit])
  const { successNotification, errorNotification } = useNotification()
  const { t: transSystem } = useTranslation('System')
  const [race, setRace] = useState<RaceModel | undefined>(undefined)
  const [races, setRaces] = useState<RaceModel[]>([])

  const history = useHistory()

  useEffect(() => {
    if (id) {
      fetchEvent(Number.parseInt(id))
      handleRefreshProgramOptions('')
    }
  }, [id])

  const handleRefreshProgramOptions = (filter: string) => {
    const customFilters = {
      or: [
        {
          name: { ...generateFilterGraphql(filter, 'contains') },
        },
      ],
      and: [
        {
          isUseForHVC: { ...generateFilterGraphql(true, 'eq') },
        },
      ],
    }

    getProgramSelectOptions({ where: customFilters, take: 1000, skip: 0 })
  }

  const fetchEvent = (eventId: number) => {
    getEvent(eventId)
  }

  const handleSave = (eventToSave: EventModel) => {
    if (!eventToSave.id) {
      const response = addProgramEvent({
        variables: {
          input: {
            name: eventToSave.name,
            date: formatToShortLocalDate(eventToSave.date),
            performanceCode: eventToSave.performanceCode,
            numberOfRaces: eventToSave.numberOfRaces,
            isActive: eventToSave.isActive,
          },
        },
      })
      response
        .then((result: any) => {
          successNotification(transSystem('DATO_REGISTRADO'))
          const eventId = _.get(result, 'data.addProgramEvent.programEvent.id', undefined)
          history.push(`/admin/entities/events/${eventId}`)
        })
        .catch((e) => {
          errorNotification(transSystem(e.message))
        })
    } else if (eventToSave) {
      const raceNumber = races.length
      if (eventToSave.numberOfRaces < raceNumber) {
        errorNotification(transSystem('NUMERO_DE_CARRERA_ES_MAYOR_AL_NUMERO_PERMITIRO_POR_EL_EVENTO'))
      } else {
        if (!dateIsBeforeThanTodayMoment(eventToSave.date)) {
          const response = updateProgramEvent({
            variables: {
              id: eventToSave.id,
              input: {
                name: eventToSave.name,
                date: formatToShortLocalDate(eventToSave.date),
                performanceCode: eventToSave.performanceCode,
                numberOfRaces: eventToSave.numberOfRaces,
                isActive: eventToSave.isActive,
              },
            },
          })
          response
            .then(() => {
              successNotification(transSystem('DATO_ACTUALIZADO'))
            })
            .catch((e) => {
              errorNotification(transSystem(e.message))
            })
        } else {
          errorNotification(transSystem('LA_FECHA_DEL_EVENTO_HA_PASADO'))
        }
      }
    }
  }

  const handleRaces = (races: RaceModel[]) => {
    setRaces(races)
  }

  const handleRace = (race: RaceModel | undefined) => {
    setRace(race)
  }

  return (
    <Loader loading={false}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <EventForm
            isSaving={addLoading || updateLoading}
            onSave={handleSave}
            event={event}
            programSelectOptions={
              programSelectOptions && programSelectOptions.results ? programSelectOptions.results : []
            }
          />
        </Grid>
        {event !== undefined && (actions.canCreate || actions.canEdit) && (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Race
              getRaces={handleRaces}
              onRaceStatusChange={(action: string) => setRaceStatus(action)}
              isEditable={runnerStatus === 'CANCEL' && !dateIsBeforeThanTodayMoment(event?.date)}
              onFilterProgramOptions={handleRefreshProgramOptions}
              handleRace={handleRace}
              event={event}
              programSelectOptions={
                programSelectOptions && programSelectOptions.results ? programSelectOptions.results : []
              }
            />
          </Grid>
        )}

        {race !== undefined && (actions.canCreate || actions.canEdit) && event && (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Runner
              event={event}
              onRunnerStatusChange={(action: string) => setRunnerStatus(action)}
              onFilterProgramOptions={handleRefreshProgramOptions}
              race={race}
              programSelectOptions={
                programSelectOptions && programSelectOptions.results ? programSelectOptions.results : []
              }
            />
          </Grid>
        )}
      </Grid>
    </Loader>
  )
}
