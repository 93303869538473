import { Metadata, ProfitAndLoss, ResultsList } from '../../../core/models'
import { gql, useLazyQuery, useQuery } from '@apollo/client'
import { useState, useEffect, useCallback } from 'react'

const GET_PROFIT_AND_LOSSES = gql`
  query getAllProfitsAndLossesQuery($where: ProfitAndLossFilterInput, $skip: Int, $take: Int) {
    profitAndLosses(where: $where, take: $take, skip: $skip) {
      items {
        id
        enterpriseId
        amountBet
        balance
        beginning
        comission
        currencyName
        entityId
        entityName
        eventDate
        inOut
        winner
        riskWin
        ticketPending
        debit
        credit
      }
    }
  }
`

interface ProfitAndLossData {
  profitAndLosses: {
    items: ProfitAndLoss[]
  }
}

export const useProfitAndLosses = () => {
  const [getResults, { loading, error, data }] = useLazyQuery<ProfitAndLossData>(GET_PROFIT_AND_LOSSES, {
    fetchPolicy: 'network-only',
  })

  const [results, setResults] = useState<ResultsList<ProfitAndLoss, Metadata>>({
    results: [],
    metadata: {},
  })

  const getProfitAndLosses = useCallback(
    ({ where, take, skip }: any) => {
      getResults({
        variables: {
          where: { ...where },
          skip: skip ? skip : 0,
          take: take ? take : 20,
        },
      })
    },
    [getResults],
  )

  useEffect(() => {
    setResults({
      results: data?.profitAndLosses.items || [],
      metadata: {},
    })
  }, [data?.profitAndLosses.items, loading])

  return { loading, error, results, getProfitAndLosses }
}

const GET_SALE_SUMMARY = gql`
  query getSaleSummary {
    resumenVentaMachineChartRace(take: 100, skip: 0) {
      items {
        periodicityName
        enterpriseId
        enterpriseName
        salesdate
        dataJson
      }
    }
  }
`

export interface MachineChartModel {
  dataJson: string
  salesdate: string
}

interface MachineChartData {
  resumenVentaMachineChartRace: {
    items: MachineChartModel[]
  }
}

export const useSaleSummary = () => {
  const { loading, error, data } = useQuery(GET_SALE_SUMMARY)

  const [results, setResults] = useState<ResultsList<MachineChartData, Metadata>>({
    results: [],
    metadata: {},
  })

  useEffect(() => {
    setResults({
      results: data?.resumenVentaMachineChartRace?.items || [],
      metadata: {},
    })
  }, [data?.resumenVentaMachineChartRace?.items, loading])

  return { loading, error, results }
}
