import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
  makeStyles,
  createStyles,
  Theme,
  FormControlLabel,
  FormLabel,
  Radio,
} from '@material-ui/core'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import { useTranslation } from 'react-i18next'
import {
  HeaderCardTitle,
  AppBreadcrumbsBar,
  Loader,
  BackButton,
  ModifyButton,
  SaveButton,
  AddTitle,
  ModifyTitle,
  ViewTitle,
  BooleanRadioButton,
  DialogAlerts,
} from '../../../core/components'
import { Formik, Form, Field } from 'formik'
import { TextField, RadioGroup } from 'formik-material-ui'
import { useParams } from 'react-router-dom'

import { useUpdateCompany, UpdateCompanyInput, useCompany } from './hooks'
import { useHistory } from 'react-router-dom'
import { useNotification } from '../../../core'

const initialValues: UpdateCompanyInput = {
  name: '',
  active: true,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonActions: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    labelEditActive: {
      color: '#ff6600',
    },
    labelEditDisabled: {
      color: '#000000',
    },
  }),
)

export const CompanyDetail = () => {
  const history = useHistory()
  const { id } = useParams<{ id: string }>()
  const { updateCompany, loading, error } = useUpdateCompany()
  const { getCompany, data } = useCompany()
  const [isEditing, setIsEditing] = useState(false)
  const [alertForm, setAlertForm] = useState(false)
  const { t: translationLabel } = useTranslation('Label')
  const { t: translationAction } = useTranslation('Action')
  const { successNotification, errorNotification } = useNotification()

  const classes = useStyles()

  const formSchema = Yup.object().shape({
    name: Yup.string().required(translationLabel('REQUERIDO')),
    active: Yup.boolean(),
  })

  useEffect(() => {
    if (id !== undefined) {
      getCompany(parseInt(id))
    }
  }, [id])

  return (
    <Grid container>
      <Formik
        validationSchema={formSchema}
        initialValues={data !== undefined ? { ...(data.company as object) } : { ...initialValues }}
        onSubmit={(values: UpdateCompanyInput, { setSubmitting }) => {
          setSubmitting(true)
          const companyToUpdate: UpdateCompanyInput = {
            name: values.name,
            active: values.active,
          }
          const response = updateCompany({
            variables: {
              id: parseInt(id),
              input: companyToUpdate,
            },
          })
          response
            .then((res) => {
              setTimeout(() => {
                history.push(`/admin/entities/companies`)
              }, 1000)
              successNotification(translationLabel('COMPANIA_ACTUALIZADA'))
            })
            .catch((e) => {
              errorNotification(translationLabel('ERROR_ACTUALIZANDO_LA_COMPANIA'))
            })
        }}
        enableReinitialize
      >
        {({ submitForm, isValid, dirty, isSubmitting }) => {
          const goToCompanies = () => {
            if (dirty === false) {
              history.push(`/admin/entities/companies`)
            } else {
              setAlertForm(true)
            }
          }

          return (
            <Grid item xs={12}>
              <Grid item xs={12}>
                <AppBreadcrumbsBar
                  title={translationLabel('COMPANIA_TITULO')}
                  description={translationLabel('COMPANIA_SUB_TITULO')}
                  actions={
                    <React.Fragment>
                      {isEditing === false ? (
                        <ModifyButton className={classes.buttonActions} onClick={() => setIsEditing(true)} />
                      ) : (
                        <SaveButton
                          className={classes.buttonActions}
                          onClick={submitForm}
                          disabled={!(dirty && isValid)}
                          isLoading={false}
                        />
                      )}
                      <Button
                        className={classes.buttonActions}
                        variant="contained"
                        onClick={goToCompanies}
                        startIcon={<KeyboardBackspaceIcon />}
                      >
                        {translationAction('REGRESAR')}
                      </Button>
                    </React.Fragment>
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <Card>
                  <Box display="flex" justifyContent="space-between" alignItems="center" mr={1}>
                    <CardHeader
                      component={() => (
                        <HeaderCardTitle
                          title={
                            isEditing ? (
                              <ModifyTitle title={'ACTUALIZANDO_LA_COMPANIA'} />
                            ) : (
                              <ViewTitle title={'DATOS_DE_LA_COMPANIA'} />
                            )
                          }
                        />
                      )}
                    />

                    <Typography variant="h6" component="h6">
                      <b>
                        {loading
                          ? translationLabel('ACTUALIZANDO')
                          : isSubmitting === true
                          ? error === undefined
                            ? translationLabel('COMPANIA_ACTUALIZADA')
                            : translationLabel('ERROR_SALVANDO_COMPANIA')
                          : ''}
                      </b>
                    </Typography>
                  </Box>

                  <Divider />
                  <CardContent>
                    <Form>
                      <Grid container spacing={3}>
                        <Grid item lg={12} xs={12} sm={12}>
                          <Field
                            component={TextField}
                            type="text"
                            label={translationLabel('COMPANIA')}
                            name="name"
                            disabled={!isEditing}
                          />
                        </Grid>

                        <Grid item lg={2} xs={12} sm={2}>
                          <Field
                            component={BooleanRadioButton}
                            title={'ESTADO'}
                            name="active"
                            label1={'ACTIVO'}
                            label2={'INACTIVO'}
                            value1={'active'}
                            value2={'inactive'}
                            disabled={!isEditing}
                          />
                        </Grid>
                      </Grid>
                    </Form>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          )
        }}
      </Formik>
      <DialogAlerts
        state={alertForm}
        titulo={'SE_HAN_DETECTADO_CAMBIOS_EN_EL_FORMULARIO'}
        contenido={'SI_CONTINUA_PERDERA_LOS_CAMBIOS_EN_EL_FORMULARIO_DESEA_CONTINUAR'}
        tipo="PREGUNTA"
        onSecondaryClick={() => setAlertForm(false)}
        handleClose={() => setAlertForm(false)}
        onPrimaryClick={() => history.push(`/admin/entities/companies`)}
      />
    </Grid>
  )
}
