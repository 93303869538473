import React, { useCallback, useState } from 'react'
import { Button, Grid, CardContent, Card } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import { AppBreadcrumbsBar, CustomDataGrid, DataGridColumns, Loader } from '../../../core/components'
import { useTranslation } from 'react-i18next'
import { AgencySimulationFilter } from './AgencySimulationFilter'
import { AgenciesTotalsResults } from './AgenciesTotalsResults'
import { useAgencySituation } from './hooks'
import { Metadata, ResultsList, Dictionary, SearchGraphql, ClaimActions } from '../../../core/models'
import { Agency } from './models'
import { GridPageChangeParams } from '@material-ui/data-grid'
import { useUserClaimActions } from '../../../core'

export const AgencySituation = () => {
  const { t: translationLabel } = useTranslation('Label')
  const { t: translationAction } = useTranslation('Action')
  const { results: agencyData, loading, getAgencySituation } = useAgencySituation()
  const [searchRequest, setSearchRequest] = useState<SearchGraphql>({})
  const { validateClaimActions } = useUserClaimActions()
  const actions = validateClaimActions('consults.agency-situation', [
    ClaimActions.CanSearch,
    ClaimActions.CanView,
    ClaimActions.CanCreate,
  ])
  const getColumnDefinitions = useCallback((): DataGridColumns => {
    return {
      AGENCIA: { field: 'locationName', contentType: 'string' },
      BALANCE_INICIAL: { field: 'lastBalance', contentType: 'currency' },
      BALANCE_ACTUAL: { field: 'actualBalance', contentType: 'currency' },
      VENTA: { field: 'incomeCash', contentType: 'currency' },
      PAGO: { field: 'outcomeCash', contentType: 'currency' },
      INGRESOS_CONFIRMADOS: { field: 'incomeConfirmedTransference', contentType: 'currency' },
      EGRESOS_CONFIRMADOS: { field: 'outcomeConfirmedTransference', contentType: 'currency' },
      INGRESO_TRANSITO: { field: 'incomeUnconfirmedTransference', contentType: 'currency' },
      EGRESO_TRANSITO: { field: 'outcomeUnconfirmedTransference', contentType: 'currency' },
      INGRESO_TC: { field: 'incomeCreditCard', contentType: 'currency' },
      EGRESO_TC: { field: 'outcomeCreditCard', contentType: 'currency' },
    }
  }, [])

  const getOperationData = (data: ResultsList<Agency, Metadata>): Dictionary | undefined => {
    if (data.results !== undefined) {
      let actualBalance = 0
      let lastBalance = 0
      let incomeCash = 0
      let outcomeCash = 0
      let outcomeConfirmedTransference = 0
      let incomeConfirmedTransference = 0
      let incomeUnconfirmedTransference = 0
      let outcomeUnconfirmedTransference = 0
      let incomeCreditCard = 0
      let outcomeCreditCard = 0

      data.results.forEach((item) => {
        actualBalance = item.actualBalance + actualBalance
        lastBalance = item.lastBalance + lastBalance
        incomeCash = item.incomeCash + incomeCash
        outcomeCash = item.outcomeCash + outcomeCash
        outcomeConfirmedTransference = item.outcomeConfirmedTransference + outcomeConfirmedTransference
        incomeConfirmedTransference = item.incomeConfirmedTransference + incomeConfirmedTransference
        incomeUnconfirmedTransference = item.incomeUnconfirmedTransference + incomeUnconfirmedTransference
        outcomeUnconfirmedTransference = item.outcomeUnconfirmedTransference + outcomeUnconfirmedTransference
        incomeCreditCard = item.incomeCreditCard + incomeCreditCard
        outcomeCreditCard = item.outcomeCreditCard + outcomeCreditCard
      })
      return {
        BALANCE_INICIAL: lastBalance,
        BALANCE_ACTUAL: actualBalance,
        VENTA: incomeCash,
        PAGO: outcomeCash,
        ENVIOS: outcomeConfirmedTransference,
        RECIBOS: incomeConfirmedTransference,
        INGRESO_TRANSITO: incomeUnconfirmedTransference,
        EGRESO_TRANSITO: outcomeUnconfirmedTransference,
        INGRESO_TC: incomeCreditCard,
        EGRESO_TC: outcomeCreditCard,
      }
    }
    return undefined
  }

  const operationData = getOperationData(agencyData)

  function onChangeFilter(filter: SearchGraphql) {
    filter = {
      ...filter,
      skip: 0,
      take: 20,
    }
    setSearchRequest(filter)
    getAgencySituation(filter)
  }

  const handlePageChange = (params: GridPageChangeParams) => {
    const { pageSize, page } = params
    const newFilter = {
      ...searchRequest,
      take: pageSize,
      skip: pageSize * (page - 1),
    }

    setSearchRequest(newFilter)
    getAgencySituation(newFilter)
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <AppBreadcrumbsBar
          title={translationLabel('ESTADO_SITUACION_AGENCIAS_TITULO')}
          description={`${translationLabel('ESTADO_SITUACION_AGENCIAS_SUBTITULO')}`}
          actions={
            actions.canCreate ? (
              <Button variant="contained" color="primary" startIcon={<Add />}>
                {translationAction('TRANSFERENCIAS')}
              </Button>
            ) : null
          }
        />
      </Grid>
      {actions.canSearch && (
        <Grid item xs={12}>
          {/*  onChange={handleChangeFilter} getWeeksDate={getWeeksDate} */}

          <AgencySimulationFilter onChange={onChangeFilter} />
        </Grid>
      )}

      <Grid item xs={12} lg={1} />
      <Grid
        item
        xs={12}
        lg={11}
        style={{
          width: '95vw',
        }}
      >
        {/*  onChange={handleChangeFilter} getWeeksDate={getWeeksDate} */}
        {operationData !== undefined && <AgenciesTotalsResults data={operationData} />}
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Loader loading={loading}>
              <div style={{ minHeight: '70vh', width: '100%' }}>
                <CustomDataGrid
                  // onRowClick={rowSelectHandler}
                  columns={getColumnDefinitions()}
                  pageSize={20}
                  data={agencyData}
                  onPageChange={handlePageChange}
                />
              </div>
            </Loader>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}
