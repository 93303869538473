import React, { useState } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import {
  IconButton,
  useScrollTrigger,
  Container,
  Typography,
  CssBaseline,
  Toolbar,
  AppBar,
  Fade,
} from '@material-ui/core'
import { PersonalData } from './PersonalData'
import { Dependents } from './Dependents'
import { Plans } from './Plans'

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window
  children: React.ReactElement
}

function ElevationScroll(props: Props) {
  const { children, window } = props
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  })

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  })
}

const screens = [
  {
    title: 'Datos Personales',
    hasNextPage: true,
    hasBackPage: false,
    showAddButton: false,
  },
  {
    title: 'Dependientes',
    hasNextPage: true,
    hasBackPage: true,
    showAddButton: true,
  },
  {
    title: 'Planes',
    hasNextPage: true,
    hasBackPage: true,
    showAddButton: true,
  },
]

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    mainContainer: {
      position: 'relative',
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    footer: {
      position: 'absolute',
      bottom: 0,
      width: '92%',
      backgroundColor: '#cdd5dc',
      color: 'white',
      textAlign: 'center',
    },
    continueBtnDiv: {
      textAlign: 'right',
    },
    continueBtn: {
      textTransform: 'capitalize',
      marginTop: 10,
    },
    addFloatingBtnDiv: {
      textAlign: 'left',
      paddingLeft: 10,
    },
  }),
)

export const Calculator = (props: Props) => {
  const classes = useStyles()
  const [currentScreen, setCurrentScreen] = useState(0)

  const handleContinue = () => {
    setCurrentScreen(currentScreen + 1)
  }

  return (
    <React.Fragment>
      <CssBaseline />

      <Container maxWidth="sm" className={classes.mainContainer}>
        <Typography component="div" style={{ backgroundColor: '#e8e8e8', height: '80vh' }}>
          <ElevationScroll {...props}>
            <AppBar position="static">
              <Toolbar variant="dense">
                {screens[currentScreen].hasBackPage && (
                  <IconButton
                    edge="start"
                    className={classes.menuButton}
                    color="inherit"
                    aria-label="menu"
                    onClick={() => setCurrentScreen(currentScreen - 1)}
                  >
                    <ArrowBackIcon />
                  </IconButton>
                )}

                <Typography variant="h6" color="inherit">
                  {screens[currentScreen].title}
                </Typography>
              </Toolbar>
            </AppBar>
          </ElevationScroll>
          {currentScreen === 0 && (
            <Fade in={true}>
              <PersonalData onContinue={handleContinue} />
            </Fade>
          )}
          {currentScreen === 1 && (
            <Fade in={true}>
              <Dependents onContinue={handleContinue} />
            </Fade>
          )}
          {currentScreen === 2 && (
            <Fade in={true}>
              <Plans />
            </Fade>
          )}
        </Typography>
      </Container>
    </React.Fragment>
  )
}
