import { Form, Field, useFormik, FormikProvider } from 'formik'
import { Card, CardContent, CardHeader, Divider, Grid, FormControl } from '@material-ui/core'
import { HeaderCardTitle, TextFieldDebounceFormik, SearchButton } from '../../../../core/components'
import { useTranslation } from 'react-i18next'
// My Components

export type TrainerFiltersType = {
  name: string
}

interface EventsFilterIProps {
  onFilterChange: (filter: TrainerFiltersType) => void
}

export const TrainerFilters = ({ onFilterChange }: EventsFilterIProps) => {
  const { t: translationLabel } = useTranslation('Label')

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    onSubmit: onFilterChange,
  })

  return (
    <Card>
      <CardHeader component={() => <HeaderCardTitle title={translationLabel('CRITERIOS_DE_BUSQUEDA')} />} />
      <Divider />
      <CardContent>
        <FormikProvider value={formik}>
          <Form>
            <Grid container item alignItems="center" spacing={3} xs={12} sm={12}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormControl fullWidth>
                  <Field
                    component={TextFieldDebounceFormik}
                    type="text"
                    label={translationLabel('NOMBRE')}
                    name="name"
                    fullWidth
                  />
                </FormControl>
              </Grid>
              <Grid item lg={3} xs={12} sm={4}>
                <SearchButton onClick={formik.submitForm} />
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </CardContent>
    </Card>
  )
}
