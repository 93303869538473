import { gql, useQuery } from '@apollo/client'
import { Dictionary } from '../../../../core/models'
import { useState } from 'react'

const GET_WORKSTATION = gql`
  query GetWorkstation($id: Int!) {
    workstation(id: $id) {
      id
      name
      enterpriseId
      locationId
      workstationTypeId
      active
    }
  }
`

export interface WorkstationInput extends Dictionary {
  id: number
  name?: string
  active?: boolean
}

export const useWorkstation = () => {
  const [id, setId] = useState<number | undefined>(undefined)

  const { loading, error, data } = useQuery(GET_WORKSTATION, {
    variables: { id },
  })

  const getWorkstation = (id: number) => {
    setId(id)
  }

  return { getWorkstation, loading, error, data }
}
