import React from 'react'
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  makeStyles,
  createStyles,
  Theme,
  Button,
} from '@material-ui/core'
import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-material-ui'
import SaveIcon from '@material-ui/icons/Save'
import CancelIcon from '@material-ui/icons/Cancel'
import * as Yup from 'yup'

import { useTranslation } from 'react-i18next'
import { Role } from './models'

type AddRoleProps = {
  open: boolean
  submit: (values: Role) => void
  cancel: () => void
  initialRole: Role
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      '& .MuiFormControl-root': {
        minWidth: '100%',
      },
    },
    dialog: {
      '& .MuiDialog-paper': {
        minWidth: 400,
      },
    },
  }),
)

export const AddRole = ({ open, submit, cancel, initialRole }: AddRoleProps) => {
  const classes = useStyles()

  // App Label Translation
  const { t: transLabel } = useTranslation('Label')
  const { t: transAction } = useTranslation('Action')

  const formSchema = Yup.object().shape({
    name: Yup.string().required(transLabel('REQUERIDO')),
  })

  return (
    <Dialog open={open} aria-labelledby="form-dialog-title" className={classes.dialog}>
      <DialogTitle id="form-dialog-title">{transLabel('CREAR_ROLE')}</DialogTitle>

      <Formik validationSchema={formSchema} initialValues={{ ...initialRole }} onSubmit={submit}>
        {({ submitForm, isSubmitting }) => (
          <Form className={classes.formControl}>
            <DialogContent>
              <Field component={TextField} name="name" type="text" label={transLabel('NOMBRE_ROLE')} />
            </DialogContent>
            <DialogActions>
              <Button onClick={cancel} color="inherit" startIcon={<CancelIcon />} size={'small'}>
                {transAction('CANCELAR')}
              </Button>
              <Button onClick={submitForm} color="primary" startIcon={<SaveIcon />} size={'small'}>
                {transAction('GUARDAR')}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}
