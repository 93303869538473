import moment from 'moment'

export const formatDate = (date: Date | undefined | null) => {
  if (!date) return ''

  var offset = moment().utcOffset()
  return moment.utc(date.toString()).utcOffset(offset).format('dddd, MMMM Do YYYY, h:mm:ss a')
}

export const getCurrentShortDateUs = () => {
  var offset = moment().utcOffset()
  return moment.utc(moment().toString()).utcOffset(offset).format('MM/DD/YYYY')
}

export const formatToShortDate = (date: Date | undefined | null | string) => {
  if (!date) return ''

  var offset = moment().utcOffset()
  return moment.utc(date.toString()).utcOffset(offset).format('DD/MM/YYYY')
}

export const formatToShortDateUs = (date: Date | undefined | null) => {
  if (!date) return ''

  var offset = moment().utcOffset()
  return moment.utc(date.toString()).utcOffset(offset).format('MM/DD/YYYY')
}

export const formatDateTime = (date: Date | undefined | null) => {
  if (!date) return ''

  var offset = moment().utcOffset()
  return moment.utc(date.toString()).utcOffset(offset).format('DD/MM/YYYY h:mm:ss')
}

export const isDateToday = (date: Date | string | undefined) => {
  return moment(date).isSame(new Date(), 'day')
}

export const isValidDate = (date: Date | string | undefined) => {
  return moment(date, moment.ISO_8601, true).isValid()
}

export const formatToShortLocalDate = (
  date: Date | undefined | null | string,
  format: string | undefined = 'MM/DD/YYYY',
) => {
  if (!date) return ''
  return moment.utc(date.toString()).format(format)
}

export const formatToShortDateUS = (
  date: Date | undefined | null | string,
  format: string | undefined = 'DD/MM/YYYY',
) => {
  if (!date) return ''
  return moment.utc(date.toString()).format(format)
}

export const getDayNumber = (date: Date | string | undefined) => {
  const localDate = moment(date, 'YYYY/MM/DD')
  return date === undefined ? '' : localDate.format('D')
}

export const getYearNumber = (date: Date | string | undefined) => {
  const localDate = moment(date, 'YYYY/MM/DD')
  return date === undefined ? '' : localDate.format('Y')
}

export const formatTime = (date: Date | undefined | null) => {
  if (!date) return ''

  return moment.utc(date.toString()).format('h:mm A')
}

export const dateIsBeforeThanToday = (date: Date | undefined | null): boolean => {
  if (!date) return true

  const shortDate = moment.utc(date.toString()).format('DD/MM/YYYY')
  const incomeDay = shortDate.split('/')[0]
  const todayNumber = moment().date()

  if (incomeDay) {
    return Number.parseFloat(incomeDay) < todayNumber
  }
  return true
}

export const dateIsBeforeThanTodayMoment = (date: Date | undefined | null): boolean => {
  if (!date) return true

  // const shortDate = moment.utc(date.toString()).format('DD/MM/YYYY')
  const shortDate = moment(date).utc()
  return moment(shortDate).isBefore(moment())
}

export const addMinutesToDateTimeUTC = (dateTime: Date | string, minsToAdd: number) => {
  return moment.utc(dateTime).add(minsToAdd, 'minutes').format()
}

export const formatDateTimeToUTC = (date: Date) => {
  if (!date) return ''

  return moment(date).utc().format('YYYY-MM-DD HH:mm')
}

// ****************** LOCAL DATE FUNCTIONS ******************** //

export const formatToLocalTime = (date: Date | undefined | null | string) => {
  if (!date) return ''

  return moment(date.toString()).format('h:mm a')
}

export const formatToLocalDateTime = (date: Date | undefined | null | string) => {
  if (!date) return ''

  return moment(date.toString()).format('DD/MM/YYYY h:mm:ss')
}

export const getLocalDate = () => {
  return moment().format('DD/MM/YYYY')
}

export const convertStringTimeToLocalDateTime = (stringDate: string) => {
  return moment(stringDate)
}
