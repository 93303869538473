import { Dictionary } from './../models/shared'
import { createSelector } from 'reselect'
import { AppBarState } from './reducer'

interface ClaimsWithAction {
  claims: string | number | Boolean | object[] | Date | undefined | string[]
  action: string
}

const selectAppBarState = (state: AppBarState): Dictionary => state.userClaims

const findClaimsWithAction = (state: AppBarState, module: string, action: string): ClaimsWithAction => {
  return {
    claims: state.userClaims[module],
    action,
  }
}

export const canDoesActionSelector = createSelector(findClaimsWithAction, (claimsWithAction) => {
  let hasPermission: boolean = false
  const { claims, action } = claimsWithAction
  if (!claims) {
    return false
  } else {
    if (Array.isArray(claims)) {
      const list = claims as []
      hasPermission = list.some((item: string) => item === action)
    } else {
      hasPermission = claims === action
    }
  }

  return hasPermission
})

export const isAdminSelector = createSelector(selectAppBarState, (claims): boolean => {
  let isAdmin: boolean = false
  if (claims !== undefined && Array.isArray(claims['role'])) {
    isAdmin = claims['role'].some((item: any) => item === 'administrator')
  } else {
    isAdmin = claims['role'] === 'administrator'
  }
  return isAdmin
})
